import { Card, CardBody, Input } from "reactstrap";
import styles from "./styles.module.scss";
import { DebouncedInput, Dropdown } from "../../Components/Form";
import { isEmpty } from "../../helpers/utils";
import { useEffect, useState } from "react";
import { default as ArrowLeft } from "../../assets/icons/arrow_left.svg?react";
import classnames from "classnames";
import { useBoolean } from "../../helpers/hooks";
import { getIconByDatastoreType } from "./DatastoreImage";
import { capitalize } from "lodash";
import { default as ArrowDown } from "../../assets/icons/arrow_down.svg?react";
import { default as FilterIcon } from "../../assets/icons/filter.svg?react";
import { ResourceName } from "../../Components/Tags";
import useFiltersBySearchParams from "@/helpers/useFiltersBySearchParams";

const TILE_LIST_ITEMS_LIMIT = 5;

const Tile = ({ label, onChange, options }) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const { value: isExpand, toggle } = useBoolean();
  useEffect(() => {
    setFilteredOptions(options);
  }, [options, setFilteredOptions]);

  if (isEmpty(options)) return null;
  return (
    <Card className={styles.card}>
      <div className="m-3">
        <div className="text-black fw-regular mb-2">{label}</div>
        <DebouncedInput
          size="sm"
          debounceDelay={100}
          placeholder="Search"
          onChange={(_s) => {
            const s = _s.toLowerCase();
            setFilteredOptions(
              options.filter(
                (item) => item.label.includes(s) || item.value.includes(s)
              )
            );
          }}
        />
      </div>
      <div className={styles.divider} />
      <ul className={classnames("m-3", styles.list)}>
        {(isExpand
          ? filteredOptions
          : filteredOptions.slice(0, TILE_LIST_ITEMS_LIMIT)
        ).map((item) => (
          <li
            key={item.label}
            onClick={(e) => {
              e.stopPropagation();
              onChange(item.value);
            }}
          >
            <div>
              <Input
                type="checkbox"
                checked={item.checked}
                className="me-2"
                readOnly
              />
              {item.label}
            </div>
            <div>{item.count}</div>
          </li>
        ))}
      </ul>
      {filteredOptions.length > TILE_LIST_ITEMS_LIMIT && (
        <button
          className={styles.show_more_button}
          onClick={(e) => {
            e.stopPropagation();
            toggle();
          }}
        >
          {isExpand ? "- Hide" : "+ Show"}
        </button>
      )}
    </Card>
  );
};

const Filter = ({
  datasourceType,
  onDatasourceTypeChange,
  datasources,
  onDatasourceChange,
  databases,
  onDatabaseChange,
  schemas,
  onSchemaChange,
  contractOptions,
  onContractFilter,
  resetFilter,
  tagOptions,
  onTagChange,
  recentlyAccessedTables,
}) => {
  const {searchParams, deleteSearchParamsByKeyValue, updateSearchParamsByKey} = useFiltersBySearchParams();
  
  const expandedParam = searchParams.get("expanded") || "true";
  const expanded = expandedParam === "true";

  const expandFilter = () => {
    deleteSearchParamsByKeyValue({ expanded: undefined });
  };
  const collapseFilter = () => {
    updateSearchParamsByKey({"expanded": "false"});
  };

  if (!expanded) {
    return (
      <div className={styles.filter_icon} onClick={expandFilter}>
        <FilterIcon />
      </div>
    );
  }

  return (
    <div className={styles.filter}>
      <Card>
        <CardBody>
          <div className="w-100 mb-3 d-flex justify-content-between align-items-baseline">
            <div className="text-black fs-5">Filter</div>
            <div className="d-flex align-items-center text-muted fs-6 cursor-pointer">
              <div
                className="me-2"
                onClick={(e) => {
                  e.stopPropagation();
                  resetFilter();
                }}
              >
                Reset
              </div>
              <div
                className={styles.collapse_button_open}
                onClick={collapseFilter}
              >
                <ArrowLeft />
              </div>
            </div>
          </div>
          <div className={styles.tile_container}>
            <Card className={styles.card}>
              <div className="m-3">
                <div className="text-black fw-regular">Datastore Type</div>
              </div>
              <div className={styles.divider} />
              <Dropdown
                id="datasource-type-dropdown"
                onChange={onDatasourceTypeChange}
                value={datasourceType}
                options={["s3", "postgres", "snowflake"].map((v) => ({
                  label: (
                    <div>
                      {getIconByDatastoreType(v, "me-2 icon-md")}
                      {capitalize(v)}
                    </div>
                  ),
                  value: v,
                }))}
                showDivider
              >
                <div className={styles.datasource_dropdown}>
                  {datasourceType === "" ? (
                    <div className="w-100 d-flex align-items-center justify-content-between">
                      <div>Select datastore type</div>
                      <div className={styles.icon}>
                        <ArrowDown />
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center gap-xs">
                      {getIconByDatastoreType(datasourceType, "icon-md")}
                      {capitalize(datasourceType)}
                    </div>
                  )}
                </div>
              </Dropdown>
            </Card>
            <Tile
              label="Datastores"
              options={datasources}
              onChange={onDatasourceChange}
            />
            <Tile
              label="Databases"
              options={databases}
              onChange={onDatabaseChange}
            />
            <Tile label="Schema" options={schemas} onChange={onSchemaChange} />
            {/* {contractOptions.length > 0 ? (
              <Tile
                label="Contracts"
                options={contractOptions}
                onChange={onContractFilter}
              />
            ) : (
              <Card className={styles.card}>
                <CardBody>
                  <div className="text-black fw-regular">Contracts</div>
                  <div className="text-muted mt-2">No contract set</div>
                </CardBody>
              </Card>
            )} */}
            <Tile
              label="Insights"
              options={tagOptions}
              onChange={onTagChange}
            />
            {recentlyAccessedTables && (
              <Card className={styles.card}>
                <div className="m-3">
                  <div className="text-black fw-regular">Recent Views</div>
                </div>
                <div className={styles.divider} />
                <div
                  className={classnames(
                    styles.recent_tables,
                    "d-flex flex-column"
                  )}
                >
                  {recentlyAccessedTables.map((res, idx) => (
                    <div
                      key={idx}
                      className={classnames(
                        styles.recent_table,
                        "d-flex text-muted gap-sm"
                      )}
                    >
                      <div>
                        {getIconByDatastoreType(
                          res.datasource_type,
                          styles.datastore_icon
                        )}
                      </div>
                      <ResourceName resource={res} />
                    </div>
                  ))}
                </div>
              </Card>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export { Filter };
