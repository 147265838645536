import React from "react";
import { Container, Button } from "reactstrap";
import pageNotFound from "../../assets/images/404.png";

export const SomethingWrong = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-not-found-wrapper">
            <div>
              <div className="sorry-text1">Sorry</div>
              <div className="sorry-text2 mt-2">
                Something went wrong on our end!
              </div>
              <div className="sorry-text3 mt-2">
                Please go back and try again or
              </div>
              <Button
                className="sorry-contact-us px-5 mt-4"
                onClick={() => {
                  window.open(
                    "https://form.jotform.com/230650839143455",
                    "_blank"
                  );
                }}
              >
                Contact US
              </Button>
            </div>
            <div>
              <img src={pageNotFound} alt="" />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};
