import { z } from "zod";

export const tenantInfoSchema = z.object({
  currency: z.string().default("USD"),
  frontendUrl: z.string(),
  teammatesEnabled: z.boolean(),
});

export type GetTenantInfoResponse = z.infer<typeof tenantInfoSchema>;

export const urlResponse = z.object({
  url: z.string(),
});

export type UrlResponse = z.infer<typeof urlResponse>;

export const GetOpportunitiesWarehouseFilterResponseSchema = z.object({
  label: z.string(),
  value: z.string(),
});

export type GetOpportunitiesWarehouseFilterResponse = z.infer<
  typeof GetOpportunitiesWarehouseFilterResponseSchema
>;

export const GetTableLastRefreshedResponseSchema = z.object({
  last_refreshed: z.string(),
});

export type GetTableLastRefreshedResponse = z.infer<
  typeof GetTableLastRefreshedResponseSchema
>;

export const permissionsFullSchema = z.object({
  uuid: z.string(),
  name: z.string(),
  title: z.string(),
  description: z.string(),
  group: z.string().nullable(),
});

export const rolePermissionFullSchema = z.object({
  role_name: z.string(),
  role_description: z.string(),
  role_title: z.string(),
  is_custom: z.boolean(),
  permission: z.array(permissionsFullSchema),
});

export const userLoginOutSchema = z.object({
  id: z.number().nullable(),
  email: z.string(),
  auth_token: z.string(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  is_onboarded: z.boolean(),
  tz: z.string().nullable(),
  lang: z.string().nullable(),
  uuid: z.string(),
  role_FK: rolePermissionFullSchema.nullable(),
});

export type UserLoginOutResponse = z.infer<typeof userLoginOutSchema>;
