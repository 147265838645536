import { useState, useEffect } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import styles from "./styles.module.scss";
import SliderCheck from "@/assets/icons/slider_check.svg";
import SliderCross from "@/assets/icons/slider_cross.svg";
import SliderCheckGray from "@/assets/icons/slider_check_gray.svg";
import SliderCrossGray from "@/assets/icons/slider_cross_gray.svg";

const Slider = ({ data, handleChange, currentValue }) => {
  const [selected, setSelected] = useState(0);

  const handleInputClick = (index) => {
    setSelected(index[1]);
    handleChange(index[1]);
  };

  const bars = Array.from({ length: 3 }, (_, i) => (
    <div
      key={i}
      className={`${styles.bar} ${i <= selected ? styles.active : ""}`}
    ></div>
  ));

  return (
    <div className={styles.slider}>
      <div className={styles.title}>{data[selected]}</div>
      <div className="d-flex align-items-center gap-xs">
        <div className={styles.barsContainer}>{bars}</div>
        <RangeSlider
          min={0}
          max={data.length - 1}
          step={1}
          value={[0, selected]}
          onInput={handleInputClick}
          className={"single_thumb"}
          defaultValue={[0, 50]}
          thumbsDisabled={[true, false]}
          rangeSlideDisabled={true}
        />
        {selected !== 0 ? (
          <div className="d-flex align-items-center">
            <img src={SliderCrossGray} className={styles.cross} />
            <img src={SliderCheckGray} className={styles.check} />
          </div>
        ) : (
          <div className={styles.spacer} />
        )}
      </div>
    </div>
  );
};

export { Slider };
