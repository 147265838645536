import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  BarChart,
  AreaChart,
  Tooltip,
  TooltipProps,
  PieChart,
} from "recharts";
import { ChartTypes } from "./types";
import { useState } from "react";
import classes from "./chart.module.scss";
import { AxisInterval } from "recharts/types/util/types";
import IconButton from "../iconButton/IconButton";
import { FullscreenIcon } from "@assets/icons";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Stack from "../stack/Stack";
import { yAxisLabelProps } from "./constants";

const ChartsByType = ({
  type,
  children,
  ...rest
}: { type: ChartTypes } & Record<string, unknown>) => {
  switch (type) {
    case ChartTypes.LineChart:
      return <LineChart {...rest}>{children}</LineChart>;
    case ChartTypes.BarChart:
      return <BarChart {...rest}>{children}</BarChart>;
    case ChartTypes.AreaChart:
      return <AreaChart {...rest}>{children}</AreaChart>;
    case ChartTypes.PieChart:
      return <PieChart {...rest}>{children}</PieChart>;
  }
};

const CustomYAxisTick = ({
  x,
  y,
  payload,
  yAxisLabelFormatter,
}: {
  x: number;
  y: number;
  payload: Record<string, string>;
  yAxisLabelFormatter?: (value: string) => string;
}) => (
  <g transform={`translate(${x},${y})`}>
    <text textAnchor="end" dy={5} fill="#66768D">
      {yAxisLabelFormatter ? yAxisLabelFormatter(payload.value) : payload.value}
    </text>
  </g>
);

const CustomXAxisTick = ({
  x,
  y,
  payload,
  xAxisLabelFormatter,
}: {
  x?: number;
  y?: number;
  payload?: Record<string, string>;
  xAxisLabelFormatter?: (value: string) => string;
}) =>
  payload ? (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-30}
        dy={4}
        transform="rotate(270)"
        textAnchor="middle"
        fill="#66768D"
      >
        {xAxisLabelFormatter
          ? xAxisLabelFormatter(payload.value)
          : payload.value}
      </text>
    </g>
  ) : null;

const getXAxisInterval = (length: number, interval?: AxisInterval) => {
  if (interval) {
    return interval;
  }
  return length > 100 ? Math.round(length / 10) : 0;
};

const ChartComponent = ({
  data,
  type,
  children,
  xAxisDataKey,
  yAxisId,
  yAxisLabel,
  xAxisLabelFormatter,
  yAxisLabelFormatter,
  tooltipProps,
  height = 400,
  xAxisInterval,
  chartProps,
}: Props) => {
  const [selectedLegend, setSelectedLegend] = useState<string | undefined>();
  const [barSize, setBarSize] = useState(7);
  const onLegendClick = (legend?: string) => {
    setSelectedLegend((prev) => (prev === legend ? "" : legend));
  };

  const onRender = (ref: HTMLDivElement) => {
    if (ref) {
      const width = ref.getBoundingClientRect().width;
      setBarSize(width / (data?.length || 1) - 10);
    }
  };

  return (
    <ResponsiveContainer width="100%" height="100%" ref={onRender}>
      <ChartsByType
        type={type}
        data={data}
        height={height}
        margin={{
          top: 25,
          bottom: 25,
          right: 5,
        }}
        {...chartProps}
      >
        {type === ChartTypes.PieChart ? null : (
          <>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey={xAxisDataKey}
              interval={getXAxisInterval((data || []).length, xAxisInterval)}
              tick={
                <CustomXAxisTick xAxisLabelFormatter={xAxisLabelFormatter} />
              }
            />
            <YAxis
              yAxisId={yAxisId}
              label={{
                ...yAxisLabelProps,
                ...yAxisLabel,
              }}
              tick={
                //   @ts-expect-error other values will be injected
                <CustomYAxisTick yAxisLabelFormatter={yAxisLabelFormatter} />
              }
            />
          </>
        )}
        <Tooltip {...tooltipProps} />

        <Legend
          align="center"
          verticalAlign="bottom"
          wrapperStyle={{ bottom: -20 }}
          onClick={(data) => onLegendClick(data.dataKey as string)}
          formatter={(value) => <span className={classes.legend}>{value}</span>}
        />
        {children?.({ barSize, selectedLegend })}
      </ChartsByType>
    </ResponsiveContainer>
  );
};

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any[];
  type: ChartTypes;
  children?: ({
    barSize,
    selectedLegend,
  }: {
    barSize: number;
    selectedLegend?: string;
  }) => JSX.Element;
  xAxisLabelFormatter?: (value: string) => string;
  yAxisLabelFormatter?: (value: string) => string;
  xAxisDataKey: string;
  yAxisId?: string;
  yAxisLabel?: object;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tooltipProps?: TooltipProps<any, any>;
  title: string;
  height?: number;
  xAxisInterval?: AxisInterval;
  hideFullscreenButton?: boolean;
  chartProps: Record<string, unknown>;
}

const Chart = (props: Props) => {
  const [fullscreen, setFullscreen] = useState(false);

  const handleFullScreenClick = () => {
    setFullscreen((prev) => !prev);
  };

  return (
    <>
      {fullscreen ? (
        <Modal isOpen={fullscreen} toggle={handleFullScreenClick} fullscreen>
          <ModalHeader toggle={handleFullScreenClick}>
            {props.title}
          </ModalHeader>
          <ModalBody>
            <ChartComponent {...props} />
          </ModalBody>
        </Modal>
      ) : null}
      <div className={classes.chart}>
        <ChartComponent {...props} />
        {props.hideFullscreenButton ? null : (
          <IconButton
            className={classes.fullscreen}
            onClick={handleFullScreenClick}
          >
            <FullscreenIcon />
          </IconButton>
        )}
        {props.data?.length ? null : (
          <Stack className={classes.no_data}>No data available</Stack>
        )}
      </div>
    </>
  );
};

export default Chart;
