import dayjs from "dayjs";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import styles from "./styles.module.scss";
import { Dropdown } from "../../Components/Form";
import { default as ArrowDown } from "../../assets/icons/arrow_down.svg?react";
import { useMemo } from "react";
import { uniqueId } from "lodash";
import { useAppState } from "@/modules/app/useAppContext";

const CustomYAxisTick = ({ x, y, payload }) => {
  const { currencySymbol } = useAppState();
  return (
    <g transform={`translate(${x},${y})`}>
      <text textAnchor="end" dy={5} fill="#66768D">
        {currencySymbol}
        {payload.value}
      </text>
    </g>
  );
};

const CustomXAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-24}
        dy={4}
        transform="rotate(270)"
        textAnchor="middle"
        fill="#66768D"
      >
        {dayjs(payload.value).format("MMM-DD")}
      </text>
    </g>
  );
};

const BarGraph = ({ graphData, colorsMap, yLabel, dataKeys }) => {
  return (
    <ResponsiveContainer minHeight={300}>
      <BarChart data={graphData}>
        <CartesianGrid vertical={false} />
        <Legend verticalAlign="bottom" wrapperStyle={{ bottom: -24 }} />
        <XAxis
          dataKey="date"
          angle={-90}
          label={{
            value: "Date",
            position: "insideBottomRight",
            fill: "black",
          }}
          tick={<CustomXAxisTick />}
          interval={0}
          padding={{ right: 36 }}
        />
        <YAxis
          label={{
            value: yLabel,
            position: "insideTopLeft",
            fill: "black",
            offset: 0,
          }}
          padding={{ top: 20 }}
          tick={<CustomYAxisTick />}
        />
        <Tooltip />
        {dataKeys.map((item) => (
          <Bar
            key={item}
            barSize={8}
            dataKey={item}
            fill={colorsMap[item]}
            stackId="single"
            shape={(props) => {
              props.radius = [5, 5, 0, 0];
              return <Rectangle {...props} />;
            }}
          />
        ))}
      </BarChart>
    </ResponsiveContainer>
  );
};

const TimeFrameSelectorDropdown = ({ options, period, setPeriod }) => {
  const getLabelByPeriod = (period) => {
    return options.find((p) => p.value === period).label;
  };
  const id = useMemo(() => uniqueId("graph-period-dropdown-"), []);

  return (
    <Dropdown
      id={id}
      onChange={setPeriod}
      value={period}
      options={options.map((p) => ({
        label: <div>{p.label}</div>,
        value: p.value,
      }))}
      showDivider
    >
      <div className={styles.graph_dropdown}>
        <div className={styles.graph_label}>{getLabelByPeriod(period)}</div>
        <div className={styles.arrow_down}>
          <ArrowDown />
        </div>
      </div>
    </Dropdown>
  );
};

export { BarGraph, TimeFrameSelectorDropdown };
