import styles from "./styles.module.scss";
import { useState } from "react";
import { Button, Input } from "reactstrap";
import classnames from "classnames";
import { Alert } from "reactstrap";
import eyeOn from "../../assets/icons/eye_on.svg";
import eyeOff from "../../assets/icons/eye_off.svg";

export const FormInput = (props) => (
  <Input className={styles.form_input} {...props} />
);

export const FormPassword = (props) => {
  const [passwordShow, setPasswordShow] = useState(false);
  return (
    <div className="d-flex align-items-center position-relative">
      <FormInput type={passwordShow ? "text" : "password"} {...props} />
      <img
        onClick={() => setPasswordShow((b) => !b)}
        src={passwordShow ? eyeOff : eyeOn}
        alt=""
        className={styles.password_eye}
      />
    </div>
  );
};

export const FormAlert = ({ error }) => {
  return (
    <Alert
      color="danger"
      className={classnames(
        "border-0 text-black mt-3 mb-3",
        !error ? "d-none" : "d-block"
      )}
    >
      {error}
    </Alert>
  );
};

export const FormSubmit = ({ children, ...props }) => {
  return (
    <Button
      color="primary"
      className={classnames(styles.form_submit)}
      type="submit"
      {...props}
    >
      {children}
    </Button>
  );
};

export const FormCancel = ({ ...props }) => {
  return (
    <Button outline className={styles.cancel_button} {...props}>
      Cancel
    </Button>
  );
};
