import {
  AssistedSavingsResponse,
  AutoTuneSavingsSummaryResponse,
  GetSummaryOpportunitiesDataResponse,
  OpportunitiesSummary,
  OverallSavingsEstimateResponse,
  OverallSavingsResponse,
  SummaryCostGraphRequest,
} from "../pages/Summary/Context/types";
import {
  FilterResponse,
  GetExportLineageItem,
  GetOpportunitiesDataRequest,
  GetOpportunitiesDataResponse,
  GetOpportunitiesRedirectionRequest,
  GetOpportunitiesRedirectResponse,
  GetOpportunitiesResourceNameFilterRequest,
  GetOpportunitiesResourceNameFilterResponse,
  GetOpportunitiesResourceRequest,
  GetOpportunitiesResourceResponse,
  GetOpportunitiesSummaryRequest,
  GetOpportunitiesSummaryResponse,
  GetOpportunitiesUserFilterRequest,
  GetOpportunitiesUserFilterResponse,
  GetOpportunitiesWarehouseFilterRequest,
  GetOpportunityUpdatesRequest,
  GetOpportunityUpdatesResponse,
  GetQuerySQLLineageResponse,
  Page,
  UpdateOpportunitiesDataRequest,
  UpdateOpportunitiesDataResponse,
} from "../pages/Explorer/types";
import { QueryTextResponse } from "../pages/Query/types";

import { APIClient } from "./api_helper";
import {
  GetOpportunitiesWarehouseFilterResponse,
  GetOpportunitiesWarehouseFilterResponseSchema,
  GetTableLastRefreshedResponse,
  GetTableLastRefreshedResponseSchema,
  GetTenantInfoResponse,
  tenantInfoSchema,
  UrlResponse,
  urlResponse,
  UserLoginOutResponse,
  userLoginOutSchema,
} from "./types";
import { FilterType, SavedFilter, savedFilterSchema } from "@/Components/Tags/types";
import { z } from "zod";

export const api = new APIClient();

// Summary v2
export const getCostBreakdown = <R>(
  path: string,
  params: SummaryCostGraphRequest
) => api.get<R>(`/summary/v2/cost/${path}`, params);

export const getSavingsBreakdown = (
  path: string,
  params: SummaryCostGraphRequest
) =>
  api.get<
    | AutoTuneSavingsSummaryResponse
    | OverallSavingsResponse
    | OverallSavingsEstimateResponse
    | AssistedSavingsResponse
  >(`/summary/v2/savings/${path}`, params);

export const getSummaryPageOpportunities = (params: OpportunitiesSummary) =>
  api.get<GetSummaryOpportunitiesDataResponse[]>(
    "/summary/v2/opportunities",
    params
  );

export const getOpportunities = (data: GetOpportunitiesDataRequest) =>
  api.get<Page<GetOpportunitiesDataResponse>>("/opportunities", data);

export const getGroupedOpportunities = (data: GetOpportunitiesDataRequest) =>
  api.get<Page<GetOpportunitiesDataResponse>>("/opportunities/grouped", data);

export const updateOpportunities = (data: UpdateOpportunitiesDataRequest) =>
  api.post<UpdateOpportunitiesDataResponse>("/opportunities/", data);

export const getOpportunitiesSummary = (data: GetOpportunitiesSummaryRequest) =>
  api.get<GetOpportunitiesSummaryResponse[]>("/opportunities/summary", data);

export const getOpportunitiesResourceNameFilters = (
  data: GetOpportunitiesResourceNameFilterRequest
) =>
  api.get<GetOpportunitiesResourceNameFilterResponse[]>(
    "/opportunities/resource_name_filter",
    data
  );

export const getOpportunitiesUserFilters = (
  data: GetOpportunitiesUserFilterRequest
) =>
  api.get<GetOpportunitiesUserFilterResponse[]>(
    "/opportunities/filters/user",
    data
  );

export const getOpportunitiesWarehouseFilters = (
  data: GetOpportunitiesWarehouseFilterRequest
) =>
  api.get<GetOpportunitiesWarehouseFilterResponse[]>(
    "/opportunities/filters/warehouse",
    data,
    10000,
    GetOpportunitiesWarehouseFilterResponseSchema
  );

export const getOpportunityNameFilters = () =>
  api.get<FilterResponse[]>("/opportunities/filters/opportunity_name", {});

export const getOpportunitiesResource = (
  data: GetOpportunitiesResourceRequest
) => api.get<GetOpportunitiesResourceResponse>("/opportunities/resource", data);

export const getOpportunitiesRedirection = (
  data: GetOpportunitiesRedirectionRequest
) =>
  api.get<GetOpportunitiesRedirectResponse>("/opportunities/redirection", data);

export const getOpportunityUpdates = (data: GetOpportunityUpdatesRequest) =>
  api.get<GetOpportunityUpdatesResponse[]>("/opportunities/updates", data);

export const getQuerySqlLineageById = (
  id: string,
  params: Record<string, string>
) =>
  api.get<GetQuerySQLLineageResponse>(
    `/query/v2/sql_lineage/${id}`,
    params,
    60000
  );

export const getQueryGroupSqlLineageById = (
  id: string,
  params: Record<string, string>
) =>
  api.get<GetQuerySQLLineageResponse>(
    `/query/v2/sql_lineage_group/${id}`,
    params,
    60000
  );

export const getQueryGroupDetailsByHash = <T>(
  hash: string,
  params: Record<string, string>
) => api.get<T>(`/query/v2/query_group/stats/${hash}`, params);

export const getQueryTextByHash = (hash: string) =>
  api.get<QueryTextResponse>(`/query/v2/query_group/query_text/${hash}`, {});

export const getTenantInfo = () =>
  api.get<GetTenantInfoResponse>(
    `/auth/tenant-info`,
    {},
    10000,
    tenantInfoSchema
  );

export const getSSOUserInfo = () =>
  api.get<{ email?: string }>("/auth/supertokens_user_info", {});

export const postRegisterSSO = (data: {
  firstName: string;
  lastName: string;
}) => api.post("/auth/register-sso", data);
export const updateTenantInfo = (data: Partial<GetTenantInfoResponse>) =>
  api.update<{ ok: boolean }>(`/auth/tenant-info`, data, tenantInfoSchema);

export const postSendDatamateRequest = (requestType: string) =>
  api.post<{ ok: boolean }>("/notification/send_datamate_request", {
    request_type: requestType,
  });

export const getExportLineage = (params: { page: number; size: number }) =>
  api.get<Page<GetExportLineageItem>>(`/dbt/v4/export-lineage`, params);

export const getExportLineageItem = (id: string) =>
  api.get<GetExportLineageItem>(`/dbt/v4/export-lineage/${id}`, {});

export const getDownloadUrl = (s3_path: string) =>
  api.post<UrlResponse>(
    "/dbt/v1/download_signed_url",
    { s3_path },
    urlResponse
  );

  export const getSavedFilters = (data: {
    applicableFor: string;
    type: FilterType;
  }) => {
    return api.get<{presets: SavedFilter[]}>(
      `/preset`,
      { applicable_for: data.applicableFor, type: data.type },
      0,
      z.object({presets: z.array(savedFilterSchema.extend({id: z.unknown()}))})
    );
  };

  export const createFilter = (data: Omit<SavedFilter, "id">) => {
    return api.post<{
      preset_id: string;
    }>(`/preset`, data);
  };

  export const updateFilter = (data: SavedFilter) => {
    return api.update<{
      preset_id: string;
    }>(`/preset/${data.id}`, data);
  };

  export const deleteFilter = (id: string) => {
    return api.delete(`/preset/${id}`, {});
  };

export const getLastTableRefreshedTime = (table_rk: string) =>
  api.get<GetTableLastRefreshedResponse>(
    `/tables/last_refreshed/?table_rk=${table_rk}`,
    {},
    10000,
    GetTableLastRefreshedResponseSchema
  );
  
export const regenerateToken = () =>
  api.post<UserLoginOutResponse>(
    "/auth/regenerate_token",
    undefined,
    userLoginOutSchema
  );
