import styles from "./styles.module.scss";
import {
  DatasetsIcon,
  QueryGroupIcon,
  WarehouseIcon,
  UserIcon,
  RoleIcon,
  ClockBlackIcon,
} from "@/assets/icons";

const resourceImageMap: Record<string, any> = {
  datasets: <DatasetsIcon />,
  query_group: <QueryGroupIcon />,
  query_hash: <QueryGroupIcon />,
  query: <QueryGroupIcon />,
  warehouse: <WarehouseIcon />,
  user: <UserIcon />,
  role: <RoleIcon />,
};

const blueResourceImageMap: Record<string, any> = {
  datasets: <DatasetsIcon />,
  query_group: <QueryGroupIcon />,
  query_hash: <QueryGroupIcon />,
  query: <QueryGroupIcon />,
  warehouse: <WarehouseIcon />,
  user: <UserIcon />,
  role: <RoleIcon />,
};

const defaultImage = <ClockBlackIcon />;

export const getResourceImageByType = (type: string): JSX.Element => {
  const imageSrc = resourceImageMap[type] || defaultImage;
  const altText =
    type === "query_group"
      ? "QRY"
      : type === "query_hash"
      ? "QRY"
      : type === "query"
      ? "QRY"
      : type === "datasets"
      ? "TBL"
      : type === "user"
      ? "USR"
      : type === "role"
      ? "ROL"
      : type === "warehouse"
      ? "WRH"
      : "";

  return <img src={imageSrc} alt={altText} />;
};

export const getNamedResourceImageByType = (type: string): JSX.Element => {
  const imageSrc = blueResourceImageMap[type] || defaultImage;
  const altText =
    type === "query_group"
      ? "QRY"
      : type === "query_hash"
      ? "QRY"
      : type === "query"
      ? "QRY"
      : type === "datasets"
      ? "TBL"
      : type === "user"
      ? "USR"
      : type === "role"
      ? "ROL"
      : type === "warehouse"
      ? "WRH"
      : "";

  return (
    <div className="d-flex flex-column" style={{ color: "#247EFE" }}>
      {imageSrc}
      <div className={styles.resourceImageText}>{altText}</div>
    </div>
  );
};
