import React from "react";
import { createPortal } from "react-dom";
import "./SidebarModal.css";
import closeButton from "../../assets/icons/x-close.svg";

const sidebarElem = document.getElementById("sidebar");

const SidebarModal = ({
  isOpen,
  toggleModal,
  width = 350,
  children,
  bgColor = "white",
}) => {
  return createPortal(
    <div
      className="sidebar-modal"
      style={{
        width: `${width}px`,
        right: `-${width}px`,
        transform: isOpen ? `translateX(-${width}px)` : ``,
        backgroundColor: bgColor === "gray" ? `#F3F4F5` : `white`,
      }}
    >
      {isOpen && (
        <>
          <div className="sidebar-close-button" onClick={toggleModal}>
            <img src={closeButton} alt="close_button" />
          </div>
          <div className="sidebar-background-screen" onClick={toggleModal} />
          <div className="sidebar-modal-content">{children}</div>
        </>
      )}
    </div>,
    sidebarElem
  );
};

export default SidebarModal;
