import {
  Chart,
  Stack,
  Tabs,
  Tile,
  ChartTypes,
  Card,
  NumberFormatter,
  formatNumber,
} from "@uicore";
import { useQuery } from "react-query";
import { useState, useEffect, useMemo } from "react";
import classes from "../summary.module.scss";
import { Bar, TooltipProps } from "recharts";
import AggregateFilter from "./AggregateFilter";
import BreakdownSkeleton from "./BreakdownSkeleton";
import { TotalCostEntities, BarColors, AggregateByValues } from "./constants";
import { getCostBreakdown } from "@/helpers/apis";
import {
  AggregatedComputeGraphResponse,
  AggregatedStorageGraphResponse,
  OverallCostSummaryResponse,
  TotalCosts,
} from "../Context/types";
import { formatDate, getKey } from "./utils";
import AccordionTitle from "./AccordionTitle";
import FailureComponent from "./FailureComponent";
import CostChartTooltip from "./CostChartTooltip";
import dayjs from "dayjs";
import { useAppState } from "@/modules/app/useAppContext";
import {
  getDateByEndOfDayWithoutTimeoffset,
  getDateByStartOfDayWithoutTimeoffset,
} from "@/Components/DateRange/utils";
import SummaryChatButton from "./SummaryChatButton";
import { useSearchParams } from "react-router-dom";
import { TaskLabels } from "@components";

interface Props {
  isFuture?: boolean;
  currentStartDate: Date;
  currentEndDate: Date | null;
}

const CostBreakdown = ({
  isFuture,
  currentStartDate,
  currentEndDate,
}: Props) => {
  const [searchParams] = useSearchParams();
  const { currency } = useAppState();
  const [costType, setCostType] = useState("overall");
  const [aggregateBy, setAggregateBy] = useState(
    isFuture ? AggregateByValues.Monthly : AggregateByValues.Daily
  );
  const yearMode = aggregateBy === AggregateByValues.Monthly;
  const onAggregateSelect = (value: string) => {
    setAggregateBy(value);
  };

  const getEndPoint = () => {
    if (isFuture) {
      return `${costType}/estimate`;
    }

    return costType;
  };

  const { isLoading, isFetching, data, error, refetch } = useQuery({
    queryKey: [
      `costBreakdown-${isFuture ? "future" : "past"}`,
      currentStartDate,
      currentEndDate,
      aggregateBy,
    ],
    queryFn: () =>
      getCostBreakdown<
        | OverallCostSummaryResponse
        | AggregatedStorageGraphResponse
        | AggregatedComputeGraphResponse
      >(getEndPoint(), {
        start_date:
          getDateByStartOfDayWithoutTimeoffset(currentStartDate).toISOString(),
        end_date: currentEndDate
          ? getDateByEndOfDayWithoutTimeoffset(currentEndDate)?.toISOString()
          : undefined,
        aggregation_level: aggregateBy,
      }),
    enabled: Boolean(currentStartDate && currentEndDate),
    onError(err) {
      window.posthog.capture("costBreakdownFailed", { error: err });
    },
  });

  const { data: totalCosts, refetch: refetchTotalCosts } = useQuery({
    queryKey: [
      `totalcosts-${isFuture ? "future" : "past"}`,
      currentStartDate,
      currentEndDate,
      aggregateBy,
    ],
    queryFn: () =>
      getCostBreakdown<TotalCosts>(isFuture ? `total/estimate` : "total", {
        start_date:
          getDateByStartOfDayWithoutTimeoffset(currentStartDate).toISOString(),
        end_date:
          getDateByEndOfDayWithoutTimeoffset(currentEndDate)?.toISOString(),
        aggregation_level: aggregateBy,
      }),
    enabled: Boolean(currentStartDate && currentEndDate),
    onError(err) {
      window.posthog.capture("costBreakdownFailed", { error: err });
    },
  });

  useEffect(() => {
    refetchTotalCosts();
  }, [refetchTotalCosts, currentStartDate, currentEndDate]);

  useEffect(() => {
    refetch();
  }, [costType, refetch, aggregateBy, currentStartDate, currentEndDate]);

  const chartsData = useMemo(() => {
    if (!isFuture) {
      return data?.graph;
    }
    // future cost graph has only monthly aggregation
    // For current month, we need to set projected amount to be difference so the graph bar will render properly
    return data?.graph.map((item) => {
      const isCurrentMonth = dayjs().isSame(item.date, "month");
      if (!isCurrentMonth) {
        return item;
      }

      const fields =
        TotalCostEntities[costType as keyof typeof TotalCostEntities].fields;

      return {
        ...item,
        ...fields.reduce((acc: Partial<typeof item>, f) => {
          const keys = f.key;
          // @ts-expect-error valid keys
          acc[keys.estimate] = item[keys.estimate] - item[keys.current];
          return acc;
        }, {}),
      };
    });
  }, [isFuture, data?.graph, costType]);

  const costBar = (props: {
    d: (typeof TotalCostEntities.overall.fields)[0];
    barSize: number;
    selectedLegend: string | null | undefined;
    isFuture: boolean;
    data?: any[];
    isLast: boolean;
  }) => {
    const { d, barSize, selectedLegend } = props;
    const currentKey = getKey(d, false);
    const futureKey = getKey(d, true);

    // Since estimate legend has different name, we need to parse the string and modify to have estimate key
    const legend = selectedLegend?.includes(" (projected)")
      ? `${selectedLegend.replace(" (projected)", "")}_estimate`
      : selectedLegend;

    return (
      <>
        <Bar
          key={currentKey}
          barSize={barSize}
          name={d.label}
          hide={Boolean(legend && legend !== currentKey)}
          dataKey={currentKey}
          fill={BarColors[currentKey as keyof typeof BarColors]}
          stackId="single"
          radius={!isFuture && props.isLast ? [7, 7, 0, 0] : [0, 0, 0, 0]}
        />
        {/* show predictions only for future state */}
        {isFuture ? (
          <Bar
            key={futureKey}
            barSize={barSize}
            name={`${d.label} (projected)`}
            hide={Boolean(legend && legend !== futureKey)}
            dataKey={futureKey}
            fill={BarColors[futureKey as keyof typeof BarColors]}
            stackId="single"
            radius={[3, 3, 0, 0]}
          />
        ) : null}
      </>
    );
  };

  if (isLoading) {
    return <BreakdownSkeleton type="section" />;
  }

  return (
    <Card className={classes.section_card}>
      <AccordionTitle
        title={<h4 className="text-black">Costs</h4>}
        howToText="How to read the charts"
        howToContent={
          <p>
            {isFuture
              ? "These charts show the cost data for this year - split between already occurred costs and projected costs. If you hover over, you will see change % and breakdown no.s (if applicable). Don't miss the DataPilot icon in the top right corner of the chart to start talking with the data!"
              : "These charts show the cost data for the period specified for Current state. Charts can aggregate data at daily, weekly or monthly level. If you hover over, you will see change % and breakdown no.s (if applicable). Don't miss the DataPilot icon in the top right corner of the chart to start talking with the data!"}
          </p>
        }
      />
      <Stack>
        {error ? (
          <FailureComponent
            title={"Failed to load cost breakdown. Please try again later."}
          />
        ) : (
          <>
            <div className={classes.tileWrap}>
              <Tile
                color="#FF754C"
                title="Total Costs"
                helpText={
                  isFuture
                    ? "Projected total Snowflake costs during this year"
                    : `Snowflake costs during the period selected: ${formatDate(
                        currentStartDate,
                        yearMode
                      )} to ${formatDate(currentEndDate, yearMode)}`
                }
                value={
                  <NumberFormatter
                    value={totalCosts?.total}
                    options={{ currency }}
                  />
                }
              >
                <Stack direction="column">
                  <Tile
                    title="Compute"
                    value={
                      <NumberFormatter
                        value={totalCosts?.compute}
                        options={{ currency }}
                      />
                    }
                  />
                  <Tile
                    title="Storage"
                    value={
                      <NumberFormatter
                        value={totalCosts?.storage}
                        options={{ currency }}
                      />
                    }
                  />
                  <Tile
                    title="Serverless"
                    value={
                      <NumberFormatter
                        value={totalCosts?.serverless}
                        options={{ currency }}
                      />
                    }
                  />
                  <Tile
                    title="Cloud Services"
                    value={
                      <NumberFormatter
                        value={totalCosts?.cloud_services}
                        options={{ currency }}
                      />
                    }
                  />
                </Stack>
              </Tile>
            </div>
            <div className={classes.graphs}>
              <Stack className="justify-content-between">
                <Tabs
                  items={Object.entries(TotalCostEntities.overall.fields).map(
                    ([_k, v]) => {
                      const label = v.label.replace("_", " ");
                      const value = v.tabKey || getKey(v, false);
                      return {
                        label,
                        value,
                      };
                    }
                  )}
                  selectedTab={costType}
                  onTabSelect={setCostType}
                />
                <Stack className="align-items-center">
                  <AggregateFilter
                    onSelect={onAggregateSelect}
                    aggregateBy={aggregateBy}
                    isFuture={isFuture}
                    startDate={currentStartDate}
                    endDate={currentEndDate}
                  />
                  <SummaryChatButton
                    chartType={isFuture ? "future" : "current"}
                    aggregation={aggregateBy}
                    analysisType="cost"
                    startDate={currentStartDate}
                    endDate={currentEndDate}
                    costType={costType}
                    isOpen={
                      !isFuture &&
                      searchParams.get("ref") === TaskLabels.ChartBot
                    }
                  />
                </Stack>
              </Stack>
              <div style={{ width: "100%", height: 400, padding: "1rem" }}>
                {isFetching ? (
                  <BreakdownSkeleton type="chart" />
                ) : (
                  <Chart
                    title="Cost Breakdown"
                    xAxisDataKey="date"
                    yAxisLabel={{
                      value: "Cost",
                    }}
                    xAxisLabelFormatter={(value) =>
                      `${formatDate(value, yearMode)}`
                    }
                    yAxisLabelFormatter={(value) =>
                      `${formatNumber(value, {
                        currency,
                        maximumFractionDigits: 2,
                      })}`
                    }
                    type={ChartTypes.BarChart}
                    data={chartsData}
                    tooltipProps={{
                      content: (props: TooltipProps<any, any>) => (
                        <CostChartTooltip
                          {...props}
                          timeRange={aggregateBy as "day" | "week" | "month"}
                          isFuture={isFuture}
                          type={costType}
                        />
                      ),
                    }}
                    chartProps={
                      costType === "cloud_services"
                        ? { stackOffset: "sign" }
                        : {}
                    }
                  >
                    {({ barSize, selectedLegend }) => {
                      const fields = isFuture
                        ? [
                            TotalCostEntities[
                              costType as keyof typeof TotalCostEntities
                            ].fields.find((d) => d.isTotal),
                          ]
                        : TotalCostEntities[
                            costType as keyof typeof TotalCostEntities
                          ].fields.filter((d) => !d.isTotal);
                      return (
                        <>
                          {fields?.map((d, index) => {
                            if (!d) {
                              return null;
                            }
                            return (
                              <>
                                {costBar({
                                  d,
                                  barSize,
                                  selectedLegend,
                                  isFuture: Boolean(isFuture),
                                  data: chartsData,
                                  isLast: index === fields.length - 1,
                                })}
                              </>
                            );
                          })}
                        </>
                      );
                    }}
                  </Chart>
                )}
              </div>
            </div>
          </>
        )}
      </Stack>
    </Card>
  );
};

export default CostBreakdown;
