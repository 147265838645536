import { Stack, Card, CardTitle, NumberFormatter, IconButton } from "@uicore";
import { AssistedSavingsResponse } from "../Context/types";
import classes from "../summary.module.scss";
import classNames from "classnames";
import {
  ClockResponsiveIcon,
  DollarIcon,
  InfoIcon,
  LightBulbIcon,
} from "@/assets/icons";
import { useAppState } from "@/modules/app/useAppContext";

const AssistedSavingsSection = ({
  opportunities,
  time_saved,
  total_assisted_savings,
}: AssistedSavingsResponse) => {
  const {currency} = useAppState()
  return (
    <Stack direction="column" className="h-100">
      <h5>Savings</h5>
      <Stack className="flex-1">
        <Card
          className={classNames(classes.assistedSavingsCard, classes.green)}
        >
          <CardTitle>
            <i><DollarIcon /></i>
            <span>Money Savings</span>{" "}
            <IconButton title="Money saved during the period when users implemented the opportunities identified by the product.">
              <InfoIcon />
            </IconButton>
          </CardTitle>
          <p>
            <NumberFormatter
              value={total_assisted_savings}
              options={{ currency }}
            />
          </p>
        </Card>
        <Card className={classNames(classes.assistedSavingsCard, classes.blue)}>
          <CardTitle>
            <i><ClockResponsiveIcon /></i>
            <span>Time Savings</span>
            <IconButton title="Time saved during the period when users implemented the opportunities identified by the product.">
              <InfoIcon />
            </IconButton>
          </CardTitle>
          <p>{time_saved}</p>
        </Card>
        <Card className={classNames(classes.assistedSavingsCard, classes.warn)}>
          <CardTitle>
            <i><LightBulbIcon /></i>
            <span>Opportunities</span>
            <IconButton title="No. of money or time savings opportunities implemented during the period">
              <InfoIcon />
            </IconButton>
          </CardTitle>
          <p>{opportunities}</p>
        </Card>
      </Stack>
    </Stack>
  );
};

export default AssistedSavingsSection;
