import { z } from "zod";

export enum FilterType {
  FILTER = "filter",
  COLUMN_PICKER = "column_picker",
}

export enum FilterApplicableFor {
  WAREHOUSE_QUERIES = "warehouse_queries",
  DBT_MODELS = "dbt_models",
  AUTOTUNE_JOBS = "autotune_jobs",
  JOBS = "jobs",
  QUERY_GROUP = "query_group",
  GOVERNANCE_ROLES = "governance_roles",
  GOVERNANCE_USERS = "governance_users",
  WAREHOUSES = "warehouses",
  DATASET_LIST = "datasetslist",
  DATASETS = "datasets",
  QUERY = "query",
  DISCOVER = "discover",
}

export interface Filter {
  filterData?: Record<string, unknown>;
  filterStr: string | string[];
  label: string;
  onclose?: (removedFilter?: string | string[]) => void;
  searchKey?: string;
  bgColor?: string;
}

export const savedFilterSchema = z.object({
  name: z
    .string()
    .max(100, { message: "Name must not exceed 100 characters" })
    .min(1, { message: "Name is required" }),
  data: z
    .record(z.string(), z.unknown())
    .refine((data) => Object.keys(data).length > 0, {
      message: "At least one filter must be selected",
    }),
  applicable_for: z.enum(
    Object.values(FilterApplicableFor) as [string, ...string[]]
  ),
  type: z.enum(Object.values(FilterType) as [string, ...string[]]),
});

export type SavedFilter = z.infer<typeof savedFilterSchema> & { id: string };
