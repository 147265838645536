import {
  Button,
  Card,
  CardBody,
  CardTitle,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { BetterPopover } from "../Popover";
import { withZodSchema } from "formik-validator-zod";
import { Field, FieldProps, Form, Formik } from "formik";
import {
  savedFilterSchema,
  Filter,
  FilterApplicableFor,
  FilterType,
  SavedFilter,
} from "./types";
import { FilterTag } from ".";
import { Stack } from "@/uiCore";
import { flattenFilters } from "./utils";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMutation } from "react-query";
import { createFilter } from "@/helpers/apis";
import { LoadingButton } from "@/lib/altimate/altimate-components";
import { toast } from "react-toastify";

interface Props {
  applicableFor: FilterApplicableFor;
  filterData: Record<string, any>;
  filters: Record<string, Filter>;
}
const SaveFilters = ({ applicableFor, filters: availableFilters }: Props) => {
  const ref = useRef<HTMLButtonElement | null>(null);
  const [filters, setFilters] = useState(availableFilters);

  useEffect(() => {
    setFilters(availableFilters);
  }, [availableFilters]);

  const flatFilters = useMemo(() => flattenFilters(filters), [filters]);

  const { mutate, isLoading } = useMutation(createFilter, {
    onSuccess: (resp) => {
      if (resp?.preset_id) {
        toast("Filter successfully saved!");
        const event = new CustomEvent("showFilter", {
          detail: resp?.preset_id,
        });
        document.dispatchEvent(event);
        ref.current?.click();
      }
    },
    onError: (error) => {
      toast.error(
        (error as Error | undefined)?.message || "Failed to save filter"
      );
    },
  });

  const onSubmit = (data: Omit<SavedFilter, "id">) => {
    mutate(data);
  };

  const handleFilterDelete = (removedFilter: string, filter: Filter) => {
    setFilters((prevFilters) => {
      return Object.entries(prevFilters).reduce(
        (acc: Record<string, Filter>, [key, value]) => {
          let clone: Filter | undefined = { ...value };
          if (value.label === filter.label) {
            // If filterStr is array, remove the removedFilter from the array
            if (Array.isArray(value.filterStr)) {
              clone.filterStr = value.filterStr.filter(
                (filterStr) => filterStr !== removedFilter
              );
              // If filterStr is string or custom data, remove the filter
            } else if (
              value.filterData ||
              typeof value.filterStr === "string"
            ) {
              clone = undefined;
            }
          }
          if (clone) {
            acc[key] = clone;
          }
          return acc;
        },
        {}
      );
    });
  };

  return (
    <>
      <Button color="link" id="save-filter" innerRef={ref}>
        Save filter
      </Button>
      <BetterPopover
        target={`save-filter`}
        hideArrow
        placement="bottom-end"
        containerClass=""
      >
        {({ close }) => (
          <Card className="m-0" style={{ width: 480 }}>
            <CardTitle className="px-3 pt-2 mb-0">Save filter</CardTitle>
            <CardBody>
              <Formik
                initialValues={{
                  name: "",
                  data: {},
                  applicable_for: applicableFor,
                  type: FilterType.FILTER,
                }}
                onSubmit={onSubmit}
                validate={withZodSchema(savedFilterSchema)}
              >
                {({ setFieldValue, errors, isValid }) => {
                  useEffect(() => {
                    const newData = Object.entries(filters).reduce(
                      (acc: Record<string, unknown>, [, filter]) => {
                        if (filter.filterData) {
                          Object.assign(acc, filter.filterData);
                          return acc;
                        }
                        if (!filter.searchKey) {
                          return acc;
                        }
                        const filterStr = filter.filterStr;

                        // If filter value is string
                        // Or if it is array, should contain atleast 1 value
                        if (
                          filterStr &&
                          (!Array.isArray(filterStr) || filterStr.length)
                        ) {
                          acc[filter.searchKey] = filterStr;
                        }
                        return acc;
                      },
                      {}
                    );
                    setFieldValue("data", newData);
                  }, [filters]);

                  return (
                    <Form>
                      <Field
                        name="name"
                        render={({ field }: FieldProps) => (
                          <FormGroup>
                            <Label>Filter Name</Label>
                            <Input
                              {...field}
                              placeholder="Name of the filter"
                              invalid={!!errors.name}
                            />
                            {errors.name ? (
                              <FormFeedback>{errors.name}</FormFeedback>
                            ) : null}
                          </FormGroup>
                        )}
                      />

                      <Stack className="flex-wrap">
                        {flatFilters.map((filter, index) => {
                          const key = filter.filterStr
                            ? Array.isArray(filter.filterStr)
                              ? filter.filterStr.join()
                              : filter.filterStr
                            : index;
                          return (
                            <FilterTag
                              key={key}
                              searchKey={undefined} // set as undefined to not remove from url params in save filters
                              label={filter.label}
                              filterStr={filter.filterStr}
                              onClose={(removedFilter: string) => {
                                handleFilterDelete(removedFilter, filter);
                              }}
                              bgColor={filter.bgColor || "grey"}
                            />
                          );
                        })}
                      </Stack>

                      {errors.data ? (
                        <FormFeedback style={{display: "block"}}>{errors.data as string}</FormFeedback>
                       ) : null}
                       
                      <div className="mt-3">
                        <LoadingButton
                          loading={isLoading}
                          color="primary"
                          disabled={!isValid}
                        >
                          Save
                        </LoadingButton>
                        <Button color="link" onClick={close}>
                          Cancel
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </CardBody>
          </Card>
        )}
      </BetterPopover>
    </>
  );
};

export default SaveFilters;
