import { Button } from "reactstrap";
import { FilterDropdown } from "../FilterDropdown";
import { ArrowDown } from "@/assets/icons";
import { useMutation, useQuery } from "react-query";
import { FilterType } from "../Tags/types";
import { createFilter, getSavedFilters, updateFilter } from "@/helpers/apis";
import { useEffect } from "react";
import { setSelectedColumns } from "./columnPickerSlice";
import { useColumnPickerContext } from "./types";

/**
 * This component is responsible for rendering the column picker dropdown
 * and handling the state of the selected columns on page load depending on the component from which it is rendered (using `applicableFor` prop).
 * Also it saves the disabled columns as a preset filter in the backend.
 */
const ColumnPicker = () => {
  const {
    dispatch,
    state: { headers, applicableFor, columns },
  } = useColumnPickerContext();

  // Unset the selected columns on page change
  useEffect(() => {
    return () => {
      dispatch(setSelectedColumns(new Set()));
    };
  }, []);

  const { data, isLoading, refetch } = useQuery({
    queryKey: [`COLUMN_PICKER_${applicableFor}`],
    queryFn: () =>
      getSavedFilters({
        applicableFor: applicableFor!,
        type: FilterType.COLUMN_PICKER,
      }),
    enabled: !!applicableFor,
    onSuccess: (data) => {
      const disabledColumns = data?.presets[0]?.data.disabledColumns as
        | string[]
        | undefined;
      if (disabledColumns) {
        dispatch(
          setSelectedColumns(
            new Set(
              headers
                .filter((h) => !disabledColumns.includes(h.value))
                .map((h) => h.value)
            )
          )
        );
      }
    },
  });

  const { mutate } = useMutation({
    mutationFn: (enabledColumns: string[]) => {
      const disabledColumns = headers
        .filter((h) => !enabledColumns.includes(h.value))
        .map((h) => h.value);
      if (!data?.presets.length) {
        return createFilter({
          applicable_for: applicableFor!,
          type: FilterType.COLUMN_PICKER,
          data: { disabledColumns },
          name: `${applicableFor} columns`,
        });
      }

      return updateFilter({
        ...data.presets[0],
        data: { disabledColumns },
      });
    },
    onSuccess: () => {
      refetch();
    },
  });

  if (isLoading) return null;

  return (
    <FilterDropdown
      label={"Columns"}
      value={columns}
      options={[...headers]}
      onChange={mutate}
    >
      <Button color="primary" outline>
        Columns <ArrowDown style={{ width: 16 }} />
      </Button>
    </FilterDropdown>
  );
};

export default ColumnPicker;
