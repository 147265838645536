import { useContext } from "react";
import { SummaryStateProps } from "./Context/types";
import { SummaryContext } from "./SummaryNew";

const useSummaryState = (): SummaryStateProps => {
  const { state } = useContext(SummaryContext);

  return state;
}

export default useSummaryState;