import { CostGreenIcon, TimeGreenIcon } from "@assets/icons";
import { Tooltip } from "@uicore";

const InsightLabel = ({
  tag,
}: {
  tag: { name: string; cost_savings: boolean; time_savings: boolean };
}) => {
  return (
    <div className="d-flex gap-xxs align-items-center">
      <div>{tag.name}</div>
      {tag.cost_savings && (
        <Tooltip content="Cost savings for the insights is available">
          <div className="icon-xs d-flex align-items-center">
            <CostGreenIcon />
          </div>
        </Tooltip>
      )}
      {tag.time_savings && (
        <Tooltip content="Time savings for the insights is available">
          <div className="icon-xs d-flex align-items-center">
            <TimeGreenIcon />
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default InsightLabel;
