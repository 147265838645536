import styles from "./styles.module.scss";
import { useState } from "react";
import { Button, Card, CardBody } from "reactstrap";
import {
  Summary,
  TableInsightsSummary,
  TableStorageSummary,
} from "../Incidents";
import { CopyIconButton } from "../../Components/CopyButton";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { getIconByDatastoreType } from "./DatastoreImage";
import { DatasetIncidents } from "./Incidents";
import { Lineage } from "../../Components/Lineage";
import { Tab } from "../../Components/Tab";
import { TableInsights } from "./TableInsights";
import {
  altimateToAmundsen,
  downstreamTables,
  getTableById,
  getTableInsightsById,
  getTableInsightsStatus,
  getTableRk,
  postColumns,
  postConnectedColumns,
  upstreamTables,
} from "../../helpers/backend_helper";
import { useQuery } from "react-query";
import { ComponentLoader } from "../../Components/Loader";
import { isLineage } from "../../helpers/tenant";
import { getScopeString } from "../../helpers/utils";
import { TableAccessHistory } from "./TableAccessHistory";
import { Schema } from "./Schema";
import { BackButton } from "../../Components/Button";
import { InsightSection } from "../Query/components";
import { GET_OPPORTUNITY_RESOURCE_DATA } from "@/services/constants";
import { Stack } from "@/uiCore";
import dayjs from "dayjs";
import {
  useDateFromSearchParams,
  useResourceOpportunities,
} from "@/helpers/useTimeHooks";
import { ResourceType } from "../Explorer/types";
import SingleLineEllipses from "@/uiCore/components/singleLineEllipses/SingleLineEllipses";
import { Close2Icon } from "@/assets/icons";
import { DateRange } from "@/Components/DateRange";
import useFiltersBySearchParams from "@/helpers/useFiltersBySearchParams";
import TimeInfoDisclaimer from "../../Components/TimeInfoDisclaimer";
import { getLastTableRefreshedTime } from "@/helpers/apis";

const GET_TABLE_INSIGHTS_STATUS = "GET-TABLE-INSIGHTS-STATUS";
export const GET_TABLE_RK = "GET-TABLE-RK";
export const GET_TABLE_BY_ID = "GET-TABLE-BY-ID";
const GET_TABLE_INSIGHTS_BY_ID = "GET-TABLE_INSIGHTS-BY-ID";
const GET_LAST_REFRESHED_TIME = "GET-LAST-REFRESHED-TIME";

function SideContent({ opportunities, closeSideContent }) {
  const { startDate, endDate } = useDateFromSearchParams();
  const {searchParams, updateSearchParamsByKey} = useFiltersBySearchParams();
  const selectedOpportunityId = searchParams.get("opportunityId");
  const setSelectedOpportunityId = (v) =>
    updateSearchParamsByKey({ opportunityId: v });

  const selectedOpportunity = (opportunities || []).find(
    (o) => o.opportunity_id === selectedOpportunityId
  );

  return (
    <Card className="mb-0">
      <CardBody>
        <Stack direction="column">
          <div className="d-flex align-items-center">
            {selectedOpportunity && (
              <SingleLineEllipses
                content={selectedOpportunity?.opportunity_name}
                className="fs-5 fw-semibold"
              />
            )}
            <div className="spacer" />
            <Close2Icon onClick={closeSideContent} className="cursor-pointer" />
          </div>
          <InsightSection
            opportunities={opportunities?.filter(
              (o) => o.opportunity_id === selectedOpportunityId
            )}
            sideContentMode={true}
            selectedOpportunityId={selectedOpportunityId}
            setOpportunityId={setSelectedOpportunityId}
            startDate={startDate}
            endDate={endDate}
            explanationEndpoint="/opportunities/explanation"
          />
        </Stack>
      </CardBody>
    </Card>
  );
}

const MainContent = ({
  tableId,
  defaultTab,
  opportunities,
  table_rk,
  table,
}) => {
  const {searchParams, updateSearchParamsByKey} = useFiltersBySearchParams();
  const selectedOpportunityId = searchParams.get("opportunityId");
  const setSelectedOpportunityId = (v) =>
    updateSearchParamsByKey({"opportunityId": v});

  const navigate = useNavigate();
  const { state } = useLocation();
  const lineage = isLineage();
  const { startDate, endDate, onUpdate } = useDateFromSearchParams();

  const tabs = [
    {
      label: "Schema",
      component: <Schema table={table} />,
    },
    {
      label: "Lineage",
      component: (
        <Lineage
          tableId={tableId}
          getStartingNode={altimateToAmundsen}
          postColumns={postColumns}
          upstreamTables={upstreamTables}
          downstreamTables={downstreamTables}
          postConnectedColumns={postConnectedColumns}
        />
      ),
      disabled: !lineage,
    },
    {
      label: "Opportunities",
      component: (
        <div>
          <div className="d-flex m-2">
            <div className="fs-4">Opportunities</div>
            <div className="spacer" />
            <DateRange
              startDate={startDate}
              endDate={endDate}
              availableStaticRanges={[
                "Last day",
                "Last 7 days",
                "Last 28 days",
              ]}
              disableCalendarSelection
              onDateRangeSelect={onUpdate}
            />
          </div>
          <InsightSection
            opportunities={opportunities}
            selectedOpportunityId={selectedOpportunityId}
            setOpportunityId={setSelectedOpportunityId}
            startDate={startDate}
            endDate={endDate}
          />
        </div>
      ),
    },
    {
      label: "Access History",
      component: <TableAccessHistory tableId={tableId} />,
    },
  ];
  const [selectedLabel, setSelectedLabel] = useState(tabs[defaultTab].label);

  const handleSelectedTabChange = (label) => {
    setSelectedLabel(label);
  };
  const scope = getScopeString(table.scope);
  const tableName = table.scope[table.scope.length - 1];

  const { data: infoTimeString, isLoading } = useQuery({
    queryKey: [GET_LAST_REFRESHED_TIME],
    queryFn: () => getLastTableRefreshedTime(table_rk),
  });

  return (
    <div className={styles.dataset_details}>
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-start">
          <BackButton defaultUrl="/datasets" />
          <div className="d-flex flex-column">
            <div className="d-flex">
              {getIconByDatastoreType(table.datasource_type, "me-2 icon-md")}
              <div className="text-primary fs-5 text-overflow">{tableName}</div>
            </div>
            <div className="d-flex align-items-center mt-2 mb-2">
              <div className="me-2 text-overflow">{scope}</div>
              <CopyIconButton
                value={scope}
                color="rgba(8, 34, 71, 0.5)"
                isLight
              />
            </div>
          </div>
        </div>
        {!isLoading && infoTimeString && (
          <TimeInfoDisclaimer
            color="#ECEEEF"
            numOfSecondsActive={3}
            textToDisplay={
              <div className="text-muted m-2">
                The data was last updated at
                <br />
                <span className="text-black fw-semibold">
                  {dayjs
                    .utc(infoTimeString["last_refreshed"])
                    .local()
                    .format("D MMM, YYYY HH:mm:ss A")}
                </span>
              </div>
            }
          />
        )}
      </div>
      {selectedLabel == "Incidents" && <Summary table={tableId} />}
      {selectedLabel !== "Lineage" && <TableStorageSummary tableId={tableId} />}
      {(selectedLabel == "Opportunities" || selectedOpportunityId) && (
        <TableInsightsSummary tableId={tableId} />
      )}
      <Card className="flex-grow" style={{ height: "93%" }}>
        <CardBody className="h-100">
          <Tab
            defaultTab={defaultTab}
            tabs={tabs}
            onSelectedTabChange={handleSelectedTabChange}
            fullscreen={true}
          />
        </CardBody>
      </Card>
    </div>
  );
};

const DatasetsDetails = () => {
  const { tableId } = useParams();
  const {searchParams, deleteSearchParamsByKeyValue} = useFiltersBySearchParams();
  const { data: tableRk, isLoading: isTableRkLoading } = useQuery({
    queryKey: [GET_TABLE_RK, tableId],
    queryFn: () => getTableRk(tableId),
  });
  const opportunities = useResourceOpportunities(
    tableRk?.table_rk,
    ResourceType.Table
  );

  const { data: tableInsights, isLoading: isInsightsLoading } = useQuery({
    queryKey: [GET_TABLE_INSIGHTS_STATUS, tableId],
    queryFn: () => getTableInsightsStatus(tableId),
  });
  const { data: table, isLoading: isTableLoading } = useQuery({
    queryKey: [GET_TABLE_BY_ID, tableId],
    queryFn: () => getTableById(tableId),
  });

  const selectedOpportunityId = searchParams.get("opportunityId");

  const closeSideContent = () => {
    deleteSearchParamsByKeyValue({opportunityId: undefined});
  };

  if (
    isTableRkLoading ||
    opportunities.isLoading ||
    isInsightsLoading ||
    isTableLoading
  ) {
    return <ComponentLoader />;
  }
  const selectedOpportunity = opportunities?.data?.data.find(
    (o) => o.opportunity_id === selectedOpportunityId
  );

  if (!table) {
    return <Navigate to="/datasets" />;
  }

  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: selectedOpportunityId ? "2fr 1fr" : "1fr",
        gap: "1rem",
      }}
    >
      <MainContent
        tableId={tableId}
        defaultTab={parseInt(searchParams.get("tab")) || 2}
        opportunities={opportunities?.data?.data}
        table_rk={tableRk.table_rk}
        table={table}
      />
      {selectedOpportunity && (
        <SideContent
          opportunities={[selectedOpportunity]}
          closeSideContent={closeSideContent}
        />
      )}
    </div>
  );
};

export { DatasetsDetails };
