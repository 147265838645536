import { HTMLAttributes } from "react";
import classes from "./singleLineEllipses.module.scss";
import Tooltip from "../tooltip/Tooltip";

const SingleLineEllipses = ({
  content = "",
  ...rest
}: {
  content?: string;
} & HTMLAttributes<HTMLDivElement>): JSX.Element => (
  <Tooltip
    content={content}
    placement="top"
    tooltipContentWrapperClassName={classes.singleLineEllipsesTooltip}
  >
    <div
      {...rest}
      className={`${rest.className} ${classes.singleLineEllipses}`}
    >
      {content}
    </div>
  </Tooltip>
);

export default SingleLineEllipses;
