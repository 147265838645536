import { useContext } from "react";
import { AppContext } from "./AppProvider";
import { AppStateProps, ContextProps } from "./types";
import { UnknownAction } from "@reduxjs/toolkit";

const useAppContext = (): ContextProps => {
  return useContext(AppContext);
};

export const useAppDispatch = (): React.Dispatch<UnknownAction> => {
  const { dispatch } = useContext(AppContext);
  return dispatch;
};

export const useAppState = (): AppStateProps => {
  const { state } = useContext(AppContext);
  return state;
};

export default useAppContext;
