import dayjs from "dayjs";
import { Badge, List, NumberFormatter, Stack } from "@uicore";
import { TooltipProps } from "recharts";
import { BarColors, TotalCostEntities } from "./constants";
import { InfoIcon } from "@/assets/icons";
import classes from "../summary.module.scss";
import { useAppState } from "@/modules/app/useAppContext";

interface Props extends TooltipProps<any, any> {
  timeRange: "day" | "week" | "month";
  isFuture?: boolean;
  type: keyof typeof TotalCostEntities;
}
const CostChartTooltip = ({ payload, timeRange, type, isFuture }: Props) => {
  const { currency } = useAppState();
  const data = payload && payload[0] && payload[0].payload;

  if (!data) {
    return null;
  }

  const allFields = TotalCostEntities[type].fields;
  const totalObject = allFields.find((item) => item.isTotal);
  const otherFields = allFields.filter((item) => !item.isTotal);

  const totalKey = totalObject?.key["current"];

  const isCurrentMonth = dayjs().isSame(dayjs(data.date), "month");
  const isBeforeMonth = dayjs(data.date).isBefore(dayjs(), "month");
  const isAfterMonth = dayjs(data.date).isAfter(dayjs(), "month");

  const showCurrent = !isFuture || isBeforeMonth || isCurrentMonth;
  const showFuture = isFuture && (isCurrentMonth || isAfterMonth);

  const changePercent = (
    showChangePercentage: boolean,
    key: string,
    data: Record<string, string | number | null>
  ) => {
    if (!showChangePercentage) {
      return null;
    }
    const change = data[`percentage_${key}`];
    if (change) {
      const number = parseFloat(`${change}`);
      return (
        <span className={number >= 0 ? "negative" : "positive"}>
          ({number > 0 ? "+" : ""}
          {number?.toFixed(2)}%)
        </span>
      );
    }
  };

  const sticker = (
    field: (typeof allFields)[0],
    key: keyof typeof BarColors
  ) => {
    if (field.hideSticker) {
      return null;
    }
    return (
      <span
        className={classes.sticker}
        style={{
          background: BarColors[key],
        }}
      />
    );
  };

  let hasChangePercentage = false;

  // For future estimates for current month, we need to add both current and estimate to get full estimated value
  const getEstimatedValue = (key: NonNullable<typeof totalObject>["key"]) => {
    if (isCurrentMonth) {
      return data[key["estimate"]] + data[key["current"]];
    }

    return data[key["estimate"]];
  };

  return (
    <div className={classes.costChartTooltip}>
      <h6>
        {dayjs(data.date).format("DD-MMM-YY")} (Aggregation: {timeRange})
      </h6>
      {totalKey ? (
        <Stack>
          {showCurrent ? (
            <Badge tooltip="">
              Total Cost:{" "}
              <NumberFormatter options={{ currency }} value={data[totalKey]} />
              {!isCurrentMonth ? changePercent(true, totalKey, data) : null}
            </Badge>
          ) : null}
          {showFuture ? (
            <Badge color="dark" tooltip="">
              Projected:{" "}
              <NumberFormatter
                options={{ currency }}
                value={getEstimatedValue(totalObject.key)}
              />
              {changePercent(true, totalKey, data)}
            </Badge>
          ) : null}
        </Stack>
      ) : null}
      <List>
        {otherFields.map((field) => {
          const key = field.key["current"];
          hasChangePercentage =
            hasChangePercentage || Boolean(field.showChangePercentage);
          return (
            <li key={key}>
              <Stack
                className={
                  isFuture && isCurrentMonth
                    ? "justify-content-between"
                    : "flex-start"
                }
              >
                {isFuture && isCurrentMonth
                  ? null
                  : sticker(
                      field,
                      (isFuture
                        ? field.key["estimate"]
                        : key) as keyof typeof BarColors
                    )}
                {field.label}:{" "}
                {showCurrent ? (
                  <>
                    <NumberFormatter options={{ currency }} value={data[key]} />
                  </>
                ) : null}
                <span>
                  {showFuture ? (
                    <>
                      <NumberFormatter
                        options={{ currency }}
                        value={data[field.key["estimate"]]}
                      />
                    </>
                  ) : null}
                  {changePercent(
                    Boolean(field.showChangePercentage),
                    key,
                    data
                  )}
                </span>
              </Stack>
            </li>
          );
        })}
      </List>
      {hasChangePercentage && timeRange ? (
        <div style={{ background: "#F5FAFF", padding: 8 }}>
          <div style={{ maxWidth: 215 }}>
            <InfoIcon /> Change is calculated from the value for the previous{" "}
            {timeRange}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CostChartTooltip;
