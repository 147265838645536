import styles from "./styles.module.scss";
import { useState } from "react";
import _ from "lodash";
import { getIconByDatastoreType } from "./DatastoreImage";
import datastore_sync from "../../assets/icons/datastore_sync.png";
import datastore_sync_gray from "../../assets/icons/datastore_sync_gray.svg";
import datastore_processing from "../../assets/images/datastore_processing.png";
import datastore_success from "../../assets/images/datastore_success.svg";
import datastore_fail from "../../assets/images/datastore_fail.svg";
import datastore_play from "../../assets/icons/datastore_play.svg";
import datastore_play_gray from "../../assets/icons/datastore_play_gray.svg";
import classNames from "classnames";
import { Tooltip } from "react-tooltip";
import { Input, Label } from "reactstrap";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";
import {
  testDatastoreConnection,
  syncDatastore,
  toggleDatastoreSyncSchedule,
} from "../../helpers/backend_helper";
import { getUser } from "../../services/auth";
import { PermissionsEnum } from "../../helpers/constants";

const API_RESULT_TIMEOUT = 2000;
const Datastore = ({ datastore, syncOpAllowed }) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(datastore?.auto_sync || false);
  const [showTestSuccess, setShowTestSuccess] = useState(false);
  const [showTestFail, setShowTestFail] = useState(false);
  const [showSyncing, setShowSyncing] = useState(false);
  const isTestDisabled = !datastore?.can_sync;

  const {
    mutate: runTestConnection,
    isLoading: isTestLoading,
    error,
  } = useMutation(testDatastoreConnection, {
    onSuccess: (response) => {
      if (response?.ok) {
        setShowTestSuccess(true);
        setTimeout(() => {
          setShowTestSuccess(false);
        }, API_RESULT_TIMEOUT);
      } else {
        setShowTestFail(true);
        setTimeout(() => {
          setShowTestFail(false);
        }, API_RESULT_TIMEOUT);
      }
    },
    onError: () => {
      setShowTestFail(true);
      setTimeout(() => {
        setShowTestFail(false);
      }, API_RESULT_TIMEOUT);
    },
  });

  const { mutate: runSyncDatastore } = useMutation(syncDatastore, {
    onSuccess: () => {
      setShowSyncing(false);
    },
    onError: () => {
      setShowSyncing(false);
    },
  });

  const { mutate: runToggleSchedule, isLoading: isRunningToggle } = useMutation(
    () => toggleDatastoreSyncSchedule(datastore?.id),
    {
      onSuccess: (res) => {
        if (res?.ok) {
          setIsChecked(!isChecked);
        }
      },
    }
  );

  const handleToggleSwitch = (e) => {
    e.stopPropagation();
    runToggleSchedule();
  };

  const handleTestConnection = (e, id) => {
    e.stopPropagation();
    if (!isTestDisabled) {
      runTestConnection(id);
    }
  };

  const handleSync = (e, id) => {
    e.stopPropagation();
    setShowSyncing(true);
    runSyncDatastore(id);
  };

  return (
    <div
      className={styles.card}
      onClick={() => {
        navigate(`/settings/datastores/${datastore.id}`);
      }}
    >
      <div
        className={classNames(
          "mb-2 d-flex justify-content-between align-items-center",
          styles.icon_row
        )}
      >
        <div className="d-flex justify-content-between align-items-center">
          {getIconByDatastoreType(datastore.type, styles.datastore_icon)}
          <div className="ms-2">
            <div className="fs-5 fw-semibold">{datastore.name}</div>
            <div className={styles.subtext}>
              {_.capitalize(datastore?.type)}
            </div>
          </div>
        </div>

        <div className={styles.right_column}>
          <div className="form-check form-switch d-flex align-items-center justify-content-between">
            <Label
              className={styles.input_switch_title}
              for={"flexSwitchCheckDefault" + datastore.id}
            >
              Enable Sync
            </Label>
            <div data-tooltip-id={"enable_sync" + datastore.id}>
              <Input
                className={classNames("form-check-input", styles.input_switch)}
                type="checkbox"
                role="switch"
                id={"flexSwitchCheckDefault" + datastore.id}
                checked={isChecked}
                onClick={(e) => {
                  handleToggleSwitch(e);
                }}
                disabled={!syncOpAllowed}
              />
            </div>
            {!syncOpAllowed &&
              <Tooltip
                id={"enable_sync" + datastore.id}
                className={styles.sync_disabled_tooltip}
              >
                <div className="d-flex flex-column align-items-left gap-xs justify-content-center">
                  <div>Insufficient permissions for enabling sync</div>
                </div>
              </Tooltip>
            }
          </div>
        </div>
      </div>
      <div
        className={classNames(
          "mb-2 d-flex justify-content-between align-items-center text-muted fw-400",
          styles.description
        )}
      >
        {datastore?.description}
      </div>
      <div className={styles.sync_details}>
        <div className="d-flex justify-content-between align-items-center mt-1">
          {showTestSuccess && (
            <div className={styles.success_text}>Success</div>
          )}
          {showTestFail && (
            <div className={styles.fail_text}>Failed, Try Again</div>
          )}
          {!showTestSuccess &&
            !showTestFail &&
            (datastore?.last_synced_at ? (
              <div className={styles.last_sync}>
                Synced at {datastore?.last_synced_at}
              </div>
            ) : (
              <div></div>
            ))}
          <div className="d-flex justify-content-between align-items-center">
            {(datastore?.can_sync && syncOpAllowed) ? (
              <div
                className={classNames(styles.inside_card, "me-2")}
                onClick={(e) => handleSync(e, datastore.id)}
              >
                {showSyncing ? (
                  <>
                    <img
                      src={datastore_sync}
                      alt="syncing"
                      className={styles.rotateIcon}
                    />
                    <div className={styles.title}>Syncing...</div>
                  </>
                ) : (
                  <>
                    <img src={datastore_sync} alt="sync_now" />
                    <div className={styles.title}>Sync Now</div>
                  </>
                )}
              </div>
            ) : (
              <>
                <div
                  className={classNames(styles.inside_card, "me-2")}
                  data-tooltip-id={"sync_gray" + datastore.id}
                >
                  <img src={datastore_sync_gray} alt="sync_now_gray" />
                  <div className={styles.title}>Sync Now</div>
                </div>
                <Tooltip
                  id={"sync_gray" + datastore.id}
                  className={styles.sync_disabled_tooltip}
                >
                  <div className="d-flex flex-column align-items-left gap-xs justify-content-center">
                    {!syncOpAllowed ?
                      <div>Insufficient permissions for performing sync</div> : (
                        <div>
                          <div>Credentials are not added</div>
                          <div> or synced to SaaS UI</div>
                        </div>
                      )
                    }
                  </div>
                </Tooltip>
              </>
            )}

            <div
              className={classNames(styles.inside_card, {
                [styles.no_pointer_events]: isTestDisabled,
              })}
              data-tooltip-id={"test_connection" + datastore.id}
              onClick={(e) => handleTestConnection(e, datastore.id)}
            >
              {isTestLoading ? (
                <img
                  src={datastore_processing}
                  className={styles.rotateIcon}
                  alt="test_connection"
                />
              ) : (
                <>
                  {!showTestSuccess &&
                    !showTestFail &&
                    (isTestDisabled ? (
                      <img
                        src={datastore_play_gray}
                        alt="test_connection_disabled"
                      />
                    ) : (
                      <img src={datastore_play} alt="test_connection" />
                    ))}
                  {showTestSuccess && (
                    <img src={datastore_success} alt="test_connection" />
                  )}
                  {showTestFail && (
                    <img src={datastore_fail} alt="test_connection" />
                  )}
                </>
              )}

              <div className={styles.title}>Test</div>
              <Tooltip
                id={"test_connection" + datastore.id}
                className={styles.test_connection_tooltip}
              >
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <div>Test Connection</div>
                  {/* {last_test_timestamp && (
                        <span className={styles.tooltip_date}>
                          Last&nbsp;:&nbsp;
                          {dayjs(last_test_timestamp).format(
                            "h:mm A, D MMMM YY"
                          )}
                        </span>
                      )} */}
                </div>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DatastoreList = ({ datastores }) => {
  const useCompactGrid = datastores["data_stores"].length <= 2;
  const user = getUser();
  return (
    <div
      className={classNames(styles.datastore_list, {
        [styles.compact]: useCompactGrid,
      })}
    >
      {datastores["data_stores"].map((datastore) => (
        <Datastore key={datastore.id} datastore={datastore} syncOpAllowed={user.permission.includes(PermissionsEnum.DATASTORE_EDIT)} />
      ))}
    </div>
  );
};

export { DatastoreList };
