import tableStyles from "@/Components/Tables/styles.module.scss";
import styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import SidebarModal from "../../Components/SidebarModal";
import { WarehouseDetails } from "./WarehouseDetails";
import { Paginate } from "../../Components/Paginate";
import { useQuery } from "react-query";
import {
  getAllWarehouses,
  getWarehouseFilters,
  getWarehouseLastEventTime,
} from "../../helpers/backend_helper";
import { ComponentLoader } from "../../Components/Loader";
import { ColorInsightTags, ColorTag, FilterTagWrapper, Tags } from "../../Components/Tags";
import {
  GET_LAST_UPDATED_TIME,
  GET_WAREHOUSE,
  GET_WAREHOUSE_FILTERS,
} from "./constants";
import { Dropdown } from "../../Components/Form";
import { default as ArrowDown } from "../../assets/icons/arrow_down.svg?react";
import { default as CalendarIcon } from "../../assets/icons/calendar.svg?react";
import dayjs from "dayjs";
import TimeInfoDisclaimer from "../../Components/TimeInfoDisclaimer";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useAppState } from "@/modules/app/useAppContext";
import { formatNumber, getCurrencySymbol } from "@/uiCore";
import useFiltersBySearchParams from "@/helpers/useFiltersBySearchParams";
import { FilterApplicableFor } from "@/Components/Tags/types";
import SavedFilters from "@/Components/Tags/SavedFilters";
import ColumnPicker from "@/Components/Column/ColumnPicker";
import { Table } from "@/Components/Tables";
import usePaginate from "@/Components/Paginate/usePaginate";

const AUTO_TUNE_MAP = {
  "auto-tune-enabled": { color: "#01CD8C", text: "On", bgColor: "#E6FAF4" },
  "auto-tune-disabled": { color: "#EE140F", text: "Off", bgColor: "#FEE8E7" },
};

const AutoSavingsStatus = ({ autoTuneStatus }) => (
  <div
    style={{
      backgroundColor: AUTO_TUNE_MAP[autoTuneStatus].bgColor,
      color: AUTO_TUNE_MAP[autoTuneStatus].color,
      paddingInline: 6,
      paddingBlock: 2,
    }}
  >
    {AUTO_TUNE_MAP[autoTuneStatus].text}
  </div>
);

const RenderTextBasedOnPeriod = ({ period }) => {
  const endDate = dayjs().subtract(1, "day");
  let startDate;

  switch (period) {
    case 1:
      startDate = endDate;
      return (
        <div className={styles.date_range}>
          {startDate.format("D MMM YYYY")}
        </div>
      );

    case 7:
      startDate = endDate.subtract(7, "days");
      return (
        <div className={styles.date_range}>
          {startDate.format("D MMM YYYY")} - {endDate.format("D MMM YYYY")}
        </div>
      );

    case 30:
      startDate = endDate.subtract(1, "month");
      return (
        <div className={styles.date_range}>
          {startDate.format("D MMM YYYY")} - {endDate.format("D MMM YYYY")}
        </div>
      );

    default:
      return null;
  }
};

const WarehouseHeader = ({ period, setPeriod }) => {
  const ALLOWED_PERIODS = [
    {
      label: "Last Day",
      period: 1,
    },
    {
      label: "Last Week",
      period: 7,
    },
    {
      label: "Last Month",
      period: 30,
    },
  ];

  const getLabelByPeriod = (period) => {
    return ALLOWED_PERIODS.find((p) => p.period === period).label;
  };

  const { data: infoTimeString, isLoading } = useQuery({
    queryKey: [GET_LAST_UPDATED_TIME],
    queryFn: getWarehouseLastEventTime,
  });

  return (
    <div className="d-flex justify-content-between align-items-center gap-lg bg-white p-3 border-radius-top">
      <div className="d-flex gap-lg">
        <div className="fs-4">Warehouses</div>
      </div>
      <div className="d-flex align-items-center gap-sm">
        {!isLoading && infoTimeString && (
          <TimeInfoDisclaimer
            numOfSecondsActive={3}
            textToDisplay={
              <div className="text-muted m-2">
                The Data was last updated at{" "}
                <span className="text-black fw-semibold">
                  {infoTimeString["time_string"]}
                </span>{" "}
                UTC
              </div>
            }
          />
        )}
        <RenderTextBasedOnPeriod period={period} />
        <Dropdown
          id="period-dropdown"
          onChange={setPeriod}
          value={period}
          options={ALLOWED_PERIODS.map((p) => ({
            label: <div>{p.label}</div>,
            value: p.period,
          }))}
          showDivider
        >
          <div className={styles.period_dropdown}>
            <CalendarIcon />
            <div className={styles.period_label}>
              {getLabelByPeriod(period)}
            </div>
            <div className={styles.arrow_down}>
              <ArrowDown />
            </div>
          </div>
        </Dropdown>
        <SavedFilters applicableFor={FilterApplicableFor.WAREHOUSES} />
        <ColumnPicker />
      </div>
    </div>
  );
};

const WarehouseListWithFilter = ({
  setSelectedWarehouseRk,
  period,
  defaultFilters = {},
}) => {
  const { currency, currencySymbol } = useAppState();
  const roundToTwoDecimalPlaces = (number) => {
    return Math.round(number * 100) / 100;
  };
  const {
    searchParams,
    deleteSearchParamsByKeyValue,
    updateSearchParamsByKey,
  } = useFiltersBySearchParams();
  const {page, pageSize, setPage, updatePageSize, resetPage} = usePaginate();
  const [tagFilter, setTagFilter] = useState(defaultFilters.tags);
  const [warehouseCostFilter, setCostFilter] = useState(defaultFilters.cost);
  const [warehouseFilter, setWarehouseFilter] = useState(
    defaultFilters.warehouse
  );
  const [autoTuneFilter, setAutoTuneFilter] = useState(defaultFilters.autotune);
  const [sizeFilter, setSizeFilter] = useState(defaultFilters.size);
  const [sortAttribute, setSortAttribute] = useState("");
  const [sortOrder, setSortOrder] = useState({
    total_cost: "",
    execution_time_p90: "",
    median_total_elapsed_time: "",
    median_queuing_time: "",
    total_active_time: "",
    idle_time: "",
    auto_tune_savings: "",
  });

  useEffect(() => {
    setPage(Number(defaultFilters.page));
    setTagFilter(defaultFilters.tags);
    setCostFilter(defaultFilters.cost);
    setWarehouseFilter(defaultFilters.warehouse);
    setAutoTuneFilter(defaultFilters.autotune);
    setSizeFilter(defaultFilters.size);
  }, [defaultFilters]);

  const handleFilterChange = (setFilter, param) => {
    return (value) => {
      updateSearchParamsByKey({ [param]: value });
      setFilter(value);
    };
  };

  const handleTagFilterChange = handleFilterChange(setTagFilter, "tags");
  const handleWarehouseFilterChange = handleFilterChange(
    setWarehouseFilter,
    "warehouse"
  );
  const handleSizeFilterChange = handleFilterChange(setSizeFilter, "size");
  const handleCostFilterChange = handleFilterChange(setCostFilter, "cost");
  const handleAutotuneFilterChange = handleFilterChange(
    setAutoTuneFilter,
    "autotune"
  );

  const handlePageChange = handleFilterChange(setPage, "page");

  const getOptions = (options) =>
    options
      ? options.map((o) =>
          o === "" ? { label: "None", value: o } : { label: o, value: o }
        )
      : [];

  const autoTuneFilterOptions = [
    { label: "On", value: "auto-tune-enabled" },
    { label: "Off", value: "auto-tune-disabled" },
  ];

  const handleSortChange = (attribute) => (so) => {
    if (so) {
      setSortAttribute(attribute);
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        [attribute]: so,
      }));
    }
  };

  const handleCostSortChange = handleSortChange("total_cost");

  const handlePercentileExecTimeSortChange =
    handleSortChange("execution_time_p90");

  const handleMedianElapsedSortChange = handleSortChange(
    "median_total_elapsed_time"
  );

  const handleActiveTimeSortChange = handleSortChange("total_active_time");

  const handleIdleTimeSortChange = handleSortChange("idle_time");

  const handleMedianQueuingTimeSortChange = handleSortChange(
    "median_queuing_time"
  );

  const handleAutoTuneSavingsSortChange = handleSortChange("auto_tune_savings");

  const { data: filters, isLoading: isfiltersLoading } = useQuery({
    queryKey: [GET_WAREHOUSE_FILTERS],
    queryFn: () => getWarehouseFilters(),
  });

  const getFilterParams = () => {
    const params = { page: page + 1, size: pageSize };
    if (period) params.period = period;
    if (sortAttribute) {
      params.sortAttribute = sortAttribute;
      params.sortOrder = sortOrder[sortAttribute];
    }
    if (warehouseFilter.length > 0) params.warehouseName = warehouseFilter;
    if (tagFilter.length > 0) params.tags = tagFilter;
    if (warehouseCostFilter) params.costFilter = warehouseCostFilter;
    if (sizeFilter.length > 0) params.warehouseSize = sizeFilter;
    if (autoTuneFilter.length > 0) params.autoTuneFilter = autoTuneFilter;
    return params;
  };

  const { data: warehouses, isLoading: isQueriesLoading } = useQuery({
    queryKey: [
      GET_WAREHOUSE,
      page,
      pageSize,
      ...warehouseFilter,
      ...tagFilter,
      ...sizeFilter,
      ...autoTuneFilter,
      sortOrder,
      sortAttribute,
      warehouseCostFilter,
      period,
    ],
    queryFn: () => getAllWarehouses(getFilterParams()),
  });

  const handleRowClick = (rk) => {
    setSelectedWarehouseRk(rk.warehouse_rk);
  };

  const handleFilterRemoval = (filter, setFilter, param) => {
    return (removedFilter) => {
      const newFilter = filter.filter((f) => f !== removedFilter);
      deleteSearchParamsByKeyValue({ [param]: removedFilter });
      setFilter(newFilter);
    };
  };

  if (isQueriesLoading || isfiltersLoading) return <ComponentLoader />;

  return (
    <div className="p-3 pt-0 d-flex flex-column bg-white border-radius-bottom">
      <div className="d-flex gap-md align-items-center">
        <FilterTagWrapper
          applicableFor={FilterApplicableFor.WAREHOUSES}
          filters={{
            tagFilter: {
              filterStr: tagFilter,
              label: "Tags",
              onclose: handleFilterRemoval(tagFilter, setTagFilter, "tags"),
              searchKey: "tags",
            },
            warehouseFilter: {
              filterStr: warehouseFilter,
              label: "Warehouse",
              searchKey: "warehouse",
              onclose: handleFilterRemoval(
                warehouseFilter,
                setWarehouseFilter,
                "warehouse"
              ),
            },
            warehouseCostFilter: {
              filterStr: warehouseCostFilter,
              label: currencySymbol,
              searchKey: "cost",
              onclose: () => {
                setCostFilter(undefined);
                deleteSearchParamsByKeyValue({ cost: warehouseCostFilter });
              },
            },
            sizeFilter: {
              searchKey: "size",
              filterStr: sizeFilter,
              label: "Size",
              onclose: handleFilterRemoval(sizeFilter, setSizeFilter, "size"),
            },
          }}
        />
      </div>
      <Table
        applicableFor={FilterApplicableFor.WAREHOUSES}
        header={[
          {
            id: 1,
            label: "Warehouse name",
            uid: "warehouse_name",
            width: 3,
            filter: {
              filterType: "dropdown",
              value: warehouseFilter,
              options: getOptions(filters.warehouse_names),
              onChange: handleWarehouseFilterChange,
            },
            render: (item) => (
              <div className="text-primary condense-text cursor-pointer">
                {item.warehouse_name}
              </div>
            ),
          },
          {
            id: 2,
            label: "Cost",
            uid: "total_cost",
            filter: {
              filterType: "text",
              value: warehouseCostFilter,
              placeHolder: `Specify cost in ${getCurrencySymbol(currency)}`,
              onChange: handleCostFilterChange,
              label: "Cost",
            },
            sort: {
              onChange: handleCostSortChange,
              value: sortOrder.total_cost,
            },
            render: (item) => (
              <div className={tableStyles.break_word}>
                {formatNumber(item.total_cost, { currency })}
              </div>
            ),
          },
          {
            id: 3,
            label: "Size",
            uid: "warehouse_size",
            filter: {
              filterType: "dropdown",
              value: sizeFilter,
              options: getOptions(filters.warehouse_sizes),
              onChange: handleSizeFilterChange,
            },
            render: (item) => (
              <div className={tableStyles.break_word}>{item.warehouse_size}</div>
            ),
          },
          {
            id: 4,
            label: "90% exec. time",
            uid: "execution_time_p90",
            width: 1.5,
            sort: {
              onChange: handlePercentileExecTimeSortChange,
              value: sortOrder.execution_time_p90,
            },
            render: (item) => (
              <div className={tableStyles.break_word}>{item.execution_time_p90}</div>
            ),
          },
          {
            id: 5,
            label: "Insights",
            uid: "tags",
            width: 4,
            filter: {
              filterType: "dropdown",
              value: tagFilter,
              options: getOptions(filters.tags),
              onChange: handleTagFilterChange,
            },
            render: (item) => (
              <ColorInsightTags tags={item?.tags || []} />
            ),
          },
          {
            id: 6,
            label: "Auto suspend",
            uid: "auto_suspend",
            render: (item) => (
              <div className={tableStyles.break_word}>
                {roundToTwoDecimalPlaces(item.auto_suspend)} sec
              </div>
            ),
          },
          {
            id: 7,
            label: "Med. elapsed time",
            uid: "median_total_elapsed_time",
            sort: {
              onChange: handleMedianElapsedSortChange,
              value: sortOrder.median_total_elapsed_time,
            },
            render: (item) => (
              <div className={tableStyles.break_word}>
                {item.median_total_elapsed_time}
              </div>
            ),
          },
          {
            id: 8,
            label: "Auto Tune Savings",
            uid: "auto_tune_savings",
            width: 3,
            filter: {
              filterType: "dropdown",
              value: autoTuneFilter,
              options: autoTuneFilterOptions,
              onChange: handleAutotuneFilterChange,
              showSearch: false,
            },
            tooltipInfo: "Sorting by annualized savings",
            sort: {
              onChange: handleAutoTuneSavingsSortChange,
              value: sortOrder.auto_tune_savings,
            },
            render: (item) => (
              <div className={styles.auto_tune}>
                <div className={styles.auto_tune_inner}>
                  {item.is_auto_tune_enabled === "auto-tune-disabled" && (
                    <div className={styles.savings_text}>
                      Estimated (annualized):{" "}
                      <span className={styles.disabled}>
                        {formatNumber(item.estimated_annualized_savings, {
                          currency,
                        })}
                      </span>
                    </div>
                  )}
                  {item.is_auto_tune_enabled === "auto-tune-enabled" && (
                    <>
                      <div className={styles.savings_text}>
                        Realized:{" "}
                        {formatNumber(item.realized_savings, {
                          currency,
                        })}
                      </div>
                      <div className={styles.savings_text}>
                        Annualized:{" "}
                        {formatNumber(item.estimated_annualized_savings, {
                          currency,
                        })}
                      </div>
                    </>
                  )}
                </div>
                <AutoSavingsStatus autoTuneStatus={item.is_auto_tune_enabled} />
              </div>
            ),
          },
          {
            id: 9,
            width: 2,
            label: "Med. queuing time",
            uid: "median_queuing_time",
            sort: {
              onChange: handleMedianQueuingTimeSortChange,
              value: sortOrder.median_queuing_time,
            },
            render: (item) => (
              <div className={tableStyles.break_word}>
                {item.median_queuing_time}
              </div>
            ),
          },
          {
            id: 10,
            width: 2,
            label: "Active time",
            uid: "total_active_time",
            width: 0.75,
            sort: {
              onChange: handleActiveTimeSortChange,
              value: sortOrder.total_active_time,
            },
            render: (item) => (
              <div className={tableStyles.break_word}>{item.total_active_time}</div>
            ),
          },
          {
            id: 11,
            width: 2,
            label: "Idle time",
            uid: "idle_time",
            sort: {
              onChange: handleIdleTimeSortChange,
              value: sortOrder.idle_time,
            },
            render: (item) => (
              <div className={tableStyles.break_word}>
                <div>{item.idle_time}</div>
                <div>{item.idle_percentage}</div>
              </div>
            ),
          },
        ]}
        onRowClick={handleRowClick}
        data={warehouses}
        resetPage={resetPage}
      />
      <Paginate
        itemCount={warehouses.total}
        page={page}
        pageSize={pageSize}
        numPages={warehouses.pages}
        onPageClick={handlePageChange}
        onPageSizeChange={updatePageSize}
        resetPage={resetPage}
        showPageSize
      />
    </div>
  );
};

const Warehouses = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const warehouseFilters = {
    page: searchParams.get("page") || 0,
    tags: searchParams.getAll("tags") || [],
    cost: searchParams.get("cost") || undefined,
    warehouse: searchParams.getAll("warehouse") || [],
    size: searchParams.getAll("size") || undefined,
    autotune: searchParams.getAll("autotune") || [],
  };
  const navigate = useNavigate();
  const [period, setPeriod] = useState(30); //period can be 1, 7 or 30

  return (
    <div className="d-flex flex-column">
      <WarehouseHeader period={period} setPeriod={setPeriod} />
      <WarehouseListWithFilter
        setSelectedWarehouseRk={(rk) => navigate(`/warehouse/${rk}`)}
        period={period}
        defaultFilters={warehouseFilters}
      />
    </div>
  );
};

export { Warehouses };
