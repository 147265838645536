import React from "react";
import { Label, Form } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormAlert, FormInput, FormSubmit } from "./components/form";
import { BaseContainer, Header } from "./components/base";
import { useMutation } from "react-query";
import { postForgotPassword } from "../../helpers/backend_helper";
import { isFormFieldError } from "./helper";
import { Loader } from "../../Components/Loader";
import classNames from "classnames";
import styles from "./components/styles.module.scss";

const EMAIL_INPUT_ID = "free-user-forgot-email-input";

const Forgot = () => {
  const { mutate, isSuccess, data, isLoading, error } =
    useMutation(postForgotPassword);
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: { email: "" },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please Enter Your Email")
        .email("Email should be valid"),
    }),
    onSubmit: (values) => mutate(values),
  });

  const showFormFieldError = isFormFieldError(validation);
  const _error = error || showFormFieldError("email");
  const isOK = isSuccess && data.ok;

  const isMobile = window.innerWidth <= 768;

  return (
    <>
      {isLoading && <Loader />}
      <BaseContainer>
        <div className={classNames({ [styles.mobile_forgot]: isMobile })}>
          <Header>Forgot password?</Header>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
            }}
            className={`${isMobile ? "w-100 mt-3" : "mt-4"}`}
          >
            <div
              className={classNames(styles.tenant_form_input, {
                [styles.in_focus]: document.activeElement.id === EMAIL_INPUT_ID,
                [styles.is_invalid]: _error,
              })}
            >
              <FormInput
                id={EMAIL_INPUT_ID}
                name="email"
                type="email"
                placeholder="Enter email"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.email}
              />
            </div>
            {_error && (
              <div
                className={classNames(styles.form_feedback, {
                  [styles.is_error]: _error,
                })}
              >
                <span>{_error}</span>
              </div>
            )}

            <div className="text-center mt-4">
              <FormSubmit disabled={isOK}>Send instructions</FormSubmit>
            </div>
          </Form>
          {isOK && (
            <div className="mt-3 text-primary">
              Password reset instructions are sent if it's a registered email
            </div>
          )}
        </div>
      </BaseContainer>
    </>
  );
};

export { Forgot };
