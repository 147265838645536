import styles from "./styles.module.scss";
import classNames from "classnames";
import formCheck from "../../assets/icons/form_progress_check.svg";

export const FormProgress = ({ labels, currentPage = 1 }) => {
  return (
    <div className={classNames(styles.main)}>
      {labels.map((label, i) => {
        const isCurrentPage = i + 1 === currentPage;
        const isPageDone = i + 1 < currentPage;
        const formattedNumber = (i + 1 < 10 ? "0" : "") + (i + 1);

        return (
          <div
            key={i}
            className={classNames(styles.progress_box, {
              [styles.progress_box_current]: isCurrentPage,
            })}
          >
            {isPageDone ? (
              <img src={formCheck} className={styles.check} alt="check" />
            ) : (
              <div
                className={classNames(styles.circle, {
                  [styles.circle_current]: isCurrentPage,
                })}
              >
                {formattedNumber}
              </div>
            )}
            <div
              className={classNames({
                [styles.label]: !(isCurrentPage || isPageDone),
                [styles.label_blue]: isCurrentPage || isPageDone,
              })}
            >
              {label}
            </div>
          </div>
        );
      })}
    </div>
  );
};
