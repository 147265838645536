import styles from "./styles.module.scss";
import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import { useQuery } from "react-query";
import { ListSummary, FlexListSummary } from "../../Components/ListSummary";
import {
  getSummary,
  getTableInsightsById,
  getTableStorageOverTimeById,
} from "../../helpers/backend_helper";
import {
  ComponentLoader,
  RelativeComponentLoader,
} from "../../Components/Loader";
dayjs.extend(RelativeTime);
import bytes from "bytes";
import classnames from "classnames";
import { formatNumber } from "@/uiCore";
import { useAppState } from "@/modules/app/useAppContext";
import { formatCost, formatDate } from "@/helpers/utils";
import { Tooltip as UITooltip } from "@uicore";
import { InfoGray } from "@assets/icons";

const GET_SUMMARY = "GET-SUMMARY";
const GET_TABLE_INSIGHTS_BY_ID = "GET-TABLE_INSIGHTS-BY-ID";
const GET_TABLE_STORAGE_OVER_TIME_BY_ID = "GET-STORAGE_OVER_TIME-BY-ID";
const Summary = ({ table }) => {
  const { data, isLoading } = useQuery({
    queryKey: GET_SUMMARY,
    queryFn: () => getSummary(table ? { table_id: table } : null),
  });
  const items = [
    {
      label: "Last Incident",
      value: data?.last_violation_timestamp
        ? `${dayjs(data?.last_violation_timestamp).fromNow(true)} ago`
        : "None",
    },
    { label: "Errors", value: data?.total_error_count },
    { label: "Warning", value: data?.total_warning_count },
    {
      label: "Policies",
      value: data?.total_policy_violation_count,
    },
    { label: "Validations", value: data?.total_validation_count },
  ];
  if (isLoading) {
    return <ComponentLoader top={25} left={60} />;
  }
  return <ListSummary summary={items} />;
};

const TableInsightsSummary = ({ tableId }) => {
  const { currency } = useAppState();
  const convertGBtoBytes = (value) => {
    if (!value) return 0;
    return value * 1073741824;
  };
  const { data: tableInsights, isLoading } = useQuery({
    queryKey: [GET_TABLE_INSIGHTS_BY_ID, tableId],
    queryFn: () => {
      return getTableInsightsById(tableId);
    },
  });
  if (!tableInsights) {
    return null;
  }

  const items = [
    {
      label: (
        <div className="d-flex align-items-center gap-xs">
          Annualized Storage Spend
          <UITooltip
            content="(ACTIVE_BYTES + TIME_TRAVEL_BYTES + FAILSAFE_BYTES + RETAINED_FOR_CLONE_BYTES) / (1024 ^4) * 23 * 12"
            placement="top"
          >
            <div className="d-flex align-items-center">
              <InfoGray />
            </div>
          </UITooltip>
        </div>
      ),
      value: formatCost(
        tableInsights?.insights?.estimated_annualized_storage_costs,
        currency
      ),
    },
    {
      label: "Annual Data Insertion Cost",
      value:
        formatCost(tableInsights?.insights?.annualized_insert_cost, currency) ||
        "None",
    },
    {
      label: "Last Write",
      value: formatDate(tableInsights?.insights?.last_modified),
    },
    {
      label: "Last Read",
      value: formatDate(tableInsights?.insights?.last_read),
    },
    {
      label: "Reads/Writes \n(in last month)",
      value: tableInsights?.insights?.read_write_ratio || "None",
    },
  ];
  if (isLoading) {
    return <RelativeComponentLoader componentHeight={20} />;
  }
  return <FlexListSummary summary={items || []} />;
};

const TableStorageSummary = ({ tableId }) => {
  const { currency } = useAppState();
  const { data: tableStorageOverTime, isLoading } = useQuery({
    queryKey: [GET_TABLE_STORAGE_OVER_TIME_BY_ID, tableId],
    queryFn: () => {
      return getTableStorageOverTimeById(tableId);
    },
  });
  if (isLoading) {
    return <RelativeComponentLoader componentHeight={20} />;
  }
  if (
    !tableStorageOverTime?.table_owner ||
    tableStorageOverTime["table_owner"] === "Owner not found"
  ) {
    return null;
  }
  return (
    <div className={styles.summary_card}>
      {Object.keys(tableStorageOverTime).map(
        (key) =>
          tableStorageOverTime[key] !== null && (
            <p key={key}>
              <span className={classnames("text-muted", styles.summary_text)}>
                {key} :
              </span>
              <span>
                {key === "cluster_cost"
                  ? formatNumber(tableStorageOverTime[key], { currency })
                  : tableStorageOverTime[key]}
              </span>
              {"   "}
            </p>
          )
      )}
    </div>
  );
};

export { Summary, TableInsightsSummary, TableStorageSummary };
