import { Chart, ChartTypes, formatNumber, yAxisLabelProps } from "@/uiCore";
import BreakdownSkeleton from "./BreakdownSkeleton";
import dayjs from "dayjs";
import { CustomTooltip } from "@/Components/Graph/misc";
import { getKey, getTooltipNameValues } from "./utils";
import { Line, YAxis } from "recharts";
import { TotalCostEntities, BarColors } from "./constants";
import { AutoTuneSavingsSummaryResponse } from "../Context/types";
import { useAppState } from "@/modules/app/useAppContext";

interface Props {
  isFetching: boolean;
  yearMode: boolean;
  data?: AutoTuneSavingsSummaryResponse;
  savingsType: string;
  currency?: string;
  aggregateBy: string;
  isFuture?: boolean;
}

const AutonomousSavingsSection = ({
  isFetching,
  yearMode,
  data,
  savingsType,
  aggregateBy,
  isFuture,
}: Props) => {
  const { currency } = useAppState();

  if (isFetching) {
    return <BreakdownSkeleton type="chart" />;
  }
  if (!data) {
    return null;
  }

  return (
    <Chart
      title="Autonomous Savings"
      xAxisDataKey="date"
      yAxisLabel={{
        value: "Savings",
      }}
      yAxisId="value"
      xAxisLabelFormatter={(value) =>
        `${dayjs(value).format(`${yearMode ? "MMM-YY" : "DD-MMM-YY"}`)}`
      }
      yAxisLabelFormatter={(value) => `${formatNumber(value, { currency })}`}
      type={ChartTypes.LineChart}
      data={data.graph}
      tooltipProps={{
        content: (
          // @ts-ignore TODO fix this
          <CustomTooltip
            timeRange={aggregateBy as "day" | "week" | "month"}
            nameValue={getTooltipNameValues(
              savingsType as keyof typeof TotalCostEntities,
              currency
            )}
          />
        ),
      }}
    >
      {({ selectedLegend }) => (
        <>
          <YAxis
            yAxisId="number_of_decisions"
            orientation="right"
            label={{
              dx: -45,
              ...yAxisLabelProps,
              value: "Agent Decisions",
            }}
          />
          {TotalCostEntities[
            savingsType as keyof typeof TotalCostEntities
          ].fields.map((d) => {
            const k = getKey(d, isFuture);
            return (
              <Line
                yAxisId={k}
                type="monotone"
                name={d.label}
                dataKey={k}
                hide={Boolean(selectedLegend && selectedLegend !== k)}
                stroke={BarColors[k]}
                strokeWidth={5}
                dot={false}
              />
            );
          })}
        </>
      )}
    </Chart>
  );
};

export default AutonomousSavingsSection;
