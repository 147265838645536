import { useSearchParams } from "react-router-dom";

/**
 * Hook to handle table filters
 * Stores the selected filters in the URL search params
 * Also provides utility to update and delete the filters from the URL
 */
const useFiltersBySearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const clearSearchParams = () => {
    const params = new URLSearchParams();
    setSearchParams(params.toString(), { replace: true });
  };

  const updateSearchParamsByKey = (values: {
    [searchKey: string]: string | string[];
  }) => {
    if (!Object.keys(values).length) {
      return;
    }
    setSearchParams(
      () => {
        const params = new URLSearchParams(window.location.search);
        Object.entries(values).forEach(([searchKey, value]) => {
          if (Array.isArray(value)) {
            params.delete(searchKey);
            value.forEach((val) => params.append(searchKey, val));
          } else {
            // Assuming it is string or undefined
            params.set(searchKey, value);
          }
        });
        return params.toString();
      },
      { replace: true }
    );
  };

  const deleteSearchParamsByKeyValue = (values: {
    [key: string]: string | undefined;
  }) => {
    if (!Object.keys(values).length) {
      return;
    }
    setSearchParams(
      () => {
        const params = new URLSearchParams(window.location.search);
        Object.entries(values).forEach(([searchKey, value]) => {
          params.delete(searchKey, value);
        });
        return params.toString();
      },
      { replace: true }
    );
  };

  return {
    searchParams,
    updateSearchParamsByKey,
    deleteSearchParamsByKeyValue,
    clearSearchParams,
  };
};

export default useFiltersBySearchParams;
