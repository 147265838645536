import useFiltersBySearchParams from "@/helpers/useFiltersBySearchParams";
import { Button } from "reactstrap";

const ClearFilters = () => {
  const { clearSearchParams } = useFiltersBySearchParams();
  return (
    <Button color="link" onClick={clearSearchParams}>
      Clear filters
    </Button>
  );
};

export default ClearFilters;
