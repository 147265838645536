import { Tooltip } from "react-tooltip";
import styles from "./styles.module.scss";
import { useMemo } from "react";
import { uniqueId } from "lodash";

export const BetterTooltip = ({ children, tooltipContent }) => {
  const tooltipId = useMemo(() => uniqueId("tooltip-"), []);

  return (
    <>
      <div data-tooltip-id={tooltipId} data-tooltip-content={tooltipContent}>
        {children}
      </div>
      <Tooltip id={tooltipId} className={styles.tooltip} />
    </>
  );
};
