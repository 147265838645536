import styles from "./styles.module.scss";

export const SignupOptionsComponents = ({ option, signupSource }) => (
  <div key={option} className={styles.select_source_options}>
    <input
      className="me-2"
      type="radio"
      checked={signupSource === option}
      readOnly
    />
    <div className="text-muted">{option}</div>
  </div>
);
