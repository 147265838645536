import classnames from "classnames";
import {
  ScrollContainer,
  scroll_style,
} from "../../Components/ScrollContainer";
import styles from "./styles.module.scss";
import { Card, CardBody } from "reactstrap";
import { useState, useMemo } from "react";
import { DebouncedInput } from "../../Components/Form";
import { ColumnDatatype } from "../../Components/Column";
import { ColorTag } from "../../Components/Tags";
import classNames from "classnames";

const ColumnCard = ({ column }) => {
  return (
    <div className={styles.column_card}>
      <div className="d-flex align-items-center">
        <ColumnDatatype datatype={column.datatype} />
        <div className="ms-2">{column.name}</div>
        <div className="spacer" />
        <ColorTag label={column.datatype} color={styles.column_tag} />
      </div>
      <div className="mb-1 divider" />
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex gap-xs align-items-center">
          <div>Description</div>
        </div>
      </div>
      <div className="text-muted font-normal">{column.description}</div>
    </div>
  );
};

const HeaderSection = ({ table }) => {
  return (
    <div className="d-flex align-items-center">
      <div className={styles.column_table_name}>{table.model_name}</div>
    </div>
  );
};

const ColumnSection = ({ columns }) => {
  const [search, setSearch] = useState("");
  const filteredColumns = useMemo(() => {
    if (!search) return columns;
    const _search = search.toLowerCase();
    return columns.filter((c) => c.name.includes(_search));
  }, [columns, search]);
  return (
    <Card className={classnames(styles.column_section, "mb-0", styles.bg_gray)}>
      <CardBody
        className={classNames(styles.column_body, "d-flex flex-column gap-sm")}
      >
        <div className="d-flex justify-content-between align-items-center mb-1">
          <div className="d-flex justify-content-between align-items-center gap-sm">
            <div className="fs-5 fw-semibold">Columns</div>
            <div className={styles.searchBox}>
              <DebouncedInput
                size="sm"
                placeholder="Search Column"
                onChange={setSearch}
              />
            </div>
          </div>
          {search ? (
            <div className="text-primary">
              {filteredColumns.length} results found
            </div>
          ) : (
            <div>{columns.length} columns</div>
          )}
        </div>
        <div className={styles.grid}>
          <div className={styles.columnGrid}>
            {filteredColumns.map((c) => (
              <ColumnCard key={c.rk} column={c} />
            ))}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const PurposeSection = ({ purpose }) => {
  return (
    <>
      {/* <Card className={classNames("mb-0", styles.bg_gray)}>
        <CardBody className="d-flex flex-column gap-sm">
          <div className="d-flex gap-xs align-items-center justify-content-between">
            <div className="fs-5 fw-semibold">
              Generate Description for the table and columns
            </div>
          </div>
        </CardBody>
      </Card> */}

      <Card className={styles.table_description}>
        <CardBody>
          <div className="d-flex gap-xs align-items-center mb-2">
            <div className="fw-semibold">Description</div>
            <div className="spacer" />
          </div>
          <div>
            <div className="text-muted font-normal">{purpose}</div>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

const Schema = ({ table }) => {
  return (
    <div className={classnames("d-flex flex-column h-100 gap-md")}>
      <HeaderSection table={table} />
      {table.description && <PurposeSection purpose={table.description} />}
      <div className="mb-1"></div>
      <ColumnSection columns={table.columns} />
    </div>
  );
};

export { Schema };
