import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { ListItemNode, ListNode } from "@lexical/list";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS, CODE, INLINE_CODE } from "@lexical/markdown";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { CodeNode, CodeHighlightNode } from "@lexical/code";
import CodeHighlightPlugin from "./CodeHighlightPlugin";
import CodeActionMenuPlugin from "./CodeActionMenuPlugin";
import { LinkNode } from "@lexical/link";
import ImagesPlugin from "./ImagesPlugin";
import { ImageNode } from "./ImageNode";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { uniqueId } from "lodash";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";

import { DRAG_DROP_PASTE } from "@lexical/rich-text";
import { isMimeType, mediaFileReader } from "@lexical/utils";
import { COMMAND_PRIORITY_LOW } from "lexical";
import { INSERT_IMAGE_COMMAND } from "./ImagesPlugin";

const theme = {
  // Theme styling goes here
  // ...
};

// Catch any errors that occur during Lexical updates and log them
// or throw them as needed. If you don't throw them, Lexical will
// try to recover gracefully without losing user data.
function onError(error) {
  console.error(error);
}

const ACCEPTABLE_IMAGE_TYPES = [
  "image/",
  "image/heic",
  "image/heif",
  "image/gif",
  "image/webp",
];

export default function DragDropPaste() {
  const [editor] = useLexicalComposerContext();
  useEffect(() => {
    return editor.registerCommand(
      DRAG_DROP_PASTE,
      (files) => {
        (async () => {
          const filesResult = await mediaFileReader(
            files,
            [ACCEPTABLE_IMAGE_TYPES].flatMap((x) => x)
          );
          for (const { file, result } of filesResult) {
            if (isMimeType(file, ACCEPTABLE_IMAGE_TYPES)) {
              editor.dispatchCommand(INSERT_IMAGE_COMMAND, {
                altText: file.name,
                src: result,
              });
            }
          }
        })();
        return true;
      },
      COMMAND_PRIORITY_LOW
    );
  }, [editor]);
  return null;
}

function _MarkdownEditor(props, ref) {
  const editorStateRef = useRef();
  const initialConfig = useMemo(() => {
    const id = uniqueId("markdown-editor");
    const config = {
      namespace: id,
      editable: !props.readOnly,
      theme,
      onError,
      nodes: [
        HeadingNode,
        QuoteNode,
        ListNode,
        ListItemNode,
        LinkNode,
        ImageNode,
        CodeNode,
        CodeHighlightNode,
      ],
    };
    if (props.defaultValue) {
      config.editorState = props.defaultValue;
    }
    return config;
  }, [props.defaultValue, props.readOnly]);
  const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
  useImperativeHandle(
    ref,
    () => ({ getValue: () => JSON.stringify(editorStateRef.current) }),
    []
  );

  const onRef = (_floatingAnchorElem) => {
    if (_floatingAnchorElem !== null) {
      setFloatingAnchorElem(_floatingAnchorElem);
    }
  };

  return (
    <LexicalComposer initialConfig={initialConfig}>
      <RichTextPlugin
        contentEditable={
          <div ref={onRef}>
            <ContentEditable />
          </div>
        }
        ErrorBoundary={LexicalErrorBoundary}
      />
      <HistoryPlugin />
      <DragDropPaste />
      <ListPlugin />
      <LinkPlugin />
      <ImagesPlugin />
      <CodeHighlightPlugin />
      {floatingAnchorElem && (
        <CodeActionMenuPlugin anchorElem={floatingAnchorElem} />
      )}
      <OnChangePlugin
        onChange={(editorState) => (editorStateRef.current = editorState)}
      />
      <MarkdownShortcutPlugin
        transformers={[...TRANSFORMERS, CODE, INLINE_CODE]}
      />
    </LexicalComposer>
  );
}
const MarkdownEditor = forwardRef(_MarkdownEditor);
export { MarkdownEditor };
