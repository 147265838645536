import { useNavigate, useParams } from "react-router-dom";
import { default as ArrowRightIcon } from "../../assets/icons/arrow_right.svg?react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  EntityTypeLabels,
  GET_EXPERIMENTATION,
  GET_TABLE_ID,
} from "./constants";
import {
  getExperimentById,
  getQueryGroupData,
  getQueryJobData,
  getTableId,
  getWarehouseCost,
  updateExperimentStatus,
} from "../../helpers/backend_helper";
import { RelativeComponentLoader } from "../../Components/Loader";
import styles from "./styles.module.scss";
import { Card, CardBody } from "reactstrap";
import { ColorInsightTags, ModelTag, Tags } from "../../Components/Tags";
import { MarkdownEditor } from "../../Components/MarkdownEditor";
import { useMemo, useRef, useState } from "react";
import { uniqueId } from "lodash";
import { default as ArrowDown } from "../../assets/icons/arrow_down.svg?react";
import { Dropdown } from "../../Components/Form";
import { FlexListSummary } from "../../Components/ListSummary";
import dayjs from "dayjs";
import { useUsersWithServiceUser } from "../../helpers/hooks";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { default as ArrowDownIcon } from "@/assets/icons/arrow_down.svg?react";

const CustomYAxisTick = ({ x, y, payload, dataType }) => (
  <g transform={`translate(${x},${y})`}>
    <text textAnchor="end" dy={5} fill="#66768D">
      {dataType?.prefix ?? ""}
      {payload.value}
      {dataType?.suffix ?? ""}
    </text>
  </g>
);

const CustomXAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-30}
        dy={4}
        transform="rotate(270)"
        textAnchor="middle"
        fill="#66768D"
      >
        {payload.value}
      </text>
    </g>
  );
};

const DataTypes = [
  { label: "Cost", value: "cost", prefix: "$", suffix: "" },
  {
    label: "Execution Time",
    value: "execution_time",
    prefix: "",
    suffix: "mins",
  },
  // {
  //   label: "Cost per GB Processed",
  //   value: "cost_per_gb_processed",
  //   prefix: "$",
  //   suffix: "",
  // },
  {
    label: "Bytes Scanned",
    value: "bytes_scanned",
    prefix: "",
    suffix: "GB",
  },
  {
    label: "Spillage (Local)",
    value: "bytes_spilled_local",
    prefix: "",
    suffix: "GB",
  },
  {
    label: "Spillage (Remote)",
    value: "bytes_spilled_remote",
    prefix: "",
    suffix: "GB",
  },
];

const Graph = ({ data, dataType, xDataKey, yDataKey }) => {
  return (
    <ResponsiveContainer minHeight={400} className="mb-4">
      <BarChart data={data} margin={{ top: 40, right: 50, bottom: 50 }}>
        <CartesianGrid />
        <XAxis
          dataKey={xDataKey}
          interval={0}
          label={{ value: "Time", position: "right", fill: "black" }}
          tick={<CustomXAxisTick />}
        />
        <YAxis
          tick={<CustomYAxisTick dataType={dataType} />}
          label={{
            value: dataType?.label,
            fill: "black",
            position: "top",
            offset: 20,
          }}
        />
        <Tooltip />
        <Bar
          barSize={8}
          dataKey={yDataKey}
          fill="#5B9FFF"
          radius={[5, 5, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
};

const GET_ENTITY_DATA = "GET-ENTITY-DATA";

const EntityGraph = (props) => {
  const [dataType, setDataType] = useState("cost");
  const { entity_type, entity_rk } = props;
  const { data: _data, isLoading } = useQuery({
    queryKey: [GET_ENTITY_DATA, entity_type, entity_rk],
    queryFn: () => {
      if (entity_type === "datasets" || entity_type === "schema") {
        return;
      }
      if (entity_type === "warehouse") {
        return getWarehouseCost(props.entity_rk, { period: 30 });
      }
      if (entity_type === "query_jobs") {
        return getQueryJobData(props.entity_rk, { period: 30 });
      }
      if (entity_type === "query_groups") {
        return getQueryGroupData(props.entity_rk, { period: 30 });
      }
    },
  });
  const [xDataKey, yDataKey] = useMemo(() => {
    if (entity_type === "datasets" || entity_type === "schema") return ["", ""];
    if (entity_type === "warehouse") return ["date", "cost"];
    if (entity_type === "query_jobs") return ["date", dataType];
    if (entity_type === "query_groups") return ["date", dataType];
    return ["", ""];
  }, [dataType, entity_type]);
  if (isLoading) return <RelativeComponentLoader />;
  if (!_data) return null;
  const { data } = _data;
  if (data.length === 0) return null;

  const currentDataType = DataTypes.find((x) => x.value === dataType);
  return (
    <Card className="mb-0">
      <CardBody>
        {entity_type !== "query_jobs" && entity_type !== "query_groups" ? (
          <div className="fs-5 mb-3">{props.entity_name} cost over time</div>
        ) : (
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="fs-5">{currentDataType?.label} over time</div>
            <Dropdown
              id="data-type-dropdown"
              options={DataTypes}
              onChange={setDataType}
              value={dataType}
              popoverProps={{ placement: "bottom-end", offset: [0, 0] }}
            >
              <div className={styles.chart_data_type}>
                {currentDataType?.label}
                <ArrowDownIcon />
              </div>
            </Dropdown>
          </div>
        )}
        <div className={styles.warehouse_graph}>
          <Graph
            data={data}
            dataType={currentDataType}
            xDataKey={xDataKey}
            yDataKey={yDataKey}
          />
        </div>
      </CardBody>
    </Card>
  );
};

const ExperimentationItem = () => {
  const { experimentId } = useParams();
  const [tableId, setTableId] = useState();
  const navigate = useNavigate();
  const hypothesisRef = useRef();
  const instructionRef = useRef();
  const id = useMemo(() => uniqueId("experiment-status-"), []);
  const { users } = useUsersWithServiceUser();
  const queryClient = useQueryClient();
  const { data: experiment, isLoading } = useQuery({
    queryKey: [GET_EXPERIMENTATION, experimentId],
    queryFn: async () => {
      const _experiment = await getExperimentById(experimentId);
      const _entity = (_experiment.entities || [])[0];
      if (!_entity) return _experiment;
      if (_entity.entity_type !== "datasets") return _experiment;
      const _tableId = await getTableId(_entity.entity_rk);
      if (_tableId?.table_id) setTableId(_tableId.table_id);
      return _experiment;
    },
  });
  const { mutate } = useMutation({
    mutationFn: (data) => updateExperimentStatus(experimentId, data),
    onSuccess: () => {
      queryClient.invalidateQueries([GET_EXPERIMENTATION, experimentId]);
    },
  });

  const insights = useMemo(() => {
    return Array.from(
      new Set(
        (experiment?.entities || [])
          .map((e) => e.insights || [])
          .flat()
          .map((i) => i.tag_rk)
      )
    );
  }, [experiment?.entities]);

  if (isLoading) return <RelativeComponentLoader />;
  return (
    <div className="h-100 d-flex flex-column">
      <div className="mb-4 d-flex align-items-center gap-sm">
        <h3 className="text-black mb-0 cursor-default">Experimentation</h3>
        <div className={styles.header_icon}>
          <ArrowRightIcon />
        </div>
        <h3 className="text-black mb-0">{experiment.name}</h3>
        <div className="spacer" />
        <Dropdown
          id={id}
          value={experiment.experiment_status}
          options={[
            { label: "Planned", value: "Planned" },
            { label: "In Progress", value: "In Progress" },
            { label: "Success", value: "success" },
            { label: "Failure", value: "failure" },
            { label: "Aborted", value: "Aborted" },
          ]}
          onChange={(v) => {
            mutate({ experiment_status: v });
          }}
        >
          <div className={styles.experiment_status_dropdown}>
            {experiment.experiment_status}
            <div className={styles.arrow_down}>
              <ArrowDown />
            </div>
          </div>
        </Dropdown>
      </div>
      <FlexListSummary
        summary={[
          {
            label: "Period",
            value: `${dayjs(experiment.experiment_start).format(
              "DD MMM YYYY"
            )} - ${dayjs(experiment.experiment_end).format("DD MMM YYYY")}`,
          },
          {
            label: "Status",
            value: experiment.experiment_status,
          },
          {
            label: "Owner",
            value: users?.find((u) => u.id === experiment.created_by)
              ?.first_name,
          },
          {
            label: "Effort level",
            value: experiment.effort_level,
          },
        ]}
      />
      <div className={styles.grid_col_2}>
        {(experiment.entities || []).map((e) => (
          <EntityGraph {...e} key={e.entity_rk} />
        ))}
        {insights.length > 0 && (
          <Card className="mb-0">
            <CardBody>
              <div className="fs-5 mb-3">Insights</div>
              <Tags>
                {insights.map((item) => (
                  <ModelTag key={item} label={item} />
                ))}
              </Tags>
            </CardBody>
          </Card>
        )}
        <Card className="mb-0">
          <CardBody>
            <div className="fs-5 mb-3">
              {EntityTypeLabels[(experiment.entities || [])?.[0]?.entity_type]}
            </div>
            <ColorInsightTags
              tags={experiment.entities || []}
              labelFn={(e) => e.entity_name}
              onClick={(item) => {
                if (item.entity_type === "warehouse") {
                  navigate(`/warehouse/${item.entity_rk}`);
                } else if (item.entity_type === "query_groups") {
                  navigate(`/query/groups/${item.entity_rk}`);
                } else if (item.entity_type === "query_jobs") {
                  navigate(`/query/jobs/${item.entity_rk}`);
                } else if (item.entity_type === "datasets" && tableId) {
                  navigate(`/datasets/${tableId}`);
                }
              }}
            />
          </CardBody>
        </Card>
        <Card className="mb-0">
          <CardBody>
            <div className="fs-5 mb-3">Instructions</div>
            <div className={styles.markdown_input}>
              <MarkdownEditor
                ref={instructionRef}
                defaultValue={experiment.instructions}
                readOnly
              />
            </div>
          </CardBody>
        </Card>
        <Card className="mb-0">
          <CardBody>
            <div className="fs-5 mb-3">Hypothesis / Reason</div>
            <div className={styles.markdown_input}>
              <MarkdownEditor
                ref={hypothesisRef}
                defaultValue={experiment.hypothesis}
                readOnly
              />
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export { ExperimentationItem };
