import React, { useState, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Cell,
  Legend,
  Rectangle,
  Label,
  ResponsiveContainer,
  LineChart,
  Line,
} from "recharts";
import styles from "./styles.module.scss";
import classNames from "classnames";
import dayjs from "dayjs";
import { Dropdown } from "../../Components/Form";
import { default as ArrowDown } from "../../assets/icons/arrow_down.svg?react";
import { useQuery } from "react-query";
import {
  GET_WAREHOUSE_CLUSTER_UTILIZATION_EFFICIENCY,
  GET_WAREHOUSE_COST,
} from "./constants";
import {
  getWarehouseClusterWarehouseUtilization,
  getWarehouseCost,
  getWarehouseCostV2,
} from "../../helpers/backend_helper";
import { RelativeComponentLoader } from "../../Components/Loader";
import { EmptyScreen } from "./misc";
import { Chart, ChartTypes, Select } from "@/uiCore";
import {
  getDateByEndOfDayWithoutTimeoffset,
  getDateByStartOfDayWithoutTimeoffset,
} from "@/Components/DateRange/utils";
import { CustomTooltip } from "@/Components/Graph/misc";
import CostChartTooltip from "../Summary/Components/CostChartTooltip";
import { useAppState } from "@/modules/app/useAppContext";

// const TimeFrameSelector = ({
//   width = 180,
//   options,
//   timeframe,
//   setTimeframe,
// }) => {
//   const handleOptionClick = (timeframe) => {
//     setTimeframe(timeframe);
//   };
//   return (
//     <div className={styles.timeframe} style={{ width: `${width}px` }}>
//       {options.map((o, i) => {
//         const isSelected = o.value === timeframe;
//         return (
//           <div
//             key={i}
//             className={classNames(styles.item, {
//               [styles.selected]: isSelected,
//             })}
//             onClick={() => handleOptionClick(o.value)}
//           >
//             <div className={styles.text}>{o.label}</div>
//           </div>
//         );
//       })}
//     </div>
//   );
// };

const TimeFrameSelectorDropdown = ({ options, period, setPeriod }) => {
  const getLabelByPeriod = (period) => {
    return options.find((p) => p.value === period).label;
  };

  return (
    <Dropdown
      id="graph-period-dropdown"
      onChange={setPeriod}
      value={period}
      options={options.map((p) => ({
        label: <div>{p.label}</div>,
        value: p.value,
      }))}
      showDivider
    >
      <div className={styles.graph_dropdown}>
        <div className={styles.graph_label}>{getLabelByPeriod(period)}</div>
        <div className={styles.arrow_down}>
          <ArrowDown />
        </div>
      </div>
    </Dropdown>
  );
};

const CustomYAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text textAnchor="end" dy={5} fill="#66768D">
      ${payload.value}
    </text>
  </g>
);

const CustomYAxisTickEfficiency = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text textAnchor="end" dy={5} fill="#66768D">
      {payload.value}
    </text>
  </g>
);

const CustomXAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-30}
        dy={4}
        transform="rotate(270)"
        textAnchor="middle"
        fill="#66768D"
      >
        {payload.value}
      </text>
    </g>
  );
};

const CostsGraph = ({
  stackedData,
  threshold,
  timeframe,
  setTimeframe,
  aggregation,
}) => {
  const { currencySymbol } = useAppState();
  if (stackedData && stackedData.length === 0)
    return (
      <EmptyScreen
        title="Graph not available"
        subTitle="No data available for the selected period."
      />
    );

  return (
    <div className={styles.graph}>
      {/* <div className={styles.info}>
        <TimeFrameSelectorDropdown
          options={timeframes}
          period={timeframe}
          setPeriod={setTimeframe}
        />
      </div> */}

      <div style={{ height: "400px", width: "100%" }}>
        <Chart
          title="Cost"
          xAxisDataKey="time"
          yAxisLabel={{
            value: "Cost",
            angle: 0,
            fill: "black",
            position: "insideTopLeft",
            offset: 5,
          }}
          yAxisLabelFormatter={(value) => `${currencySymbol}${value}`}
          type={ChartTypes.BarChart}
          data={stackedData}
          tooltipProps={{
            content: (props) => (
              <CostChartTooltip
                {...props}
                timeRange={aggregation}
                isFuture={false}
                type={"warehouse"}
              />
            ),
          }}
        >
          {({ barSize, selectedLegend }) => (
            <>
              {/* <ReferenceLine
                y={threshold}
                stroke="#B8BFCA"
                strokeDasharray="3 3"
                label={{
                  position: "left",
                  offset: -60,
                  value: `${currencySymbol}${threshold}`,
                  fill: "#66768D",
                }}
              /> */}

              <Bar
                barSize={8}
                hide={Boolean(
                  selectedLegend && selectedLegend !== "compute cost"
                )}
                name="Compute cost"
                dataKey="compute cost"
                fill="#5B9FFF"
                stackId="cost"
                shape={(props) => {
                  if (props.payload["query acceleration cost"] === 0) {
                    props.radius = [5, 5, 0, 0];
                  }

                  return <Rectangle {...props} />;
                }}
              />
              <Bar
                barSize={8}
                dataKey="query acceleration cost"
                hide={Boolean(
                  selectedLegend && selectedLegend !== "query acceleration cost"
                )}
                name="Query acceleration cost"
                fill="#FFCE73"
                stackId="cost"
                radius={[5, 5, 0, 0]}
              />
            </>
          )}
        </Chart>
      </div>
    </div>
  );
};

const UTILIZATION_COLORS = [
  "#247EFE", // Original color
  "#FF6B6B", // Soft Red
  "#4ECDC4", // Teal
  "#F7B731", // Mustard
  "#A06CD5", // Purple
  "#45B7D1", // Sky Blue
  "#FF8C00", // Dark Orange
  "#00CED1", // Dark Turquoise
  "#FF69B4", // Hot Pink
  "#32CD32", // Lime Green
  "#FF7F50", // Coral
  "#9370DB", // Medium Purple
  "#20B2AA", // Light Sea Green
  "#FF4500", // Orange Red
  "#7B68EE", // Medium Slate Blue
  "#00FA9A", // Medium Spring Green
  "#FF1493", // Deep Pink
  "#00BFFF", // Deep Sky Blue
  "#FFD700", // Gold
  "#8A2BE2", // Blue Violet
  "#3CB371", // Medium Sea Green
  "#DC143C", // Crimson
  "#1E90FF", // Dodger Blue
  "#FF8C69", // Salmon
  "#6A5ACD", // Slate Blue
  "#2E8B57", // Sea Green
];

const UtilizationEfficiencyGraph = ({ stackedData }) => {
  const dataKeys =
    stackedData.length > 0
      ? stackedData.reduce((acc, curr) => {
          Object.keys(curr).forEach((key) => {
            if (key !== "date" && !acc.includes(key)) {
              acc.push(key);
            }
          });
          return acc;
        }, [])
      : [];

  return (
    <div style={{ height: "400px", width: "100%" }}>
      <Chart
        title="Utilization Efficiency"
        xAxisDataKey="date"
        yAxisLabel={{
          value: "Utilization Efficiency",
          angle: 0,
          fill: "black",
          position: "insideTopLeft",
          offset: 5,
        }}
        type={ChartTypes.LineChart}
        data={stackedData}
      >
        {({ selectedLegend }) => (
          <>
            {dataKeys.map((key, index) => {
              const color =
                UTILIZATION_COLORS[index % UTILIZATION_COLORS.length];
              const name =
                key === "overall_utilization_efficiency"
                  ? "Overall"
                  : `Cluster ${key.split("_").pop()}`;
              return (
                <Line
                  key={key}
                  type="monotone"
                  dataKey={key}
                  stroke={color}
                  dot={{
                    strokeWidth: 2,
                    fill: color,
                  }}
                  hide={Boolean(selectedLegend && selectedLegend !== key)}
                  name={name}
                />
              );
            })}
          </>
        )}
      </Chart>
    </div>
  );
};

const SelectorDropdown = ({
  id = "chart-selector",
  label = "Chart Of",
  options,
  method,
  setMethod,
}) => {
  return (
    <Select
      id={id}
      onChange={setMethod}
      value={method}
      options={options.map((o) => ({
        label: <div>{o.label}</div>,
        value: o.value,
      }))}
      label={label}
      showDivider
    />
  );
};

const ChartOptions = [
  { label: "Costs", value: "costs" },
  {
    label: "Utilization Efficiency",
    value: "utilization_efficiency",
  },
];

const AggregationOptions = [
  { label: "Daily", value: "day" },
  { label: "Weekly", value: "week" },
  { label: "Monthly", value: "month" },
];
const timeframes = [
  { label: "Day", value: 1 },
  { label: "Week", value: 7 },
  { label: "Month", value: 30 },
];

const Graph = ({ warehouse_rk, startDate, endDate }) => {
  const [chartType, setChartType] = useState("costs");
  const [aggregation, setAggregation] = useState("day");
  const [data, setData] = useState([]);
  const [efficiencyData, setEfficiencyData] = useState([]);
  const [threshold, setThreshold] = useState([]);

  const getParams = () => {
    const params = {};
    if (startDate) params.start_date = startDate.toISOString();
    if (endDate) params.end_date = endDate.toISOString();
    if (aggregation) params.aggregation = aggregation;
    return params;
  };

  const { isLoading } = useQuery({
    queryKey: [GET_WAREHOUSE_COST, startDate, endDate, aggregation],
    queryFn: () => getWarehouseCostV2(warehouse_rk, getParams()),
    onSuccess: (res) => {
      setData(res?.warehouse_cost);
      setEfficiencyData(res?.utilization_efficiency);
      setThreshold(res?.threshold);
    },
  });

  const {
    data: clusterEfficiencyData,
    isLoading: isClusterEfficiencyDataLoading,
  } = useQuery({
    queryKey: [
      GET_WAREHOUSE_CLUSTER_UTILIZATION_EFFICIENCY,
      startDate,
      endDate,
      aggregation,
    ],
    queryFn: () =>
      getWarehouseClusterWarehouseUtilization(warehouse_rk, getParams()),
    enabled: chartType === "utilization_efficiency",
  });

  const formatDate = (date) => {
    return dayjs(date).format("DD MMM YY");
  };

  const roundToTwoDecimalPlaces = (number) => {
    return Math.round(number * 100) / 100;
  };

  const addPercentageInFormattedData = (formattedData, key) => {
    formattedData.forEach((entry, index) => {
      if (index === 0) return;
      const previousEntry = formattedData[index - 1];
      const previousValue = previousEntry[key];
      const currentValue = entry[key];
      const percentage = roundToTwoDecimalPlaces(
        ((currentValue - previousValue) / previousValue) * 100
      );
      entry[`percentage_${key}`] = percentage;
    });
  };
  const stackedData = useMemo(() => {
    if (!data || data.length === 0) return [];
    const formattedData = data.map((entry) => ({
      time: formatDate(entry.date),
      date: formatDate(entry.date),
      cost: roundToTwoDecimalPlaces(entry.cost),
      "cost over threshold":
        entry.cost > threshold
          ? roundToTwoDecimalPlaces(entry.cost - threshold)
          : 0,
      threshold: threshold,
      "total cost": roundToTwoDecimalPlaces(
        entry.cost + entry.query_acceleration_cost
      ),
      "compute cost": roundToTwoDecimalPlaces(entry.cost),
      "query acceleration cost": roundToTwoDecimalPlaces(
        entry.query_acceleration_cost
      ),
      "cost under threshold":
        entry.cost > threshold
          ? roundToTwoDecimalPlaces(threshold)
          : roundToTwoDecimalPlaces(entry.cost),
    }));
    addPercentageInFormattedData(formattedData, "total cost");
    addPercentageInFormattedData(formattedData, "compute cost");
    addPercentageInFormattedData(formattedData, "query acceleration cost");
    return formattedData;
  }, [data, threshold]);

  const stackedClusterEfficiencyData = useMemo(() => {
    if (
      !clusterEfficiencyData ||
      !clusterEfficiencyData.overall_utilization_efficiency ||
      !clusterEfficiencyData.cluster_utilization_efficiency
    ) {
      return [];
    }

    const overallData = clusterEfficiencyData.overall_utilization_efficiency;
    const clusterData = clusterEfficiencyData.cluster_utilization_efficiency;
    const clusterKeys = Object.keys(clusterData);

    const clusterMap = clusterKeys.reduce((acc, clusterKey) => {
      acc[clusterKey] = clusterData[clusterKey].reduce((clusterAcc, entry) => {
        clusterAcc[formatDate(entry.date)] = entry.utilization;
        return clusterAcc;
      }, {});
      return acc;
    }, {});

    return overallData.map((overall) => {
      const result = {
        date: formatDate(overall.date),
        overall_utilization_efficiency: overall.utilization,
      };

      clusterKeys.forEach((clusterKey) => {
        const utilization = clusterMap[clusterKey][formatDate(overall.date)];
        if (utilization) {
          result[`utilization_efficiency_cluster_${clusterKey}`] = utilization;
        }
      });

      return result;
    });
  }, [clusterEfficiencyData]);

  if (isLoading) {
    return (
      <RelativeComponentLoader
        componentHeight={40}
        label="Fetching warehouse cost data..."
      />
    );
  }

  if (isClusterEfficiencyDataLoading) {
    return (
      <RelativeComponentLoader
        componentHeight={40}
        label="Fetching cluster utilization efficiency data..."
      />
    );
  }

  return (
    <div className="d-flex flex-column">
      <div className="d-flex align-items-center justify-content-between">
        <h4>{ChartOptions.find((c) => c.value === chartType).label}</h4>
        <div className="d-flex align-items-center gap-sm">
          <SelectorDropdown
            options={ChartOptions}
            method={chartType}
            setMethod={setChartType}
          />
          <SelectorDropdown
            label="Aggregate"
            options={AggregationOptions}
            method={aggregation}
            setMethod={setAggregation}
          />
        </div>
      </div>
      <div className={styles.warehouse_graph}>
        {chartType === "costs" && (
          <CostsGraph
            stackedData={stackedData}
            threshold={threshold}
            aggregation={aggregation}
          />
        )}
        {chartType === "utilization_efficiency" && (
          <UtilizationEfficiencyGraph
            stackedData={stackedClusterEfficiencyData}
          />
        )}
      </div>
    </div>
  );
};

export { Graph };
