import { useQuery } from "react-query";
import { getDatastores } from "../../helpers/backend_helper";
import { DatastoreList } from "./DatastoreList";
import { ComponentLoader } from "../../Components/Loader";
import { getUser } from "../../services/auth";
import styles from "./styles.module.scss";
import { Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { PermissionsEnum } from "../../helpers/constants";
import { Tooltip } from "react-tooltip";

export const GET_DATASTORES = "GET-DATASTORES";
const Datastores = () => {
  const navigate = useNavigate();
  const user = getUser();
  const { data, isLoading } = useQuery({
    queryKey: [GET_DATASTORES],
    queryFn: getDatastores,
  });
  const addDatastorePermission = user.permission.includes(PermissionsEnum.DATASTORE_CREATE)

  return (
    <div>
      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h3 className=" text-black">Datastores</h3>
        <div data-tooltip-id="add_datastores_permissions">
          <Button
            color="primary"
            onClick={() => navigate("/settings/datastores/add")}
            className={styles.create_datastore_button}
            disabled={!addDatastorePermission}
          >
            Add Datastore
          </Button>
          {!addDatastorePermission &&
            <Tooltip
              id={"add_datastores_permissions"}
              className={styles.sync_disabled_tooltip}
            >
              <div className="d-flex flex-column align-items-left gap-xs justify-content-center">

                <div>Insufficient permissions for adding a Datastore</div>
              </div>
            </Tooltip>
          }
        </div>
      </div>
      {isLoading ? (
        <ComponentLoader top={60} left={60} />
      ) : (
        <DatastoreList datastores={data} />
      )}
    </div>
  );
};

export { Datastores };
