import { formatNumber } from "@/uiCore";
import { BarColors, TotalCostEntities } from "./constants";
import dayjs from "dayjs";
import { CustomTooltipItemProps } from "@/Components/Graph/misc";

export const getTooltipNameValues = (
  costType: keyof typeof TotalCostEntities,
  currency?: string,
  isFuture?: boolean
) => {
  return [
    {
      name: "",
      value: "date",
      color: "gray",
      formatter: (payload: Record<string, string | number>) =>
        dayjs(payload.date).format("DD-MMM-YY"),
    },
    ...TotalCostEntities[costType].fields.map((d) => {
      const k = getKey(d);

      const colorKey = isFuture
        ? (`${k}_future` as unknown as keyof typeof BarColors)
        : k;

      return {
        name: d.label,
        value: k,
        color: BarColors[colorKey],
        formatter: (
          payload: Record<string, string | number>,
          item: CustomTooltipItemProps
        ) =>
          tooltipFormatter(
            payload,
            item,
            costType,
            isPropertyDisabled(d, "hideCurrency") ? "" : currency
          ),
        showSticker: !isPropertyDisabled(d, "hideSticker"),
        showChangePercentage: Boolean(
          isPropertyDisabled(d, "showChangePercentage")
        ),
      };
    }),
  ];
};

const getSavingsValueOrRange = (
  payload: Record<string, string | number>,
  value: string,
  currency?: string
) => {
  if (
    payload[`min_${value}`] as number > 0 &&
    payload[`max_${value}`] as number > 0
  ) {
    const min = formatNumber(payload[`min_${value}`], {
      currency,
    });
    const max = formatNumber(payload[`max_${value}`], {
      currency,
    });
    return `${min} - ${max}`;
  }
  return  payload[value] ? formatNumber( payload[value], {
    currency,
  }) : undefined;
}

const tooltipFormatter = (
  payload: Record<string, string | number>,
  item: CustomTooltipItemProps,
  costType: string,
  currency?: string
) => {
  const value = getSavingsValueOrRange(payload, item.value, currency);
  if (!value) {
    return undefined;
  }
  if (costType === "overall") {
    if (item.name === "total") {
      const sum =
        Number(payload.compute) +
        Number(payload.storage) +
        Number(payload.serverless);
      return `${formatNumber(sum, { currency })}`;
    }
    return value;
  }
  return value;
};

const isPropertyDisabled = (
  k: string | Record<string, unknown>,
  property: string
) => {
  if (typeof k === "object" && property in k) {
    return k[property];
  }
  return false;
};

export const getKey = (
  k: {
    key: { current: string; estimate: string };
    label: string;
  },
  isFuture?: boolean
): keyof typeof BarColors => {
  return k.key[
    isFuture ? "estimate" : "current"
  ] as unknown as keyof typeof BarColors;
};

export const formatDate = (date: string | Date | null, yearMode: boolean) =>
  date ? dayjs(date).format(yearMode ? "MMM-YY" : "DD-MMM-YY") : "";
