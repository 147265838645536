import { Link } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";
import styles from "./styles.module.scss";
import no_resource from "@/assets/images/no_resource.svg";
import classNames from "classnames";

export const NoResource = ({
  title,
  message,
}: {
  title: string;
  message: string;
}) => {
  return (
    <div className={styles.no_incident}>
      <Card className={styles.card}>
        <CardBody className="d-flex justify-content-center">
          <div
            className={classNames(
              "d-flex flex-column align-items-center p-4",
              styles.container
            )}
          >
            <img src={no_resource} alt="No Incident" className={styles.img} />
            <h2 className="mt-4 mb-4 text-black">{title}</h2>
            <p className="text-muted text-center">{message}</p>
            <Link to="/quickstart">
              <Button color="primary">Go to Quickstart</Button>
            </Link>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
