import React, { lazy, Suspense, forwardRef } from "react";

const LazyCueCodeEditor = lazy(() => import("./CueCodeEditor"));
const LazySQLCodeEditor = lazy(() => import("./SQLCodeEditor"));
const LazyCodeEditor = lazy(() => import("./CodeEditor"));
const LazyROCodeEditor = lazy(() => import("./ROCodeEditor"));
const LazyCodeDiffEditor = lazy(() => import("./CodeDiffEditor"));

export const CueCodeEditor = (props) => (
  <Suspense fallback={<div>Loading...</div>}>
    <LazyCueCodeEditor {...props} />
  </Suspense>
);
export const CodeSQLEditor = forwardRef(function Editor(props, ref) {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LazySQLCodeEditor {...props} ref={ref} />
    </Suspense>
  );
});

export const ROCodeEditor = (props) => (
  <Suspense fallback={<div>Loading...</div>}>
    <LazyROCodeEditor {...props} />
  </Suspense>
);

export const CodeEditor = forwardRef(function Editor(props, ref) {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyCodeEditor {...props} ref={ref} />
    </Suspense>
  );
});

export const CodeDiffEditor = forwardRef(function Editor(props, ref) {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <LazyCodeDiffEditor {...props} ref={ref} />
    </Suspense>
  );
});
