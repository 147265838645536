import { lazy, Suspense, useEffect } from "react";
import { RelativeComponentLoader } from "@/Components/Loader";
import classes from "../summary.module.scss";
import { api, generateStream } from "@/helpers/api_helper";
import { ChatMessage } from "@ant-design/pro-chat";
import { useIntercom } from "react-use-intercom";
import useSummaryState from "../useSummaryState";
import { StatusInfoMessage, TeamMatesConfig } from "@/lib/altimate/altimate-components";
import { TaskLabels } from "@/lib/altimate/altimate-components";
import { useAppDispatch } from "@/modules/app/useAppContext";
import { setSidebarExpanded } from "@/modules/app/appSlice";
import { IconButton } from "@/uiCore";
import { CloseIcon } from "@/assets/icons";
import { useSummaryDispatch } from "../SummaryNew";
import { setShowChatbot } from "../Context/summarySlice";

const Chatbot = lazy(() => import("@/modules/chatbot/Chatbot"));

const SummaryChatbot = () => {
  const { update } = useIntercom();
  const { showChatbot } = useSummaryState();
  const appDispatch = useAppDispatch();
  const summaryDispatch = useSummaryDispatch();

  const showSummaryChatBot = showChatbot !== undefined;

  useEffect(() => {
    if (showSummaryChatBot) {
      update({ hideDefaultLauncher: true });
      appDispatch(setSidebarExpanded(false));
    } else {
      update({ hideDefaultLauncher: false });
      appDispatch(setSidebarExpanded(true));
    }
  }, [showSummaryChatBot]);

  const handleFollowupRequest = async (sessionId: string) => {
    try {
      const response = await api.get<{ questions: string[] }>(
        "/summary/v2/datapilot/follow-up-questions-chart-chat",
        {
          session_id: sessionId,
        }
      );
      return response?.questions;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const onRequest = async (
    messages: ChatMessage[],
    sessionId: string,
    onStatusUpdate: (info: StatusInfoMessage) => void
  ) => {
    const userMessages = messages.filter((message) => message.role === "user");

    const lastMessage = userMessages[userMessages.length - 1];
    if (!lastMessage.content) {
      return;
    }

    return new Promise((resolve) => {
      generateStream(
        "/summary/v2/datapilot/start-chart-chat",
        (chunk: string) => {
          try {
            console.log("processing chunk", chunk);
            const status = JSON.parse(chunk) as StatusInfoMessage;
            if (status.type === "info") onStatusUpdate(status);
            if (status.type === "agent_outcome") {
              if (status.citations){
                onStatusUpdate({type: "citations", citations: status.citations, message: ""})
              }
              resolve(status.message);
            }
          } catch (err) {
            console.warn("error while parsing stream onprogress", err);
          }
        },
        {
          session_id: sessionId,
          ...params,
          user_query: lastMessage.content,
        }
      );
    });
  };

  if (!showSummaryChatBot) {
    return;
  }

  const { helloMessage, ...params } = showChatbot;

  const chartAnalyserBot = TeamMatesConfig.find(
    (t) => t.key === TaskLabels.ChartBot
  );

  const assistantMeta = chartAnalyserBot
    ? { avatar: chartAnalyserBot.avatar, name: chartAnalyserBot.name }
    : undefined;

  const closeSummaryChatbot = () => {
    summaryDispatch(setShowChatbot(undefined));
  };

  return (
    <div className={classes.summaryChatbot}>
      <Suspense
        fallback={<RelativeComponentLoader label={"Loading Chatbot"} />}
      >
        <IconButton className={classes.closeBtn} onClick={closeSummaryChatbot}>
          <CloseIcon />
        </IconButton>
        <Chatbot
          onRequest={onRequest}
          feedback_id={`summary-${Date.now()}`}
          feedback_type={`summary`}
          onFollowupRequest={handleFollowupRequest}
          helloMessage={helloMessage}
          assistantMeta={assistantMeta}
        />
      </Suspense>
    </div>
  );
};

export default SummaryChatbot;
