import { Button, Card, CardBody } from "reactstrap";
import styles from "./styles.module.scss";
import classnames from "classnames";
import no_resource from "../../assets/images/no_resource.svg";
import no_compiled_sql from "../../assets/images/no_compiled_sql.svg";
import { Link } from "react-router-dom";
import { getIntegrationEnvironments } from "../../helpers/backend_helper";
import { useQuery } from "react-query";
import { useState } from "react";
import { RelativeComponentLoader } from "../../Components/Loader";

const GET_INTEGRATION_ENVIRONMENTS = "GET-INTEGRATION-ENVIRONMENTS";

const NoDbtModels = () => {
  return (
    <div className={styles.no_dbt_models}>
      <Card className={styles.card}>
        <CardBody className="d-flex justify-content-center">
          <div
            className={classnames(
              "d-flex flex-column align-items-center p-4",
              styles.container
            )}
          >
            <img src={no_resource} alt="No Datasets" className={styles.img} />
            <h2 className="mt-4 mb-4 text-black">No dbt Models</h2>
            <p className="text-muted text-center">
              {/* TODO: Update this message */}
              Please integrate your dbt project to view models
            </p>
            <Link to="/settings/integrations">
              <Button color="primary">Add Integration</Button>
            </Link>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

const NoCompiledSql = ({
  modelName,
  integrationId,
  integrationEnvironmentId,
}) => {
  const [integrationEnvironment, setIntegrationEnvironment] = useState(null);

  const { data, isLoading } = useQuery({
    queryKey: [GET_INTEGRATION_ENVIRONMENTS],
    queryFn: () => getIntegrationEnvironments(integrationId),
    onSuccess: (data) => {
      setIntegrationEnvironment(
        data.find(
          (integrationEnvironment) =>
            integrationEnvironment.id === integrationEnvironmentId
        )
      );
    },
  });

  if (isLoading) {
    return <RelativeComponentLoader componentHeight={50} />;
  }

  return (
    <div className={styles.no_dbt_models}>
      <div className={styles.card}>
        <div className="d-flex justify-content-center">
          <div
            className={classnames(
              "d-flex flex-column align-items-center p-4",
              styles.container
            )}
          >
            <img
              src={no_compiled_sql}
              alt="No Compiled SQL"
              className={styles.no_sql}
            />
            <p className="text-center">
              The model <span className="text-muted">{modelName}</span> did not
              have the compiled sql in the manifest. Please re upload the
              manifest and catalog after running{" "}
              <span className="text-muted">(dbt compile)</span>
            </p>
            <Link
              to={`/settings/integrations/${integrationId}/${integrationEnvironment?.environment_type}`}
            >
              <Button color="primary">Upload Manifest</Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const NoCompiledSqlTest = () => {
  return (
    <div className={styles.no_dbt_models}>
      <div className={styles.card}>
        <div className="d-flex justify-content-center">
          <div
            className={classnames(
              "d-flex flex-column align-items-center p-4",
              styles.container
            )}
          >
            <img
              src={no_compiled_sql}
              alt="No Compiled SQL"
              className={styles.no_sql}
            />
            <p className="text-center">
              The test does not have any sql to visualize.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { NoDbtModels, NoCompiledSql, NoCompiledSqlTest };
