import { Dropdown, Stack } from "@uicore";
import classes from "../summary.module.scss";
import OpportunitiesList from "./OpportunitiesList";
import dayjs from "dayjs";

const Opportunities = () => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return (
    <section id="opportunities">
      <Stack className="justify-content-between">
        <h3>Opportunities</h3>
        <Stack className="align-items-center">
          <Stack>
            <div>
              {dayjs()
                .subtract(28, "day")
                .toDate()
                .toLocaleDateString("en-US", options)}
            </div>
            <div>-</div>
            <div>{dayjs().toDate()?.toLocaleDateString("en-US", options)}</div>
          </Stack>
          <Dropdown
            value={"Last 28 days"}
            onOptionSelect={() => {}}
            options={[
              {
                label: "Last 28 days",
                value: "Last 28 days",
              },
            ]}
          />
        </Stack>
      </Stack>
      <p className={classes.caption}>
        This section shows key areas in which major cost saving opportunities
        are present
      </p>
      <OpportunitiesList />
    </section>
  );
};

export default Opportunities;
