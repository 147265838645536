import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import UTC from "dayjs/plugin/utc";

dayjs.extend(updateLocale);
dayjs.locale('en'); 
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});
dayjs.extend(UTC);

