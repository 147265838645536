export const GET_QUERY = "GET-QUERY";
export const GET_QUERY_FILTERS = "GET-QUERY-FILTERS";
export const GET_LAST_UPDATED_TIME = "GET-LAST-UPDATED-TIME";
export const GET_QUERY_BY_ID = "GET-QUERY-BY-ID";
export const GET_QUERY_GROUP_BY_ID = "GET-QUERY-GROUP-BY-ID";
export const GET_ALL_QUERY_GROUPS = "GET-ALL-QUERY-GROUPS";
export const GET_JOBS = "GET-JOBS";
export const GET_JOB_EXECUTIONS = "GET-JOB-EXECUTIONS";
export const GET_JOB_DEFINTIONS = "GET-JOB-QUERIES";
export const GET_JOB_FILTERS = "GET-JOB-FILTERS";
export const GET_QUERY_JOB_DATA = "GET-QUERY-JOB-DATA";

export enum QueryGroupTabs {
    Opportunities = "Opportunities",
    Queries = "Queries",
}

export const QUERY_TEXT_API_KEY = "GET_QUERY_TEXT_BY_HASH";
