import Session from "supertokens-auth-react/recipe/session";
import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import ThirdParty from "supertokens-auth-react/recipe/thirdparty";
import { API_URL, VITE_HOST } from "./config";
import Multitenancy from "supertokens-auth-react/recipe/multitenancy";
import { getTenant, isSSOEnabled } from "./helpers/tenant";
import { ReactNode } from "react";

const ssoMode: boolean = isSSOEnabled();

export const initializeSuperTokens = (): void => {
  if (ssoMode) {
    SuperTokens.init({
      //   enableDebugLogs: true,
      appInfo: {
        appName: "altimate",
        apiDomain: API_URL!,
        websiteDomain: window.location.origin,
        apiBasePath: "/superauth",
        websiteBasePath: "/login",
      },
      style: `
        [data-supertokens~=superTokensBranding] {
            display: none;
        }
      `,
      usesDynamicLoginMethods: true,
      recipeList: [
        Multitenancy.init({
          override: {
            functions: (oI) => {
              return {
                ...oI,
                getTenantId: getTenant,
              };
            },
          },
        }),
        ThirdParty.init(),
        Session.init({
          sessionTokenFrontendDomain: `.${VITE_HOST}`,
          tokenTransferMethod: "header", // or "cookie"
        }),
      ],
    });
  }
};

interface SupertokensProviderProps {
  children: ReactNode;
}

export const SupertokensProvider = ({
  children,
}: SupertokensProviderProps): React.ReactNode => {
  return ssoMode ? (
    <SuperTokensWrapper>{children}</SuperTokensWrapper>
  ) : (
    <>{children}</>
  );
};
