import React, {
  useEffect,
  useRef,
  useState,
  createContext,
  useContext,
  useMemo,
} from "react";
import styles from "../styles.module.scss";
import { default as InsightIcon } from "@/assets/icons/insight.svg?react";
import classNames from "classnames";
import { isEmpty } from "../../../helpers/utils";
import { OpportunitySummary } from "./OpportunitySummary";
import { UserInfo } from "@/Components/Tags";
import dayjs from "dayjs";
import { DynamicHTML, Tooltip } from "@/uiCore";
import { useUsers } from "@/helpers/hooks";
import {
  CountTag,
  LinenumberTag,
  MoneySavingsTag,
  TimeSavingsTag,
} from "./tags";
import EffortTag from "@/pages/Explorer/components/EffortTag/EffortTag";
import StatusTag from "@/pages/Explorer/components/StatusTag/StatusTag";
import { getEffortLabel, getOpportunityStatus } from "@/pages/Explorer/utils";
import { GetOpportunitiesDataResponse } from "@/pages/Explorer/types";
import { RelativeComponentLoader } from "@/Components/Loader";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import gfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { generateStream } from "@/helpers/api_helper";
import { Shines2Icon } from "@/assets/icons";
import { EmptyScreen } from "@/pages/Warehouse/misc";
import { Paginate } from "@/Components/Paginate";

type Report = {
  indices?: [[number, number], [number, number]];
  startLineNumber: number;
  endLineNumber: number;
  text: string;
};

enum StreamStatus {
  Loading = "loading",
  Ongoing = "ongoing",
  Finished = "finished",
  Error = "error",
}

export const reportsToSpans = (reports: Report[]): Partial<Report>[] => {
  if (isEmpty(reports)) return [];
  return reports
    .map(({ indices, ...rest }) =>
      (
        indices || [
          [
            [0, 0],
            [0, 0],
          ],
        ]
      ).map((index) => ({ index, ...rest }))
    )
    .flat()
    .sort((a, b) => a.startLineNumber - b.startLineNumber);
};

const pattern = /```[^`]*```|\n\n/gi;
const replaceNewlines = (value: string): string =>
  value.replace(pattern, (match) =>
    match.startsWith("```") ? match : " \n \n"
  );

type StreamingResponseProps = {
  streamingEndpoint: string;
  requestBody: any;
  opportunityId: string;
};

const StreamingResponse: React.FC<StreamingResponseProps> = ({
  streamingEndpoint,
  requestBody,
  opportunityId,
}) => {
  const {
    streamStatus,
    setStreamStatus,
    explanationValue,
    setExplanationValue,
  } = useInsightContext();

  useEffect(() => {
    const controller = new AbortController();
    const getStream = async () => {
      if (streamStatus[opportunityId] === StreamStatus.Finished) return;
      setStreamStatus(opportunityId, StreamStatus.Loading);
      const onProgress = (chunk: string) => {
        if (!explanationValue[opportunityId] && chunk.length > 0) {
          setStreamStatus(opportunityId, StreamStatus.Ongoing);
        }
        setExplanationValue(opportunityId, chunk);
      };
      await generateStream(
        streamingEndpoint,
        onProgress,
        requestBody,
        controller
      )
        .then(() => {
          setStreamStatus(opportunityId, StreamStatus.Finished);
        })
        .catch((error) => {
          if (error.name === "AbortError") return;
          setExplanationValue(opportunityId, error.message);
          setStreamStatus(opportunityId, StreamStatus.Error);
        });
    };
    getStream();
    return () => {
      controller.abort();
      setStreamStatus(opportunityId, undefined);
    };
  }, [opportunityId]);

  if (streamStatus[opportunityId] === StreamStatus.Loading) {
    return (
      <RelativeComponentLoader
        componentHeight={40}
        label="Gathering detailed insights..."
      />
    );
  }

  return (
    <ReactMarkdown
      remarkPlugins={[gfm, remarkBreaks]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <SyntaxHighlighter
              {...props}
              style={vscDarkPlus}
              language={match[1]}
              wrapLines={true}
              wrapLongLines={true}
              PreTag="div"
            >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code {...props} className={styles.syntax_highlighter}>
              {children}
            </code>
          );
        },
      }}
    >
      {replaceNewlines(explanationValue[opportunityId])}
    </ReactMarkdown>
  );
};

type OpportunitySectionProps = {
  opportunities: Array<GetOpportunitiesDataResponse>;
  selectedOpportunityId: string | null;
  setOpportunityId: (item: string) => void;
  sideContentMode?: boolean;
  totalRuns: string;
  startDate: string;
  endDate: string;
  explanationEndpoint?: string;
  showMultiStatementError?: boolean;
};

type InsightContextType = {
  streamStatus: Record<string, StreamStatus>;
  setStreamStatus: (
    opportunityId: string,
    status: StreamStatus | undefined
  ) => void;
  explanationValue: Record<string, string>;
  setExplanationValue: (opportunityId: string, value: string) => void;
};

const InsightContext = createContext<InsightContextType | undefined>(undefined);

export const InsightProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [streamStatus, setStreamStatus] = useState<
    Record<string, StreamStatus>
  >({});
  const [explanationValue, setExplanationValue] = useState<
    Record<string, string>
  >({});

  const updateStreamStatus = (
    opportunityId: string,
    status: StreamStatus | undefined
  ) => {
    setStreamStatus((prev) => {
      const newValue = { ...prev };
      if (newValue[opportunityId] === StreamStatus.Finished) {
        return newValue;
      }
      if (status) {
        newValue[opportunityId] = status;
      } else {
        delete newValue[opportunityId];
      }
      return newValue;
    });
  };

  const updateExplanationValue = (opportunityId: string, value: string) => {
    setExplanationValue((prev) => ({
      ...prev,
      [opportunityId]: (prev[opportunityId] || "") + value,
    }));
  };

  return (
    <InsightContext.Provider
      value={{
        streamStatus,
        setStreamStatus: updateStreamStatus,
        explanationValue,
        setExplanationValue: updateExplanationValue,
      }}
    >
      {children}
    </InsightContext.Provider>
  );
};

export const useInsightContext = () => {
  const context = useContext(InsightContext);
  if (context === undefined) {
    throw new Error("useInsightContext must be used within an InsightProvider");
  }
  return context;
};

const PAGE_SIZE = 6;
export const InsightSection: React.FC<OpportunitySectionProps> = ({
  opportunities: _opportunities,
  selectedOpportunityId,
  setOpportunityId,
  sideContentMode = false,
  totalRuns,
  startDate,
  endDate,
  explanationEndpoint = "/opportunities/explanation",
  showMultiStatementError = false,
}) => {
  const { streamStatus, setStreamStatus, setExplanationValue } =
    useInsightContext();
  const explanationRef = useRef<HTMLDivElement>(null);
  const { getById } = useUsers();
  const [page, setPage] = useState(() => {
    if (sideContentMode) return 0;
    const index = _opportunities?.findIndex(
      (o) => o.opportunity_id === selectedOpportunityId
    );
    if (index === -1) return 0;
    return Math.floor(index / PAGE_SIZE);
  });

  const opportunities = useMemo(() => {
    const opportunityLinenumberMap: Record<string, number> = {};
    const opportunityCountMap: Record<string, number> = {};

    // Count occurrences of each opportunity_name
    _opportunities.forEach((o) => {
      opportunityCountMap[o.opportunity_name] =
        (opportunityCountMap[o.opportunity_name] || 0) + 1;
    });

    return _opportunities
      .map((o) => {
        if (o.linenumber) {
          return o;
        }
        if (opportunityCountMap[o.opportunity_name] === 1) {
          // Do not add linenumber if only one item exists
          return o;
        }
        opportunityLinenumberMap[o.opportunity_name] =
          opportunityLinenumberMap[o.opportunity_name] || 0;
        opportunityLinenumberMap[o.opportunity_name] += 1;
        return {
          ...o,
          linenumber: String(opportunityLinenumberMap[o.opportunity_name]),
        };
      })
      .sort((a, b) => a.opportunity_name.localeCompare(b.opportunity_name));
  }, [_opportunities]);

  if (showMultiStatementError) {
    return (
      <EmptyScreen
        title="No Opportunities"
        subTitle="Opportunities for multi-statement SQL queries are not currently supported. We're actively working on this support."
      />
    );
  }

  if (!opportunities || opportunities.length === 0) {
    return (
      <EmptyScreen
        title="No Opportunities"
        subTitle="No opportunities found for this resource."
      />
    );
  }

  const pageOpportunities = sideContentMode
    ? opportunities
    : opportunities.slice(page * PAGE_SIZE, (page + 1) * PAGE_SIZE);

  return (
    <div className="mb-2 mt-2">
      <div
        className={classNames(styles.insight_container, {
          "overflow-x": sideContentMode,
        })}
        style={{
          gridTemplateColumns: sideContentMode
            ? "1fr"
            : "repeat(auto-fit, minmax(500px, 1fr))",
        }}
      >
        {pageOpportunities.map((item) => {
          const _selected = selectedOpportunityId === item.opportunity_id;
          return (
            <div
              className={classNames(styles.insight_card, {
                [styles.selected]: _selected,
              })}
              key={item.opportunity_id}
              onClick={() => setOpportunityId(item.opportunity_id)}
            >
              <div
                className={` ${
                  !sideContentMode
                    ? "d-flex flex-column justify-content-between h-100"
                    : ""
                }`}
              >
                <div>
                  <div className="d-flex gap-xs mb-2 align-items-center">
                    <div className="icon-sm">
                      <InsightIcon />
                    </div>
                    <div className="fs-5 lines-2 d-flex align-items-center gap-sm">
                      <span>{item.opportunity_name}</span>
                      {item.linenumber && (
                        <Tooltip
                          content={
                            item.metadata.report.type === "global"
                              ? "Whole query"
                              : item.metadata.report.actual_query_span ||
                                "Unable to map to query"
                          }
                          placement="top"
                          maxWidth="500px"
                        >
                          <LinenumberTag linenumber={item.linenumber} />
                        </Tooltip>
                      )}
                    </div>
                    <div className="spacer" />
                    {(!sideContentMode || !_selected) && (
                      <>
                        <Tooltip
                          content={
                            "Expected effort to realize this opportunity"
                          }
                          placement="top"
                        >
                          <EffortTag effort={getEffortLabel(item?.effort)} />
                        </Tooltip>
                        {item?.money_savings && (
                          <Tooltip
                            content="Projected money savings during the time period"
                            placement="top"
                          >
                            <MoneySavingsTag savings={item?.money_savings} />
                          </Tooltip>
                        )}
                        {item?.time_savings && (
                          <Tooltip
                            content="Projected time savings during the time period"
                            placement="top"
                          >
                            <TimeSavingsTag savings={item?.time_savings} />
                          </Tooltip>
                        )}
                      </>
                    )}
                    {sideContentMode && item.explanation_context && (
                      <div
                        className={styles.ai_icon}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (
                            streamStatus[item.opportunity_id] ===
                            StreamStatus.Finished
                          ) {
                            return;
                          }
                          setExplanationValue(item.opportunity_id, "");
                          setStreamStatus(
                            item.opportunity_id,
                            StreamStatus.Loading
                          );
                          setTimeout(() => {
                            explanationRef.current?.scrollIntoView({
                              behavior: "smooth",
                              block: "center",
                            });
                          }, 500);
                        }}
                      >
                        <Shines2Icon />
                      </div>
                    )}
                  </div>
                  <div className="text-muted">
                    <DynamicHTML htmlContent={item.description || ""} />
                  </div>
                </div>
                {(!sideContentMode || !_selected) && (
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-between gap-sm">
                      <Tooltip
                        content={
                          totalRuns ? (
                            <div>
                              This opportunity was detected {item?.count} times
                              in {totalRuns} query runs that happened.
                            </div>
                          ) : (
                            <div>
                              This opportunity was detected {item?.count} times.
                            </div>
                          )
                        }
                        placement="top"
                      >
                        <CountTag count={item?.count} />
                      </Tooltip>
                      <div className={styles.seen}>
                        <Tooltip
                          content={
                            <>
                              <div>First seen</div>
                              <div>
                                {dayjs(item?.first_seen).format(
                                  "DD MMM YYYY hh:mm:ss A"
                                )}
                              </div>
                            </>
                          }
                          placement="top"
                        >
                          <div className="cursor-pointer">
                            {dayjs(item?.first_seen).fromNow()} ago
                          </div>
                        </Tooltip>{" "}
                        |{" "}
                        <Tooltip
                          content={
                            <>
                              <div>Last seen</div>
                              <div>
                                {dayjs(item?.last_seen).format(
                                  "DD MMM YYYY hh:mm:ss A"
                                )}
                              </div>
                            </>
                          }
                          placement="top"
                        >
                          <div className="cursor-pointer">
                            {dayjs(item?.last_seen).fromNow()} old
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="d-flex gap-sm">
                      {item?.owner && item.owner !== "-1" && (
                        <UserInfo user={getById(Number(item?.owner))} />
                      )}
                      {item?.status && (
                        <StatusTag
                          status={getOpportunityStatus(item?.status)}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
              {_selected && sideContentMode && (
                <OpportunitySummary
                  opportunity={item}
                  startDate={startDate}
                  endDate={endDate}
                  savingsInfo={item?.savings_attribution}
                />
              )}
              {streamStatus[item.opportunity_id] &&
                sideContentMode &&
                explanationEndpoint && (
                  <div className="bg-white br-2 mt-2 p-2" ref={explanationRef}>
                    <StreamingResponse
                      key={item.opportunity_id}
                      streamingEndpoint={explanationEndpoint}
                      requestBody={item.explanation_context}
                      opportunityId={item.opportunity_id}
                    />
                  </div>
                )}
            </div>
          );
        })}
      </div>
      {!sideContentMode && opportunities?.length > PAGE_SIZE && (
        <Paginate
          itemCount={opportunities?.length}
          page={page}
          pageSize={PAGE_SIZE}
          numPages={Math.ceil(opportunities?.length / PAGE_SIZE)}
          onPageClick={setPage}
          onPageSizeChange={() => {}}
        />
      )}
    </div>
  );
};
