import { Stack } from "@/uiCore";
import { default as FailureImage } from "./failure.svg?react";
import classes from "../summary.module.scss";

interface Props {
  title: string;
  caption?: string;
}
const FailureComponent = ({ title, caption }: Props) => {
  return (
    <Stack direction="column" className={classes.failure}>
      <FailureImage />
      <h6>{title}</h6>
      <p>{caption}</p>
    </Stack>
  );
};

export default FailureComponent;
