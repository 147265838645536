import { Shines2Icon } from "@/assets/icons";
import { IconButton, Stack } from "@uicore";
import classes from "../summary.module.scss";
import { AggregateByValues } from "./constants";
import { ReactNode, useEffect, useState } from "react";
import { api } from "@/helpers/api_helper";
import {
  getDateByEndOfDayWithoutTimeoffset,
  getDateByStartOfDayWithoutTimeoffset,
} from "@/Components/DateRange/utils";
import { useSearchParams } from "react-router-dom";
import {
  ChatTriggerLink,
  TaskLabels,
  TeamMatesConfig,
} from "@/lib/altimate/altimate-components";
import { useSummaryDispatch } from "../SummaryNew";
import { setShowChatbot } from "../Context/summarySlice";

interface Props {
  chartType: "current" | "future";
  analysisType: "cost" | "savings";
  costType?: string;
  savingsType?: string;
  startDate: Date;
  endDate: Date | null;
  aggregation: (typeof AggregateByValues)[keyof typeof AggregateByValues];
  isOpen?: boolean;
}

const SummaryChatButton = ({
  chartType,
  analysisType,
  costType,
  savingsType,
  startDate,
  endDate,
  aggregation,
  isOpen,
}: Props) => {
  const dispatch = useSummaryDispatch();
  const [searchParams] = useSearchParams();
  const [helloMessage, setHelloMessage] = useState<ReactNode | undefined>();
  const chartAnalyserBot = TeamMatesConfig.find(
    (t) => t.key === TaskLabels.ChartBot
  );

  // Temporary feature flag
  const showSummaryChatButton = isOpen || searchParams.get("chat") === "true";

  const params = {
    start_date: getDateByStartOfDayWithoutTimeoffset(startDate).toISOString(),
    end_date: getDateByEndOfDayWithoutTimeoffset(endDate)?.toISOString(),
    cost_type: costType,
    savings_type: savingsType,
    chart_type: chartType,
    analysis_type: analysisType,
    cost_analysis_type: analysisType === "cost" ? costType : undefined,
    savings_analysis_type: analysisType === "savings" ? savingsType : undefined,
    aggregation,
  };

  useEffect(() => {
    if (!showSummaryChatButton) {
      return;
    }
    api
      .get<{ questions: string[] }>(
        "/summary/v2/datapilot/initial-questions-chart-chat",
        params
      )
      .then((response) => {
        if (!response) {
          return;
        }
        setHelloMessage(
          <div>
            <p className="ant-default-message">
              Hi, I am your {chartAnalyserBot?.name}. I can help you understand
              the {chartType} {analysisType} chart better. Here are few
              questions that you may be interested in. If not, please use the
              input below to ask any question about this chart.
            </p>
            <Stack direction="column">
              {response.questions.map((question) => (
                <ChatTriggerLink key={question} text={question} />
              ))}
            </Stack>
          </div>
        );
      })
      .catch((err) => console.error(err));
  }, [showSummaryChatButton]);

  const openChatbot = () => {
    dispatch(setShowChatbot({ ...params, helloMessage }));
  };

  if (!showSummaryChatButton) {
    return;
  }

  return (
    <IconButton
      onClick={openChatbot}
      title="Ask questions about this chart"
      className={classes.chatButton}
    >
      <Shines2Icon />
    </IconButton>
  );
};

export default SummaryChatButton;
