import DatapilotAnalysis from "./Components/DatapilotAnalysis";
import FutureAnalysis from "./Components/FutureAnalysis";
import PastAnalysis from "./Components/PastAnalysis";
import {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useState,
} from "react";
import { UnknownAction } from "@reduxjs/toolkit";
import summarySlice from "./Context/summarySlice";
import { SummaryStateProps } from "./Context/types";
import classes from "./summary.module.scss";
import { Stack, Tabs } from "@uicore";
import Opportunities from "./Components/Opportunities";
import classNames from "classnames";
import { default as Analyze } from "./analyze.svg?react";
import { default as ArrowUp } from "./arrow_up.svg?react";
import SummaryChatbot from "./Components/SummaryChatbot";

interface ContextProps {
  state: SummaryStateProps;
  dispatch: React.Dispatch<UnknownAction>;
}

export const SummaryContext = createContext<ContextProps>({
  state: summarySlice.getInitialState(),
  dispatch: () => null,
});

const Summary = (): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState("");
  const [state, dispatch] = useReducer(
    summarySlice.reducer,
    summarySlice.getInitialState()
  );

  const values = useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  const onTabSelect = (value: string) => {
    setSelectedTab(value);
    document
      .getElementById(value)
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const [isDatapilotAnalysisOpen, setIsDatapilotAnalysisOpen] = useState(false);

  return (
    <SummaryContext.Provider value={values}>
      <div className={classes.summary}>
        <Stack className={classes.stickyHeader}>
          <h3 className="text-black">Summary</h3>
          <Tabs
            className={classes.summaryTabs}
            pills={false}
            items={[
              { label: "Current State", value: "current" },
              { label: "Future State", value: "future" },
              { label: "Opportunities", value: "opportunities" },
            ]}
            selectedTab={selectedTab}
            onTabSelect={onTabSelect}
          />
        </Stack>
        <Stack>
          <div
            style={{
              flex: 1,
              maxWidth: Boolean(state.showChatbot)
                ? "calc(100% - 400px)"
                : "100%",
            }}
          >
            <Stack
              className={classNames(classes.datapilotAnalysisCoverUp, {
                [classes.analysisOpen]: isDatapilotAnalysisOpen,
              })}
            >
              <div className={classes.text}>
                DataPilot can analyze your usage for last 30 days and uncover
                key highlights
              </div>
              <div
                className={classes.button}
                onClick={() =>
                  setIsDatapilotAnalysisOpen(!isDatapilotAnalysisOpen)
                }
              >
                {isDatapilotAnalysisOpen ? (
                  <ArrowUp />
                ) : (
                  <>
                    <Analyze /> Analyze
                  </>
                )}
              </div>
            </Stack>
            {isDatapilotAnalysisOpen && <DatapilotAnalysis />}
            <PastAnalysis />
            <FutureAnalysis />
            <Opportunities />
          </div>
          <SummaryChatbot />
        </Stack>
      </div>
    </SummaryContext.Provider>
  );
};

export const useSummaryDispatch = (): React.Dispatch<UnknownAction> => {
  const { dispatch } = useContext(SummaryContext);
  return dispatch;
};

export default Summary;
