import { UnknownAction } from "@reduxjs/toolkit";
import { FilterApplicableFor } from "../Tags/types";
import { useContext } from "react";
import { ColumnPickerContext } from "./ColumnPickerProvider";

export interface ColumnPickerStateProps {
  headers: { label: string; value: string, width?: number }[];
  columns: Set<string>;
  applicableFor?: FilterApplicableFor;
}

export interface ContextProps {
  state: ColumnPickerStateProps;
  dispatch: React.Dispatch<UnknownAction>;
  gridDimensions: string;
}

export const useColumnPickerContext = () => {
  return useContext(ColumnPickerContext);
};
