import styles from "./styles.module.scss";
import { IconContext } from "react-icons";
import classNames from "classnames";
import { useQuery } from "react-query";
import {
  getColumnAccessHistory,
  getColumnUserAccessHistory,
  getColumnUserAccessHistoryFilters,
} from "../../helpers/backend_helper";
import { RelativeComponentLoader } from "../../Components/Loader";
import { Card, CardBody } from "reactstrap";
import { useEffect, useState } from "react";
import SidebarModal from "../../Components/SidebarModal";
import { Paginate } from "../../Components/Paginate";
import { FilterTagWrapper } from "../../Components/Tags";
import { ListingTable } from "../../Components/Tables";
import TimeInfoDisclaimer from "../../Components/TimeInfoDisclaimer";
import no_access_history_contract from "../../assets/images/no_incident_contract.svg";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const NoAccessHistory = () => {
  return (
    <div className={styles.no_incidents}>
      <div className="d-flex h-100 justify-content-center">
        <div
          className={classNames(
            "d-flex flex-column align-items-center p-4",
            styles.container
          )}
        >
          <img
            src={no_access_history_contract}
            alt="No Access history"
            className={styles.img}
          />
          <h3 className="mt-3 mb-3 text-black">No History Found</h3>
          <p className="text-muted text-center">
            There is no access history in the last 30 days!
          </p>
          <Link to="/datasets">
            <Button color="primary" style={{ borderRadius: "5px" }}>
              Check Datasets
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

const GET_ACCESS_HISTORY = "GET-ACCESS-HISTORY";
const GET_ACCESS_HISTORY_FILTERS = "GET-ACCESS-HISTORY-FILTERS";
const GET_USER_ACCESS_HISTORY = "GET-USER-ACCESS-HISTORY";

const getUsageColor = (percentage, backgroundBar) => {
  let r, g, b;

  if (percentage <= 50) {
    // #EE140F -> #FF754C
    r = Math.round(238 + (percentage / 50) * (255 - 238));
    g = Math.round(20 + (percentage / 50) * (117 - 20));
    b = Math.round(15 + (percentage / 50) * (76 - 15));
  } else {
    // #FF754C -> #237efe
    percentage -= 50;
    r = Math.round(255 + (percentage / 50) * (35 - 255));
    g = Math.round(117 + (percentage / 50) * (126 - 117));
    b = Math.round(76 + (percentage / 50) * (254 - 76));
  }

  if (backgroundBar) return `rgba(${r}, ${g}, ${b}, 0.1)`;
  return `rgba(${r}, ${g}, ${b}, 1)`;
};

const UsagePercentageBar = ({ usagePercentage }) => (
  <div className={styles.usage}>
    <div className={styles.usage_bar}>
      <div className={styles.bar}>
        <div
          className={classNames(styles.background_bar)}
          style={{ background: getUsageColor(usagePercentage, true) }}
        >
          <div
            className={styles.percentage_bar}
            style={{
              width: `${usagePercentage}%`,
              background: getUsageColor(usagePercentage, false),
            }}
          />
        </div>
      </div>
    </div>
    <div>{usagePercentage + "%"}</div>
  </div>
);

const ColumnsAccessedSidebar = ({ columnObj }) => {
  const [sortedUsers, setSortedUsers] = useState([]);
  const { data: usersData, isLoading: isUsersLoading } = useQuery({
    queryKey: GET_USER_ACCESS_HISTORY,
    queryFn: () => getColumnUserAccessHistory({ column_rk: columnObj.rk }),
    onSuccess: (data) => setSortedUsers(data.user_access),
  });
  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  // Sort users listed in place(locally)
  const handleSort = (column) => {
    if (column === sortBy) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortBy(column);
      setSortOrder("asc");
    }
  };

  if (sortBy) {
    sortedUsers.sort((a, b) => {
      const aValue = a[sortBy];
      const bValue = b[sortBy];
      if (sortOrder === "asc") {
        if (typeof aValue === "number") return aValue - bValue;
        return aValue.localeCompare(bValue);
      } else {
        if (typeof aValue === "number") return bValue - aValue;
        return bValue.localeCompare(aValue);
      }
    });
  }

  const handleUserNameSort = (so) => {
    if (so) {
      handleSort("user_name");
      setSortOrder(so);
    }
  };

  const handleUsageSort = (so) => {
    if (so) {
      handleSort("usage");
      setSortOrder(so);
    }
  };

  if (isUsersLoading) return <RelativeComponentLoader />;
  return (
    <div className={styles.side_panel}>
      <div className="d-flex gap-md align-items-center">
        <div className="flex">
          <div className="fs-6 fw-500 me-3 text-muted">
            User-Centric Column Utilization
          </div>
          <div className="fs-4 fw-300 me-3 text-primary">{columnObj.name}</div>
        </div>
        <div className="spacer" />
        <Card className={classNames("me-3 mb-0 no-break", styles.query_count)}>
          <CardBody className="pt-2 pb-2 ps-4 pe-4">
            <div className="d-flex align-items-center justify-content-between">
              <div>Queries: {usersData.num_queries}</div>
            </div>
          </CardBody>
        </Card>
      </div>

      <ListingTable
        header={[
          {
            id: 1,
            label: "User Name",
            sort: {
              onChange: handleUserNameSort,
            },
          },
          {
            id: 2,
            label: "Column Accessed %",
            sort: {
              onChange: handleUsageSort,
            },
          },
        ]}
        onItemClick={() => {}}
        idKey={(item) => columnObj.rk + item.user_name}
        items={sortedUsers}
        templateColumns="3fr 5fr"
        rowRender={(item) => (
          <>
            <div className="text-primary condense-text cursor-pointer">
              {item.user_name}
            </div>
            <div className="cursor-pointer">
              <UsagePercentageBar usagePercentage={item.usage} />
            </div>
          </>
        )}
      />
    </div>
  );
};

const TableHeading = ({
  numQueries,
  filters,
  filterObj,
  resetColumnFilter,
}) => (
  <div
    className={classNames(
      "d-flex gap-md align-items-center",
      styles.filter_tags
    )}
  >
    <div className="fs-4 fw-500 me-3">Columns</div>
    <FilterTagWrapper
      filters={{
        columnFilter: {
          filterStr: Object.keys(filterObj),
          label: "Column",
          onclose: (removedFilter) => {
            resetColumnFilter(
              filters.column_rk.filter(
                (filter) => filter !== filterObj[removedFilter]
              )
            );
          },
        },
      }}
    />
    <div className="spacer" />
    <TimeInfoDisclaimer
      numOfSecondsActive={3}
      textToDisplay={
        <div className={classNames(styles.info_text, "text-muted m-2")}>
          Column accessed for this table in the past 30 days
        </div>
      }
    />
    <Card className={classNames("me-3 mb-0 no-break", styles.query_count)}>
      <CardBody className="pt-2 pb-2 ps-4 pe-4">
        <div className="d-flex align-items-center justify-content-between">
          <div>Queries: {numQueries}</div>
        </div>
      </CardBody>
    </Card>
  </div>
);

const TableAccessHistory = ({ tableId }) => {
  const [columnFilter, setColumnFilter] = useState([]);
  const [columnFilterDispTag, setColumnFilterDispTag] = useState({});
  const [sortAttribute, setSortAttribute] = useState("usage");
  const [sortOrder, setSortOrder] = useState({
    users: "",
    usage: "",
  });
  const size = 25;
  const [page, setPage] = useState(0);
  const [selectedColumn, setSelectedColumn] = useState("");

  const getRequestBody = () => {
    const data = { table_id: tableId };
    if (columnFilter) data.column_rk = columnFilter;
    if (sortAttribute) {
      data.order_by = sortAttribute;
      data.sort_order = sortOrder[sortAttribute];
    }
    return data;
  };

  const { data: columns, isLoading: isColumnsLoading } = useQuery({
    queryKey: [
      GET_ACCESS_HISTORY,
      page,
      ...columnFilter,
      sortOrder,
      sortAttribute,
    ],
    queryFn: () => getColumnAccessHistory(page + 1, size, getRequestBody()),
  });

  const { data: columnFilters, isLoading: isColumnFiltersLoading } = useQuery({
    queryKey: [GET_ACCESS_HISTORY_FILTERS, page],
    queryFn: () => getColumnUserAccessHistoryFilters(tableId),
  });

  const handleNumUserSortChange = (so) => {
    if (so) {
      setSortAttribute("users");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        users: so,
      }));
    }
  };

  const handleUsageSortChange = (so) => {
    if (so) {
      setSortAttribute("usage");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        usage: so,
      }));
    }
  };

  useEffect(() => {
    if (columnFilter.length) {
      setColumnFilterDispTag(
        columnFilters.reduce(
          (acc, { rk, name }) =>
            columnFilter.includes(rk) ? { ...acc, [name]: rk } : acc,
          {}
        )
      );
    } else {
      setColumnFilterDispTag({});
    }
  }, [columnFilter, columnFilters]);

  if (isColumnsLoading || isColumnFiltersLoading)
    return <RelativeComponentLoader />;
  const header = [
    {
      id: 1,
      label: "Column",
      filter: {
        filterType: "dropdown",
        value: columnFilter,
        options: columnFilters.map((t) => ({
          label: t.name,
          value: t.rk,
        })),
        onChange: setColumnFilter,
      },
    },
    {
      id: 2,
      label: "No. Users",
      sort: {
        onChange: handleNumUserSortChange,
        value: sortOrder.users,
      },
    },

    {
      id: 3,
      label: "Queries",
    },

    {
      id: 4,
      label: "Column Query Usage %",
      sort: {
        onChange: handleUsageSortChange,
        value: sortOrder.usage,
      },
    },
  ];
  if (columns.total === 0) {
    return <NoAccessHistory />;
  }
  return (
    <>
      <div
        className={classNames(
          styles.access_history,
          "p-3 d-flex h-100 flex-column bg-white border-radius-bottom"
        )}
      >
        <TableHeading
          numQueries={columns.items[0].queries}
          filters={getRequestBody()}
          filterObj={columnFilterDispTag}
          resetColumnFilter={setColumnFilter}
        />
        <ListingTable
          header={header}
          items={columns.items}
          idKey={(item) => item.column.rk}
          onItemClick={setSelectedColumn}
          resetPage={() => setPage(0)}
          templateColumns="4fr 1fr 1fr 3fr"
          rowRender={(item) => (
            <>
              <div
                className={classNames(
                  styles.word_break,
                  "text-primary condense-text cursor-pointer"
                )}
              >
                {item.column.name}
              </div>
              <div className={styles.word_break}>{item.user_count}</div>
              <div className={classNames(styles.word_break, "condense-text")}>
                {item.column_count}
              </div>
              <UsagePercentageBar usagePercentage={item.usage} />
            </>
          )}
        />
        <Paginate
          itemCount={columns.total}
          page={page}
          pageSize={columns.size}
          numPages={columns.pages}
          onPageClick={setPage}
        />
      </div>
      <SidebarModal
        isOpen={selectedColumn !== ""}
        toggleModal={() => setSelectedColumn("")}
        width="600"
      >
        <ColumnsAccessedSidebar columnObj={selectedColumn.column} />
      </SidebarModal>
    </>
  );
};

export { TableAccessHistory };
