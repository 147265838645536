import { BaseContainer, Header } from "./components/base";
import link_expired from "../../assets/images/link_expired.svg";
import { useLocation } from "react-router-dom";
import { unix } from "dayjs";

const Expired = () => {
  const { state } = useLocation();
  const inviteDate = state?.inviteDate
    ? unix(state?.inviteDate).utc().format("DD-MMM-YY")
    : ""; // in case the invite timestamp is not passed.
  return (
    <BaseContainer
      footer={
        <div>
          <span className="text-muted">Learn more about </span>
          <a
            color="primary"
            href="https://www.altimate.ai/"
            target="_blank"
            rel="noreferrer"
          >
            Altimate AI
          </a>
        </div>
      }
    >
      <img src={link_expired} alt="Link Expired" />
      <Header>Your Invitation Link Expired</Header>
      <p className="text-muted">
        The {inviteDate && `invitation email was sent on ${inviteDate}, the`}{" "}
        invitation link has expired{inviteDate && " after 30 days"}.
      </p>
      <p className="text-muted">
        Reach out to the sender and ask for a fresh invitation link. Don't be
        shy, they clearly want you there!
      </p>
    </BaseContainer>
  );
};

export { Expired };
