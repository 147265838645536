import { createContext, ReactNode, useMemo, useReducer } from "react";
import { ContextProps } from "./types";
import columnPickerSlice, { initialState } from "./columnPickerSlice";

export const ColumnPickerContext = createContext<ContextProps>({
  state: initialState,
  dispatch: () => null,
  gridDimensions: "",
});

/**
 * This component stores the columns state in global level
 * so any child component can access the columns state and dispatch actions to update the columns state.
 * Also calculates the grid dimensions based on the columns selected and make it available for child components.
 */
const ColumnPickerProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [state, dispatch] = useReducer(
    columnPickerSlice.reducer,
    columnPickerSlice.getInitialState()
  );

  const gridDimensions = useMemo(() => {
    return state.headers
      .filter((h) => state.columns.has(h.value))
      .map((h) => `minmax(0, ${h.width || 1}fr)`)
      .join(" ");
  }, [state.headers, state.columns]);

  const values = useMemo(
    () => ({
      state,
      gridDimensions,
      dispatch,
    }),
    [state, dispatch]
  );

  return (
    <ColumnPickerContext.Provider value={values}>
      {children}
    </ColumnPickerContext.Provider>
  );
};

export default ColumnPickerProvider;
