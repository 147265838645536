import dayjs from "dayjs";

export const formatDate = (d) => dayjs(d).format("DD/MM/YY hh:mm A");
export const formatDateV2 = (d) => dayjs(d).format("DD-MMM-YYYY HH:mm");

export const executeOnlyIfNumber = (fn) => (v) => {
  if (/^\d+$/.exec(v)) fn(v);
};

export const isSnoozedFor1Day = (snoozed_till) =>
  snoozed_till && dayjs().add(1, "day").isAfter(snoozed_till);
