import { useParams } from "react-router-dom";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { useQuery } from "react-query";
import {
  getDatastoreDetail,
  getSyncHistory,
  testDatastoreConnection,
  syncDatastore,
  toggleDatastoreSyncSchedule,
} from "../../helpers/backend_helper";
import { useState } from "react";
import { ComponentLoader } from "../../Components/Loader";
import _ from "lodash";
import { getIconByDatastoreType } from "./DatastoreImage";
import datastore_sync from "../../assets/icons/datastore_sync.png";
import datastore_sync_gray from "../../assets/icons/datastore_sync_gray.svg";
import datastore_processing from "../../assets/images/datastore_processing.png";
import datastore_success from "../../assets/images/datastore_success.svg";
import datastore_fail from "../../assets/images/datastore_fail.svg";
import datastore_play from "../../assets/icons/datastore_play.svg";
import datastore_play_gray from "../../assets/icons/datastore_play_gray.svg";
import classNames from "classnames";
import dayjs from "dayjs";
import { Tooltip } from "react-tooltip";
import { Input, Label } from "reactstrap";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { RectColorTag } from "../../Components/Tags";
import { getUser } from "../../services/auth";
import { PermissionsEnum } from "../../helpers/constants";
import { Paginate } from "../../Components/Paginate";

const GET_DATASTORE_DETAILS = "GET-DATASTORE-DETAILS";
const GET_SYNC_HISTORY = "GET-SYNC-HISTORY";
const API_RESULT_TIMEOUT = 2000;

const Datastore = ({ datastore }) => {
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(datastore?.auto_sync || false);
  const [showTestSuccess, setShowTestSuccess] = useState(false);
  const [showTestFail, setShowTestFail] = useState(false);
  const [showSyncing, setShowSyncing] = useState(false);
  const isTestDisabled = !datastore?.can_sync;
  const user = getUser();
  const syncOpAllowed = user.permission.includes(
    PermissionsEnum.DATASTORE_EDIT
  );
  const {
    mutate: runTestConnection,
    isLoading: isTestLoading,
    error,
  } = useMutation(testDatastoreConnection, {
    onSuccess: (response) => {
      if (response?.ok) {
        setShowTestSuccess(true);
        setTimeout(() => {
          setShowTestSuccess(false);
        }, API_RESULT_TIMEOUT);
      } else {
        setShowTestFail(true);
        setTimeout(() => {
          setShowTestFail(false);
        }, API_RESULT_TIMEOUT);
      }
    },
    onError: () => {
      setShowTestFail(true);
      setTimeout(() => {
        setShowTestFail(false);
      }, API_RESULT_TIMEOUT);
    },
  });

  const { mutate: runSyncDatastore } = useMutation(syncDatastore, {
    onSuccess: () => {
      setShowSyncing(false);
    },
    onError: () => {
      setShowSyncing(false);
    },
  });

  const { mutate: runToggleSchedule, isLoading: isRunningToggle } = useMutation(
    () => toggleDatastoreSyncSchedule(datastore?.id),
    {
      onSuccess: (res) => {
        if (res?.ok) {
          setIsChecked(!isChecked);
        }
      },
    }
  );

  const handleToggleSwitch = (e) => {
    e.stopPropagation();
    runToggleSchedule();
  };

  const handleTestConnection = (id) => {
    runTestConnection(id);
  };

  const handleSync = (e, id) => {
    e.stopPropagation();
    setShowSyncing(true);
    runSyncDatastore(id);
  };

  function extractCredentials(apiResponse) {
    let credentialData = [];

    let account = null;
    let database = null;
    let role = null;
    let warehouse = null;

    switch (apiResponse.type) {
      case "s3":
        var s3ConnectionParts = apiResponse.connection_info.split("/");
        var s3AccountParts = s3ConnectionParts[0].split(".");
        account = s3AccountParts[0];
        database = s3ConnectionParts[1].split("?")[0];
        break;
      case "postgres":
        var postgresConnectionParts = apiResponse.connection_info.split(":");
        var postgresHostParts = postgresConnectionParts[0].split(".");
        account = postgresHostParts[0];
        database =
          postgresConnectionParts[postgresConnectionParts.length - 1].split(
            "/"
          )[1];
        break;
      case "snowflake":
        var connectionParams = apiResponse.connection_info.split("/");
        account = connectionParams[0];
        var queryParts = connectionParams[1].split("?");
        database = queryParts[0];
        var queryParams = new URLSearchParams(queryParts[1]);
        role = queryParams.get("role") || null;
        warehouse = queryParams.get("warehouse") || null;
        break;
      default:
        account = null;
        database = null;
        role = null;
        warehouse = null;
    }

    if (account) {
      credentialData.push({
        label: "Account",
        value: account,
      });
    }
    if (role) {
      credentialData.push({
        label: "Role",
        value: role,
      });
    }
    if (database) {
      credentialData.push({
        label: "Database",
        value: database,
      });
    }
    if (warehouse) {
      credentialData.push({
        label: "Warehouse",
        value: warehouse,
      });
    }

    return credentialData;
  }

  const credentialData = extractCredentials(datastore);

  return (
    <div className={styles.details_card}>
      <div className={styles.details_left}>
        <div>
          <div
            className={classNames(
              "mb-2 d-flex justify-content-between align-items-center",
              styles.icon_row
            )}
          >
            <div className="d-flex justify-content-between align-items-center">
              {getIconByDatastoreType(datastore.type, styles.datastore_icon)}
              <div className="ms-2">
                <div className="fs-5 fw-semibold">{datastore.name}</div>
                <div className={styles.subtext}>
                  {_.capitalize(datastore?.type)}
                </div>
              </div>
            </div>
            <div className={styles.right_column}>
              <div className={classNames(styles.inside_card)}>
                <div className="form-check form-switch d-flex align-items-center ">
                  <Label
                    className={styles.input_switch_title}
                    for={"flexSwitchCheckDefault" + datastore.id}
                  >
                    Enable Sync
                  </Label>
                  <div data-tooltip-id={"enable_sync_details_" + datastore.id}>
                    <Input
                      className={classNames(
                        "form-check-input",
                        styles.input_switch
                      )}
                      type="checkbox"
                      role="switch"
                      id={"flexSwitchCheckDefault" + datastore.id}
                      checked={isChecked}
                      onClick={handleToggleSwitch}
                      disabled={!syncOpAllowed}
                    />
                  </div>
                  {!syncOpAllowed && (
                    <Tooltip
                      id={"enable_sync_details_" + datastore.id}
                      className={styles.sync_disabled_tooltip}
                    >
                      <div className="d-flex flex-column align-items-left gap-xs justify-content-center">
                        <div>Insufficient permissions for enabling sync</div>
                      </div>
                    </Tooltip>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            className={classNames(
              "mb-2 d-flex justify-content-between align-items-center text-muted fw-400",
              styles.description
            )}
          >
            {datastore?.description}
          </div>
        </div>
        <div className={styles.sync_details}>
          <div className="d-flex justify-content-between align-items-center mt-1">
            <div className="d-flex justify-content-between align-items-center">
              {datastore?.can_sync && syncOpAllowed ? (
                <div
                  className={classNames(styles.inside_card, "me-2")}
                  onClick={(e) => handleSync(e, datastore.id)}
                >
                  {showSyncing ? (
                    <>
                      <img
                        src={datastore_sync}
                        alt="syncing"
                        className={styles.rotateIcon}
                      />
                      <div className={styles.title}>Syncing...</div>
                    </>
                  ) : (
                    <>
                      <img src={datastore_sync} alt="sync_now" />
                      <div className={styles.title}>Sync Now</div>
                    </>
                  )}
                </div>
              ) : (
                <>
                  <div
                    className={classNames(styles.inside_card, "me-2")}
                    data-tooltip-id={"sync_gray" + datastore.id}
                  >
                    <img src={datastore_sync_gray} alt="sync_now_gray" />
                    <div className={styles.title}>Sync Now</div>
                  </div>
                  <Tooltip
                    id={"sync_gray" + datastore.id}
                    className={styles.sync_disabled_tooltip}
                  >
                    <div className="d-flex flex-column align-items-left gap-xs justify-content-center">
                      {!syncOpAllowed ? (
                        <div>Insufficient permissions for performing sync</div>
                      ) : (
                        <div>
                          <div>Credentials are not added</div>
                          <div> or synced to SaaS UI</div>
                        </div>
                      )}
                    </div>
                  </Tooltip>
                </>
              )}
              <div
                className={classNames(styles.inside_card, {
                  [styles.no_pointer_events]: isTestDisabled,
                })}
                onClick={() => handleTestConnection(datastore.id)}
              >
                {isTestLoading ? (
                  <img
                    src={datastore_processing}
                    data-tooltip-id={"test_connection" + datastore.id}
                    className={styles.rotateIcon}
                    alt="test_connection"
                  />
                ) : (
                  <>
                    {!showTestSuccess &&
                      !showTestFail &&
                      (isTestDisabled ? (
                        <img
                          src={datastore_play_gray}
                          alt="test_connection_disabled"
                        />
                      ) : (
                        <img
                          src={datastore_play}
                          data-tooltip-id={"test_connection" + datastore.id}
                          alt="test_connection"
                        />
                      ))}
                    {showTestSuccess && (
                      <img
                        src={datastore_success}
                        data-tooltip-id={"test_connection" + datastore.id}
                        alt="test_connection"
                      />
                    )}
                    {showTestFail && (
                      <img
                        src={datastore_fail}
                        data-tooltip-id={"test_connection" + datastore.id}
                        alt="test_connection"
                      />
                    )}
                  </>
                )}

                <div className={styles.title}>Test</div>
                <Tooltip
                  id={"test_connection" + datastore.id}
                  className={styles.test_connection_tooltip}
                >
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <div>Test Connection</div>
                    {/* {last_test_timestamp && (
                        <span className={styles.tooltip_date}>
                          Last&nbsp;:&nbsp;
                          {dayjs(last_test_timestamp).format(
                            "h:mm A, D MMMM YY"
                          )}
                        </span>
                      )} */}
                  </div>
                </Tooltip>
              </div>
            </div>
            {showTestSuccess && (
              <div className={styles.success_text}>Success</div>
            )}
            {showTestFail && (
              <div className={styles.fail_text}>Failed, Try Again</div>
            )}
          </div>
        </div>
      </div>

      <div
        className={classNames(styles.credentials, "d-flex flex-column gap-lg")}
      >
        <div className={styles.title}>Details</div>
        <div className="d-flex flex-column gap-xs">
          {credentialData.map((item, i) => {
            return (
              <div key={i} className="d-flex">
                <div className={styles.credential_title}>
                  {item.label}:&nbsp;
                </div>
                <div className={styles.credential_value}>{item.value}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

const SyncHistory = ({ datastoreId }) => {
  const [page, setPage] = useState(0);
  const size = 8;

  const getParams = () => {
    const params = { page: page + 1, size: size };
    return params;
  };

  const { data: sync_data, isLoading } = useQuery({
    queryKey: [GET_SYNC_HISTORY, page],
    queryFn: () => {
      return getSyncHistory(datastoreId, getParams());
    },
  });

  if (isLoading) {
    return <ComponentLoader />;
  }

  return (
    <div className={styles.sync_history}>
      <div className={styles.title}>Sync History</div>
      <div className={styles.table}>
        <div className={styles.table_header}>
          <div className="d-flex justify-content-between align-items-center">
            <span>Type</span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span>Time</span>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <span>Status</span>
          </div>
        </div>
        <div className={styles.table_body}>
          {sync_data["items"].map((item) => {
            let tagColor = "lightGreen";
            if (item.sync_status === "running") {
              tagColor = "purple";
            } else if (item.sync_status === "failed") {
              tagColor = "pink";
            }
            return (
              <div
                key={item.id}
                className={styles.table_row}
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <div
                  className={classnames(
                    styles.type,
                    styles.cursor_pointer,
                    styles.break_word
                  )}
                >
                  {item.sync_event_type}
                </div>
                <div
                  className={classnames(
                    styles.time,
                    styles.cursor_pointer,
                    styles.break_word
                  )}
                >
                  {dayjs(item.created_on).format("h:mm A, D MMMM YY")}
                </div>
                <RectColorTag
                  label={_.capitalize(item.sync_status)}
                  color={tagColor}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles.paginate}>
        <Paginate
          itemCount={sync_data.total}
          page={page}
          pageSize={sync_data.size}
          numPages={sync_data.pages}
          onPageClick={setPage}
        />
      </div>
    </div>
  );
};

const DatastoreDetails = () => {
  const { datastoreId } = useParams();
  const { data: datastore, isLoading } = useQuery({
    queryKey: [GET_DATASTORE_DETAILS, datastoreId],
    queryFn: () => getDatastoreDetail(datastoreId),
  });

  if (isLoading) return <ComponentLoader left={60} />;

  return (
    <>
      <div className={styles.datastore_details}>
        <div className={classnames("fs-3 fw-semibold no-break", styles.header)}>
          {datastore.name}
        </div>
        <div className="d-flex flex-column gap-md">
          <Datastore datastore={datastore} />
          <SyncHistory datastoreId={datastore.id} />
        </div>
      </div>
    </>
  );
};

export { DatastoreDetails };
