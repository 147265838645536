import snowflake from "../../assets/images/datastore_snowflake.png";
import postgres from "../../assets/images/postgres.png";
import s3 from "../../assets/images/s3_bucket.png";
import mySQL from "../../assets/images/my_sql.png";
import bigQuery from "../../assets/images/big_query.png";
import { capitalize, lowerCase } from "lodash";
import { useQuery } from "react-query";
import { getDatastoreTypes } from "../../helpers/backend_helper";
import classNames from "classnames";
import styles from "./styles.module.scss";

export const GET_DATASTORE_TYPES = "GET-DATASTORE-TYPES";

const iconMap = {
  postgres: postgres,
  snowflake: snowflake,
  s3: s3,
  bigquery: bigQuery,
  mysql: mySQL,
};

export const getIconByDatastoreType = (datastoreType, className) => {
  const icon = iconMap[lowerCase(datastoreType).replace(/ /g, "")];
  if (!icon) return null;

  return (
    <img src={icon} alt={capitalize(datastoreType)} className={className} />
  );
};

export const DatastoreTypeIcons = ({ datastoreType, setDatastoreType }) => {
  const { data: datastoreTypes, isLoading } = useQuery({
    queryKey: [GET_DATASTORE_TYPES],
    queryFn: getDatastoreTypes,
  });

  if (isLoading && !datastoreTypes) return null;
  return datastoreTypes.map((ds) => (
    <div
      key={ds.value}
      className={classNames("d-flex align-items-center p-2", styles.ds_card, {
        [styles.selected]: ds.value === datastoreType,
        [styles.disabled]: !ds.enabled,
      })}
      onClick={() => {
        if (!ds.enabled) return;
        setDatastoreType(ds.value);
      }}
    >
      {getIconByDatastoreType(ds.value, styles.datastore_icons)}
      {capitalize(ds.value)}
    </div>
  ));
};
