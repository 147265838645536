import { useMutation, useQuery } from "react-query";
import {
  getJobDefinitions,
  getQueryJobData,
  getQueryJobExecutions,
  getQueryJobFilters,
  getQueryJobs,
  updateQueryJobName,
} from "../../helpers/backend_helper";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { ListingTable } from "../../Components/Tables";
import { useMemo, useState } from "react";
import { RelativeComponentLoader } from "../../Components/Loader";
import { default as ArrowLeftIcon } from "../../assets/icons/arrow_left.svg?react";
import { default as EditIcon } from "@/assets/icons/edit.svg?react";
import { Button, Input } from "reactstrap";
import dayjs from "dayjs";
import { Paginate } from "../../Components/Paginate";
import SidebarModal from "../../Components/SidebarModal";
import arrow_down from "../../assets/icons/arrow_down.svg";
import { CueCodeEditor } from "@/Components/CodeEditor";
import { roundToTwoDecimalPlaces } from "../../helpers/utils";
import {
  GET_JOB_DEFINTIONS,
  GET_JOB_EXECUTIONS,
  GET_JOB_FILTERS,
  GET_QUERY_JOB_DATA,
} from "./constants";
import { FilterTagWrapper } from "../../Components/Tags";
import { ScrollContainer } from "../../Components/ScrollContainer";
import { useNavigate } from "react-router-dom";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { EmptyScreen } from "../Warehouse/misc";
import { CustomTooltip } from "../../Components/Graph/misc";
import { useAppState } from "@/modules/app/useAppContext";
import { formatNumber } from "@/uiCore";
import useFiltersBySearchParams from "@/helpers/useFiltersBySearchParams";

const JobSelectedHeader = ({ initialJobName, jobData, close, environment }) => {
  const { currency } = useAppState();
  const navigate = useNavigate();
  const [isNameEditing, setIsNameEditing] = useState(false);
  const [jobName, setJobName] = useState(initialJobName);
  const [showJobInsights, setShowJobInsights] = useState(false);

  const toggleInsights = () => {
    setShowJobInsights((prev) => !prev);
  };

  const jobInfo = useMemo(() => {
    const jobFrequency = jobData?.job_frequency;
    const jobInfoList = [
      {
        label: "Average run time",
        value: jobData?.avg_total_execution_time,
      },
      {
        label: "90th percentile",
        value: jobData?.p90_total_execution_time,
      },
      {
        label: "Max run time",
        value: jobData?.max_total_execution_time,
      },
      {
        label: "Job Frequency",
        value: jobFrequency,
      },
      {
        label: "Annualized Cost",
        value: formatNumber(jobData?.annualized_cost, { currency }),
      },
    ];

    if (environment) {
      jobInfoList.push({
        label: "Environment",
        value: environment,
      });
    }

    return jobInfoList;
  }, [jobData, environment]);

  // const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: (data) => updateQueryJobName(jobData.job_id, data),
    onSuccess: (data) => {
      if (data.ok) {
        setIsNameEditing(false);
        // queryClient.invalidateQueries({ queryKey: [GET_QUERY_GROUP_BY_ID] });
      }
    },
  });

  return (
    <div className="bg-blue-light p-3 border-radius-top d-flex align-items-start gap-sm">
      <div
        className="p-1 br-2 bg-blue text-white cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          close();
          if (environment) {
            navigate("/query/autotune_jobs");
          } else {
            navigate("/query/jobs");
          }
        }}
      >
        <ArrowLeftIcon className="icon-sm" />
      </div>
      <div className="d-flex flex-column gap-sm">
        <div className="d-flex gap-xs align-items-center">
          {!isNameEditing ? (
            <>
              <div className="text-primary">{jobName}</div>
              <EditIcon
                className={classNames("cursor-pointer", styles.edit_icon, {
                  [styles.active]: isNameEditing,
                })}
                onClick={(e) => {
                  e.stopPropagation();
                  if (isNameEditing) {
                    setIsNameEditing(false);
                  } else {
                    setIsNameEditing(true);
                  }
                }}
              />
            </>
          ) : (
            <>
              <Input
                disabled={!isNameEditing}
                rows={1}
                className={styles.name_input}
                value={jobName}
                onChange={(e) => setJobName(e.target.value)}
                placeholder={initialJobName}
              />
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  mutate({ job_name: jobName });
                }}
              >
                Save
              </Button>
              <Button
                outline
                onClick={(e) => {
                  e.stopPropagation();
                  setIsNameEditing(false);
                }}
              >
                Cancel
              </Button>
            </>
          )}
        </div>
        <div className="d-flex gap-xl">
          {jobInfo.map((item) => (
            <div key={item.label}>
              <span className="text-muted">{item.label}: </span>
              <span>{item.value}</span>
            </div>
          ))}
        </div>
      </div>
      <div className="spacer" />
      <Button outline onClick={toggleInsights}>
        Job Insights
      </Button>
      <SidebarModal
        isOpen={showJobInsights}
        toggleModal={toggleInsights}
        width="660"
      >
        <JobInsights jobData={jobData} />
      </SidebarModal>
    </div>
  );
};

const JobInsights = ({ jobData }) => {
  const { currency } = useAppState();
  const navigate = useNavigate();
  const infoData = [
    {
      label: "Annualized Cost",
      value: formatNumber(jobData.annualized_cost, { currency }),
    },
    {
      label: "Average Execution Time",
      value: jobData.avg_total_execution_time,
    },
  ];
  return (
    <div className={styles.job_insights}>
      <div className="d-flex gap-sm align-items-center">
        <div className={styles.title}>Job Name: {jobData.job_name}</div>
      </div>
      <div className={styles.info}>
        {infoData.map((item, index) => (
          <div key={index}>
            <div className={styles.info_title}>
              {item.label}:{" "}
              <span className={styles.info_text}>{item.value}</span>
            </div>
          </div>
        ))}
      </div>
      <JobQueries selectedJob={jobData} />
    </div>
  );
};

const JobQueries = ({ selectedJob }) => {
  const [selectedQuery, setSelectedQuery] = useState(null);

  const { data, isLoading } = useQuery({
    queryKey: [GET_JOB_DEFINTIONS, selectedJob.job_id],
    queryFn: () => getJobDefinitions(selectedJob.job_id),
  });

  const headers = ["Query Text", "Avg. Execution Time", "Average Cost"];

  if (isLoading) return <RelativeComponentLoader />;

  return (
    <div className={styles.queries}>
      <div className={styles.title}>Queries</div>
      <div className={styles.table}>
        <div className={styles.header}>
          {headers.map((header, index) => (
            <>
              {index !== 0 && <div className={styles.spacer} />}
              <div className={styles.text} key={index}>
                {header}
              </div>
            </>
          ))}
        </div>
        <ScrollContainer>
          <div className={styles.scrollContent}>
            {data?.map((item, index) => (
              <QueryDropdown
                key={index}
                item={item}
                index={index}
                selectedQuery={selectedQuery}
                setSelectedQuery={setSelectedQuery}
              />
            ))}
          </div>
        </ScrollContainer>
      </div>
    </div>
  );
};

const QueryDropdown = ({ item, index, selectedQuery, setSelectedQuery }) => {
  const { currency } = useAppState();
  const navigate = useNavigate();
  const closeDropdown = () => {
    if (selectedQuery !== index) setSelectedQuery(index);
    else setSelectedQuery(null);
  };

  const handleQueryClick = () => {
    navigate(
      `/query/all?queryParameterizedHash=${item.query_parameterized_hash}`
    );
  };

  const infoData = [
    {
      label: "Cost",
      value: formatNumber(item.avg_cost, { currency }),
    },
    {
      label: "p90 exc time",
      value: item.p90_execution_time,
    },
  ];

  return (
    <>
      <div className={styles.query_dropdown} onClick={closeDropdown}>
        <div>{item?.query_name || item?.query_trimmed}</div>
        <div>{item?.avg_execution_time}</div>
        <div></div>
        <div className={styles.light_text}>
          {formatNumber(item.avg_cost, { currency })}
        </div>
        <img
          src={arrow_down}
          className={classNames(styles.image, {
            [styles.rotate]: selectedQuery === index,
          })}
          alt="arrow"
        />
      </div>
      {selectedQuery === index && (
        <>
          <div className={styles.query_information}>
            <div className={styles.query_info}>
              {infoData.map((item, index) => (
                <div key={index}>
                  <div className={styles.info_title}>{item.label}: </div>
                  <span className={styles.info_text}>{item.value}</span>
                </div>
              ))}
            </div>
            <Button onClick={handleQueryClick}>View Query</Button>
          </div>
          <CueCodeEditor
            className={styles.animation}
            value={item?.query_text}
            height="20vh"
          />
        </>
      )}
    </>
  );
};

const CustomXAxisTick = ({ x, y, payload }) => {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dx={-30}
        dy={4}
        transform="rotate(270)"
        textAnchor="middle"
        fill="#66768D"
      >
        {dayjs(payload.value).format("MMM DD")}
      </text>
    </g>
  );
};

const CustomYAxisTickCost = ({ x, y, payload }) => {
  const { currencySymbol } = useAppState();
  return (
    <g transform={`translate(${x},${y})`}>
      <text textAnchor="end" dy={5} fill="#66768D">
        {currencySymbol}
        {payload.value}
      </text>
    </g>
  );
};

const CustomYAxisTickTime = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text textAnchor="start" dy={5} fill="#66768D">
      {payload.value} mins
    </text>
  </g>
);

const JobCostAndExecutionTimeGraph = ({ jobId }) => {
  const { currency } = useAppState();
  const [data, setData] = useState(null);
  const [typeFilter, setTypeFilter] = useState("all");

  const { data: _data, isLoading } = useQuery({
    queryKey: [GET_QUERY_JOB_DATA],
    queryFn: () => {
      return getQueryJobData(jobId, { period: 30 });
    },
    onSuccess: (data) => {
      setData(data?.data);
    },
  });

  const handleLegendClick = (data) => {
    const datakey = data?.value;
    if (datakey) {
      if (typeFilter === datakey) {
        setTypeFilter("all");
      } else {
        setTypeFilter(datakey);
      }

      return;
    }
    setTypeFilter("all");
  };

  const stackedData = useMemo(() => {
    if (!data || data.length === 0) return [];
    return data.map((entry) => ({
      time: entry.date,
      cost: roundToTwoDecimalPlaces(entry.cost),
      execution_time: roundToTwoDecimalPlaces(entry.execution_time),
      costUI: formatNumber(entry.cost, { currency }),
      executionTimeUI: `${roundToTwoDecimalPlaces(entry.execution_time)} mins`,
    }));
  }, [currency, data]);

  if (stackedData && stackedData.length === 0) return;

  if (isLoading) return <RelativeComponentLoader componentHeight={30} />;

  return (
    <div className={styles.job_cost_execution_graph}>
      <div className={styles.title}>
        Job Cost and Execution Time for last 30 days
      </div>
      <ResponsiveContainer minHeight={400} className={styles.graph}>
        <LineChart
          data={stackedData}
          margin={{
            top: 50,
            right: 50,
            left: 50,
            bottom: 50,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="time" tick={<CustomXAxisTick />} />
          <YAxis
            yAxisId="left"
            stroke="#9382D9"
            label={{
              value: "Cost",
              fill: "black",
              angle: 0,
              position: "insideTopLeft",
              offset: -20,
            }}
            tick={<CustomYAxisTickCost />}
          />
          <YAxis
            yAxisId="right"
            orientation="right"
            stroke="#01CD8C"
            label={{
              value: "Time",
              fill: "black",
              angle: 0,
              position: "insideTopRight",
              offset: -20,
            }}
            tick={<CustomYAxisTickTime />}
          />
          <Tooltip
            content={
              <CustomTooltip
                nameValue={[
                  {
                    name: "Cost",
                    value: "costUI",
                    color: "#9382D9",
                  },
                  {
                    name: "Execution Time",
                    value: "executionTimeUI",
                    color: "#01CD8C",
                  },
                ]}
              />
            }
          />
          <Line
            name="Cost"
            yAxisId="left"
            type="monotone"
            dataKey={`${
              typeFilter == "all" || typeFilter == "Cost"
                ? "cost"
                : "cost_hidden"
            }`}
            stroke="#9382D9"
            dot={{ strokeWidth: 2, fill: "#9382D9" }}
          />
          <Line
            name="Execution Time"
            yAxisId="right"
            type="monotone"
            dataKey={`${
              typeFilter == "all" || typeFilter == "Execution Time"
                ? "execution_time"
                : "execution_time_hidden"
            }`}
            stroke="#01CD8C"
            dot={{ strokeWidth: 2, fill: "#247EFE" }}
          />
          <Legend
            verticalAlign="bottom"
            wrapperStyle={{ bottom: 4 }}
            onClick={handleLegendClick}
            formatter={(value, entry, index) => (
              <span className={styles.legend}>{value}</span>
            )}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

const JobExecutions = ({ selectedJob, jobFilters, autoTune = false }) => {
  const { currency, currencySymbol } = useAppState();
  const navigate = useNavigate();
  const {searchParams, deleteSearchParamsByKeyValue, updateSearchParamsByKey} = useFiltersBySearchParams();
  const [page, setPage] = useState(0);
  const [sortAttribute, setSortAttribute] = useState("timestamp");
  const warehouseFilter = jobFilters?.warehouseFilter || [];
  const warehouseSizeFilter = jobFilters?.warehouseSizeFilter || [];
  const userFilter = jobFilters?.userFilter || [];
  const costFilter = jobFilters?.costFilter || 0;
  const [sortOrder, setSortOrder] = useState({
    timestamp: "desc",
    cost: "",
    job_execution_time: "",
    total_data_scanned: "",
    total_local_spill: "",
    total_remote_spill: "",
  });

  const handleTimestampSortChange = (so) => {
    if (so) {
      setSortAttribute("timestamp");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        timestamp: so,
      }));
    }
  };

  const handleCostSortChange = (so) => {
    if (so) {
      setSortAttribute("cost");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        cost: so,
      }));
    }
  };

  const handleExecutionTimeSortChange = (so) => {
    if (so) {
      setSortAttribute("job_execution_time");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        job_execution_time: so,
      }));
    }
  };

  const handleDataScannedSortChange = (so) => {
    if (so) {
      setSortAttribute("total_data_scanned");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        total_data_scanned: so,
      }));
    }
  };

  const handleLocalSpillSortChange = (so) => {
    if (so) {
      setSortAttribute("total_local_spill");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        total_local_spill: so,
      }));
    }
  };

  const handleRemoteSpillSortChange = (so) => {
    if (so) {
      setSortAttribute("total_remote_spill");
      setSortOrder((prevSortOrder) => ({
        ...prevSortOrder,
        total_remote_spill: so,
      }));
    }
  };

  const getFilterParams = () => {
    const params = { page: page + 1, size: 8 };
    if (sortAttribute) {
      params.sort_key = sortAttribute;
      params.sort_order = sortOrder[sortAttribute];
    }
    if (costFilter) params.executionCostFilter = costFilter;
    if (warehouseFilter.length > 0) params.warehouses = warehouseFilter;
    if (warehouseSizeFilter.length > 0)
      params.warehouse_sizes = warehouseSizeFilter;
    if (userFilter.length > 0) params.users = userFilter;
    return params;
  };

  const getJobFilterParams = () => {
    const filters = {};
    if (selectedJob?.job_id) filters.job_id = selectedJob.job_id;
    return filters;
  };

  const { data: filters, isLoading: isfiltersLoading } = useQuery({
    queryKey: [GET_JOB_FILTERS, selectedJob.job_id],
    queryFn: () => getQueryJobFilters(getJobFilterParams()),
  });

  const { data, isLoading } = useQuery({
    queryKey: [
      GET_JOB_EXECUTIONS,
      page,
      ...warehouseFilter,
      ...warehouseSizeFilter,
      ...userFilter,
      sortOrder[sortAttribute],
      sortAttribute,
      selectedJob,
      costFilter,
    ],
    queryFn: () => getQueryJobExecutions(selectedJob.job_id, getFilterParams()),
  });

  if (isLoading || isfiltersLoading) return <RelativeComponentLoader />;

  const handleExecutionClick = (item) => {
    navigate(`/query/all?sessionID=${item.session_id}`);
  };

  const handleCostFilterChange = (value) => {
    updateSearchParamsByKey({"cost": value});
  };

  const handleWarehouseFilterChange = (value) => {
    updateSearchParamsByKey({"warehouses": value});
  };

  const handleUserFilterChange = (value) => {
    updateSearchParamsByKey({"users": value});
  };

  const handleWarehouseSizeFilterChange = (value) => {
    updateSearchParamsByKey({"warehouseSize": value});
  };

  const listingTableHeaders = [
    {
      id: 1,
      label: "Job Start Time",
      sort: {
        onChange: handleTimestampSortChange,
        value: sortOrder.timestamp,
      },
    },
    {
      id: 3,
      label: "Est. Cost",
      sort: {
        onChange: handleCostSortChange,
        value: sortOrder.cost,
      },
      filter: {
        filterType: "text",
        value: costFilter,
        placeHolder: `Specify cost in ${currencySymbol}`,
        onChange: handleCostFilterChange,
        label: "Annualized Cost",
      },
    },
    {
      id: 4,
      label: "Exec. Time",
      sort: {
        onChange: handleExecutionTimeSortChange,
        value: sortOrder.job_execution_time,
      },
    },
    {
      id: 5,
      label: "Warehouse",
      filter: {
        filterType: "dropdown",
        value: warehouseFilter,
        options: filters.warehouses
          ? filters.warehouses.map((w) =>
              w === "" ? { label: "None", value: w } : { label: w, value: w }
            )
          : [],
        onChange: handleWarehouseFilterChange,
      },
    },
    {
      id: 6,
      label: "Warehouse Size",
      filter: {
        filterType: "dropdown",
        value: warehouseSizeFilter,
        options: (filters?.warehouse_sizes || []).map((env) => ({
          label: env,
          value: env,
        })),
        onChange: handleWarehouseSizeFilterChange,
      },
    },
    {
      id: 7,
      label: "User",
      filter: {
        filterType: "dropdown",
        value: userFilter,
        options: filters.users
          ? filters.users.map((w) => ({
              label: w,
              value: w,
            }))
          : [],
        onChange: handleUserFilterChange,
      },
    },
    {
      id: 8,
      label: "GB Scanned",
      sort: {
        onChange: handleDataScannedSortChange,
        value: sortOrder.total_data_scanned,
      },
    },
    {
      id: 9,
      label: "Local Spillage",
      sort: {
        onChange: handleLocalSpillSortChange,
        value: sortOrder.total_local_spill,
      },
    },
    {
      id: 10,
      label: "Remote Spillage",
      sort: {
        onChange: handleRemoteSpillSortChange,
        value: sortOrder.total_remote_spill,
      },
    },
  ];

  return (
    <div className={classNames(styles.jobs, styles.card_mode)}>
      <div className="d-flex gap-md align-items-center mt-2">
        <div className={styles.title}>Query Job Executions</div>
        <FilterTagWrapper
          filters={{
            costFilter: {
              label: "Cost",
              filterStr: costFilter,
              onclose: () => {
                deleteSearchParamsByKeyValue({ cost: undefined });
              },
            },
            userFilter: {
              label: "Users",
              filterStr: userFilter,
              onclose: (removedFilter) => {
                deleteSearchParamsByKeyValue({ users: removedFilter });
              },
            },
            warehouseFilter: {
              label: "Warehouse",
              filterStr: warehouseFilter,
              onclose: (removedFilter) => {
                deleteSearchParamsByKeyValue({ warehouses: removedFilter });
              },
            },
            warehouseSizeFilter: {
              label: "Warehouse Size",
              filterStr: warehouseSizeFilter,
              onclose: (removedFilter) => {
                deleteSearchParamsByKeyValue({ warehouseSize: removedFilter });
              },
            },
          }}
        />
      </div>
      <ListingTable
        header={listingTableHeaders}
        onItemClick={(item) => {
          handleExecutionClick(item);
        }}
        clickable
        idKey={(item) => item.id}
        items={data.items}
        resetPage={() => {}}
        templateColumns={`1.5fr 1fr 1fr 1.5fr 1fr 1.5fr 0.75fr 0.75fr 0.75fr`}
        rowRender={(item) => {
          return (
            <>
              <div classNames="d-flex flex-column align-items-start gap-xs">
                <div>{dayjs(item.start_time).format("DD-MMM-YY HH:mm A")}</div>
              </div>
              <div>{formatNumber(item?.cost, { currency })}</div>
              <div>{item?.job_execution_time}</div>
              <div>{item?.warehouse_name}</div>
              <div>{item?.warehouse_size}</div>
              <div>{item?.user_name}</div>
              <div>{item?.total_data_scanned}</div>
              <div>{item?.total_local_spill}</div>
              <div>{item?.total_remote_spill}</div>
            </>
          );
        }}
      />
      <Paginate
        itemCount={data.total}
        page={page}
        pageSize={data.size}
        numPages={data.pages}
        onPageClick={setPage}
      />
    </div>
  );
};

export { JobSelectedHeader, JobExecutions, JobCostAndExecutionTimeGraph };
