import { Button } from "reactstrap";
import styles from "./styles.module.scss";
import NoIntegrationImage from "@/assets/images/no_channel.svg";

export const NoIntegration = ({ toggleAddIntegration }) => {
  return (
    <div className={styles.no_integration}>
      <img src={NoIntegrationImage} alt="no_integration" />
      <div className={styles.title}>No integration found</div>
      <div className={styles.subtitle}>Add integration</div>
      <Button className={styles.button} onClick={toggleAddIntegration}>
        Add Integration
      </Button>
    </div>
  );
};
