import logo from "../../../assets/images/logo.svg";
import amran from "../../../assets/images/amran.svg";

export const Logo = () => {
  return (
    <div className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center">
        <img src={logo} alt="Altimate AI" />
        <h3 className="mb-0 ms-2" style={{ textWrap: "nowrap" }}>
          Altimate AI
        </h3>
      </div>
      <img src={amran} alt="Amran" className="invisible" />
    </div>
  );
};
