import { useMemo, useState } from "react";
import { Button, Input, PopoverBody } from "reactstrap";
import { ColumnDatatype } from "../../Components/Column";
import styles from "./styles.module.scss";
import { DebouncedInput } from "../../Components/Form";
import { BetterPopover } from "../../Components/Popover";

const COLUMN_SELECTION_LIMIT = 20;
const DBT_COLUMN_SELECTION_LIMIT = 10;

const ColumnPopover = ({ id, resource, setColumns }) => {
  const [active, setActive] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [search, setSearch] = useState("");

  const columns = useMemo(() => {
    setActive({});
    setSelectAll(false);
    setSearch("");
    return resource?.columns || [];
  }, [resource]);

  const filteredColumns = useMemo(() => {
    if (!search) return columns;
    const _search = search.toLowerCase();
    return columns.filter((c) => c.name.toLowerCase().includes(_search));
  }, [search, columns]);

  if (!resource) return null;

  const activeCount = Object.values(active).filter((x) => x).length;
  return (
    <BetterPopover target={id} hideArrow placement="bottom">
      {({ close }) => (
        <PopoverBody className={styles.popover_container}>
          <div className="mb-3 ps-3 pe-3">
            <DebouncedInput
              size="sm"
              placeholder="Search by column name"
              onChange={setSearch}
            />
          </div>
          <div className="mb-3 d-flex justify-content-between ps-3 pe-3">
            {columns.length > COLUMN_SELECTION_LIMIT ? (
              <div>Remaining: {COLUMN_SELECTION_LIMIT - activeCount}</div>
            ) : (
              <div
                className="d-flex align-items-center cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  if (!selectAll) {
                    const _active = {};
                    for (const c of columns) _active[c.name] = true;
                    setActive(_active);
                  } else {
                    setActive({});
                  }
                  setSelectAll((b) => !b);
                }}
              >
                <Input
                  type="checkbox"
                  checked={selectAll}
                  className="m-0 me-2"
                  readOnly
                />
                <div>Select All</div>
              </div>
            )}
            <div>Selected: {activeCount}</div>
          </div>
          <div className={styles.scroll_container}>
            {filteredColumns.map((c) => (
              <div key={c.name} className="d-flex align-items-center ps-3 pe-3">
                <div
                  className="d-flex gap-xs align-items-center cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    setActive((a) => ({ ...a, [c.name]: !a[c.name] }));
                    setSelectAll(false);
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={!!active[c.name]}
                    className="m-0"
                    readOnly
                  />
                  <ColumnDatatype datatype={c.datatype} />
                  <div>{c.name}</div>
                </div>
                <div className="spacer" />
                <div className="text-muted">{c.datatype}</div>
              </div>
            ))}
          </div>
          <Button
            className="mt-3 ms-3 me-3"
            disabled={activeCount > COLUMN_SELECTION_LIMIT}
            onClick={(e) => {
              e.stopPropagation();
              if (activeCount > COLUMN_SELECTION_LIMIT) return;
              setColumns(columns.filter((c) => active[c.name]));
              close();
            }}
          >
            Get Recommendation
          </Button>
        </PopoverBody>
      )}
    </BetterPopover>
  );
};

const DocGenColumnPopover = ({
  id,
  resource,
  setColumns,
  setColumnsSelected,
}) => {
  const [active, setActive] = useState({});
  const [search, setSearch] = useState("");

  const columns = useMemo(() => {
    setActive({});
    setSearch("");
    return resource.columns || [];
  }, [resource]);

  const filteredColumns = useMemo(() => {
    if (!search) return columns;
    const _search = search.toLowerCase();
    return columns.filter((c) => c.column_name.toLowerCase().includes(_search));
  }, [search, columns]);

  if (!resource) return null;

  const activeCount = Object.values(active).filter((x) => x).length;

  const handleColumnClick = (e, c) => {
    e.stopPropagation();
    if (DBT_COLUMN_SELECTION_LIMIT > activeCount || active[c.column_name]) {
      if (active[c.column_name]) {
        setColumnsSelected(activeCount - 1);
      } else {
        setColumnsSelected(activeCount + 1);
      }
      setActive((a) => ({
        ...a,
        [c.column_name]: !a[c.column_name],
      }));
      setColumns((a) => ({
        ...a,
        [c.column_name]: !a[c.column_name],
      }));
    }
  };
  return (
    <BetterPopover target={id} hideArrow placement="bottom">
      {({ close }) => (
        <PopoverBody className={styles.popover_container}>
          <div className="mb-3 ps-3 pe-3">
            <DebouncedInput
              size="sm"
              placeholder="Search by column name"
              onChange={setSearch}
            />
          </div>
          <div className="mb-3 d-flex justify-content-between ps-3 pe-3">
            <div className={styles.selected_columns}>
              {activeCount} Selected
            </div>
            <div className={styles.remaining_columns}>
              {DBT_COLUMN_SELECTION_LIMIT - activeCount} Remaining
            </div>
          </div>
          <div className={styles.scroll_container}>
            {filteredColumns.map((c) => (
              <div
                key={c.column_name}
                className="d-flex align-items-center ps-3 pe-3"
              >
                <div
                  className="d-flex gap-xs align-items-center cursor-pointer"
                  onClick={(e) => {
                    handleColumnClick(e, c);
                  }}
                >
                  <Input
                    type="checkbox"
                    checked={!!active[c.column_name]}
                    className="m-0"
                    readOnly
                  />
                  <ColumnDatatype datatype={c.data_type || "default"} />
                  <div>{c.column_name}</div>
                </div>
                <div className="spacer" />
              </div>
            ))}
          </div>
        </PopoverBody>
      )}
    </BetterPopover>
  );
};

export { ColumnPopover, DocGenColumnPopover };
