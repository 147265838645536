import text_logo from "../../assets/images/text_logo.svg";
import { useEffect } from "react";
import { IntercomProvider, useIntercom } from "react-use-intercom";
import { INTERCOM_APP_ID } from "../../config";
import classes from "./contactus.module.scss";
import { Link } from "react-router-dom";

const ContactUs = () => {
  if (INTERCOM_APP_ID) {
    return (
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
        <ContactUsIntercom />
      </IntercomProvider>
    );
  }

  return null;
};

const ContactUsIntercom = () => {
  const { show } = useIntercom();

  useEffect(() => {
    const config = { attributes: false, childList: true, subtree: false };

    const callback = (mutations: MutationRecord[], obs: MutationObserver) => {
      for (const mutation of mutations) {
        if (mutation.addedNodes.length > 0) {
          mutation.addedNodes.forEach((node) => {
            if (node instanceof HTMLElement) {
              if (node.id === "intercom-container") {
                obs.disconnect();
                show();
              }
            }
          });
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(document.body, config);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className={classes.contactus}>
      <header>
        <div className={classes.logo}>
          <Link to={"/"}>
            <img src={text_logo} alt="Altimate AI" />
          </Link>
        </div>
      </header>
      <div className={classes.bg_img} />
    </div>
  );
};

export { ContactUs };
