import { Tab } from "../../Components/Tab";
import { Validations } from "./Validations";
import { Contracts } from "./Contracts";

const ContractTabs = () => {
  const tabs = [
    {
      label: "Contracts",
      component: <Contracts />,
    },
    {
      label: "Validations",
      component: <Validations />,
    },
  ];
  return <Tab tabs={tabs} />;
};

export { ContractTabs };
