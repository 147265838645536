import { Drawer, List } from "@/uiCore";
import { FilterApplicableFor, FilterType } from "./types";
import { useQuery } from "react-query";
import { getSavedFilters } from "@/helpers/apis";
import { useEffect, useRef, useState } from "react";
import { ComponentLoader } from "../Loader";
import SavedFilterComponent from "./SavedFilter";
import classes from "./savedFilters.module.scss";
import { DrawerRef } from "@/uiCore/components/drawer";
import { ButtonProps } from "reactstrap";

interface Props {
  applicableFor: FilterApplicableFor;
  buttonProps?: ButtonProps;
}
const SavedFilters = ({ applicableFor, buttonProps }: Props) => {
  const ref = useRef<DrawerRef | null>(null);
  const [selectedFilter, setSelectedFilter] = useState("");
  const { data, isLoading, refetch } = useQuery({
    queryKey: [`SAVED_FILTERS_${applicableFor}`],
    queryFn: () => getSavedFilters({ applicableFor, type: FilterType.FILTER }),
    retry: 0,
    enabled: false,
  });

  const onShowFilter = (event: CustomEvent<string>) => {
    if (event.detail) {
      refetch();
      setSelectedFilter(event.detail);
      ref.current?.open();
    }
  };
  useEffect(() => {
    document.addEventListener("showFilter", onShowFilter as EventListener);
    return () => {
      document.removeEventListener("showFilter", onShowFilter as EventListener);
    };
  }, []);

  return (
    <Drawer
      buttonText="Saved Filters"
      buttonProps={{ ...buttonProps, outline: true }}
      onOpen={() => refetch()}
      ref={ref}
    >
      <div className={classes.saved_filters}>
        <h4>Saved Filters</h4>
        <div>
          {isLoading ? (
            <ComponentLoader label="Loading saved filters..." />
          ) : data?.presets.length ? (
            <List>
              {data.presets.map((filter) => (
                <SavedFilterComponent
                  key={filter.id}
                  filter={filter}
                  selectedFilter={selectedFilter}
                  refetch={refetch}
                />
              ))}
            </List>
          ) : (
            <div>No saved filters found</div>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default SavedFilters;
