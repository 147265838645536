import { Button, Card, CardBody, CardFooter, CardTitle } from "reactstrap";
import { SavedFilter } from "./types";
import { FilterTag } from ".";
import classes from "./savedFilters.module.scss";
import useFiltersBySearchParams from "@/helpers/useFiltersBySearchParams";
import { Stack } from "@/uiCore";
import { useEffect, useRef } from "react";
import { Popconfirm } from "antd";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { deleteFilter } from "@/helpers/apis";
import { LoadingButton } from "@/lib/altimate/altimate-components";

interface Props {
  filter: SavedFilter;
  selectedFilter?: string;
  refetch: () => void;
}
const SavedFilterComponent = ({ filter, selectedFilter, refetch }: Props) => {
  const ref = useRef<HTMLLIElement | null>(null);
  const isSelected = filter.id === selectedFilter;
  const { clearSearchParams, updateSearchParamsByKey } =
    useFiltersBySearchParams();

  const handleApply = () => {
    clearSearchParams();
    updateSearchParamsByKey(filter.data as { [searchKey: string]: string | string[]; });
  };

  const { mutate, isLoading } = useMutation(deleteFilter, {
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      toast.error(
        (error as Error | undefined)?.message || "Failed to delete filter"
      );
    },
  });

  useEffect(() => {
    if (isSelected) {
      ref.current?.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [isSelected]);

  const onConfirm = () => {
    mutate(filter.id);
  };

  return (
    <li ref={ref}>
      <Card className={isSelected ? classes.active : null}>
        <CardTitle>{filter.name}</CardTitle>
        <CardBody>
          <Stack className="flex-wrap">
            {Object.entries(filter.data).map(([key, value]) => {
              if (Array.isArray(value)) {
                return value.map((val) => (
                  <FilterTag
                    key={val}
                    label={key}
                    filterStr={val}
                    searchKey=""
                    onClose={() => {}}
                    disableClose
                    bgColor="light"
                  />
                ));
              }
              return (
                <FilterTag
                  key={key}
                  label={key}
                  filterStr={value}
                  searchKey=""
                  onClose={() => {}}
                  bgColor="light"
                  disableClose
                />
              );
            })}
          </Stack>
        </CardBody>
        <CardFooter>
          <Stack>
            <Button size="sm" onClick={handleApply}>
              Apply
            </Button>
            <Popconfirm
              title="Delete the filter"
              description="Are you sure to delete this filter?"
              onConfirm={onConfirm}
              okText="Yes"
              cancelText="No"
            >
              <LoadingButton
                loading={isLoading}
                size="sm"
                outline
                color="danger"
              >
                Delete
              </LoadingButton>
            </Popconfirm>
          </Stack>
        </CardFooter>
      </Card>
    </li>
  );
};

export default SavedFilterComponent;
