import { useQuery } from "react-query";
import {
  Stack,
  Tabs,
  Tile,
  Card,
  NumberFormatter,
  Select,
  Tooltip,
  getCurrencySymbol,
  formatNumber,
} from "@uicore";
import { useState, useEffect } from "react";
import classes from "../summary.module.scss";
import AggregateFilter from "./AggregateFilter";
import BreakdownSkeleton from "./BreakdownSkeleton";
import { getSavingsBreakdown } from "@/helpers/apis";
import AssistedSavingsSection from "./AssistedSavingsSection";
import { AggregateByValues } from "./constants";
import SavingsInFuture from "./SavingsInFuture";
import {
  AssistedSavingsResponse,
  AutoTuneSavingsSummaryResponse,
  OverallSavingsResponse,
  OverallSavingsEstimateResponse,
} from "../Context/types";
import AutonomousSavingsSection from "./AutonomousSavingsSection";
import { formatDate } from "./utils";
import AccordionTitle from "./AccordionTitle";
import FailureComponent from "./FailureComponent";
import { useAppState } from "@/modules/app/useAppContext";
import {
  getDateByStartOfDayWithoutTimeoffset,
  getDateByEndOfDayWithoutTimeoffset,
} from "@/Components/DateRange/utils";
import SummaryChatButton from "./SummaryChatButton";
import dayjs from "dayjs";
import styles from "./styles.module.scss";

interface Props {
  isFuture?: boolean;
  currentStartDate: Date;
  currentEndDate: Date | null;
}

const SavingsBreakdown = ({
  isFuture,
  currentStartDate,
  currentEndDate,
}: Props) => {
  const { currency } = useAppState();
  const [savingsType, setSavingsType] = useState("autonomous");
  const [aggregateBy, setAggregateBy] = useState(
    isFuture ? AggregateByValues.Monthly : AggregateByValues.Daily
  );
  const [savingsPeriod, setSavingsPeriod] = useState("next1Year");

  const yearMode = aggregateBy === AggregateByValues.Monthly;

  const onAggregateSelect = (value: string) => {
    setAggregateBy(value);
  };

  const getEndPoint = () => {
    if (isFuture) {
      return `${savingsType}/estimate`;
    }

    return savingsType;
  };

  const { isLoading, isFetching, data, error, refetch } = useQuery({
    queryKey: [
      `savingsBreakdown-${isFuture ? "future" : "past"}`,
      currentStartDate,
      currentEndDate,
      aggregateBy,
    ],
    queryFn: () =>
      getSavingsBreakdown(getEndPoint(), {
        start_date:
          getDateByStartOfDayWithoutTimeoffset(currentStartDate).toISOString(),
        end_date:
          getDateByEndOfDayWithoutTimeoffset(currentEndDate)?.toISOString(),
        aggregation_level: aggregateBy,
      }),
    enabled: Boolean(currentStartDate && currentEndDate),
    onError(err) {
      window.posthog.capture("savingsBreakdownFailed", { error: err });
    },
  });

  const {
    data: overallSavingsData,
    refetch: refetchOverallSavings,
    isLoading: isSavingsLoading,
  } = useQuery({
    queryKey: [
      `overallSavingsBreakdown-${isFuture ? "future" : "past"}`,
      currentStartDate,
      currentEndDate,
      aggregateBy,
      savingsPeriod,
    ],
    queryFn: () =>
      getSavingsBreakdown(isFuture ? `overall/estimate` : "overall", {
        start_date:
          getDateByStartOfDayWithoutTimeoffset(currentStartDate).toISOString(),
        end_date:
          getDateByEndOfDayWithoutTimeoffset(currentEndDate)?.toISOString(),
        aggregation_level: aggregateBy,
        savings_period: savingsPeriod,
      }) as
        | Promise<OverallSavingsResponse | OverallSavingsEstimateResponse>
        | undefined,
    enabled: Boolean(currentStartDate && currentEndDate),
    onError(err) {
      window.posthog.capture("savingsBreakdownFailed", { error: err });
    },
  });

  useEffect(() => {
    refetch();
    refetchOverallSavings();
  }, [
    savingsType,
    refetch,
    aggregateBy,
    currentEndDate,
    currentStartDate,
    refetchOverallSavings,
    isFuture,
  ]);

  const getSavingsValueOrRange = () => {
    if (!overallSavingsData) {
      return null;
    }

    const {
      max_total_autonomous_savings,
      min_total_autonomous_savings,
      total_autonomous_savings,
    } = overallSavingsData;
    let value = isFuture
      ? (overallSavingsData as OverallSavingsEstimateResponse)
          ?.total_autonomous_savings_estimate
      : total_autonomous_savings;
    let tooltip = `${getCurrencySymbol(currency)}${value.toFixed(2)}`;
    if (max_total_autonomous_savings > 0 && min_total_autonomous_savings > 0) {
      tooltip = `Between ${formatNumber(min_total_autonomous_savings, {
        currency,
      })} to ${formatNumber(max_total_autonomous_savings, { currency })}`;
    }
    return (
      <Tooltip content={tooltip} placement="top">
        <NumberFormatter noTooltip value={value} options={{ currency }} />
      </Tooltip>
    );
  };

  if (isLoading || isSavingsLoading) {
    return <BreakdownSkeleton type="section" />;
  }

  return (
    <Card className={classes.section_card}>
      <AccordionTitle
        title={
          <Stack className="w-100">
            <h4 className="text-black">Savings</h4>
            <div className="spacer" />
            {isFuture && (
              <Select
                id="summary-savings-period-dropdown"
                onChange={(e: any) => {
                  setSavingsPeriod(e);
                }}
                className={styles.period_selection}
                value={savingsPeriod}
                options={[
                  { value: "thisYear", label: "This Year" },
                  { value: "next1Year", label: "Next 1 Year" },
                ]}
              />
            )}
          </Stack>
        }
        howToText="How to read the charts"
        howToContent={
          <p>
            {isFuture
              ? "These section show the Snowflake savings data for the period specified for Future State. Saving may be coming from autonomous agent actions or your team implementing the recommendations shown as opportunities. We estimate future savings for a month based on the analysis of different parameters, including savings realized in the past (if any). Then, we predict future savings for the selected period by simply multiplying future savings for a month by the no. of months in future in the selected period."
              : "These charts show the Snowflake savings data for the period specified for Current state. Saving may be coming from autonomous agent actions or your team implementing the recommendations shown as opportunities. Charts can aggregate data at daily, weekly or monthly level. Don't miss the DataPilot icon in the top right corner of the chart to start talking with the data!"}
          </p>
        }
      />
      <Stack>
        {error ? (
          <FailureComponent
            title={"Failed to load savings breakdown. Please try again later."}
          />
        ) : (
          <>
            <div className={classes.tileWrap}>
              <Tile
                color="#01CD8C"
                title="Total Money Savings"
                helpText={
                  isFuture
                    ? savingsPeriod === "thisYear"
                      ? `Projected money savings by the product from ${dayjs().format(
                          "DD MMM YYYY"
                        )} till 31 Dec ${dayjs().format("YYYY")}`
                      : `Projected money savings by the product from 1 Jan ${dayjs()
                          .add(1, "year")
                          .format("YYYY")} till 31 Dec ${dayjs()
                          .add(1, "year")
                          .format("YYYY")}`
                    : `Total money saved by the product during the period selected: ${formatDate(
                        currentStartDate,
                        yearMode
                      )} to ${formatDate(currentEndDate, yearMode)}`
                }
                value={
                  <NumberFormatter
                    value={
                      isFuture
                        ? (overallSavingsData as OverallSavingsEstimateResponse)
                            ?.total_savings_estimate
                        : overallSavingsData?.total_savings
                    }
                    options={{ currency }}
                  />
                }
              >
                <Stack direction="column">
                  <Tile
                    title="Autonomous Money Savings"
                    helpText={
                      isFuture
                        ? savingsPeriod === "thisYear"
                          ? `Projected money savings by the product from ${dayjs().format(
                              "DD MMM YYYY"
                            )} till 31 Dec ${dayjs().format("YYYY")}`
                          : `Projected money savings by the product from 1 Jan ${dayjs()
                              .add(1, "year")
                              .format("YYYY")} till 31 Dec ${dayjs()
                              .add(1, "year")
                              .format("YYYY")}`
                        : "Money saved automatically by the product during the period, without users doing anything"
                    }
                    value={getSavingsValueOrRange()}
                  />
                  <Tile
                    title="Assisted Money Savings"
                    helpText={
                      isFuture
                        ? savingsPeriod === "thisYear"
                          ? `Projected money saved from ${dayjs().format(
                              "DD MMM YYYY"
                            )} till 31 Dec ${dayjs().format(
                              "YYYY"
                            )} if users implement the opportunities identified`
                          : `Projected money saved from 1 Jan ${dayjs()
                              .add(1, "year")
                              .format("YYYY")} till 31 Dec ${dayjs()
                              .add(1, "year")
                              .format(
                                "YYYY"
                              )} if users implement the opportunities identified`
                        : "Money saved during the period when users implemented the opportunities identified by the product."
                    }
                    value={
                      <NumberFormatter
                        value={
                          isFuture
                            ? (
                                overallSavingsData as OverallSavingsEstimateResponse
                              )?.total_assisted_savings_estimate
                            : overallSavingsData?.total_assisted_savings
                        }
                        options={{ currency }}
                      />
                    }
                  />
                </Stack>
              </Tile>

              <Tile
                title="Total Time Savings"
                helpText={
                  isFuture
                    ? `Projected time saved from ${dayjs().format(
                        "DD MMM YYYY"
                      )} till 31 Dec ${dayjs().format(
                        "YYYY"
                      )} if users implement the opportunities identified`
                    : `Time saved during the period when users implemented the opportunities identified by the product.`
                }
                value={
                  isFuture
                    ? (overallSavingsData as OverallSavingsEstimateResponse)
                        ?.total_time_saved_estimate
                    : overallSavingsData?.time_saved
                }
                className={classes.time_saved}
              />
            </div>
            <Stack direction="column" className={classes.graphs}>
              {isFuture ? (
                <SavingsInFuture
                  data={overallSavingsData as OverallSavingsEstimateResponse}
                />
              ) : (
                <>
                  <Stack className="justify-content-between">
                    {" "}
                    <Tabs
                      items={[
                        { label: "Autonomous", value: "autonomous" },
                        { label: "Assisted", value: "assisted" },
                      ]}
                      selectedTab={savingsType}
                      onTabSelect={setSavingsType}
                    />
                    <Stack className="align-items-center">
                      {savingsType !== "assisted" && (
                        <AggregateFilter
                          onSelect={onAggregateSelect}
                          aggregateBy={aggregateBy}
                          isFuture={isFuture}
                          startDate={currentStartDate}
                          endDate={currentEndDate}
                        />
                      )}
                      <SummaryChatButton
                        chartType={isFuture ? "future" : "current"}
                        aggregation={aggregateBy}
                        analysisType="savings"
                        startDate={currentStartDate}
                        endDate={currentEndDate}
                        savingsType={savingsType}
                      />
                    </Stack>
                  </Stack>
                  <div className="flex-1">
                    {savingsType === "autonomous" ? (
                      <div style={{ height: 400, padding: "1rem" }}>
                        <AutonomousSavingsSection
                          yearMode={yearMode}
                          currency={currency}
                          data={
                            data as AutoTuneSavingsSummaryResponse | undefined
                          }
                          isFetching={isFetching}
                          savingsType={savingsType}
                          aggregateBy={aggregateBy}
                          isFuture={isFuture}
                        />
                      </div>
                    ) : null}
                    {savingsType === "assisted" ? (
                      <AssistedSavingsSection
                        {...(data as AssistedSavingsResponse)}
                      />
                    ) : null}
                  </div>
                </>
              )}
            </Stack>
          </>
        )}
      </Stack>
    </Card>
  );
};

export default SavingsBreakdown;
