import { Button, Card, CardBody } from "reactstrap";
import styles from "./styles.module.scss";
import classnames from "classnames";
import no_contract from "../../assets/images/no_contract.svg";
import { Link } from "react-router-dom";

const NoContracts = () => {
  return (
    <div className={styles.no_contracts}>
      <Card className={styles.card}>
        <CardBody className="d-flex justify-content-center">
          <div
            className={classnames(
              "d-flex flex-column align-items-center p-4",
              styles.container
            )}
          >
            <img src={no_contract} alt="No Contracts" className={styles.img} />
            <h2 className="mt-4 mb-4 text-black">
              No contract has been set yet
            </h2>
            <p className="text-muted text-center">
              You can use <span className={styles.blue_span}>Co-pilot</span> to
              generate some contracts
            </p>
            <Link to="/copilot">
              <Button color="primary">Generate Contract</Button>
            </Link>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export { NoContracts };
