import { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';

import { getUser } from "../../services/auth";

const IntercomChatBoot = ({tenant, env}) => {
  const { boot } = useIntercom();
  const userData = getUser(); // Fetch user data

  useEffect(() => {
    if (userData) {
      const userData = getUser()
      const userName = userData['first_name'] + ' ' + userData['last_name']; // user's first name
      const userEmail = userData['email']; // user's email address
      const userId = userData['uuid'];
      const bootProps = {
        userId: userId,
        email: userEmail,
        name: userName,
        company: {
          companyId: env + "+" + tenant,
          name: tenant,
        },
      }
      boot(bootProps);
    } 
  }, [userData, boot, tenant, env]);

  return null;
};

export { IntercomChatBoot };
