import styles from "./styles.module.scss";
import { DebouncedInput, MultiSelect } from "../../Components/Form";
import { useState, useMemo, useEffect } from "react";
import { useMultiSelect } from "../../services/filters";
import { uniqueId } from "lodash";
import { BetterPopover } from "../../Components/Popover";
import classNames from "classnames";
import { ScrollContainer } from "../../Components/ScrollContainer";
import { Button } from "reactstrap";
import { useQuery } from "react-query";
import { RelativeComponentLoader } from "../Loader";
import useFiltersBySearchParams from "@/helpers/useFiltersBySearchParams";

const GET_FILTER_OPTIONS = "GET_FILTER_OPTIONS";

export const FilterDropdown = ({
  label,
  children,
  options,
  onChange,
  value,
  popoverProps = {},
  disabled = false,
  showSearch = true,
  searchBy = "label",
}) => {
  const id = useMemo(() => uniqueId("filter-dropdown-"), []);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const defaultMap = useMemo(() => {
    const _default = new Map();
    value.forEach((v) => _default.set(v, true));
    return _default;
  }, [value]);
  const { getValue, toggleValue, getTrueValues } = useMultiSelect(() => {},
  defaultMap);

  return (
    <>
      <div id={id} className="d-flex gap-sm cursor-pointer">
        {children}
      </div>
      <BetterPopover target={id} hideArrow placement="bottom" {...popoverProps}>
        {({ close }) => (
          <div
            className={classNames(
              styles.dropdown_container,
              styles.async_container
            )}
          >
            <div className="m-2 d-flex justify-content-between align-items-center gap-sm">
              <div>{label}</div>
              <Button
                size="sm"
                outline
                disabled={disabled}
                onClick={(e) => {
                  e.stopPropagation();
                  close();
                  onChange(getTrueValues());
                }}
              >
                Apply
              </Button>
            </div>
            <div className="divider w-100" />
            {showSearch && (
              <div className="m-2">
                <DebouncedInput
                  size="sm"
                  placeholder="Search"
                  onChange={(v) => {
                    const searchQuery = v.toLowerCase();
                    if (searchBy === "label") {
                      setFilteredOptions(
                        options.filter((item) =>
                          (item?.label || "")
                            .toLowerCase()
                            .includes(searchQuery)
                        )
                      );
                    } else if (searchBy === "value") {
                      setFilteredOptions(
                        options.filter((item) =>
                          (item?.value || "")
                            .toLowerCase()
                            .includes(searchQuery)
                        )
                      );
                    }
                  }}
                />
              </div>
            )}
            <div className={styles.scroll_component}>
              <ScrollContainer>
                <div className={styles.scroll_content}>
                  <MultiSelect
                    options={filteredOptions}
                    onChange={toggleValue}
                    value={getValue}
                    showDivider={false}
                  />
                </div>
              </ScrollContainer>
            </div>
          </div>
        )}
      </BetterPopover>
    </>
  );
};

export const StaticFilterDropdown = ({
  id = "testID",
  label,
  children,
  options,
  onChange,
  value,
  disabled = false,
  showSearch = true,
  searchKey
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedValues, setSelectedValues] = useState(new Map());
  const { updateSearchParamsByKey } = useFiltersBySearchParams();

  useEffect(() => {
    const _default = new Map();
    value.forEach((v) => _default.set(v, true));
    setSelectedValues(_default);
  }, [value]);

  const toggleValue = (val) => {
    setSelectedValues((prev) => {
      const newMap = new Map(prev);
      if (newMap.has(val)) {
        newMap.delete(val);
      } else {
        newMap.set(val, true);
      }
      return newMap;
    });
  };

  const getTrueValues = () => {
    return Array.from(selectedValues.keys());
  };

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  return (
    <div
      className={classNames(styles.dropdown_container, styles.async_container)}
    >
      <div className="m-2 d-flex justify-content-between align-items-center gap-sm">
        <div>{label}</div>
        <Button
          size="sm"
          outline
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            if (searchKey) {
              updateSearchParamsByKey({[searchKey]: getTrueValues()});
            } 
            onChange(getTrueValues());
          }}
        >
          Apply
        </Button>
      </div>
      <div className="divider w-100" />
      {showSearch && (
        <div className="m-2">
          <DebouncedInput
            size="sm"
            placeholder="Search"
            onChange={(v) => {
              const searchQuery = v.toLowerCase();
              setFilteredOptions(
                options.filter((item) =>
                  (item?.label || "").toLowerCase().includes(searchQuery)
                )
              );
            }}
          />
        </div>
      )}
      <div className={styles.scroll_component}>
        <ScrollContainer>
          <div className={styles.scroll_content}>
            <MultiSelect
              options={filteredOptions}
              onChange={toggleValue}
              value={(val) => selectedValues.has(val)}
              showDivider={false}
            />
          </div>
        </ScrollContainer>
      </div>
    </div>
  );
};


export const APIFilterDropdown = ({
  id = "testID",
  label,
  children,
  apiFunction,
  onChange,
  value,
  disabled = false,
  seeMore = false,
  searchKey,
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [prefix, setPrefix] = useState("");
  const [selectedValues, setSelectedValues] = useState(new Map());
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const { updateSearchParamsByKey } = useFiltersBySearchParams();

  useEffect(() => {
    const _default = new Map();
    value.forEach((v) => _default.set(v, true));
    setSelectedValues(_default);
  }, [value]);

  const toggleValue = (val) => {
    setSelectedValues((prev) => {
      const newMap = new Map(prev);
      if (newMap.has(val)) {
        newMap.delete(val);
      } else {
        newMap.set(val, true);
      }
      return newMap;
    });
  };

  const getTrueValues = () => {
    return Array.from(selectedValues.keys());
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: [GET_FILTER_OPTIONS, prefix, page],
    queryFn: () => apiFunction({ prefix, page }),
    onSuccess: (data) => {
      setTotalPages(seeMore ? data.total_pages : 1);
      setFilteredOptions((prev) =>
        seeMore
          ? page === 1
            ? data.options
            : [...prev, ...data.options]
          : data
          ? data
          : []
      );
    },
  });

  const handleSeeMore = () => {
    if (page < totalPages) {
      setPage((prev) => prev + 1);
      refetch();
    }
  };

  return (
    <div
      className={classNames(styles.dropdown_container, styles.async_container)}
    >
      <div className="m-2 d-flex justify-content-between align-items-center gap-sm">
        <div>{label}</div>
        <Button
          size="sm"
          outline
          disabled={disabled}
          onClick={(e) => {
            e.stopPropagation();
            if (searchKey) {
              updateSearchParamsByKey({ [searchKey]: getTrueValues() });
            }
            onChange(getTrueValues());
          }}
        >
          Apply
        </Button>
      </div>
      <div className="divider w-100" />
      <div className="m-2">
        <DebouncedInput
          size="sm"
          placeholder="Search"
          onChange={(v) => {
            setPrefix(v);
            if (seeMore) {
              setPage(1);
              setFilteredOptions([]);
            }
          }}
        />
      </div>
      <div>
        <div>
          {isLoading ? (
            <div style={{ marginBottom: "50px" }}>
              <RelativeComponentLoader componentHeight={5} />
            </div>
          ) : (
            <>
              <MultiSelect
                options={filteredOptions || []}
                onChange={toggleValue}
                value={(val) => selectedValues.has(val)}
                showDivider={false}
              />
              {seeMore &&
                filteredOptions?.length > 0 &&
                page < totalPages &&
                !isLoading && (
                  <div className={styles.seeMore} onClick={handleSeeMore}>
                    See next 10 of {data?.total}
                  </div>
                )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};