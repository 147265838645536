import { useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import useAppContext from "@/modules/app/useAppContext";
import { ArrowLeftIcon } from "@/assets/icons";

interface Props {
  defaultUrl: string;
}
export const BackButton = ({ defaultUrl }: Props) => {
  const navigate = useNavigate();
  const {
    state: { previousPath },
  } = useAppContext();

  const handleBackButtonClick = () => {
    // if from login/signup page, previousPath will be undefined
    if (previousPath) {
      navigate(-1);
    } else {
      navigate({ pathname: defaultUrl });
    }
  };

  return (
    <div className={styles.back_button} onClick={handleBackButtonClick}>
      <ArrowLeftIcon />
    </div>
  );
};
