import AuthSlider from "./authCarousel";
import styles from "./styles.module.scss";
import { Logo } from "./logo";
import classnames from "classnames";

const Header = ({ children }) => <h1 className={styles.header}>{children}</h1>;

const MobileBaseContainer = ({ children, footer }) => {
  return (
    <div
      className="h-100 row m-0"
      style={{ width: "100vw", overflowY: "auto", overflowX: "hidden" }}
    >
      <div className="p-0">
        <div
          className={classnames(
            "d-flex flex-column align-items-left w-100",
            styles.mobile_content
          )}
        >
          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.mobile_spacer} />
          <div className={styles.container}>{children}</div>

          {/* <div className={styles.footer_spacer} />
          {footer && <div className={styles.footer}>{footer}</div>} */}
        </div>
      </div>
    </div>
  );
};

const BaseContainer = ({ children, footer }) => {
  const isMobile = window.innerWidth <= 768;

  if (isMobile) {
    return (
      <MobileBaseContainer footer={footer}>{children}</MobileBaseContainer>
    );
  }

  return (
    <div className="w-100 h-100 row m-0">
      <div className="col p-0">
        <AuthSlider />
      </div>
      <div className="col p-0 h-100 overflow-y">
        <div
          className={classnames(
            "d-flex flex-column align-items-center",
            styles.content
          )}
        >
          <div className={styles.logo}>
            <Logo />
          </div>
          <div className={styles.header_spacer} />
          <div className={styles.container}>{children}</div>

          <div className={styles.footer_spacer} />
          {footer && <div className={styles.footer}>{footer}</div>}
        </div>
      </div>
    </div>
  );
};

export { BaseContainer, Header };
