import { RelativeComponentLoader } from "@/Components/Loader";
import { getExportLineageItem } from "@/helpers/apis";
import { useQuery } from "react-query";
import { Navigate, useParams } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import { ApiHelper, Lineage } from "@components";

export const ExportLineage = () => {
  const { itemId } = useParams();
  const { data, isLoading } = useQuery({
    queryKey: ["GET-EXPORT-LINEAGE", itemId],
    queryFn: () => getExportLineageItem(itemId!),
    onSuccess: (data) => {
      // @ts-ignore
      ApiHelper.get = async (url: string, _data: any) => {
        console.log("ApiHelper", url, _data);
        switch (url) {
          case "getColumns": {
            if (!data?.lineage_data?.details) return {};
            const { columns, purpose } = data.lineage_data.details[_data.table];
            return {
              purpose: purpose,
              columns: columns?.map((c: any) => ({
                name: c.name,
                table: c.table,
                datatype: c.datatype,
                can_lineage_expand: c.can_lineage_expand,
                description: c.description,
              })) || [],
            };
          }
          default:
            break;
        }
      };
    },
  });
  if (isLoading) {
    return <RelativeComponentLoader label="Loading..." />;
  }
  if (!data || !itemId) {
    return <Navigate to="/collaboration/export-lineage" />;
  }
  return (
    <div>
      <div className="fs-3 mb-2">{data.name}</div>
      <Card>
        <CardBody>
          <div style={{ height: "80vh" }}>
            <Lineage
              theme={"light"}
              staticLineage={data.lineage_data}
              lineageType={"static"}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
