import styles from "./styles.module.scss";

import { EffortTagColors } from "../../constants";
import { EffortTagProps } from "./types";
import { capitalize } from "lodash";

const EffortTag = ({ effort }: EffortTagProps) => {
  return (
    <div
      className={styles.effortTag}
      style={{
        backgroundColor:
          EffortTagColors[effort as keyof typeof EffortTagColors],
      }}
    >
      {capitalize(effort)}
    </div>
  );
};

export default EffortTag;
