import styles from "./styles.module.scss";
import { useQuery } from "react-query";
import { getCreditUsage } from "../../helpers/backend_helper";
import credit_box from "../../assets/images/credit_box.svg";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import ExecutionHistory from "./ExecutionHistory";
import classNames from "classnames";
import SliderSelect from "./CreditsSelectSlider";
import { GET_CREDIT_USAGE } from "./queryKey";
import {
  InfoTooltip,
  useCancelPlanDowngradeMutation,
  useCheckoutSessionMutation,
} from "./utils";
import { Button } from "reactstrap";
import { ExecutionsAnalytics } from "./Analytics";
import { ComponentLoader } from "../../Components/Loader";
import { useIntercom } from "react-use-intercom";
import { CustomPieChart } from "./GraphUtils";

const CreditUsageTooltip = ({ usedCredits }) => {
  return (
    <div className={styles.tooltip}>
      <img src={credit_box} alt="credit_box" />
      <div className={styles.usage_tooltip}>
        <div className={styles.spent}>{usedCredits}</div>
        <div className={styles.tooltip_text}>Used Credits</div>
      </div>
    </div>
  );
};

const Credits = () => {
  const [searchParams] = useSearchParams();
  const manage_credits = searchParams.get("manage_credits");
  const [postTransactionMessage, setPostTransactionMessage] =
    useState(undefined);

  const [totalAvailableExecutions, setTotalAvailableExecutions] = useState(0);
  const [totalExecutions, setTotalExecutions] = useState(0);
  // Remaining credits
  const [remainingPlanExecutions, setRemainingPlanExecutions] = useState(0);
  const [nextRefreshDate, setNextRefreshDate] = useState(0);
  const [totalPlanExecutions, setTotalPlanExecutions] = useState(0);
  const [availableGrantExecutions, setAvailableGrantExecutions] = useState(0);
  const [grantedExecutions, setGrantedExecutions] = useState(0);
  const [extraExecutions, setExtraExecutions] = useState(0);
  // Used credits
  const [used, setUsed] = useState(0);
  const [currentPlan, setCurrentPlan] = useState("");
  const [currentTenure, setCurrentTenure] = useState("month");
  const [usedBarWidth, setUsedBarWidth] = useState(0);
  const [totalBarWidth, setTotalBarWidth] = useState(0);
  const [planChangeState, setPlanChangeState] = useState("");
  const [showManageSubscriptions, setShowManageSubscriptions] = useState(
    manage_credits === "true" || false
  );
  const [isEnterprise, setIsEnterprise] = useState(true);

  const { show } = useIntercom();
  const checkoutSessionMutation = useCheckoutSessionMutation(
    setPostTransactionMessage,
    currentTenure,
    setPlanChangeState
  );

  const cancelPlanDowngradeMutation = useCancelPlanDowngradeMutation(
    setPostTransactionMessage,
    currentTenure,
    setPlanChangeState
  );

  const { data: creditUsage, isLoading } = useQuery({
    queryKey: [GET_CREDIT_USAGE],
    queryFn: getCreditUsage,
    onSuccess: (res) => {
      setCurrentTenure(res?.tenure);
      setTotalAvailableExecutions(res?.total_available_executions);
      setTotalExecutions(res?.total_executions);
      setUsed(res?.used_executions);
      setRemainingPlanExecutions(res?.plan_available_executions);
      setTotalPlanExecutions(res?.plan_total_executions);
      setExtraExecutions(res?.extra_executions);
      setNextRefreshDate(res?.next_refresh_date);
      setCurrentPlan(res?.credit_type);
      setAvailableGrantExecutions(res?.available_grant);
      setGrantedExecutions(res?.granted_executions);
      setPlanChangeState("");
      setIsEnterprise(res?.current_edition === "Enterprise");
    },
  });

  const data = [
    { name: extraExecutions ? "extra" : "spent", value: used },
    { name: "remaining", value: totalAvailableExecutions },
  ];

  const handleSuccessMessageClose = () => {
    setPostTransactionMessage(undefined);
  };

  useEffect(() => {
    if (searchParams.get("success")) {
      const sessionCheckoutID = searchParams.get("session_id");
      checkoutSessionMutation.mutate({
        sessionCheckoutID: sessionCheckoutID,
        cancelled: false,
      });
      const timeoutId = setTimeout(() => {
        setPostTransactionMessage(undefined);
      }, 30000);
      return () => clearTimeout(timeoutId);
    } else if (searchParams.get("canceled")) {
      const sessionCheckoutID = searchParams.get("session_id");
      checkoutSessionMutation.mutate({
        sessionCheckoutID: sessionCheckoutID,
        cancelled: true,
      });
    }
  }, [searchParams]);

  useEffect(() => {
    const handleResize = () => {
      // calculate used length dynamically
      const totWidth = window.innerWidth * 0.55 - 312;
      const useWidth = totWidth * (used / (totalExecutions + extraExecutions));
      setTotalBarWidth(totWidth);
      setUsedBarWidth(useWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [used, totalAvailableExecutions, extraExecutions]);

  useEffect(() => {
    if (showManageSubscriptions) {
      setTimeout(() => {
        const element = document.getElementById("manage-subscriptions");
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 3000);
    }
  }, [showManageSubscriptions]);

  return (
    <>
      {postTransactionMessage && (
        <InfoTooltip
          handleSuccessMessageClose={handleSuccessMessageClose}
          {...postTransactionMessage}
        />
      )}
      <div className={styles.credit_chart_top}>
        <div className={styles.title}>Usage</div>
        {!isLoading && (
          <div className={styles.current_plan}>
            Current Plan:
            <span className={styles.plan_type}>
              {creditUsage.current_edition}
            </span>
            <span className={styles.plan_name}>
              ({creditUsage.plan_total_executions} Credits
              {!isEnterprise &&
                ` for $
              ${creditUsage.total_cost.toFixed(0)} per month}`}
              )
            </span>
          </div>
        )}
      </div>
      <div className={styles.credit_chart}>
        <CustomPieChart
          data={data}
          infoText={extraExecutions ? "Extra" : "Remaining"}
          infoData={extraExecutions || totalAvailableExecutions}
          customStyle={extraExecutions ? "extra" : ""}
        />
        <div className={styles.usage}>
          <div className={styles.use}>
            <div className={styles.use_header}>
              <div className={styles.title}>Credits </div>
              {!isLoading && (
                <div className={styles.date_info}>
                  {creditUsage.current_start_date} - Today
                </div>
              )}
            </div>
            <div className={styles.bar}>
              <div
                className={classNames(styles.blue_bar, {
                  [styles.extra_credits]: extraExecutions,
                })}
                style={{
                  width: `${totalBarWidth}px`,
                }}
              >
                <div
                  className={styles.orange_bar}
                  style={{ width: `${usedBarWidth}px` }}
                />
                <CreditUsageTooltip
                  className={styles.credit_usage_tooltip}
                  usedCredits={used}
                />
              </div>
              <div className={styles.numbers}>
                <div className={styles.number}>0</div>
                <div
                  className={styles.number}
                  style={{
                    marginRight:
                      extraExecutions && `${totalBarWidth - usedBarWidth}px`,
                  }}
                >
                  {totalExecutions}
                </div>
              </div>
            </div>
            <div className={styles.legends}>
              <div className={styles.legend}>
                <div className={styles.orange_box}></div>
                <div className={styles.orange_text}>Used Credits</div>
              </div>
              <div className={styles.legend}>
                <div className={styles.blue_box}></div>
                <div className={styles.blue_text}>Remaining Credits</div>
              </div>
              <div className={styles.legend}>
                <div className={styles.red_box}></div>
                <div className={styles.red_text}>Extra Credits</div>
              </div>
            </div>
          </div>
          {!isLoading && !isEnterprise && (
            <div className={styles.grants_use}>
              Grant Remaining: {availableGrantExecutions} credits
            </div>
          )}
        </div>
      </div>
      {!isLoading &&
        (isEnterprise ? (
          <div className={styles.available_executions}>
            <div className={styles.text_content}>
              <span className={styles.bold}>Note:</span> Your current grant of{" "}
              <span className={styles.highlight}>
                {totalPlanExecutions} credits
              </span>{" "}
              will expire on{" "}
              <span className={styles.highlight}>{nextRefreshDate}</span>.
            </div>
          </div>
        ) : extraExecutions ? (
          <div
            className={classNames(styles.available_executions, styles.warning)}
          >
            <span className={styles.bold}>Note:</span> Your current subscription
            limit of {totalPlanExecutions} is reached.{" "}
            {creditUsage.downgraded_info ? (
              <span>
                Your Plan will be downgraded to{" "}
                {creditUsage.downgraded_info.plan_display_name} on{" "}
                {creditUsage.downgraded_info.current_subscription_end_date}.
              </span>
            ) : (
              <span>
                Your subscription of {totalPlanExecutions} will renew
                automatically on{" "}
                <span className={styles.highlight}>{nextRefreshDate}</span>. If
                needed, please upgrade your subscription
              </span>
            )}
          </div>
        ) : (
          <div className={styles.available_executions}>
            <div className={styles.text_content}>
              <span className={styles.bold}>Note:</span> Your subscription of{" "}
              {totalPlanExecutions} monthly credits will renew on{" "}
              <span className={styles.highlight}>{nextRefreshDate}</span>.{" "}
              {creditUsage.downgraded_info && (
                <span className={styles.downgrade_warning}>
                  A downgrade is Scheduled at{" "}
                  {creditUsage.downgraded_info.current_subscription_end_date}{" "}
                  and the current plan will be downgraded to{" "}
                  {creditUsage.downgraded_info.plan_display_name}.
                </span>
              )}
            </div>

            {creditUsage.downgraded_info && (
              <Button
                className={styles.button}
                onClick={() => cancelPlanDowngradeMutation.mutate()}
              >
                <div className={styles.text}>Cancel Downgrade</div>
              </Button>
            )}
          </div>
        ))}
      {!isLoading && (
        <div id="manage-subscriptions" className={styles.manage_subscriptions}>
          <div className={styles.title}>Need more credits?</div>
          {isEnterprise ? (
            <Button className={styles.button} onClick={show}>
              <div className={styles.text}>Contact Us</div>
            </Button>
          ) : (
            <Button
              className={styles.button}
              onClick={() => setShowManageSubscriptions(true)}
            >
              <div className={styles.text}>Manage Subscription</div>
            </Button>
          )}
        </div>
      )}

      {cancelPlanDowngradeMutation.isLoading && <ComponentLoader />}
      {!isLoading && showManageSubscriptions && (
        <div className="w-100">
          <SliderSelect
            initialPlanName={creditUsage.credit_type}
            initialTenure={currentTenure}
            currentPlan={currentPlan}
            setPlanName={setCurrentPlan}
            planChangeState={planChangeState}
            setPlanChangeState={setPlanChangeState}
            setShowSuccessMessage={setPostTransactionMessage}
            planToEditionMap={creditUsage.plan_to_edition_map}
            downgradeInfo={creditUsage.downgraded_info}
            cancelPlanDowngradeMutation={cancelPlanDowngradeMutation}
          />
        </div>
      )}
      {/* TODO: to be added later, once the design is finalized */}
      {!isLoading && <ExecutionsAnalytics />}
      {!isLoading && (
        <ExecutionHistory currentPlanStart={creditUsage.current_start_date} />
      )}
    </>
  );
};

export { Credits };
