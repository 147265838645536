import { Card, CardBody } from "reactstrap";
import styles from "./styles.module.scss";
import classnames from "classnames";
import classNames from "classnames";
import { Tooltip } from "@uicore";
import { InfoGray } from "@assets/icons";

const ListSummary = ({ summary }) => {
  summary[0].color = styles.blue;
  summary[1].color = styles.orange;
  summary[2].color = styles.yellow;
  summary[3].color = styles.green;
  summary[4].color = styles.pink;
  return (
    <Card>
      <CardBody className="p-0">
        <div className={styles.container}>
          {summary.map((item) => (
            <div key={item.label} className={styles.item}>
              <div className={classnames(styles.border, item.color)} />
              <div className="pt-2 pb-2">
                <h3 className="text-muted">{item.label}</h3>
                <h2 className="text-black">{item.value}</h2>
              </div>
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

const FlexListSummary = ({ summary }) => {
  const colorList = [
    styles.blue,
    styles.orange,
    styles.yellow,
    styles.green,
    styles.pink,
  ];
  return (
    <Card>
      <CardBody className="p-0">
        <div
          className={styles.container}
          style={{ gridTemplateColumns: `repeat(${summary?.length}, 1fr)` }}
        >
          {summary.map((item, i) => (
            <div key={item.label} className={styles.item}>
              <div className={classnames(styles.border, colorList[i])} />
              <div className="pt-2 pb-2">
                <div
                  className={classnames(
                    styles.label,
                    "d-flex align-items-center gap-xs text-muted"
                  )}
                >
                  {item.label}
                  {item?.info && (
                    <Tooltip content={item.info} placement="top">
                      <div className="d-flex align-items-center">
                        <InfoGray />
                      </div>
                    </Tooltip>
                  )}
                </div>
                <div className={classNames(styles.label_item, "text-black")}>
                  {item.value}
                </div>
              </div>
            </div>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export { ListSummary, FlexListSummary };
