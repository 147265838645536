import styles from "./styles.module.scss";
import classnames from "classnames";
import { Input } from "reactstrap";

const SingleSelect = ({
  options,
  onChange,
  value,
  showDivider = false,
  optionStyles = {},
}) => (
  <ul className={styles.list}>
    {options.map((item) => (
      <li
        key={item.value}
        className={classnames({
          [styles.disabled]: item.disabled ? true : false,
          [styles.active]: value === item.value,
          [styles.list_divider]: showDivider,
        })}
        onClick={(e) => {
          if (!item.disabled) {
            e.stopPropagation();
            onChange(item.value);
          }
        }}
        style={optionStyles}
      >
        {item.label}
      </li>
    ))}
  </ul>
);

const MultiSelect = ({ options, onChange, value, showDivider = false }) => {
  const displayedOptions = options
    .sort((a, b) => {
      if (typeof a.label === "string" && typeof b.label === "string") {
        return a.label.localeCompare(b.label);
      }
      return a.value.localeCompare(b.value);
    })
    .slice(0, 50);

  return (
    <ul className={styles.list}>
      {displayedOptions.map((item) => (
        <li
          key={item.value}
          className={classnames("d-flex align-items-center", {
            [styles.list_divider]: showDivider,
          })}
          onClick={(e) => {
            e.stopPropagation();
            onChange(item.value);
          }}
        >
          <Input
            type="checkbox"
            checked={value(item.value)}
            className="me-2"
            readOnly
          />
          {item.label}
        </li>
      ))}
    </ul>
  );
};

const PhaseSelect = ({
  options,
  onChange,
  value,
  showDivider = false,
  optionStyles = {},
  handleOptionHover,
}) => {
  return (
    <ul className={styles.list}>
      {options.map((item) => (
        <li
          key={item.value}
          id={item.value}
          className={classnames({
            [styles.disabled]: item.disabled,
            [styles.active]: value === item.value,
            [styles.list_divider]: showDivider,
          })}
          onClick={(e) => {
            if (!item.disabled) {
              e.stopPropagation();
              onChange?.(item.value);
            }
          }}
          onMouseEnter={handleOptionHover}
          style={optionStyles}
        >
          {item.label}
        </li>
      ))}
    </ul>
  );
};

export { SingleSelect, MultiSelect, PhaseSelect };
