import { Card, Stack } from "@uicore";
import CostBreakdown from "./CostBreakdown";
import SavingsBreakdown from "./SavingsBreakdown";
import { DateRange } from "../../../Components/DateRange";
import { useSummaryDispatch } from "../SummaryNew";
import useSummaryState from "../useSummaryState";
import {
  setCurrentEndDate,
  setCurrentStartDate,
} from "../Context/summarySlice";
import classes from "../summary.module.scss";

const PastAnalysis = () => {
  const dispatch = useSummaryDispatch();
  const { currentStartDate, currentEndDate } = useSummaryState();

  return (
    <Card className={classes.past_analysis}>
      <Stack className="justify-content-between mb-2">
        <h3 className="text-black">Current State</h3>
        <DateRange
          startDate={currentStartDate}
          endDate={currentEndDate}
          onDateRangeSelect={(startDate, endDate) => {
            dispatch(setCurrentStartDate(startDate));
            dispatch(setCurrentEndDate(endDate));
          }}
          id="past-date-range"
          maxDate={new Date()}
        />
      </Stack>
      <p className={classes.caption}>
        This section shows you costs and savings occurred in your Snowflake
        environment during the selected time period
      </p>
      <CostBreakdown
        currentEndDate={currentEndDate}
        currentStartDate={currentStartDate}
      />
      <SavingsBreakdown
        currentEndDate={currentEndDate}
        currentStartDate={currentStartDate}
      />
      <div id="future" />
    </Card>
  );
};

export default PastAnalysis;
