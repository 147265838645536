export const GET_WAREHOUSE = "GET-WAREHOUSE";
export const GET_WAREHOUSE_FILTERS = "GET-WAREHOUSE-FILTERS";
export const GET_LAST_UPDATED_TIME = "GET-LAST-UPDATED-TIME";
export const GET_WAREHOUSE_BY_ID = "GET-WAREHOUSE-BY-ID";
export const GET_WAREHOUSE_GROUP_BY_ID = "GET-WAREHOUSE-GROUP-BY-ID";
export const GET_ALL_QUERY_GROUPS = "GET-ALL-QUERY-GROUPS";
export const GET_WAREHOUSE_COST = "GET-WAREHOUSE-COST";
export const GET_WAREHOUSE_CLUSTER_UTILIZATION_EFFICIENCY =
  "GET-WAREHOUSE-CLUSTER-UTILIZATION-EFFICIENCY";
export const GET_WAREHOUSE_QUERY_EXECUTION_TIME =
  "GET-WAREHOUSE-QUERY-EXECUTION-TIME";
export const GET_WAREHOUSE_DECISION_DATA = "GET-WAREHOUSE-DECISION-DATA";
export const GET_WAREHOUSE_AUTO_SAVINGS = "GET-WAREHOUSE-AUTO-SAVINGS";
export const GET_WAREHOUSE_AUTO_SAVINGS_CONFIG =
  "GET-WAREHOUSE-AUTO-SAVINGS-CONFIG";
export const GET_WAREHOUSE_AUTO_SAVINGS_AUDIT_EVENTS =
  "GET-WAREHOUSE-AUTO-SAVINGS-AUDIT-EVENTS";
