import classNames from "classnames";
import { ReactNode, useState } from "react";
import classes from "./tile.module.scss";
import { ArrowDownIcon, ArrowUpIcon, InfoIcon } from "@assets/icons";
import IconButton from "../iconButton/IconButton";
import Stack from "../stack/Stack";

interface Props {
  color?: string;
  children?: ReactNode;
  title: string;
  value: string | ReactNode;
  onClick?: () => void;
  helpText?: string;
  className?: string;
}
const ColorTile = ({
  color = "#ffffff",
  children,
  title,
  value,
  onClick,
  helpText,
  className,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const shouldClick = !!children;
  const handleClick = () => {
    onClick?.();
    setIsExpanded((prev) => !prev);
  };

  return (
    <div
      className={classNames(classes.tile, className, {
        "cursor-pointer": shouldClick,
        [classes.visible]: isExpanded,
      })}
      style={{ background: color }}
      onClick={handleClick}
    >
      <div className={classes.title}>
        {title}

        <Stack style={{ gap: 5 }}>
          {helpText && (
            <IconButton className="p-0" title={helpText}>
              <InfoIcon />
            </IconButton>
          )}
          {shouldClick && (
            <div className={classes.expand_btn}>
              <IconButton className="p-0">
                {isExpanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </IconButton>
            </div>
          )}
        </Stack>
      </div>
      <div className={classes.value}>{value}</div>

      {children ? (
        <div className={classes.children} style={{ color }}>
          {children}
        </div>
      ) : null}
    </div>
  );
};

export default ColorTile;
