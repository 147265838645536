import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import rehypeRaw from 'rehype-raw'

const pattern = /```[^`]*```|\n\n/gi;
const replaceNewlines = (value: string) =>
  (value || "").replace(pattern, (match) =>
    match.startsWith("```") ? match : " \n \n"
  );

function CodeMarkdown({ value }: { value: string }) {
  return (
    <ReactMarkdown
      remarkPlugins={[gfm, remarkBreaks]}
      // @ts-ignore
      rehypePlugins={[rehypeRaw]}
      components={{
        code({ node, inline, className, children, ...props }) {
          const match = /language-(\w+)/.exec(className || "");
          return !inline && match ? (
            <SyntaxHighlighter
              {...props}
              style={vscDarkPlus}
              language={match[1]}
              wrapLines={true}
              wrapLongLines={true}
              PreTag="div"
            >
              {String(children).replace(/\n$/, "")}
            </SyntaxHighlighter>
          ) : (
            <code {...props} className={className}>
              {children}
            </code>
          );
        },
      }}
    >
      {replaceNewlines(value)}
    </ReactMarkdown>
  );
}

export { CodeMarkdown };
