import React, { useEffect, useState } from "react";
import { Label, Form } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { getTenant, isFreeStack } from "../../helpers/tenant";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { decodeToken } from "react-jwt";
import {
  FormAlert,
  FormInput,
  FormPassword,
  FormSubmit,
} from "./components/form";
import { BaseContainer, Header } from "./components/base";
import {
  isFormFieldError,
  passwordValidator,
  validateFormPassword,
} from "./helper";
import { useMutation, useQuery } from "react-query";
import { postRegisterInvite } from "../../helpers/backend_helper";
import { loginUser } from "../../services/auth";
import classNames from "classnames";
import styles from "./components/styles.module.scss";
import {DangerCircleCheck, SuccessCircleCheck, DisabledCircleCheck} from "@assets/icons"
import Session from "supertokens-auth-react/recipe/session";
import { getSSOUserInfo, postRegisterSSO } from "@/helpers/apis";

const GET_SSO_USER_INFO = "GET_SSO_USER_INFO";

const SSORegister = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const tenant = getTenant();

  const { data: ssoUserInfo, isLoading } = useQuery({
    queryKey: [GET_SSO_USER_INFO],
    queryFn: getSSOUserInfo,
    onSuccess: (response) => {
      if (response?.auth_token && response?.first_name) {
        loginUser(response);
        navigate("/summary");
      }
    },
    cacheTime: 300000,
  });

  useEffect(() => {
    const checkSession = async () => {
      const sessionExists = await Session.doesSessionExist();
      if (!sessionExists) {
        return (
          <Navigate
            to="/login"
            state={{ from: location.pathname, expired: true }}
          />
        );
      }
    };
    checkSession();
  }, []);

  const { mutate, error } = useMutation(postRegisterSSO, {
    onSuccess: (response) => {
      if (response?.auth_token && response?.first_name) {
        loginUser(response);
        navigate("/summary");
      }
    },
  });
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter Your First Name"),
      lastName: Yup.string().required("Please Enter Your Last Name"),
    }),
    onSubmit: (values) => mutate({ ...values }),
  });
  const [activeElement, setActiveElement] = useState(null);

  if (isLoading) {
    return null;
  }

  const showFormFieldError = isFormFieldError(validation);
  const _error =
    error || showFormFieldError("lastName") || showFormFieldError("firstName");

  const isFormDisabled = () => {
    return false;
  };
  return (
    <BaseContainer>
      <div className={styles.ssoSignupHeader}>
        Please provide the following details to complete your account setup via{" "}
        <span className={styles.ssoText}>SSO</span>
      </div>

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
        }}
        className="mt-3"
      >
        <div className="mb-3">
          <Label htmlFor="firstName" className="form-label">
            First Name <span className={styles.asterisk}>*</span>
          </Label>
          <FormInput
            name="firstName"
            type="text"
            placeholder="Enter First Name"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.firstName}
          />
        </div>

        <div className="mb-3">
          <Label htmlFor="lastName" className="form-label">
            Last Name <span className={styles.asterisk}>*</span>
          </Label>
          <FormInput
            name="lastName"
            type="text"
            placeholder="Enter Last Name"
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            value={validation.values.name}
          />
        </div>

        <FormAlert error={_error} />

        <div className="mt-4">
          <FormSubmit disabled={isFormDisabled()}>Sign Up</FormSubmit>
        </div>
      </Form>
    </BaseContainer>
  );
};

export { SSORegister };
