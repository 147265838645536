import { Card, CardTitle, Dropdown, Stack, Tabs } from "@uicore";
import { useEffect, useState } from "react";
import classes from "../summary.module.scss";
import { generateStream } from "@/helpers/api_helper";
import dayjs from "dayjs";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import gfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import SyntaxHighlighter from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  getDateByEndOfDayWithoutTimeoffset,
  getDateByStartOfDayWithoutTimeoffset,
} from "@/Components/DateRange/utils";
import { ComponentLoader } from "@/Components/Loader";

const DatapilotAnalysis = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [analysisType, setAnalysisType] = useState("analyze_cost");
  const [analysisText, setAnalysisText] = useState("");
  const [selectedDateRange, setSelectedDateRange] = useState("1 month");
  const [currentStartDate, setCurrentStartDate] = useState(
    dayjs().subtract(1, "month").toDate()
  );
  const [currentEndDate, setCurrentEndDate] = useState<Date | null>(
    dayjs().toDate()
  );

  const dateRangeOptions = ["1 month"];
  const tabs = [
    { label: "Analyse Costs", value: "analyze_cost" },
    // { label: "Analyse Savings", value: "analyze_savings" },
    { label: "Predict Future", value: "predict_future" },
  ];

  const pattern = /```[^`]*```|\n\n/gi;
  const replaceNewlines = (value: string) =>
    value.replace(pattern, (match) =>
      match.startsWith("```") ? match : " \n \n"
    );

  const handleItemClick = (value: string) => {
    setSelectedDateRange(value);
    switch (value) {
      case "1 month":
        setCurrentStartDate(dayjs().subtract(1, "month").toDate());
        break;

      case "3 months":
        setCurrentStartDate(dayjs().subtract(3, "month").toDate());
        break;

      case "1 year":
        setCurrentStartDate(dayjs().subtract(1, "year").toDate());
        break;
      default:
        break;
    }
  };

  const generateAnalysis = async () => {
    if (isLoading) return;

    setIsLoading(true);
    setAnalysisText("");
    const url = `/summary/v2/datapilot/analysis`;

    const onProgress = (chunk: string) => {
      if (!analysisText && chunk.length > 0) {
        setIsLoading(false);
      }
      setAnalysisText((prev) => prev + chunk);
    };

    await generateStream(url, onProgress, {
      start_date:
        getDateByStartOfDayWithoutTimeoffset(currentStartDate).toISOString(),
      end_date:
        getDateByEndOfDayWithoutTimeoffset(currentEndDate)?.toISOString(),
      summary_analysis_type: analysisType,
    })
      .then(() => {
        setIsLoading(false);
      })
      .catch((error) => {
        setAnalysisText(error.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    generateAnalysis();
  }, [selectedDateRange, analysisType]);

  const onTabSelect = (value: string) => {
    setAnalysisType(value);
    if (value === "predict_future") {
      setCurrentStartDate(dayjs().startOf("year").toDate());
      setCurrentEndDate(dayjs().endOf("year").toDate());
    }
    else {
      setCurrentEndDate(dayjs().toDate());
    }
  };

  return (
    <Card className="p-3 pb-0">
      <CardTitle>
        <h5 className="text-black">Datapilot Analysis</h5>
        <p className={classes.caption}>
          Let DataPilot AI agents analyze and predict costs and savings for you
        </p>
      </CardTitle>
      <Stack>
        <Card className="p-3" style={{ width: 200 }}>
          <Tabs
            className={classes.dp_tabs}
            items={tabs}
            selectedTab={analysisType}
            onTabSelect={onTabSelect}
            vertical
          />
        </Card>
        <Card className="p-3" style={{ flex: 1 }}>
          <div>
            <Stack className="justify-content-between">
              <h5>{tabs.find((t) => t.value === analysisType)?.label}</h5>
              <Dropdown
                value={selectedDateRange}
                onOptionSelect={handleItemClick}
                prefix="Time period:"
                options={dateRangeOptions.map((value) => ({
                  label: value,
                  value,
                }))}
              />
            </Stack>
            {isLoading && <ComponentLoader label="" />}
            <p className={classes.mkDownAnalysis}>
              <ReactMarkdown
                remarkPlugins={[gfm, remarkBreaks]}
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                      <SyntaxHighlighter
                        {...props}
                        style={vscDarkPlus}
                        language={match[1]}
                        wrapLines={true}
                        wrapLongLines={true}
                        PreTag="div"
                      >
                        {String(children).replace(/\n$/, "")}
                      </SyntaxHighlighter>
                    ) : (
                      <code {...props} className={className}>
                        {children}
                      </code>
                    );
                  },
                }}
              >
                {replaceNewlines(analysisText)}
              </ReactMarkdown>
            </p>
          </div>
        </Card>
      </Stack>
      <div id="current" />
    </Card>
  );
};

export default DatapilotAnalysis;
