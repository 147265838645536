import { useQuery } from "react-query";
import { getContractList } from "../../helpers/backend_helper";
import { ContractList } from "./ContractList";
import { RiFilter3Line } from "react-icons/ri";
import { IconContext } from "react-icons";
import { Dropdown } from "../../Components/Form";
import { Summary } from "../Incidents";
import { Paginate } from "../../Components/Paginate";
import { useState } from "react";
import dayjs from "dayjs";
import { NoContracts } from "./NoContracts";
import { ComponentLoader } from "../../Components/Loader";
import { FilterTagWrapper, Tags } from "../../Components/Tags";

const ContractFilterWidget = ({
  statusFilter,
  changeStatusFilter,
  timeFilter,
  changeTimeFilter,
  sortBy,
  changeSortBy,
}) => {
  if (statusFilter === "" && sortBy === "" && timeFilter === 0) return null;
  return (
    <Tags>
      <FilterTagWrapper
        filters={{
          timeFilter: {
            label: "Duration",
            filterStr: `Last ${timeFilter} ${
              timeFilter === 1 ? " day" : " days"
            }`,
            onclose: () => changeTimeFilter(0),
          },
          statusFilter: {
            label: "Status",
            filterStr: statusFilter,
            onclose: () => changeStatusFilter(""),
          },
          sortBy: {
            label: "Sort By",
            filterStr: sortBy,
            onclose: () => changeSortBy(""),
          },
        }}
      />
    </Tags>
  );
};

const FilterItem = ({ label, options, onChange, value }) => {
  const id = "filter-" + label.replaceAll(/\s+/gi, "-");
  return (
    <Dropdown
      id={id}
      options={options}
      onChange={onChange}
      value={value}
      popoverProps={{ placement: "bottom-end", offset: [-16, -8] }}
    >
      <div className="d-flex align-items-center me-4 cursor-pointer">
        <div className="me-2">{label}</div>
        <IconContext.Provider value={{ size: "1.2em" }}>
          <RiFilter3Line />
        </IconContext.Provider>
      </div>
    </Dropdown>
  );
};

const GET_CONTRACTS = "GET-CONTRACTS";
// const GET_USER_LIST = "GET-USER-LIST";

const Contracts = () => {
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterTime, setFilterTime] = useState(0);
  const [filterEmail, setFilterEmail] = useState("");
  // const tenant = getTenant();
  // const { data: users } = useQuery({
  //   queryKey: GET_USER_LIST,
  //   queryFn: () => getUserList(tenant),
  // });

  const { data, isLoading } = useQuery({
    queryKey: [
      GET_CONTRACTS,
      page,
      sortBy,
      filterStatus,
      filterTime,
      filterEmail,
    ],
    queryFn: () => {
      const params = { page: page + 1, size: 8 };
      if (sortBy) params.sort_column = sortBy;
      if (
        sortBy === "no_of_violations" ||
        sortBy === "last_violation_timestamp"
      )
        params.sort_order = "desc";
      if (filterStatus) params.contract_status = filterStatus;
      if (filterTime !== 0)
        params.last_violation_timestamp = dayjs()
          .subtract(filterTime, "d")
          .utc()
          .format()
          .slice(0, -1);
      if (filterEmail) params.contract_owner = filterEmail;

      return getContractList(params);
    },
  });

  const isFilterApplied =
    filterStatus !== "" || filterTime !== 0 || filterEmail !== "";

  return (
    <>
      {!isFilterApplied && data?.total === 0 ? (
        <NoContracts />
      ) : (
        <div>
          <Summary />

          <div className="mb-4 d-flex justify-content-between align-items-center">
            <h3 className="mb-4 text-black">Contracts</h3>
            <div className="mb-4 spacer d-md-flex">
              <ContractFilterWidget
                statusFilter={filterStatus}
                changeStatusFilter={setFilterStatus}
                timeFilter={filterTime}
                changeTimeFilter={setFilterTime}
                sortBy={sortBy}
                changeSortBy={setSortBy}
              />
            </div>
            <div className="d-flex">
              {isLoading ? (
                <ComponentLoader top={60} left={60} />
              ) : (
                <>
                  <FilterItem
                    label="Sort by"
                    options={[
                      { label: "Name", value: "name" },
                      { label: "Violation No.", value: "no_of_violations" },
                      {
                        label: "Violation Time",
                        value: "last_violation_timestamp",
                      },
                    ]}
                    value={sortBy}
                    onChange={setSortBy}
                  />
                  <FilterItem
                    label="Status"
                    options={[
                      { label: "Active", value: "active" },
                      { label: "Inactive", value: "inactive" },
                    ]}
                    value={filterStatus}
                    onChange={setFilterStatus}
                  />
                  <FilterItem
                    label="Last Violation"
                    options={[
                      { label: "Last 24 hours", value: 1 },
                      { label: "Last 7 days", value: 7 },
                    ]}
                    value={filterTime}
                    onChange={setFilterTime}
                  />
                </>
              )}
            </div>
          </div>
          {isLoading ? (
            <ComponentLoader top={60} left={60} />
          ) : (
            <>
              <ContractList contracts={data.items} />
              <Paginate
                itemCount={data.total}
                page={page}
                pageSize={data.size}
                numPages={data.pages}
                onPageClick={setPage}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export { Contracts };
