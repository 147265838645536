import { default as LogoWhiteIcon } from "@/assets/icons/logo_white.svg?react";
import styles from "./styles.module.scss";
import { getUser } from "../../services/auth";
import { useMemo } from "react";
import classNames from "classnames";

const AltimateProfileImage = () => (
  <div className={styles.ai_profile_image}>
    <LogoWhiteIcon />
  </div>
);

const sizeClassMap = {
  xs: styles.xs,
  sm: styles.sm,
  md: styles.md,
  lg: styles.lg,
  xl: styles.xl,
};

const UserProfileImage = ({ size = "md", bgColor = "#004fbf" }) => {
  const user = getUser();
  return (
    <div
      className={classNames(styles.user_profile_image, sizeClassMap[size])}
      style={{ backgroundColor: bgColor }}
    >
      {(user?.first_name?.[0] || "") + (user?.last_name?.[0] || "")}
    </div>
  );
};

export { AltimateProfileImage, UserProfileImage };
