import { Alert, Button, Input } from "reactstrap";
import { QueryHeader } from "./Query";
import { default as ArrowLeftIcon } from "../../assets/icons/arrow_left.svg?react";
import { useEffect, useState } from "react";
import { Paginate } from "../../Components/Paginate";
import styles from "./styles.module.scss";
import tableStyles from "@/Components/Tables/styles.module.scss";
import { Table, TableHeader } from "@/Components/Tables";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getAllQueryGroupCSVFile,
  getAllQueryGroups,
  getQueryFilters,
  updateQueryGroupName,
} from "../../helpers/backend_helper";
import { ComponentLoader } from "../../Components/Loader";
import {
  GET_ALL_QUERY_GROUPS,
  GET_QUERY_FILTERS,
  GET_QUERY_GROUP_BY_ID,
  QueryGroupTabs,
} from "./constants";
import {
  ColorInsightTags,
  ColorTag,
  FilterTagWrapper,
  Tags,
} from "../../Components/Tags";
import SidebarModal from "../../Components/SidebarModal";
import { default as EditIcon } from "@/assets/icons/edit.svg?react";
import classNames from "classnames";
import { Card, CardBody } from "reactstrap";
import { default as CalendarIcon } from "../../assets/icons/datetime.svg?react";
import { useCopilot } from "../Copilot";
import { useCopilotActions } from "../Copilot/useCopilotActions";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { DateRange, StaticDateRangeKeys } from "../../Components/DateRange";
import dayjs from "dayjs";
import QueryTextWithHover from "./QueryText";
import InsightLabel from "./InsightLabel";
import { formatNumber, Select } from "@/uiCore";
import { PERIOD_OPTIONS } from "../Explorer/constants";
import {
  getDateByEndOfDayWithoutTimeoffset,
  getDateByStartOfDayWithoutTimeoffset,
} from "@/Components/DateRange/utils";
import { useDateFromSearchParams } from "@/helpers/useTimeHooks";
import { useAppState } from "@/modules/app/useAppContext";
import useFiltersBySearchParams from "@/helpers/useFiltersBySearchParams";
import { FilterApplicableFor } from "@/Components/Tags/types";
import SavedFilters from "@/Components/Tags/SavedFilters";
import ColumnPicker from "@/Components/Column/ColumnPicker";
import { DEFAULT_PAGE_SIZE } from "@/Components/Paginate/constants";
import usePaginate from "@/Components/Paginate/usePaginate";

const Groups = () => {
  const location = useLocation();
  const { currency } = useAppState();
  const { setConfig } = useCopilot();
  const { queryHash } = useParams();
  const {
    searchParams,
    deleteSearchParamsByKeyValue,
    updateSearchParamsByKey,
  } = useFiltersBySearchParams();
  const {page, pageSize, setPage, updatePageSize, resetPage} = usePaginate();
  const [sortAttribute, setSortAttribute] = useState("annualized_cost");
  const [sort, setSort] = useState({
    count: "",
    avg_execution_time: "",
    avg_cost: "",
    total_cost: "",
    annualized_cost: "",
  });
  const tagFilter = searchParams.getAll("tags") || [];
  const userFilter = searchParams.getAll("users") || [];
  const warehouseFilter = searchParams.getAll("warehouses") || [];
  const [isDownloading, setIsDownloading] = useState(false);
  const { data: filters, isLoading: isfiltersLoading } = useQuery({
    queryKey: [GET_QUERY_FILTERS, "all"],
    queryFn: () => getQueryFilters({ filter_type: "query_group" }),
  });
  const navigate = useNavigate();

  const getFilteredParams = () => {
    const params = {
      page: page + 1,
      size: pageSize,
      sort_key: sortAttribute,
      sort_order: sort[sortAttribute] || "desc",
      start_date: getDateByStartOfDayWithoutTimeoffset(startDate).toISOString(),
      end_date: getDateByEndOfDayWithoutTimeoffset(endDate).toISOString(),
    };
    return params;
  };

  const onGroupClick = (group) => {
    setConfig({ entity: group.query_hash, entity_type: "query_group" });
    navigate(
      `/query/groups/${group.query_hash}${
        "?start_date=" + startDate?.toISOString()
      }${"&end_date=" + endDate?.toISOString()}`
    );
  };

  const { resetWorkflow } = useCopilotActions();

  useEffect(() => {
    () => {
      resetWorkflow();
    };
  }, []);

  const { startDate, endDate } = useDateFromSearchParams(
    dayjs().subtract(28, "day").toDate(),
    dayjs().toDate()
  );

  const {
    data: groups,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: [
      GET_ALL_QUERY_GROUPS,
      sortAttribute,
      sort[sortAttribute],
      page,
      pageSize,
      ...tagFilter,
      ...userFilter,
      ...warehouseFilter,
      startDate,
      endDate,
    ],
    queryFn: () => {
      const params = getFilteredParams();
      if (tagFilter.length > 0) params.tags = tagFilter;
      if (userFilter.length > 0) params.users = userFilter;
      if (warehouseFilter.length > 0) params.warehouses = warehouseFilter;
      if (queryHash) params.query_hash = queryHash;
      params.start_date =
        getDateByEndOfDayWithoutTimeoffset(startDate).toISOString();
      params.end_date =
        getDateByEndOfDayWithoutTimeoffset(endDate).toISOString();
      return getAllQueryGroups(params);
    },
    onSuccess: (res) => {
      if (queryHash) {
        const group = res["items"].find(
          (item) => item.query_hash === queryHash
        );
        if (group) {
          onGroupClick(group);
        }
      }
    },
  });

  const onSortChange = (key) => (newOrder) => {
    setSort((prevSortOrder) => ({
      ...prevSortOrder,
      [key]: newOrder,
    }));
    setSortAttribute(key);
  };

  const { mutateAsync: downloadCSV } = useMutation(
    () => {
      const params = getFilteredParams();
      params.responseType = "blob";
      return getAllQueryGroupCSVFile(params, setIsDownloading);
    },
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleDownloadClick = async () => {
    setIsDownloading(true);
    try {
      await downloadCSV();
      setIsDownloading(false);
    } catch (error) {
      setIsDownloading(false);
    }
  };

  const handleWarehouseFilterChange = (value) => {
    updateSearchParamsByKey({"warehouses": value});
  };

  const handleUserFilterChange = (value) => {
    updateSearchParamsByKey({"users": value});
  };

  const handleInsightsFilterChange = (value) => {
    updateSearchParamsByKey({"tags": value});
  };

  const header = [
    {
      id: 1,
      label: "Group",
      width: 3,
      uid: "query_group",
      render: (item) => (
        <div
          className={classNames(
            tableStyles.trimmed_query,
            "text-primary cursor-pointer"
          )}
        >
          <QueryTextWithHover
            queryHash={item.query_hash}
            queryText={item.query_hash}
          />
        </div>
      ),
    },
    {
      id: 2,
      label: "Total run",
      uid: "total_run",
      sort: {
        onChange: onSortChange("count"),
        value: sort.count,
      },
      render: (item) => (
        <div className={tableStyles.break_word}>{item.total_run}</div>
      ),
    },
    {
      id: 3,
      label: "Avg. execution time",
      uid: "avg_exec_time",
      width: 2,
      sort: {
        onChange: onSortChange("avg_execution_time"),
        value: sort.avg_execution_time,
      },
      render: (item) => (
        <div className={tableStyles.break_word}>{item.avg_exec_time}</div>
      ),
    },
    {
      id: 4,
      label: "Avg. cost",
      uid: "avg_cost",
      // sort: {
      //   onChange: onSortChange("avg_cost"),
      //   value: sort.avg_cost,
      // },
      render: (item) => (
        <div className={tableStyles.break_word}>
          {formatNumber(item.avg_cost, { currency })}
        </div>
      ),
    },
    {
      id: 5,
      label: "Total cost",
      uid: "total_cost",
      sort: {
        onChange: onSortChange("total_cost"),
        value: sort.total_cost,
      },
      render: (item) => (
        <div className={tableStyles.break_word}>
          {formatNumber(item.total_cost, { currency })}
        </div>
      ),
    },
    {
      id: 6,
      label: "Annualized cost",
      uid: "annualized_cost",
      width: 1.5,
      sort: {
        onChange: onSortChange("annualized_cost"),
        value: sort.annualized_cost,
      },
      render: (item) => (
        <div className={tableStyles.break_word}>
          {formatNumber(item.annualized_cost, { currency })}
        </div>
      ),
    },
    {
      id: 7,
      label: "Warehouses",
      uid: "warehouses",
      width: 2,
      filter: {
        filterType: "dropdown",
        value: warehouseFilter,
        searchParamKey: "warehouses",
        options: filters?.warehouses
          ? filters.warehouses.map((warehouse) => ({
              label: warehouse,
              value: warehouse,
            }))
          : [],
        onChange: handleWarehouseFilterChange,
      },
      render: (item) => (
        <div className={classNames(tableStyles.break_word, "lines-2")}>
          {item.warehouses.join(", ")}
        </div>
      ),
    },
    {
      id: 8,
      label: "Users",
      uid: "users",
      width: 1.5,
      filter: {
        filterType: "dropdown",
        searchParamKey: "users",
        value: userFilter,
        options: filters?.users
          ? filters.users.map((user) => ({
              label: user,
              value: user,
            }))
          : [],
        onChange: handleUserFilterChange,
      },
      render: (item) => (
        <div className={classNames(tableStyles.break_word, "lines-2")}>
          {item.users.join(", ")}
        </div>
      ),
    },
    {
      id: 9,
      label: "Insights",
      uid: "tags",
      width: 3,
      filter: {
        filterType: "dropdown",
        searchParamKey: "tags",
        value: tagFilter,
        searchBy: "value",
        options: filters?.tags
          ? filters.tags.map((t) => ({
              label: <InsightLabel tag={t} />,
              value: t.name,
            }))
          : [],
        onChange: handleInsightsFilterChange,
      },
      render: (item) => <ColorInsightTags tags={item.tags} />,
    },
  ];

  if (isLoading || isfiltersLoading) return <ComponentLoader />;
  if (!groups) {
    return <Alert color="danger">No data for query group found</Alert>;
  }

  return (
    <div className="d-flex flex-column">
      <QueryHeader />
      <div className="divider" />
      <div className="p-3 d-flex flex-column gap-md bg-white border-radius-bottom">
        <div className="d-flex gap-md align-items-center">
          <div className="spacer" />
          <DateRange
            startDate={startDate}
            endDate={endDate}
            availableStaticRanges={["Last day", "Last 7 days", "Last 28 days"]}
            disableCalendarSelection
            onDateRangeSelect={(s, e) => {
              updateSearchParamsByKey({
                start_date: s?.toISOString(),
                end_date: e?.toISOString(),
              });
            }}
          />
          <SavedFilters applicableFor={FilterApplicableFor.QUERY_GROUP} />
          <ColumnPicker />
          {/* {isDownloading ? (
            <div
              className={classNames(
                "text-primary m-2",
                styles.download_csv_text
              )}
            >
              CSV is being generated and will be ready soon!
            </div>
          ) : (
            <Button
              outline
              onClick={(e) => {
                e.stopPropagation();
                handleDownloadClick();
              }}
              disabled={!(groups && groups.total && !isDownloading)}
            >
              Download CSV file
            </Button>
          )} */}
        </div>
        <div className={styles.filter_tags}>
            <FilterTagWrapper
              applicableFor={FilterApplicableFor.QUERY_GROUP}
              filters={{
                tagFilter: {
                  label: "Tags",
                  filterStr: tagFilter,
                  searchKey: "tags",
                },
                userFilter: {
                  label: "Users",
                  filterStr: userFilter,
                  searchKey: "users",
                },
                warehouseFilter: {
                  label: "Warehouse",
                  filterStr: warehouseFilter,
                  searchKey: "warehouses",
                },
              }}
            />
          </div>
          <Table 
            applicableFor={FilterApplicableFor.QUERY_GROUP}
            data={groups}
            header={header}
            resetPage={resetPage}
            onRowClick={onGroupClick}
                  />
        
        <Paginate
          itemCount={groups.total}
          page={page}
          pageSize={pageSize}
          numPages={groups.pages}
          onPageClick={setPage}
          showPageSize
          onPageSizeChange={updatePageSize}
        />
      </div>
    </div>
  );
};

export { Groups };
