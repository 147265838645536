import { GET_OPPORTUNITY_RESOURCE_DATA } from "@/services/constants";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { getOpportunitiesResource } from "./apis";
import {
  getDateByEndOfDayWithoutTimeoffset,
  getDateByStartOfDayWithoutTimeoffset,
} from "@/Components/DateRange/utils";
import { ResourceType } from "@/pages/Explorer/types";

export const useDateFromSearchParams = (
  initialStartDate?: Date,
  initialEndDate?: Date,
  prefix = ""
) => {
  const getKey = (key: string) => (prefix ? `${prefix}_${key}` : key);
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState(
    initialStartDate || dayjs().subtract(28, "days").toDate()
  );
  const [endDate, setEndDate] = useState(initialEndDate || dayjs().toDate());

  useEffect(() => {
    const searchParamStartDate = searchParams.get(getKey("start_date"));
    const searchParamEndDate = searchParams.get(getKey("end_date"));

    if (searchParamStartDate) {
      setStartDate(new Date(searchParamStartDate));
    }

    if (searchParamEndDate) {
      setEndDate(new Date(searchParamEndDate));
    }
  }, [searchParams, setStartDate, setEndDate]);

  const onUpdate = (startDate: Date, endDate: Date) => {
    setSearchParams(
      (prev) => {
        const params = new URLSearchParams(prev);
        params.set(getKey("start_date"), startDate?.toISOString());
        params.set(getKey("end_date"), endDate?.toISOString());
        return params.toString();
      },
      { replace: true }
    );
  };

  return { startDate, endDate, onUpdate };
};

export const useResourceOpportunities = (
  resourceUri: string,
  resourceType: ResourceType
) => {
  const { startDate, endDate } = useDateFromSearchParams();
  return useQuery({
    queryKey: [
      GET_OPPORTUNITY_RESOURCE_DATA,
      resourceUri,
      resourceType,
      startDate,
      endDate,
    ],
    queryFn: () =>
      getOpportunitiesResource({
        resource_uri: resourceUri,
        resource_type: resourceType,
        start_date:
          getDateByStartOfDayWithoutTimeoffset(startDate).toISOString(),
        end_date: getDateByEndOfDayWithoutTimeoffset(endDate)!.toISOString(),
      }),
    enabled: Boolean(resourceUri),
  });
};
