import { useState } from "react";
import { useMutation } from "react-query";
import { Button, Input, Modal } from "reactstrap";
import { postFeedbackTerm } from "../../helpers/backend_helper";
import styles from "./styles.module.scss";
import classnames from "classnames";

const FeedbackModal = ({ termId, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((b) => !b);
  const [feedback, setFeedback] = useState("good");
  const [message, setMessage] = useState("");
  const { mutate: _postFeedbackTerm } = useMutation(() =>
    postFeedbackTerm({
      term_generation_id: termId,
      feedback,
      message,
    })
  );
  return (
    <>
      <div
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          toggle();
        }}
      >
        {children}
      </div>
      <Modal isOpen={isOpen} centered toggle={toggle}>
        <div className={styles.feedback}>
          <h5 className="mb-2">Feedback</h5>
          <div
            className={classnames("cursor-pointer d-flex", styles.card)}
            onClick={(e) => {
              e.stopPropagation();
              setFeedback("good");
            }}
          >
            <input
              className="me-2"
              type="radio"
              checked={feedback === "good"}
              readOnly
            />
            Looks Good
            <div className="spacer" />
            😍
          </div>
          <div
            className={classnames("cursor-pointer d-flex", styles.card)}
            onClick={(e) => {
              e.stopPropagation();
              setFeedback("bad");
            }}
          >
            <input
              className="me-2"
              type="radio"
              checked={feedback === "bad"}
              readOnly
            />
            Needs Improvement
            <div className="spacer" />
            🙂
          </div>
          <div className={styles.card}>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Type your feedback"
            />
          </div>
          <Button
            size="sm"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              _postFeedbackTerm();
              setIsOpen(false);
            }}
          >
            Submit
          </Button>
        </div>
      </Modal>
    </>
  );
};

export { FeedbackModal };
