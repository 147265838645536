import { Button, CloseButton } from "reactstrap";
import classes from "./styles.module.scss";
import { useEffect, useState } from "react";
import { useAppDispatch } from "@/modules/app/useAppContext";
import { setHasAcceptedCookies } from "@/modules/app/appSlice";
import { hasAcceptedCookies, StorageKeyName } from "./utils";
import { Stack } from "@/uiCore";

const CookieConsentBanner = () => {
  const [isClosed, setIsClosed] = useState(false);
  const hasActedOnCookieConsent = localStorage.getItem(StorageKeyName) !== null;
  const acceptedCookies = hasAcceptedCookies();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setHasAcceptedCookies(acceptedCookies));
  }, [acceptedCookies, dispatch]);

  const acceptCookies = () => {
    localStorage.setItem(StorageKeyName, "true");
    handleClose();
  };

  const handleClose = () => {
    setIsClosed(true);
  };

  if (isClosed || hasActedOnCookieConsent) {
    return null;
  }

  return (
    <div className={classes.cookie_consent}>
      <CloseButton 
        onClick={handleClose}
        aria-label="Close cookie consent banner"
      />
      <Stack className="justify-content-between align-items-center">
        <div role="dialog" aria-labelledby="cookie-consent-title">
          <h2 id="cookie-consent-title" className="sr-only">Cookie Consent</h2>
          <p className="pt-1 mb-1">
            We use essential first-party cookies to ensure our website functions
            properly and provide you with our services.
          </p>
          <div className="flex items-center gap-1">
            <a
              href="https://docs.myaltimate.com/arch/faq/#5-what-is-your-cookie-policy"
              target="_blank"
              rel="noopener noreferrer nofollow"
              aria-label="Learn more about our cookie usage (opens in new tab)"
              className="text-blue-600 hover:text-blue-800 underline flex items-center gap-1"
            >
              Learn more about our cookie usage
            </a>
          </div>
        </div>
        <Button 
          size="sm" 
          type="button" 
          onClick={acceptCookies}
          aria-label="Accept all cookies"
        >
          Accept Cookies
        </Button>
      </Stack>
    </div>
  );
};

export default CookieConsentBanner;
