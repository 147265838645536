import {
  Button,
  Card,
  CardBody,
  CloseButton,
  Input,
  Modal,
  ModalBody,
} from "reactstrap";
import { getUser, loginUser } from "../../services/auth";
import styles from "./styles.module.scss";
import { CopyButton } from "../../Components/CopyButton";
import { RobotBGCard } from "../../Components/Card";
import { useNavigate } from "react-router-dom";
import { getTenant, isFreeStack } from "../../helpers/tenant";
import alertIcon from "../../assets/icons/freemium_alert.svg";
import freemium_api_image from "../../assets/images/freemium_api.svg";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getDatastores } from "../../helpers/backend_helper";
import { useState } from "react";
import { LockIcon, RegenerateIcon, SuccessMultiIcon } from "@/assets/icons";
import { regenerateToken } from "@/helpers/apis";

export const GET_DATASTORES = "GET-DATASTORES";

const SettingsCard = ({ cardTitle, cardText, copyText }) => {
  return (
    <Card>
      <CardBody className="p-4">
        <h3>{cardTitle}</h3>
        <div className="text-muted mt-2 mb-2 fs-5">{cardText}</div>
        <div className={styles.token}>{copyText}</div>
        <CopyButton value={copyText} />
      </CardBody>
    </Card>
  );
};

const RegenerateSuccess = ({ toggleShow }) => {
  return (
    <div className={styles.regenerateSuccess}>
      <div className={styles.closeButton}>
        <CloseButton onClick={toggleShow} />
      </div>
      <SuccessMultiIcon />
      <div className={styles.text}>
        <div>Your API key has been regenerated successfully</div>
        <div className={styles.subtext}>
          Please update your Altimate CLI and Extension with the new API key.
        </div>
      </div>
    </div>
  );
};

const RegenerateModal = ({ handleClose, handleRegenerate }) => {
  const [textInput, setTextInput] = useState("");

  const handleTextInput = (e) => {
    setTextInput(e.target.value);
  };

  return (
    <div className={styles.regenerateModal}>
      <div className={styles.closeButton}>
        <CloseButton onClick={handleClose} />
      </div>
      <div className={styles.content}>
        <div className={styles.lock}>
          <LockIcon />
        </div>

        <div className={styles.text}>
          Are you sure you want to regenerate your Altimate API key? Please note
          that your current API key will be deactivated and{" "}
          <span className={styles.bold}>cannot be recovered </span>
          for security reasons.
        </div>

        <div className={styles.text}>Type “regenerate” to confirm</div>
        <div className={styles.confirm}>
          <Input
            placeholder="regenerate"
            className={styles.confirmation}
            value={textInput}
            onChange={handleTextInput}
          />
          <Button
            color="primary"
            disabled={textInput !== "regenerate"}
            onClick={handleRegenerate}
          >
            Regenerate
          </Button>
        </div>
      </div>
    </div>
  );
};

const SettingsCardWithRegenerate = ({
  cardTitle,
  cardText,
  copyText,
  showRegenerateSuccess,
  setAuthToken,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [regenerateButtonFocus, setRegenerateButtonFocus] = useState(false);

  const { mutate } = useMutation(regenerateToken, {
    onSuccess: (data) => {
      showRegenerateSuccess();
      toggleModal();
      setAuthToken(data.auth_token);
      loginUser(data);
    },
  });

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleButtonFocus = () => {
    setRegenerateButtonFocus((prev) => !prev);
  };

  const handleRegenerate = () => {
    mutate();
  };

  return (
    <>
      <Card>
        <CardBody className="p-4">
          <h3>{cardTitle}</h3>
          <div className="text-muted mt-2 mb-2 fs-5">{cardText}</div>
          <div className={styles.token}>{copyText}</div>
          <div className="d-flex justify-content-between align-items-center">
            <CopyButton value={copyText} />
            <Button
              outline
              onClick={toggleModal}
              onMouseEnter={handleButtonFocus}
              onMouseLeave={handleButtonFocus}
            >
              <div className="d-flex align-items-center gap-xs">
                <RegenerateIcon
                  style={{
                    color: `${regenerateButtonFocus ? "white" : "#247EFE"}`,
                  }}
                />
                Regenerate
              </div>
            </Button>
          </div>
        </CardBody>
      </Card>
      <Modal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen((prev) => !prev)}
        centered
      >
        <ModalBody>
          <RegenerateModal
            handleClose={toggleModal}
            handleRegenerate={handleRegenerate}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

const ApiKey = () => {
  const [showRegenerateSuccess, setShowRegenerateSuccess] = useState(false);

  const isFreeUser = isFreeStack();
  const navigate = useNavigate();
  const [showAddDatastore, setShowAddDatastore] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: [GET_DATASTORES],
    queryFn: getDatastores,
    onSuccess: (res) => {
      if (res["data_stores"].length === 0) {
        setShowAddDatastore(true);
      }
    },
  });
  const { auth_token } = getUser();
  const [authToken, setAuthToken] = useState(auth_token);

  const tenant = getTenant();

  const [showVSCodeImage, setShowVSCodeImage] = useState(false);

  const toggleVSCodeImage = () => {
    setShowVSCodeImage((prev) => !prev);
  };

  const toggleRegenerateSuccess = () => {
    setShowRegenerateSuccess((prev) => !prev);
  };

  return (
    <>
      {showRegenerateSuccess && (
        <RegenerateSuccess toggleShow={toggleRegenerateSuccess} />
      )}
      <div className={styles.api_container}>
        <SettingsCardWithRegenerate
          cardTitle="Your API Key"
          cardText="Please use the below API key with Altimate CLI and Extensions"
          copyText={authToken}
          setAuthToken={setAuthToken}
          showRegenerateSuccess={() => {
            setShowRegenerateSuccess(true);
          }}
        />
        <SettingsCard
          cardTitle="Your Instance name"
          cardText="Please use the below Instance name with Altimate CLI and API"
          copyText={tenant}
        />
        {!isFreeUser && showAddDatastore && (
          <RobotBGCard>
            <div className="h-100 d-flex flex-column align-items-start">
              <h3>Add datastore for better results.</h3>
              <div className="text-muted mt-2 fs-5">
                Only metadata will be used to give better context to AI agent.
              </div>
              <div className="spacer" />
              <Button
                className="mt-2"
                onClick={() => navigate("/settings/datastores/add")}
              >
                Add Datastores
              </Button>
            </div>
          </RobotBGCard>
        )}
      </div>
      {isFreeUser && (
        <div className={styles.freemium_api}>
          <div className="d-flex gap-sm">
            <img
              src={alertIcon}
              alt="alert-icon"
              onClick={toggleVSCodeImage}
              className={styles.info}
            />
            <div className={styles.text}>
              Where to add API key in VS Code Extension
            </div>
          </div>
          {showVSCodeImage && (
            <img
              src={freemium_api_image}
              alt="freemium_api_image"
              className={styles.vscode}
            />
          )}
        </div>
      )}
    </>
  );
};

export { ApiKey };
