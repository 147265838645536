import { Button, Input, Label, Form } from "reactstrap";
import { FormInput, FormSubmit, FormCancel, FormPassword } from "./form";
import { useState } from "react";
import { getIconByDatastoreType } from "./DatastoreImage";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { testDatastoreConnectionDuringCreation } from "../../helpers/backend_helper";
import { useMutation } from "react-query";
import DatastoreTestSuccess from "../../assets/icons/datastore_form_success.svg";
import DatastoreTestFail from "../../assets/icons/datastore_form_fail.svg";
import { useNavigate } from "react-router-dom";
import { addDatastore } from "../../helpers/backend_helper";

const API_RESULT_TIMEOUT = 6000;

const SnowflakeConnection = ({ typeData, handleCancel }) => {
  const navigate = useNavigate();
  const [account, setAccount] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [database, setDatabase] = useState("");
  const [warehouse, setWarehouse] = useState("");
  const [showTestSuccess, setShowTestSuccess] = useState(false);
  const [showTestFail, setShowTestFail] = useState(false);
  const [failedMessage, setFailedMessage] = useState("");

  const { mutate: createDatastore } = useMutation(
    (data) => addDatastore(data),
    {
      onSuccess: (res) => {
        // refresh
        navigate(0);
      },
      onError: (res) => {
        setShowTestFail(true);
        setFailedMessage(res || "Unable to add Datastore!");
        setTimeout(() => {
          setShowTestFail(false);
          setFailedMessage("");
        }, API_RESULT_TIMEOUT);
      },
    }
  );

  const handleSubmit = (connectionData) => {
    createDatastore({ ...typeData, ...connectionData });
  };

  const { mutate: testDatastoreConnection } = useMutation(
    (data) => testDatastoreConnectionDuringCreation(data),
    {
      onSuccess: (response) => {
        if (response?.ok) {
          setShowTestSuccess(true);
          setTimeout(() => {
            setShowTestSuccess(false);
          }, API_RESULT_TIMEOUT);
        } else {
          setShowTestFail(true);
          setFailedMessage(response?.message);
          setTimeout(() => {
            setShowTestFail(false);
            setFailedMessage("");
          }, API_RESULT_TIMEOUT);
        }
      },
      onError: (response) => {
        setShowTestFail(true);
        setFailedMessage(response?.message);
        setTimeout(() => {
          setShowTestFail(false);
          setFailedMessage("");
        }, API_RESULT_TIMEOUT);
      },
    }
  );

  const handleAccountChange = (e) => {
    setAccount(e.target.value);
  };
  const handleUserChange = (e) => {
    setUser(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleRoleChange = (e) => {
    setRole(e.target.value);
  };
  const handleDatabaseChange = (e) => {
    setDatabase(e.target.value);
  };
  const handleWarehouseChange = (e) => {
    setWarehouse(e.target.value);
  };

  const type = {
    name: "Snowflake",
    icon: getIconByDatastoreType("snowflake", styles.datastore_type_icon),
  };

  const handleTestConnection = () => {
    const data = {
      account: account,
      user: user,
      password: password,
      role: role,
      database: database,
      warehouse: warehouse,
    };
    testDatastoreConnection({ ...typeData, ...data });
  };

  return (
    <div className={styles.form_card}>
      <div className="d-flex justify-content-between align-items-center">
        <div className={styles.title}>Connection</div>
        <Button
          onClick={(e) => {}}
          className={classNames(
            styles.datastore_button,
            styles.no_pointer_events
          )}
        >
          {type.icon}
          <span className={styles.name}>{type.name}</span>
        </Button>
      </div>

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit({
            account: account,
            user: user,
            password: password,
            role: role,
            database: database,
            warehouse: warehouse,
          });
        }}
        className="mt-3"
      >
        <div className="mb-3">
          <Label
            className="form-label d-flex align-items-center gap-sm"
            htmlFor="account"
          >
            Account{" "}
            <Link
              className={styles.accountInfoLink}
              to="https://docs.snowflake.com/en/user-guide/organizations-gs#label-viewing-organization-name"
            >
              (Snowflake documentation)
            </Link>
          </Label>
          <FormInput
            id="account"
            name="account"
            placeholder="eufpinx-tyb12513"
            onChange={handleAccountChange}
            value={account}
          />
        </div>

        <div className="mb-3">
          <Label className="form-label" htmlFor="user">
            User
          </Label>
          <FormInput
            id="user"
            name="user"
            placeholder="altimate_user"
            onChange={handleUserChange}
            value={user}
          />
        </div>

        <div className="mb-3">
          <Label className="form-label" htmlFor="password">
            Password
          </Label>
          <FormPassword
            id="connection_password"
            name="connection_password"
            onChange={handlePasswordChange}
            value={password}
          />
        </div>

        <div className="mb-3">
          <Label className="form-label" htmlFor="role">
            Role
          </Label>
          <FormInput
            id="role"
            name="role"
            placeholder="ACCOUNTADMIN"
            onChange={handleRoleChange}
            value={role}
          />
        </div>

        <div className="mb-3">
          <Label className="form-label" htmlFor="database">
            Database
          </Label>
          <FormInput
            id="database"
            name="database"
            onChange={handleDatabaseChange}
            value={database}
          />
        </div>

        <div className="mb-4">
          <Label
            className="form-label d-flex justify-content-between align-items-center"
            htmlFor="warehouse"
          >
            Warehouse
            <Link className={styles.accountInfoLink} to="">
              (XS Size Warehouse Recommended)
            </Link>
          </Label>
          <FormInput
            id="warehouse"
            name="warehouse"
            placeholder="COMPUTE_WH"
            onChange={handleWarehouseChange}
            value={warehouse}
          />
        </div>

        <div className={styles.connection_submit_buttons}>
          <Button
            className={styles.testConection}
            disabled={
              !(account && user && password && role && database && warehouse)
            }
            onClick={handleTestConnection}
          >
            Test Connection
          </Button>
          <div className="d-flex gap-sm">
            <FormCancel onClick={handleCancel} />
            <FormSubmit
              disabled={
                !(account && user && password && role && database && warehouse)
              }
            >
              Finish
            </FormSubmit>
          </div>
        </div>

        {showTestSuccess && (
          <div className="mt-4 d-flex gap-sm">
            <img src={DatastoreTestSuccess} />
            <span className={styles.test_connection_success}>
              Connection Tested Successfully
            </span>
          </div>
        )}
        {showTestFail && (
          <div className="mt-4 d-flex gap-sm">
            <img src={DatastoreTestFail} />
            <span className={styles.test_connection_fail}>
              {failedMessage || "Test Failed"}
            </span>
          </div>
        )}
      </Form>
    </div>
  );
};

const PostgresConnection = ({ typeData, handleCancel }) => {
  const navigate = useNavigate();
  const [host, setHost] = useState("");
  const [port, setPort] = useState("");
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [database, setDatabase] = useState("");
  const [showTestSuccess, setShowTestSuccess] = useState(false);
  const [showTestFail, setShowTestFail] = useState(false);
  const [failedMessage, setFailedMessage] = useState("");

  const { mutate: testDatastoreConnection } = useMutation(
    (data) => testDatastoreConnectionDuringCreation(data),
    {
      onSuccess: (response) => {
        if (response?.ok) {
          setShowTestSuccess(true);
          setTimeout(() => {
            setShowTestSuccess(false);
          }, API_RESULT_TIMEOUT);
        } else {
          setShowTestFail(true);
          setFailedMessage(response?.message);
          setTimeout(() => {
            setShowTestFail(false);
            setFailedMessage("");
          }, API_RESULT_TIMEOUT);
        }
      },
      onError: (response) => {
        setShowTestFail(true);
        setFailedMessage(response?.message);
        setTimeout(() => {
          setShowTestFail(false);
          setFailedMessage("");
        }, API_RESULT_TIMEOUT);
      },
    }
  );

  const { mutate: createDatastore } = useMutation(
    (data) => addDatastore(data),
    {
      onSuccess: (res) => {
        // refresh
        navigate(0);
      },
      onError: (res) => {
        setShowTestFail(true);
        setFailedMessage(res || "Unable to add Datastore!");
        setTimeout(() => {
          setShowTestFail(false);
          setFailedMessage("");
        }, API_RESULT_TIMEOUT);
      },
    }
  );

  const handleSubmit = (connectionData) => {
    createDatastore({ ...typeData, ...connectionData });
  };

  const handleHostChange = (e) => {
    setHost(e.target.value);
  };
  const handlePortChange = (e) => {
    setPort(e.target.value);
  };
  const handleUserChange = (e) => {
    setUser(e.target.value);
  };
  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const handleDatabaseChange = (e) => {
    setDatabase(e.target.value);
  };

  const type = {
    name: "Postgres",
    icon: getIconByDatastoreType("postgres", styles.datastore_type_icon),
  };

  const handleTestConnection = () => {
    const data = {
      host: host,
      port: port,
      user: user,
      password: password,
      database: database,
    };
    testDatastoreConnection({ ...typeData, ...data });
  };

  return (
    <div className={styles.form_card}>
      <div className="d-flex justify-content-between align-items-center">
        <div className={styles.title}>Connection</div>
        <Button
          onClick={(e) => {}}
          className={classNames(
            styles.datastore_button,
            styles.no_pointer_events
          )}
        >
          {type.icon}
          <span className={styles.name}>{type.name}</span>
        </Button>
      </div>

      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit({
            host: host,
            port: port,
            user: user,
            password: password,
            database: database,
          });
        }}
        className="mt-3"
      >
        <div className="mb-3">
          <Label className="form-label" htmlFor="host">
            Host
          </Label>
          <FormInput
            id="host"
            name="host"
            placeholder="LocalHost"
            onChange={handleHostChange}
            value={host}
          />
        </div>

        <div className="mb-3">
          <Label className="form-label" htmlFor="port">
            Port
          </Label>
          <FormInput
            id="port"
            name="port"
            placeholder="5432"
            onChange={handlePortChange}
            value={port}
          />
        </div>

        <div className="mb-3">
          <Label className="form-label" htmlFor="user">
            User
          </Label>
          <FormInput
            id="user"
            name="user"
            placeholder="altimate_user"
            onChange={handleUserChange}
            value={user}
          />
        </div>

        <div className="mb-3">
          <Label className="form-label" htmlFor="password">
            Password
          </Label>
          <FormPassword
            id="connection_password"
            name="connection_password"
            onChange={handlePasswordChange}
            value={password}
          />
        </div>

        <div className="mb-3">
          <Label className="form-label" htmlFor="database">
            Database
          </Label>
          <FormInput
            id="database"
            name="database"
            onChange={handleDatabaseChange}
            value={database}
          />
        </div>

        <div className={styles.connection_submit_buttons}>
          <Button
            className={styles.testConection}
            disabled={!(host && port && user && password && database)}
            onClick={handleTestConnection}
          >
            Test Connection
          </Button>
          <div className="d-flex gap-sm">
            <FormCancel onClick={handleCancel} />
            <FormSubmit
              disabled={!(host && port && user && password && database)}
            >
              Finish
            </FormSubmit>
          </div>
        </div>

        {showTestSuccess && (
          <div className="mt-4 d-flex gap-sm">
            <img src={DatastoreTestSuccess} />
            <span className={styles.test_connection_success}>
              Connection Tested Successfully
            </span>
          </div>
        )}
        {showTestFail && (
          <div className="mt-4 d-flex gap-sm">
            <img src={DatastoreTestFail} />
            <span className={styles.test_connection_fail}>
              {failedMessage || "Test Failed"}
            </span>
          </div>
        )}
      </Form>
    </div>
  );
};

export { SnowflakeConnection, PostgresConnection };
