import { InfoIcon } from "@/assets/icons";
import { Stack } from "@uicore";
import { TooltipProps } from "recharts";

export interface CustomTooltipItemProps {
  name: string;
  value: string;
  color: string;
  formatter?: (
    value: Record<string, string | number>,
    item: CustomTooltipItemProps
  ) => string | undefined;
  showSticker?: boolean;
  showChangePercentage?: boolean;
}

const changePercent = (
  item: CustomTooltipItemProps,
  data: Record<string, string | number>
) => {
  if (item.showChangePercentage) {
    const change = data[`percentage_${item.value}`];
    if (change) {
      const number = parseFloat(`${change}`);
      return (
        <span className={number >= 0 ? "negative" : "positive"}>
          ({number > 0 ? "+" : ""}
          {number.toFixed(2)}%)
        </span>
      );
    }
  }
  return null;
};

export const CustomTooltip = ({
  active,
  payload,
  nameValue,
  timeRange,
}: TooltipProps<any, any> & {
  nameValue: CustomTooltipItemProps[];
  timeRange?: "day" | "week" | "month";
}) => {
  const getLabel = (item: (typeof nameValue)[0]) => {
    if (item.name) {
      return `${item.name}: `;
    }
    return "";
  };

  const getValue = (
    item: (typeof nameValue)[0],
    data: Record<string, string | number>
  ) => {
    return item.formatter ? item.formatter(data, item) : data[item.value];
  };

  if (active && payload && payload.length) {
    const data = payload[0].payload;
    let hasChangePercentage = false;
    return (
      <div className="custom-tooltip bg-white px-2 py-1 border-4 rounded">
        {nameValue.map((item, idx) => {
          const valueText = getValue(item, data);
          if (!valueText) {
            return null;
          }
          hasChangePercentage = hasChangePercentage || Boolean(item.showChangePercentage)

          return (
            <Stack
              key={idx}
              className="my-2 align-items-center"
              style={{ color: item.color }}
            >
              {item.showSticker ? (
                <span
                  className="px-2 rounded"
                  style={{ height: 6, background: item.color }}
                />
              ) : null}
              <span className="text-capitalize">{`${getLabel(item)}`}</span>
              {`${valueText}`}
              {changePercent(item, data)}
              
            </Stack>
          );
        })}
        {hasChangePercentage && timeRange ? (
          <div style={{maxWidth: 215, background: "#F5FAFF", padding: 8}}>
            <InfoIcon /> Change is calculated from the value for the
            previous {timeRange}
          </div>
        ) : null}
      </div>
    );
  }
  return null;
};
