import useComponentsHelper from "@/modules/app/useComponentsHelper";
import { Card, CardBody, CardTitle, Stack } from "@/uiCore";
import {
  TeamMates,
  TeamMateConfig,
  TaskLabels,
  TeamMateActionType,
} from "@components";
import { useNavigate } from "react-router-dom";
import LearningsToggle from "./LearningsToggle";
import { postSendDatamateRequest } from "@/helpers/apis";

const TeammatesComponent = () => {
  const navigate = useNavigate();
  const { isComponentsApiInitialized } = useComponentsHelper();
  
  if (!isComponentsApiInitialized) {
    return null;
  }

  const onTeamMateSelect = async (
    teammate: TeamMateConfig,
    action: TeamMateActionType
  ) => {
    switch (action) {
      case TeamMateActionType.REQUEST_ACCESS:
        const response = await postSendDatamateRequest(teammate.key);
        return response?.ok;
      case TeamMateActionType.SEE_IN_ACTION:
        if (teammate.key === TaskLabels.ChartBot) {
          navigate(`/summary?ref=${teammate.key}`);
        }
        break;
      case TeamMateActionType.VIEW_DETAILS:
        navigate(`/teammates/${teammate.key}`);
        break;

      default:
        break;
    }
  };

  return (
    <Stack direction="column">
      <h1>Teammates</h1>
      <p>We provide AI teammates that can help you with your work</p>

      <Card>
        <CardTitle className="p-3 pb-0 d-flex gap-2">
          Teammate learning <LearningsToggle />
        </CardTitle>
        <CardBody>
          <p>
            You can enable or disable teammate learnings here. If enabled, users
            can coach AI teammates through specific instructions in “Coach AI”
            marked product areas. Coached AI teammates can provide more custom
            and personalized experience to users.
          </p>
        </CardBody>
      </Card>
      <TeamMates client="SAAS" onSelect={onTeamMateSelect} />
    </Stack>
  );
};

export default TeammatesComponent;
