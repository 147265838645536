import { useEffect, useRef, useState } from "react";
import { Popover, PopoverProps } from "reactstrap";

interface Props extends Omit<PopoverProps, "children"> {
  children: ({ close }: { close: () => void }) => JSX.Element;
  containerClass: string;
}

const BetterPopover = ({ children, containerClass, ...props }: Props) => {
  const innerRef = useRef<HTMLDivElement | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!innerRef.current) return;
      if (innerRef.current.contains(event.target as Node)) return;
      setIsOpen(false);
    };
    const root = document.getElementById("root");

    root?.addEventListener("click", handleClickOutside, true);
    return () => {
      root?.removeEventListener("click", handleClickOutside, true);
    };
  }, [innerRef]);
  return (
    <Popover {...props as PopoverProps} isOpen={isOpen} toggle={() => setIsOpen((b) => !b)}>
      <div ref={innerRef} className={containerClass}>
        {children({ close: () => setIsOpen(false) })}
      </div>
    </Popover>
  );
};

export { BetterPopover };
