import { Summary } from "../Incidents";
import styles from "./styles.module.scss";
import { Dropdown } from "../../Components/Form";
import { useState, useMemo } from "react";
import classnames from "classnames";
import { RiFilter3Line } from "react-icons/ri";
import { IconContext } from "react-icons";
import dayjs from "dayjs";
import { Paginate } from "../../Components/Paginate";
import { Card, CardBody } from "reactstrap";
import { useQuery } from "react-query";
import { ComponentLoader } from "../../Components/Loader";
import { getValidations, getContracts } from "../../helpers/backend_helper";
import { useSingleSelect, useMultiSelect } from "../../services/filters";
import cronstrue from "cronstrue";
import { useNavigate } from "react-router-dom";
import { TableTags, TableTagsWithMore } from "../../Components/Tags";
import SidebarModal from "../../Components/SidebarModal";
import { FilterDropdown } from "../../Components/FilterDropdown";
import Icon from "@/Components/Icon/Icon";

const ValidationSidebar = ({ validationItemData }) => {
  const {
    item: validationItem,
    parsedSchedule,
    validation_status,
    timestamp,
  } = validationItemData;

  const navigate = useNavigate();
  const handleIncidentClick = (validationId) => {
    navigate(`/incidents/event/${validationId}`);
  };
  return (
    <div className={styles.validation_sidebar}>
      <div className={styles.title}>{validationItem.contract.name}</div>
      <div className={styles.item}>
        <div className={styles.item_title}>Schedule :</div>
        {parsedSchedule ? (
          <div className={styles.item_value}>{parsedSchedule}</div>
        ) : (
          <div className={styles.item_value}>
            {validationItem.contract.schedule}
          </div>
        )}
      </div>
      <div className={styles.item}>
        <div className={styles.item_title}>Timestamp :</div>
        <div className={styles.item_value}>
          {timestamp.format("DD MMMM YYYY hh:mm A")}
        </div>
      </div>
      <div className={styles.item}>
        <div className={styles.item_title}>Incident :</div>
        {validationItem.validation_status === "invalid" ? (
          <div
            className={classnames(
              "fw-semibold text-primary",
              styles.underlined_link
            )}
            onClick={() => handleIncidentClick(validationItem.id)}
          >
            {validation_status}
          </div>
        ) : (
          <div className={styles.item_value}>{validation_status}</div>
        )}
      </div>
      <div className={styles.item}>
        <div className={styles.item_title}>Scope :</div>
      </div>
      <TableTags tables={validationItem.tables} />
    </div>
  );
};

const ValidationList = ({
  validations,
  validationStatusFilterLabels,
  validationStatusFilter,
  timeFilter,
  contractFilter,
  onValidationClick,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [validationItemData, setValidationItemData] = useState(null);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();
  const handleContractClick = (id) => {
    navigate(`/contracts/${id}`);
  };
  const handleIncidentClick = (eventId) => {
    navigate(`/incidents/event/${eventId}`);
  };
  const handleMoreClick = (itemData) => {
    setValidationItemData(itemData);
    toggleModal();
  };
  return (
    <>
      <Card>
        <CardBody>
          <div className={styles.table}>
            <div className={styles.table_header}>
              <div className="d-flex gap-md align-items-center">
                <span>Contract</span>
                <FilterDropdown
                  id="contract-dropdown"
                  value={contractFilter?.value}
                  options={contractFilter?.options}
                  onChange={contractFilter?.onChange}
                  label="Contract"
                >
                  <Icon>
                    <RiFilter3Line />
                  </Icon>
                </FilterDropdown>
              </div>
              <div className="d-flex gap-md align-items-center">
                <span>Schedule</span>
              </div>
              <div className="d-flex gap-md align-items-center">
                <span>Timestamp</span>
                <Dropdown
                  id="timestamp-dropdown"
                  value={timeFilter?.value}
                  options={timeFilter?.options}
                  onChange={timeFilter?.onChange}
                >
                  <Icon>
                    <RiFilter3Line />
                  </Icon>
                </Dropdown>
              </div>
              <div className="d-flex gap-md align-items-center">
                <span>Scope</span>
              </div>
              <div className="d-flex gap-md align-items-center">
                <span>Incidents</span>
                <Dropdown
                  isMultiSelect
                  id="incident-dropdown"
                  value={validationStatusFilter?.value}
                  options={validationStatusFilter?.options}
                  onChange={validationStatusFilter?.onChange}
                  showDivider
                >
                  <Icon>
                    <RiFilter3Line />
                  </Icon>
                </Dropdown>
              </div>
            </div>
            <div className={styles.table_body}>
              {validations.map((item) => {
                let validation_status = "valid";
                if (item.validation_status == "invalid") {
                  validation_status = item.number_of_violations + " Incidents";
                } else {
                  validation_status =
                    validationStatusFilterLabels[item.validation_status];
                }
                const timestamp = dayjs(item.validated_on);
                let parsedSchedule = "";
                try {
                  parsedSchedule = `${cronstrue.toString(
                    item.contract.schedule
                  )}`;
                } catch (err) {
                  /* empty */
                }
                return (
                  <div
                    key={item.id}
                    className={styles.table_row}
                    onClick={(e) => {
                      e.preventDefault();
                      onValidationClick(item.id);
                    }}
                  >
                    <div
                      className={classnames("text-primary", styles.break_word)}
                    >
                      <span
                        className={styles.pointer}
                        onClick={() => handleContractClick(item.contract.id)}
                      >
                        {item.contract.name}
                      </span>
                    </div>
                    <div className={styles.break_word}>{parsedSchedule}</div>
                    <div>
                      {timestamp.format("DD MMMM YYYY")}
                      <br />
                      {timestamp.format("hh:mm A")}
                    </div>
                    <div
                      className={classnames("text-primary", styles.break_word)}
                    >
                      {item.tables.length > 3 ? (
                        <TableTagsWithMore
                          tables={item.tables.slice(0, 3)}
                          moreClick={() =>
                            handleMoreClick({
                              item,
                              parsedSchedule,
                              validation_status,
                              timestamp,
                            })
                          }
                        />
                      ) : (
                        <TableTags tables={item.tables} />
                      )}
                    </div>
                    {item.validation_status === "invalid" ? (
                      <div
                        className={classnames(
                          "fw-semibold text-primary",
                          styles.underlined_link
                        )}
                        onClick={() => handleIncidentClick(item.id)}
                      >
                        {validation_status}
                      </div>
                    ) : (
                      <div
                        className={classnames("fw-semibold", {
                          [styles.red]:
                            item.validation_status === "error" ||
                            item.validation_status === "cancelled",
                          [styles.orange]: item.validation_status === "pending",
                        })}
                      >
                        {validation_status}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </CardBody>
      </Card>
      <SidebarModal isOpen={isOpen} toggleModal={toggleModal} width={446}>
        <ValidationSidebar validationItemData={validationItemData} />
      </SidebarModal>
    </>
  );
};
const GET_VALIDATIONS = "GET-VALIDATIONS";
const GET_CONTRACTS = "GET-CONTRACTS";
const makeOptions = (values, labels) =>
  values.map((v) => ({ value: v, label: labels[v] }));

const Validations = () => {
  const [page, setPage] = useState(0);
  const [contractFilter, setContractFilter] = useState([]);
  const resetPage = () => setPage(0);

  const timeFilterLabels = { "24h": "24 hours", "1w": "1 week" };
  const timeFilterOptions = ["24h", "1w"];
  const validationStatusFilterLabels = {
    valid: "No Incidents",
    invalid: "Incidents Detected",
    pending: "Validation Pending",
    error: "Validation Error",
    cancelled: "Validation Cancelled",
  };
  const validationStatusFilterOptions = [
    "valid",
    "invalid",
    "pending",
    "error",
    "cancelled",
  ];

  const [timeFilter, setTimeFilter] = useSingleSelect(resetPage, true);
  const { data: contractData, isLoading: isContractDataLoading } = useQuery({
    queryKey: GET_CONTRACTS,
    queryFn: getContracts,
  });

  const {
    getValue: getValidationStatusValue,
    toggleValue: toggleValidationStatusValue,
    getTrueValues: getValidationStatusFilter,
  } = useMultiSelect(resetPage);

  const validationStatusFilter = getValidationStatusFilter();
  const { data, isLoading } = useQuery({
    queryKey: [
      GET_VALIDATIONS,
      page,
      timeFilter,
      ...validationStatusFilter,
      ...contractFilter,
    ],
    queryFn: () => {
      const params = { page: page + 1, size: 8 };
      if (contractFilter.length > 0) params.contracts = contractFilter;
      if (timeFilter) params.timestamp = timeFilter;
      if (validationStatusFilter.length > 0)
        params.validation_status = validationStatusFilter;
      return getValidations(params);
    },
  });

  return (
    <>
      {isLoading || isContractDataLoading ? (
        <ComponentLoader top={60} left={60} />
      ) : (
        <div>
          <Summary />
          <h3 className="mb-4 text-black">Validations</h3>
          <ValidationList
            validations={data.items}
            validationStatusFilterLabels={validationStatusFilterLabels}
            contractFilter={{
              options: contractData?.contracts.map((c) => ({
                label: c.name,
                value: c.id,
              })),
              value: contractFilter,
              onChange: setContractFilter,
            }}
            timeFilter={{
              options: makeOptions(timeFilterOptions, timeFilterLabels),
              value: timeFilter,
              onChange: setTimeFilter,
              getLabel: () => timeFilterLabels[timeFilter] || "",
            }}
            validationStatusFilter={{
              options: makeOptions(
                validationStatusFilterOptions,
                validationStatusFilterLabels
              ),
              value: getValidationStatusValue,
              onChange: toggleValidationStatusValue,
              getLabel: () =>
                makeOptions(
                  validationStatusFilter,
                  validationStatusFilterLabels
                ),
            }}
            onValidationClick={() => {}}
          />
          <Paginate
            itemCount={data.total}
            page={page}
            pageSize={data.size}
            numPages={data.pages}
            onPageClick={setPage}
          />
        </div>
      )}
    </>
  );
};
export { Validations };
