import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SummaryStateProps } from "./types";
import dayjs from "dayjs";

export const initialState = {
  loading: false,
  currentEndDate: dayjs().toDate(),
  currentStartDate: dayjs().subtract(7, "day").toDate(),
  showChatbot: undefined,
} as SummaryStateProps;

const summarySlice = createSlice({
  name: "summaryState",
  initialState,
  reducers: {
    setShowChatbot: (
      state,
      action: PayloadAction<SummaryStateProps["showChatbot"]>
    ) => {
      state.showChatbot = action.payload;
    },
    setLoading: (
      state,
      action: PayloadAction<SummaryStateProps["loading"]>
    ) => {
      state.loading = action.payload;
    },
    setCurrentStartDate: (
      state,
      action: PayloadAction<SummaryStateProps["currentStartDate"]>
    ) => {
      state.currentStartDate = action.payload;
    },
    setCurrentEndDate: (
      state,
      action: PayloadAction<SummaryStateProps["currentEndDate"]>
    ) => {
      state.currentEndDate = action.payload;
    },
  },
});

export const {
  setLoading,
  setCurrentEndDate,
  setCurrentStartDate,
  setShowChatbot,
} = summarySlice.actions;

export default summarySlice;
