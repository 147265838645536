import styles from "./styles.module.scss";
import { getAlertRules } from "../../helpers/backend_helper";
import { RuleList } from "./RuleList";
import { Paginate } from "../../Components/Paginate";
import { useState } from "react";
import { NoRule } from "./NoRule";
import { ComponentLoader } from "../../Components/Loader";
import { useQuery } from "react-query";
import { AlertBox } from "./AlertBox.jsx";

const GET_ALERT_RULES = "GET-ALERT_RULES";

const AlertRules = () => {
  const [page, setPage] = useState(0);

  const { data, isLoading } = useQuery({
    queryKey: [GET_ALERT_RULES, page],
    queryFn: () => {
      const params = { page: page + 1, size: 8 };
      return getAlertRules(params);
    },
  });

  return (
    <>
      {data?.total === 0 ? (
        <NoRule />
      ) : (
        <div>
          <div className="mb-4 d-flex justify-content-between align-items-center">
            <h3 className="text-black">Checks</h3>
            <AlertBox />
          </div>
          {isLoading ? (
            <ComponentLoader top={60} left={60} />
          ) : (
            <>
              <RuleList rules={data.items} />
              <Paginate
                itemCount={data.total}
                page={page}
                pageSize={data.size}
                numPages={data.pages}
                onPageClick={setPage}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};

export { AlertRules };
