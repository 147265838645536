import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import {
  DateRangePicker,
  DateRangePickerProps,
  Range,
  RangeKeyDict,
  StaticRange,
} from "react-date-range";
import styles from "./styles.module.scss";
import { ArrowDownIcon, ClockBlackIcon } from "@assets/icons";
import { isSameDay } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { BetterPopover } from "../Popover";
import dayjs from "dayjs";
import { Button } from "reactstrap";
import { Stack } from "@uicore";
import { uniqueId } from "lodash";

interface Props extends DateRangePickerProps {
  startDate?: Date;
  endDate?: Date;
  id?: string;
  wrapperClass?: string;
  popoverProps?: any;
  onDateRangeSelect: (startDate: Date, endDate: Date) => void;
  availableStaticRanges?: string[];
  disableCalendarSelection?: boolean;
  minDate?: Date;
  showTimezone?: boolean;
}

export const StaticDateRangeKeys = {
  LAST_DAY: "Last day",
  LAST_7_DAYS: "Last 7 days",
  LAST_28_DAYS: "Last 28 days",
  LAST_3_MONTHS: "Last 3 months",
  LAST_6_MONTHS: "Last 6 months",
  LAST_12_MONTHS: "Last 12 months",
};

const DateRange = ({
  wrapperClass,
  popoverProps = {},
  onDateRangeSelect,
  startDate,
  endDate,
  availableStaticRanges = Object.values(StaticDateRangeKeys),
  disableCalendarSelection,
  minDate,
  showTimezone = false,
  ...rest
}: Props) => {
  const id = useMemo(() => uniqueId("date-picker-"), []);
  const ref = useRef<DateRangePicker | null>(null);
  const [state, setState] = useState<Range[]>([
    {
      startDate: startDate || new Date(),
      endDate: endDate || new Date(),
      key: "selection",
    },
  ]);

  useEffect(() => {
    setState([
      {
        startDate: startDate || new Date(),
        endDate: endDate || new Date(),
        key: "selection",
      },
    ]);
  }, [startDate, endDate]);

  const isSelectedRange = (selectedRange: Range, definedRange: Range) => {
    return (
      isSameDay(selectedRange.startDate!, definedRange.startDate!) &&
      isSameDay(selectedRange.endDate!, definedRange.endDate!)
    );
  };

  const StaticRanges: Record<string, StaticRange> = {
    [StaticDateRangeKeys.LAST_DAY]: {
      label: "Last day",
      hasCustomRendering: true,
      range: () => ({
        startDate: dayjs().subtract(1, "day").toDate(),
        endDate: dayjs().subtract(1, "day").toDate(),
      }),
      isSelected(range) {
        return isSelectedRange(range, this.range());
      },
    },
    [StaticDateRangeKeys.LAST_7_DAYS]: {
      label: "Last 7 days",
      hasCustomRendering: true,
      range: () => ({
        startDate: dayjs().subtract(7, "day").toDate(),
        endDate: new Date(),
      }),
      isSelected(range) {
        return isSelectedRange(range, this.range());
      },
    },
    [StaticDateRangeKeys.LAST_28_DAYS]: {
      label: "Last 28 days",
      hasCustomRendering: true,
      range: () => ({
        startDate: dayjs().subtract(28, "day").toDate(),
        endDate: new Date(),
      }),
      isSelected(range) {
        return isSelectedRange(range, this.range());
      },
    },
    [StaticDateRangeKeys.LAST_3_MONTHS]: {
      label: "Last 3 months",
      hasCustomRendering: true,
      range: () => ({
        startDate: dayjs().subtract(90, "day").toDate(),
        endDate: new Date(),
      }),
      isSelected(range) {
        return isSelectedRange(range, this.range());
      },
    },
    [StaticDateRangeKeys.LAST_6_MONTHS]: {
      label: "Last 6 months",
      hasCustomRendering: true,
      range: () => ({
        startDate: dayjs().subtract(180, "day").toDate(),
        endDate: new Date(),
      }),
      isSelected(range) {
        return isSelectedRange(range, this.range());
      },
    },
    [StaticDateRangeKeys.LAST_12_MONTHS]: {
      label: "Last 12 months",
      hasCustomRendering: true,
      range: () => ({
        startDate: dayjs().subtract(365, "day").toDate(),
        endDate: new Date(),
      }),
      isSelected(range) {
        return isSelectedRange(range, this.range());
      },
    },
  };
  const staticRanges: StaticRange[] = availableStaticRanges.map(
    (r) => StaticRanges[r]
  );

  const calculateDateRangeFromTwoDates = (
    beginDate: dayjs.Dayjs,
    endDate: dayjs.Dayjs
  ) => {
    const selectedRange = staticRanges.find((r) =>
      r.isSelected({
        startDate: beginDate.toDate(),
        endDate: endDate.toDate(),
        key: "selection",
      })
    );
    return selectedRange ? selectedRange.label : false;
  };

  const handleDatePick = (item: RangeKeyDict, close: () => void) => {
    setState([item.selection]);

    if (
      item.selection.startDate &&
      item.selection.endDate &&
      calculateDateRangeFromTwoDates(
        dayjs(item.selection.startDate),
        dayjs(item.selection.endDate)
      )
    ) {
      close();
      onDateRangeSelect(item.selection.startDate, item.selection.endDate);
    }
  };

  const handleApply = (close: () => void) => {
    if (state.length) {
      const [selection] = state;
      if (selection.startDate && selection.endDate) {
        close();
        onDateRangeSelect(selection.startDate, selection.endDate);
      }
    }
  };

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const props = disableCalendarSelection
    ? { ...rest, maxDate: new Date(), minDate: new Date() }
    : rest;

  const staticRangeLabel = calculateDateRangeFromTwoDates(
    dayjs(startDate),
    dayjs(endDate)
  );

  const renderDateRange = () => {
    if (!startDate || !endDate) return null;
    return (
      <Stack>
        <div>{startDate.toLocaleDateString("en-US", options)}</div>
        {!isSameDay(startDate, endDate) && (
          <>
            <div>-</div>
            <div>{endDate?.toLocaleDateString("en-US", options)}</div>
          </>
        )}
      </Stack>
    );
  };

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return (
    <>
      <Stack
        id={id}
        className={classNames(styles.datePickerWrapper, wrapperClass)}
      >
        {showTimezone && (
          <div className={styles.timezone}>Timezone: {timezone}</div>
        )}
        {staticRangeLabel ? renderDateRange() : null}
        <div className={styles.datePicker}>
          <ClockBlackIcon />
          <div className={styles.time}>
            {staticRangeLabel || renderDateRange()}
          </div>
          <div className={styles.arrow_down}>
            <ArrowDownIcon />
          </div>
        </div>
      </Stack>
      <BetterPopover target={id} hideArrow placement="bottom" {...popoverProps}>
        {({ close }) => (
          <Stack direction="column">
            <DateRangePicker
              ref={ref}
              // disable add/substract days input
              inputRanges={[]}
              renderStaticRangeLabel={(item) => item.label}
              onChange={(item: RangeKeyDict) => handleDatePick(item, close)}
              // @ts-ignore prop exists
              showSelectionPreview
              moveRangeOnFirstSelection={false}
              months={2}
              ranges={state}
              staticRanges={staticRanges}
              direction="horizontal"
              showDateDisplay={false}
              minDate={minDate}
              {...props}
            />
            <div className={styles.apply_div}>
              <Button size="sm" onClick={() => handleApply(close)}>
                Apply
              </Button>
            </div>
          </Stack>
        )}
      </BetterPopover>
    </>
  );
};

export { DateRange };
