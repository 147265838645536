import { getTenantInfo } from "@/helpers/apis";
import { setCurrency, setTeammatesEnabled } from "./appSlice";
import { USD } from "./constants";
import { Dispatch, useState } from "react";
import { UnknownAction } from "@reduxjs/toolkit";

const useAppActions = (dispatch: Dispatch<UnknownAction>) => {
  const [loadingTenantInfo, setLoadingTenantInfo] = useState(false);
  const fetchTenantInfo = () => {
    return new Promise<void>((resolve, reject) => {
    setLoadingTenantInfo(true);
    getTenantInfo()
      .then((response) => {
        // @ts-ignore
        window.posthog.capture('tenant_info', {
          response
      })
        dispatch(setCurrency(response?.currency || USD));
        dispatch(setTeammatesEnabled(response?.teammatesEnabled || false));
        resolve();
      }).catch((err) => {
        reject(err);
      })
      .finally(() => {
        setLoadingTenantInfo(false);
      });
    });
  };

  return {
    fetchTenantInfo,
    loadingTenantInfo,
  };
};

export default useAppActions;
