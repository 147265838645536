import { RelativeComponentLoader } from "@/Components/Loader";
import { getSummaryPageOpportunities } from "@/helpers/apis";
import { Alert, Badge, Card, Col, NumberFormatter, Row } from "@uicore";
import { useQuery } from "react-query";
import classes from "../summary.module.scss";
import { Link } from "react-router-dom";
import { useAppState } from "@/modules/app/useAppContext";
import dayjs from "dayjs";

const OpportunitiesList = () => {
  const { currency } = useAppState();
  const { isFetching, data, error } = useQuery({
    queryKey: [`summary-opportunities`],
    queryFn: () => getSummaryPageOpportunities({ period: "month" }),
    onError(err) {
      window.posthog.capture("OpportunitiesList Failed", { error: err });
    },
  });

  if (isFetching) {
    return <RelativeComponentLoader label="Loading opportunities..." />;
  }

  if (error) {
    return <Alert color="danger">Failed to load opportunities data</Alert>;
  }

  return (
    <Row className={classes.opportunitiesList}>
      {data?.map((opportunity) => {
        const searchParams = new URLSearchParams(opportunity?.params);
        searchParams.set(
          "start_date",
          dayjs().subtract(28, "day").toDate().toISOString()
        );
        searchParams.set("end_date", dayjs().toDate().toISOString());
        return (
          <Col className="col-sm-3">
            <Card tag={Link} to={`/discover?${searchParams.toString()}`}>
              <Badge tooltip={""} pill tag="div">
                <NumberFormatter
                  options={{ currency, maximumFractionDigits: 1 }}
                  value={opportunity.value}
                />
              </Badge>
              <div style={{ flex: 1 }} className="text-truncate-two-lines">
                {opportunity.label}
              </div>
            </Card>
          </Col>
        );
      })}
    </Row>
  );
};

export default OpportunitiesList;
