import { STATUS_OPTIONS } from "../../constants";
import styles from "./styles.module.scss";
import { Select } from "@/uiCore";
import { useOpportunities } from "../../useOpportunity";
import { getUser } from "@/services/auth";

interface StatusAssignerDropdownProps {
  opportunity_id: string;
  assignedStatus: string;
  setAssignedStatus: (status: string) => void;
  disableUpdateOnChange?: boolean;
  startDate: string;
  endDate: string;
}

interface HandleStatusChangeParams {
  e: string | unknown;
  opportunity_id: string;
}

const StatusAssignerDropdown = ({
  opportunity_id,
  assignedStatus,
  setAssignedStatus = () => {},
  disableUpdateOnChange = false,
  startDate,
  endDate,
}: StatusAssignerDropdownProps) => {
  const { mutation } = useOpportunities("");
  const { mutate } = mutation;
  const user = getUser();

  const handleStatusChange = ({
    e,
    opportunity_id,
  }: HandleStatusChangeParams) => {
    mutate({
      changes: [
        {
          opportunity_id,
          status: e as string,
          owner: undefined,
        },
      ],
      user_id: user.id,
      start_date: startDate,
      end_date: endDate,
    });
  };

  return (
    <Select
      id={`state-assigner`}
      onChange={(e) => {
        setAssignedStatus(e as string);
        if (!disableUpdateOnChange) {
          handleStatusChange({ e, opportunity_id });
        }
      }}
      value={assignedStatus}
      options={STATUS_OPTIONS}
      showDivider
      placeholder="Status"
      className={styles.bulk_select_dropdown}
    />
  );
};

export default StatusAssignerDropdown;
