import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
} from "reactstrap";
import { getCompanyList, addNewCompany } from "../../helpers/backend_helper";
import styles from "./styles.module.scss";

const CompanyList = () => {
  const navigate = useNavigate();
  const { data } = useQuery({
    queryKey: ["get-company-list"],
    queryFn: getCompanyList,
  });
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const [company, setCompany] = useState("");
  const { mutate } = useMutation(
    () =>
      addNewCompany({
        name: company,
        country: "USA",
      }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries({ queryKey: ["get-company-list"] });
        setShowModal(false);
      },
    }
  );
  return (
    <>
      <Card className="p-4">
        <div className="d-flex justify-content-between">
          <h1>Companies</h1>
          <Button
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              setShowModal((b) => !b);
            }}
          >
            Add Company
          </Button>
        </div>
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Country</th>
            </tr>
          </thead>
          <tbody>
            {data?.items.map((item) => (
              <tr
                key={item.uuid}
                className={styles.company_row}
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/settings/companies/${item.name}`);
                }}
              >
                <td>{item.name}</td>
                <td>{item.country}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card>
      <Modal isOpen={showModal} toggle={() => setShowModal((b) => !b)} centered>
        <ModalHeader toggle={() => setShowModal((b) => !b)}>
          Add Company
        </ModalHeader>
        <ModalBody>
          <Label htmlFor="company">Company</Label>
          <Input
            id="company"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
          <Button className="mt-4" onClick={() => mutate()}>
            Submit
          </Button>
        </ModalBody>
      </Modal>
    </>
  );
};

export { CompanyList };
