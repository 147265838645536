import { useState } from "react";
import { DEFAULT_PAGE_SIZE } from "./constants";

const usePaginate = () => {
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const resetPage = () => setPage(0);

  const updatePageSize = (v: number) => {
    setPageSize(v);
    resetPage();
  }

  return {
    page,
    setPage,
    pageSize,
    updatePageSize,
    resetPage
  };
};

export default usePaginate;
