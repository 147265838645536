import { Card, Dropdown, Stack } from "@uicore";
import classes from "../summary.module.scss";
import CostBreakdown from "./CostBreakdown";
import SavingsBreakdown from "./SavingsBreakdown";
import dayjs from "dayjs";
import { useState } from "react";
import { ClockBlackIcon } from "@/assets/icons";

const FutureTimeRanges = [
  // "Next 1 month",
  // "Next 3 months",
  "This year",
  // "End-of-contract",
];

const FutureAnalysis = () => {
  const currentStartDate = dayjs().startOf("year").toDate();
  const [currentEndDate, setCurrentEndDate] = useState<Date | null>(
    dayjs().endOf("year").toDate()
  );
  const [selectedTimerange, setAggregateBy] = useState(FutureTimeRanges[0]);

  const handleItemClick = (value: string) => {
    setAggregateBy(value);
    if (value === "Next 1 month") {
      setCurrentEndDate(dayjs().add(1, "month").toDate());
    }

    switch (value) {
      case "Next 1 month":
        setCurrentEndDate(dayjs().add(1, "month").toDate());
        break;

      case "Next 3 months":
        setCurrentEndDate(dayjs().add(3, "month").toDate());
        break;
      case "This year":
        setCurrentEndDate(dayjs().endOf("year").toDate());
        break;
      case "End-of-contract":
        setCurrentEndDate(null);
        break;
      default:
        break;
    }
  };

  return (
    <Card className={classes.past_analysis} id="future">
      <Stack className="justify-content-between mb-2">
        <h3 className="text-black">Future State</h3>
        <Dropdown
          value={selectedTimerange}
          onOptionSelect={handleItemClick}
          prefix={<ClockBlackIcon />}
          options={FutureTimeRanges.map((value) => ({
            label: value,
            value,
          }))}
        />
      </Stack>
      <p className={classes.caption}>
        This section shows your projected costs and savings for the specified
        time period
      </p>

      <CostBreakdown
        isFuture
        currentEndDate={currentEndDate}
        currentStartDate={currentStartDate}
      />
      <SavingsBreakdown
        isFuture
        currentEndDate={currentEndDate}
        currentStartDate={currentStartDate}
      />
    </Card>
  );
};

export default FutureAnalysis;
