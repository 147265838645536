import styles from "./styles.module.scss";
import dayjs from "dayjs";
import slack from "../../assets/icons/slack.svg";
import { default as ArrowDown } from "../../assets/icons/arrow_down.svg?react";
import contract_icon from "../../assets/icons/contract_icon.svg";
import { default as ViolationIcon } from "../../assets/icons/violation_icon.svg?react";
import { default as CodeIcon } from "../../assets/icons/code.svg?react";
import { default as Message } from "../../assets/icons/message.svg?react";
import { TableTags } from "../../Components/Tags";
import classnames from "classnames";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { BetterPopover } from "../../Components/Popover";

const Contract = ({ contract }) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.card}
      onClick={(e) => {
        e.stopPropagation();
        navigate(`/contracts/${contract.contract_id}`);
      }}
    >
      <div className="mb-2 d-flex justify-content-between align-items-center">
        <div
          className={classnames(styles.icon_bg, {
            [styles.active]: contract.status === "active",
            [styles.inactive]: contract.status === "inactive",
          })}
        >
          <img src={contract_icon} alt="" />
        </div>
        <div className="d-flex align-items-end">
          <div className="me-2 level-red icon-sm">
            <CodeIcon />
          </div>
          <div className="me-3">{contract.no_of_violations} Violations</div>
          {contract.last_violation_timestamp && (
            <>
              <div className="me-2 level-orange icon-sm">
                <ViolationIcon />
              </div>
              <div>
                {dayjs(contract.last_violation_timestamp).format(
                  "DD MMM, YYYY"
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="fs-5 fw-semibold">{contract.name}</div>
      <div className="mt-2 mb-2">
        <TableTags tables={contract.tables} />
      </div>
      <div className="text-muted">{contract.description}</div>
      <div className={styles.spacer} />
      <div className={classnames("mt-4 mb-2", styles.divider)} />
      <div className="d-flex justify-content-between mt-2 align-items-center">
        <div
          className={classnames("d-flex", {
            invisible: !contract.slack_channel,
          })}
        >
          <img src={slack} alt="Slack" className="me-2" />
          <div className="text-black"># {contract.slack_channel}</div>
        </div>
        <div
          className="d-flex align-items-center"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="me-2">Owners</div>
          <div
            className={styles.owner_button}
            id={`owner-button-${contract.contract_id}`}
          >
            <ArrowDown />
          </div>
          <BetterPopover
            target={`owner-button-${contract.contract_id}`}
            hideArrow
            placement="bottom-end"
          >
            {() => (
              <ul className={styles.owner_popover}>
                {contract.owners.map((item) => (
                  <li key={item}>
                    <Message className="me-2" />
                    <div>{item}</div>
                  </li>
                ))}
              </ul>
            )}
          </BetterPopover>
        </div>
      </div>
    </div>
  );
};

const ContractList = ({ contracts }) => {
  return (
    <div className={styles.contract_list}>
      {contracts.map((contract) => (
        <Contract key={contract.contract_id} contract={contract} />
      ))}
    </div>
  );
};

export { ContractList };
