import styles from "./styles.module.scss";
import dayjs from "dayjs";
import slack from "../../assets/icons/slack.svg";
import bell_blue from "../../assets/icons/bell_blue.svg";
import bell_orange from "../../assets/icons/bell_orange.svg";
import triple_dot from "../../assets/icons/triple_dot.svg";
import clock from "../../assets/icons/clock.svg";
import email from "../../assets/icons/email.svg";
import schedule from "../../assets/icons/schedule.svg";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const Rule = ({ rule }) => {
  const navigate = useNavigate();

  const handleRuleClick = () => {
    navigate(`/checks/${rule.id}`);
  };

  const alert_type = rule?.notifier_config[0].type;

  let address = "";
  if (alert_type == "slack") {
    address = rule?.notifier_config[0]?.config?.webhook_url;
  } else {
    address = rule?.notifier_config[0]?.config?.emails[0];
  }

  return (
    <div className={styles.rule} onClick={handleRuleClick}>
      <div className={styles.bells}>
        <img src={bell_blue} alt="bell_blue" />
        <div className="d-flex gap-xs align-items-center">
          <img src={bell_orange} alt="bell_orange" />
          <div
            className={styles.number}
          >{`No. of issues - ${rule?.no_of_alerts}`}</div>
          <img
            src={triple_dot}
            alt="triple_dot"
            className={styles.triple_dot}
          />
        </div>
      </div>
      <div className={styles.name}>{rule.name}</div>
      <div className={styles.info}>
        <div className={styles.id}>{`ID: ${rule.id}`}</div>
        {rule?.last_alert && (
          <div className="d-flex gap-xs align-items-center">
            <img src={clock} alt="clock" />
            <div className={styles.last_alert}>{`Last issue: ${dayjs(
              rule?.last_alert
            ).format("h:mm A, D MMMM YY")}`}</div>
          </div>
        )}
      </div>
      <div className={styles.description}>{rule.description}</div>
      <div className={styles.spacer}></div>
      <div className={styles.alert_info}>
        <div className="d-flex gap-xs align-items-center">
          {alert_type === "email" ? (
            <img src={email} alt="email" />
          ) : alert_type === "slack" ? (
            <img src={slack} alt="slack" />
          ) : (
            <></>
          )}
          <div>{address}</div>
        </div>
        <div className="d-flex gap-xs align-items-center">
          <img src={schedule} alt="schedule" />
          <div>{rule.period} Days</div>
        </div>
      </div>
    </div>
  );
};

const RuleList = ({ rules }) => {
  return (
    <div className={styles.rule_list}>
      {rules.map((rule) => (
        <Rule key={rule.id} rule={rule} />
      ))}
    </div>
  );
};

export { RuleList };
