import styles from "./styles.module.scss";
import { Filter } from "./Filter";
import { TableList } from "./TableList";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ColumnsModal } from "./ColumnsModal";
import { NoDatasets } from "./NoDatasets";
import { useLocation, useNavigate } from "react-router-dom";
import { SearchContext } from "../Home";
import { useDatasetFilter } from "../../services/filters";
import { ComponentLoader } from "../../Components/Loader";
import SidebarModal from "../../Components/SidebarModal";
import { FilterTagWrapper } from "../../Components/Tags";
import classNames from "classnames";
import { isEqual } from "lodash";
import useFiltersBySearchParams from "@/helpers/useFiltersBySearchParams";
import { FilterApplicableFor } from "@/Components/Tags/types";
import SavedFilters from "@/Components/Tags/SavedFilters";

const Datasets = () => {
  const { search } = useContext(SearchContext);
  const { searchParams } = useFiltersBySearchParams();
  const { state } = useLocation();

  const navigate = useNavigate();
  const onDetailsClick = (table) =>
    navigate(`/datasets/${table.id}`, { state: { from: "datasets" } });
  const [schemaTable, setSchemaTable] = useState(null);
  const [firstLoad, setFirstLoad] = useState(true);

  const { isDataset, filter, tables, paginate, isLoading, isTableLoading } =
    useDatasetFilter({ search, defaultFilters: state?.filters || {} });
  useEffect(() => {
    if (!tables || tables.length === 0) return;
    if (!schemaTable) return;
    const t = tables.find((t) => t.id === schemaTable.id);
    if (t) setSchemaTable(t);
  }, [tables]);

  const prepFilterTags = useMemo(() => {
    if (!filter) return {};

    const filterKeys = {
      datasources: { label: "Data Store", onChange: filter.onDatasourceChange },
      databases: { label: "Database", onChange: filter.onDatabaseChange },
      schemas: { label: "Schema", onChange: filter.onSchemaChange },
      tagOptions: { label: "Insight", onChange: filter.onTagChange, searchKey: "insight" },
      contractOptions: { label: "Contract", onChange: filter.onContractFilter },
    };
    const allFilters = {};
    Object.entries(filterKeys).forEach(([key, { label, onChange, searchKey }]) => {
      allFilters[label] = {
        label: label,
        filterStr: searchParams.getAll(searchKey || key),
        searchKey: searchKey || key,
        onchange: onChange,
        bgColor: "white",
      }
    });
    return allFilters;
  }, [filter, searchParams]);

  if (isDataset) return <NoDatasets />;

  const tableListProps = {
    paginate,
    tables,
    search,
    onDetailsClick,
    onItemClick: onDetailsClick,
    select: {
      label: "View Schema",
      color: "primary",
      outline: true,
      onSelect: (table) => {
        setSchemaTable?.(table);
      },
    },
  };

  if (firstLoad && (isLoading || isTableLoading)) {
    return <ComponentLoader left={60} label={"Loading datasets..."} />;
  } else if (firstLoad) {
    setFirstLoad(false);
  }

  return (
    <>
      <div>
        <div className="mb-4 d-flex gap-lg align-items-center">
          <h3 className="text-black">Datasets</h3>
          <div
            className={classNames(
              styles.filter_tags,
              "d-flex gap-sm align-items-center flex-1"
            )}
          >
            <FilterTagWrapper filters={prepFilterTags} applicableFor={FilterApplicableFor.DATASETS}/>
          </div>
          <SavedFilters applicableFor={FilterApplicableFor.DATASETS}/>
        </div>
        <div className={styles.dataset_container}>
          <Filter {...filter} />
          {isTableLoading ? (
            <ComponentLoader left={70} label="Loading datasets..." />
          ) : (
            <TableList {...tableListProps} />
          )}
        </div>
      </div>
      <SidebarModal
        isOpen={!!schemaTable}
        toggleModal={() => setSchemaTable(null)}
        width="446"
      >
        <ColumnsModal table={schemaTable} onDetailsClick={onDetailsClick} />
      </SidebarModal>
    </>
  );
};

export { Datasets };
