import { BaseContainer } from "./components/base";
import { default as MailGradientIcon } from "../../assets/icons/mail_gradient.svg?react";
import { Navigate, useLocation } from "react-router-dom";
import styles from "./components/styles.module.scss";
import classNames from "classnames";

const Verified = () => {
  const { state } = useLocation();
  const email = state?.email;

  if (!email) return <Navigate to="/login" />;

  const isMobile = window.innerWidth <= 768;

  return (
    <BaseContainer>
      <div className={classNames({ [styles.mobile_verified]: isMobile })}>
        <MailGradientIcon className="mb-3" />
        <div className="mb-5" />
        <p className="text-black fs-5">Thanks for signing up</p>
        <p className="text-primary">
          An email has been sent to <b>{email}</b> with instructions to activate
          the account. Please check your <b>spam folder</b> if needed. <br />
          <br />
          <span className="text-warning">
            You cannot sign in without activating the account from your email.
          </span>
        </p>
      </div>
    </BaseContainer>
  );
};

export { Verified };
