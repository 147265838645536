import React, { Suspense, useEffect } from 'react';

const DbtDocsDetails = React.lazy(() => import('../../Components/DbtDocs/Details/DbtDocsDetails'));

const DbtDocsDetailsPage = () => {
  useEffect(() => {
    return () => {
      // Hack to reset the injected styles not to affect existing UI
      // dbt docs component injects styles into head tag
      // this affects the UI ofexising element and break the interactions
      // reload the window after navigating away from this page, to reset the styles
      setTimeout(() => {
        document.location.reload();
      }, 100)
    }
  }, []);
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <DbtDocsDetails />
    </Suspense>
      
  );
};

export default DbtDocsDetailsPage;
