import Tooltip from "../tooltip/Tooltip";
import { formatNumber, getCurrencySymbol } from "./format";

interface Props {
  value?: number;
  options?: Intl.NumberFormatOptions;
  noTooltip?: boolean;
}

const NumberFormatter = ({ value, options, noTooltip }: Props) => {
  if (value === undefined || value === null) {
    return null;
  }
  return (
    <Tooltip content={noTooltip ? undefined : `${getCurrencySymbol(options?.currency)}${value.toFixed(2)}`} placement="top">
      <span>{formatNumber(value, options)}</span>
    </Tooltip>
  );
};

export default NumberFormatter;
