import classNames from "classnames";
import styles from "./styles.module.scss";
import { default as RightArrow } from "../../../assets/icons/right_arrow.svg?react";

export const InstanceList = ({ instances }) => (
  <div className={styles.instance_list}>
    {instances.map((instance) => (
      <div
        className={styles.instance}
        key={instance.url}
        onClick={() => (window.location.href = instance.url)}
      >
        <div className={classNames(styles.inner_text, styles.name)}>
          {instance.name}
        </div>
        <div className={classNames(styles.inner_text, styles.exeitence_time)}>
          created {instance.creationTime} ago
        </div>
        <RightArrow />
      </div>
    ))}
  </div>
);
