import { Button, Card, CardBody, Input } from "reactstrap";
import styles from "./styles.module.scss";
import classnames from "classnames";
import dayjs from "dayjs";
import { ColumnDatatype } from "../../Components/Column";
import { getIconByDatastoreType } from "./DatastoreImage";
import { useMemo, useState, useEffect } from "react";
import { ColorTag, ModifiedByTag } from "../../Components/Tags";
import { default as EditIcon } from "@/assets/icons/edit.svg?react";
import { useBoolean } from "../../helpers/hooks";
import { DebouncedInput } from "../../Components/Form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  getTableRk,
  updateTableSchemaDesc,
} from "../../helpers/backend_helper";
import { GET_TABLES } from "../../services/filters";
import { scroll_style } from "../../Components/ScrollContainer";
import { useTenantUsers } from "../../services/common";
import { BetterTooltip } from "../../Components/Tooltip";
import { PurposeSection } from "../../Components/Lineage";
import { GET_TABLE_RK } from "./DatasetsDetails";
import { RelativeComponentLoader } from "../../Components/Loader";

const colors = ["blue", "orange", "yellow", "purple", "dark_green"];
const colorsMap = {};
let i = 0;

const ColumnCard = ({ column, users, colDescription }) => {
  const { value: isEditing, setTrue, setFalse } = useBoolean();
  const [description, setDescription] = useState(null);
  useEffect(() => {
    setDescription(colDescription);
  }, [colDescription]);
  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: (data) => updateTableSchemaDesc(column.table_schema_id, data),
    onSuccess: (data) => {
      if (data.ok) {
        setFalse();
        queryClient.invalidateQueries({ queryKey: [GET_TABLES] });
      }
    },
  });

  return (
    <div className={styles.column_card}>
      <div className="d-flex align-items-center">
        <ColumnDatatype datatype={column.datatype} />
        <div className="ms-2">{column.name}</div>
        <div className="spacer" />
        <ModifiedByTag users={users} lastModifiedBy={column.last_modified_by} />
      </div>
      <ColorTag label={column.datatype} color={styles.column_tag} />
      <div className="mb-1 divider" />
      <div className="d-flex gap-xs align-items-center">
        <div>Description</div>
        <EditIcon
          className={classnames("cursor-pointer", styles.edit_icon, {
            [styles.active]: isEditing,
          })}
          onClick={(e) => {
            e.stopPropagation();
            if (isEditing) {
              setFalse();
              setDescription(colDescription);
            } else {
              setTrue();
            }
          }}
        />
      </div>
      {isEditing ? (
        <>
          <Input
            type="textarea"
            className={classnames("mb-3", styles.multiline_input)}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter the column description"
          />
          <Button
            onClick={(e) => {
              e.stopPropagation();
              mutate({ description });
            }}
          >
            Update
          </Button>
        </>
      ) : (
        <div className="text-muted font-normal">{description}</div>
      )}
      <div className="d-flex gap-sm flex-wrap">
        {(column.tags || []).map((t) => {
          if (!colorsMap[t.name])
            colorsMap[t.name] = colors[i++ % colors.length];
          return (
            <BetterTooltip tooltipContent={t.description} key={t.name}>
              <ColorTag color={colorsMap[t.name]} label={t.name} />
            </BetterTooltip>
          );
        })}
      </div>
    </div>
  );
};

const ContractSection = ({
  contracts,
  total_violations,
  last_violation_timestamp,
}) => {
  return (
    <Card className={classnames("mb-0", styles.bg_gray)}>
      <CardBody>
        <div className="fs-5 fw-semibold">Contract</div>
        <div className="d-flex mt-2 mb-2">
          <div className="me-2">Contracts:</div>
          <div className="text-primary">
            {[...new Set(contracts.map((c) => c.name))].join(", ")}
          </div>
        </div>
        <div className="d-flex mt-2 mb-2">
          <div className="me-2">Last Validation:</div>
          <div>
            <span className={classnames("text-underline me-2", styles.orange)}>
              {total_violations} Incidents
            </span>
            {last_violation_timestamp && (
              <span className="text-primary">
                (
                {dayjs(last_violation_timestamp).format("DD MMM YYYY, hh:mm A")}
                )
              </span>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

const ColumnSection = ({ columns, users }) => {
  const [search, setSearch] = useState("");
  const filteredColumns = useMemo(() => {
    if (!search) return columns;
    const _search = search.toLowerCase();
    return columns.filter((c) => c.name.includes(_search));
  }, [columns, search]);

  return (
    <Card className={classnames("mb-0", styles.bg_gray)}>
      <CardBody className="d-flex flex-column gap-sm h-100">
        <div className="fs-5 fw-semibold mb-2">Columns</div>
        <DebouncedInput placeholder="Search Column" onChange={setSearch} />
        {search ? (
          <div className="text-primary">
            {filteredColumns.length} results found
          </div>
        ) : (
          <div>{columns.length} columns</div>
        )}
        <div className="d-flex flex-column gap-sm">
          {filteredColumns.map((c) => (
            <ColumnCard
              key={c.table_schema_id}
              column={c}
              users={users}
              colDescription={c.column_description}
            />
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

const HeaderSection = ({ table, onDetailsClick }) => {
  return (
    <div className="d-flex align-items-center">
      {getIconByDatastoreType(table.datasource_type, "me-2 icon-md")}
      <div className={styles.column_table_name}>{table.name}</div>
      <div className="spacer" />
      {onDetailsClick && (
        <Button
          className="mw-8"
          color="primary"
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            onDetailsClick(table);
          }}
        >
          View Details
        </Button>
      )}
    </div>
  );
};

const ColumnsModal = ({ table, onDetailsClick }) => {
  const { users } = useTenantUsers();
  const { data: tableRk, isLoading: isTableRkLoading } = useQuery({
    queryKey: [GET_TABLE_RK, table.id],
    queryFn: () => getTableRk(table.id),
  });

  if (isTableRkLoading) {
    return <RelativeComponentLoader />;
  }

  return (
    <div
      className={classnames(
        "d-flex flex-column h-100 p-3 gap-md m-1",
        scroll_style
      )}
    >
      <HeaderSection table={table} onDetailsClick={onDetailsClick} />
      <ContractSection
        contracts={table.contracts}
        total_violations={table.total_violations}
        last_violation_timestamp={table.last_violation_timestamp}
      />
      <PurposeSection
        table={tableRk.table_rk}
        tableId={table.id}
        purpose={table.purpose}
        users={users}
        lastModifiedBy={table.last_modified_by}
        tableName={table.name}
      />
      <ColumnSection columns={table.columns} users={users} />
    </div>
  );
};

export { ColumnsModal };
