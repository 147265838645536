export const COPY_INTERACTION_TIME = 1000;
export const INTERACTION_TIME = 1000;

export const PermissionsEnum = {
    USER_ADD: "USER_ADD",
    USER_EDIT: "USER_EDIT",
    USER_DELETE: "USER_DELETE",
    SETTINGS_IDEAS: "SETTINGS_IDEAS",
    SETTINGS_ROLES: "SETTINGS_ROLES",
    SETTINGS_GROUPS: "SETTINGS_GROUPS",
    DATASTORE_CREATE: "DATASTORE_CREATE",
    DATASTORE_EDIT: "DATASTORE_EDIT",
    SECURITY_VIEW: "SECURITY_VIEW",
    USER_ROLE_EDIT: "USER_ROLE_EDIT",
}

export const RolesEnum = {
    OWNER: "Owner",
    ADMIN: "Admin",
    USER: "User",
}
