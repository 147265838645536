import styles from "./styles.module.scss";
import { useEffect, useMemo, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { RelativeComponentLoader } from "../../Components/Loader";
import { Table } from "../../Components/Tables";
import { Paginate } from "../../Components/Paginate";
import { ColorInsightTags, FilterTagWrapper } from "../../Components/Tags";
import TimeInfoDisclaimer from "../../Components/TimeInfoDisclaimer";
import classNames from "classnames";
import { useQuery } from "react-query";
import {
  getDatasetList,
  getDatasetListFilters,
} from "../../helpers/backend_helper";
import { getIconByDatastoreType } from "./DatastoreImage";
import { useLocation, useNavigate } from "react-router-dom";
import { CopyIconButton } from "../../Components/CopyButton";
import { Tooltip } from "react-tooltip";
import { NoDatasets } from "./NoDatasets";
import { useAppState } from "@/modules/app/useAppContext";
import { formatNumber } from "@/uiCore";
import { StaticFilterDropdown } from "@/Components/FilterDropdown";
import { Tooltip as UITooltip } from "@uicore";
import { InfoGray } from "@assets/icons";
import useFiltersBySearchParams from "@/helpers/useFiltersBySearchParams";
import { FilterApplicableFor } from "@/Components/Tags/types";
import SavedFilters from "@/Components/Tags/SavedFilters";
import { onSortChange } from "@/helpers/utils";
import { DEFAULT_PAGE_SIZE } from "@/Components/Paginate/constants";
import usePaginate from "@/Components/Paginate/usePaginate";

const TableHeader = ({
  numTables,
  tableFilters,
  tableNameFilterDispTag,
  schemaFilterDispTag,
  dbFilterDispTag,
}) => {
  const { updateSearchParamsByKey } = useFiltersBySearchParams();
  const { currencySymbol } = useAppState();
  return (
    <div>
      <div className="d-flex gap-md align-items-center">
        <div className="fs-4 fw-500 me-3">Tables</div>
        <div className="spacer" />
        <TimeInfoDisclaimer
          numOfSecondsActive={3}
          textToDisplay={
            <div className={classNames(styles.info_text, "text-muted m-2")}>
              Tables & costs for the past 30 days
            </div>
          }
        />
        <Card className={classNames("mb-0 no-break", styles.query_count)}>
          <CardBody className="pt-2 pb-2 ps-4 pe-4">
            <div className="d-flex align-items-center justify-content-between">
              <div>Tables: {numTables}</div>
            </div>
          </CardBody>
        </Card>
        <SavedFilters applicableFor={FilterApplicableFor.DATASET_LIST} />
      </div>
      <FilterTagWrapper
        applicableFor={FilterApplicableFor.DATASET_LIST}
        filters={{
          tableRKs: {
            searchKey: "table",
            label: "Table Name",
            filterStr: Object.keys(tableNameFilterDispTag),
            onclose: (removedFilter) => {
              updateSearchParamsByKey({
                table: tableFilters.table_rk.filter(
                  (filter) => filter !== tableNameFilterDispTag[removedFilter]
                ),
              });
            },
          },
          schemas: {
            searchKey: "schema",
            label: "Schema",
            filterStr: Object.keys(schemaFilterDispTag),
            onclose: (removedFilter) => {
              updateSearchParamsByKey({
                schema: tableFilters.filter_schema.filter(
                  (filter) => filter !== schemaFilterDispTag[removedFilter]
                ),
              });
            },
          },
          databases: {
            searchKey: "database",
            label: "Database",
            filterStr: Object.keys(dbFilterDispTag),
            onclose: (removedFilter) => {
              updateSearchParamsByKey({
                database: tableFilters.filter_db.filter(
                  (filter) => filter !== dbFilterDispTag[removedFilter]
                ),
              });
            },
          },
          tagRKs: {
            label: "Tags",
            searchKey: "insight",
            filterStr: tableFilters.tag_rks,
          },
          totalCost: {
            searchKey: "totalcost",
            label: `${currencySymbol} Total`,
            filterStr: tableFilters.total_cost,
          },
          insertCost: {
            searchKey: "insertioncost",
            label: `${currencySymbol} Insertion`,
            filterStr: tableFilters.insertion_cost,
          },
          storageCost: {
            searchKey: "storagecost",
            label: `${currencySymbol} Storage`,
            filterStr: tableFilters.storage_cost,
          },
          clusteringCost: {
            searchKey: "clusteringcost",
            label: `${currencySymbol} Clustering`,
            filterStr: tableFilters.clustering_cost,
          },
          reads: {
            searchKey: "reads",
            label: "Reads",
            filterStr: tableFilters.min_reads,
          },
          writes: {
            searchKey: "writes",
            label: "Writes",
            filterStr: tableFilters.min_writes,
          },
          readWriteRatio: {
            searchKey: "readWriteRatio",
            label: "Read Write Ratio",
            filterStr: tableFilters.min_read_write_ratio,
          },
          owner: {
            searchKey: "owner",
            label: "Owner",
            filterStr: tableFilters.owners,
          },
        }}
      />
    </div>
  );
};

const GET_DATASET_LIST = "GET-DATASET-LIST";
const GET_DATASET_LIST_FILTERS = "GET-DATASET-LIST-FILTERS";

const TablePath = ({ text, maxLength }) => {
  const truncatedText =
    text.length > maxLength ? text.substring(0, maxLength) + "..." : text;

  return <div className="text-muted condense-text me-2">{truncatedText}</div>;
};

const DatasetsList = () => {
  const { updateSearchParamsByKey, searchParams } = useFiltersBySearchParams();
  const { currency, currencySymbol } = useAppState();
  const { state } = useLocation();
  const [sortAttribute, setSortAttribute] = useState("total_cost");
  const [sortOrder, setSortOrder] = useState({
    total_cost: "",
    insertion_cost: "",
    storage_cost: "",
    clustering_cost: "",
    reads: "",
    writes: "",
    read_write_ratio: "",
  });
  const {page, pageSize: size, setPage, updatePageSize, resetPage} = usePaginate();
  const navigate = useNavigate();
  const onTableClick = (item) => navigate(`/datasets/${item.resource_id}`);

  const tagFilter = searchParams.getAll("insight") || [];
  const tableNameFilter = searchParams.getAll("table") || [];
  const schemaFilter = searchParams.getAll("schema") || [];
  const dbFilter = searchParams.getAll("database") || [];
  const totalCostFilter = searchParams.get("totalcost") || null;
  const insertionCostFilter = searchParams.get("insertioncost") || null;
  const storageCostFilter = searchParams.get("storagecost") || null;
  const clusteringCostFilter = searchParams.get("clusteringcost") || null;
  const readsFilter = searchParams.get("reads") || null;
  const writesFilter = searchParams.get("writes") || null;
  const readWriteRatioFilter = searchParams.get("readWriteRatio") || null;
  const ownerFilter = searchParams.getAll("owner") || [];

  const getRequestBody = () => {
    const data = { page: page + 1, size: size };
    if (tableNameFilter.length) data.table_rk = tableNameFilter;
    if (schemaFilter.length) data.filter_schema = schemaFilter;
    if (dbFilter.length) data.filter_db = dbFilter;
    if (sortAttribute) {
      data.order_by = sortAttribute;
      data.sort_order = sortOrder[sortAttribute];
    }
    if (totalCostFilter != undefined) data.total_cost = totalCostFilter;
    if (insertionCostFilter != undefined)
      data.insertion_cost = insertionCostFilter;
    if (storageCostFilter != undefined) data.storage_cost = storageCostFilter;
    if (clusteringCostFilter != undefined)
      data.clustering_cost = clusteringCostFilter;
    if (tagFilter.length > 0) data.tag_rks = tagFilter;
    if (readsFilter != undefined) data.min_reads = readsFilter;
    if (writesFilter != undefined) data.min_writes = writesFilter;
    if (readWriteRatioFilter != undefined)
      data.min_read_write_ratio = readWriteRatioFilter;
    if (ownerFilter.length > 0) data.owners = ownerFilter;
    return data;
  };

  const { data: tables, isLoading: isTablesLoading } = useQuery({
    queryKey: [
      GET_DATASET_LIST,
      page,
      size,
      ...tableNameFilter,
      ...schemaFilter,
      ...dbFilter,
      ...tagFilter,
      ...ownerFilter,
      totalCostFilter,
      insertionCostFilter,
      clusteringCostFilter,
      storageCostFilter,
      readsFilter,
      writesFilter,
      readWriteRatioFilter,
      sortOrder,
      sortAttribute,
    ],
    queryFn: () => getDatasetList(getRequestBody()),
  });

  const { data: filters, isLoading: isFiltersLoading } = useQuery({
    queryKey: [GET_DATASET_LIST_FILTERS, page],
    queryFn: () => getDatasetListFilters(),
  });

  const tableNameFilterDispTag = useMemo(() => {
    return (
      filters?.rks?.reduce((acc, { rk, name }) => {
        if (tableNameFilter.includes(rk)) {
          acc[name] = rk;
        }
        return acc;
      }, {}) || {}
    );
  }, [tableNameFilter, filters]);

  const schemaFilterDispTag = useMemo(() => {
    return filters?.rks?.reduce((acc, { rk, path, name }) => {
      const schema = path.split(".")[1];
      if (schemaFilter.includes(schema)) {
        acc[schema] = schema;
      }
      return acc;
    }, {});
  }, [schemaFilter, filters]);

  const dbFilterDispTag = useMemo(() => {
    return (filters?.rks || []).reduce((acc, { rk, path, name }) => {
      const db = path.split(".")[0];
      if (dbFilter.includes(db)) {
        acc[db] = db;
      }
      return acc;
    }, {});
  }, [dbFilter, filters]);

  const tableNameFilterOptions = useMemo(
    () =>
      filters?.rks?.map((t) => ({
        label: t.name,
        value: t.rk,
      })),
    [filters]
  );

  const schemaFilterOptions = useMemo(
    () =>
      filters?.rks
        ?.map((t) => t.path.split(".")[1])
        ?.filter((value, index, self) => self.indexOf(value) === index)
        ?.map((t) => ({
          label: t,
          value: t,
        })),
    [filters]
  );

  const dbFilterOptions = useMemo(
    () =>
      filters?.rks
        ?.map((t) => t.path.split(".")[0])
        ?.filter((value, index, self) => self.indexOf(value) === index)
        ?.map((t) => ({
          label: t,
          value: t,
        })),
    [filters]
  );

  if (isTablesLoading || isFiltersLoading) return <RelativeComponentLoader />;
  const header = [
    {
      id: 1,
      label: "Table Names",
      width: 3,
      uid: "table-name",
      render: (item) => (
        <div className="cursor-pointer">
          <div
            className={classNames(
              styles.word_break,
              "text-primary condense-text"
            )}
          >
            {getIconByDatastoreType("snowflake")}
            {item.rk.name}
          </div>
          <div
            className={styles.word_break}
            data-tooltip-id={"table-" + item.rk.rk}
          >
            <TablePath text={item.rk.path} maxLength={100} />
            <CopyIconButton
              value={item.rk.path}
              color="rgba(8, 34, 71, 0.5)"
              isLight
            />
          </div>
          <Tooltip
            id={"table-" + item.rk.rk}
            className={styles.sync_disabled_tooltip}
          >
            <div className="d-flex flex-column align-items-left gap-xs justify-content-center">
              <div>{item.rk.path}</div>
            </div>
          </Tooltip>
        </div>
      ),
      filter: {
        filterType: "multiDropdown",
        options: [
          {
            value: "table-name-filter",
            label: "Table Name",
            subComponent: (close) => {
              return (
                <StaticFilterDropdown
                  id="table-name-filter"
                  label={"Table Name"}
                  value={tableNameFilter || []}
                  options={tableNameFilterOptions}
                  onChange={(value) => {
                    updateSearchParamsByKey({ table: value });
                    close();
                  }}
                />
              );
            },
          },
          {
            value: "schema-filter",
            label: "Schema",
            subComponent: (close) => {
              return (
                <StaticFilterDropdown
                  id="schema-filter"
                  label={"Schema"}
                  value={schemaFilter || []}
                  options={schemaFilterOptions}
                  onChange={(value) => {
                    updateSearchParamsByKey({ schema: value });
                    close();
                  }}
                />
              );
            },
          },
          {
            value: "db-filter",
            label: "Database",
            subComponent: (close) => {
              return (
                <StaticFilterDropdown
                  id="db-filter"
                  label={"Database"}
                  value={dbFilter || []}
                  options={dbFilterOptions}
                  onChange={(value) => {
                    updateSearchParamsByKey({ database: value });
                    close();
                  }}
                />
              );
            },
          },
        ],
      },
    },
    {
      id: 2,
      label: "Annualized Total Cost",
      uid: "total-cost",
      render: (item) => (
        <div className={classNames(styles.word_break, "condense-text")}>
          {formatNumber(item.total_cost, { currency })}
        </div>
      ),
      sort: {
        onChange: onSortChange("total_cost", setSortOrder, setSortAttribute),
        value: sortOrder.total_cost,
      },
      filter: {
        searchKey: "totalcost",
        filterType: "text",
        value: totalCostFilter,
        placeHolder: `Specify cost in ${currencySymbol}`,
        label: "Annualized Total Cost",
      },
    },

    {
      id: 3,
      label: "Annualized Insertion Cost",
      uid: "insertion-cost",
      render: (item) => (
        <div className={classNames(styles.word_break, "condense-text")}>
          {formatNumber(item.insertion_cost, { currency })}
        </div>
      ),
      sort: {
        onChange: onSortChange(
          "insertion_cost",
          setSortOrder,
          setSortAttribute
        ),
        value: sortOrder.insertion_cost,
      },
      filter: {
        searchKey: "insertioncost",
        filterType: "text",
        value: insertionCostFilter,
        placeHolder: `Specify cost in ${currencySymbol}`,
        label: "Annualized Insertion Cost",
      },
    },

    {
      id: 4,
      label: (
        <div className="d-flex justify-content-between align-items-center">
          Annualized Storage Cost
          <UITooltip
            content="(ACTIVE_BYTES + TIME_TRAVEL_BYTES + FAILSAFE_BYTES + RETAINED_FOR_CLONE_BYTES) / (1024 ^4) * 23 * 12"
            placement="top"
          >
            <div className="d-flex align-items-center">
              <InfoGray />
            </div>
          </UITooltip>
        </div>
      ),
      uid: "storage-cost",
      render: (item) => (
        <div className={classNames(styles.word_break, "condense-text")}>
          {formatNumber(item.storage_cost, { currency })}
        </div>
      ),
      sort: {
        onChange: onSortChange("storage_cost", setSortOrder, setSortAttribute),
        value: sortOrder.storage_cost,
      },
      filter: {
        searchKey: "storagecost",
        filterType: "text",
        value: storageCostFilter,
        placeHolder: `Specify cost in ${currencySymbol}`,
        label: "Annualized Storage Cost",
      },
    },

    {
      id: 5,
      label: "Annualized Clustering Cost",
      uid: "clustering-cost",
      render: (item) => (
        <div className={classNames(styles.word_break, "condense-text")}>
          {formatNumber(item.clustering_cost, { currency })}
        </div>
      ),
      sort: {
        onChange: onSortChange(
          "clustering_cost",
          setSortOrder,
          setSortAttribute
        ),
        value: sortOrder.clustering_cost,
      },
      filter: {
        searchKey: "clusteringcost",
        filterType: "text",
        value: clusteringCostFilter,
        placeHolder: `Specify cost in ${currencySymbol}`,
        label: "Annualized Clustering Cost",
      },
    },

    {
      id: 6,
      label: "Insights",
      uid: "insights",
      width: 1.5,
      render: (item) => <ColorInsightTags tags={item.tag_rks} />,
      filter: {
        searchKey: "insight",
        filterType: "dropdown",
        value: tagFilter,
        options: filters.tag_rks.map((t) => ({
          label: t,
          value: t,
        })),
      },
    },

    {
      id: 7,
      label: "Reads",
      uid: "reads",
      width: 0.75,
      render: (item) => (
        <div className={classNames(styles.word_break, "condense-text")}>
          {formatNumber(item.reads)}
        </div>
      ),
      sort: {
        onChange: onSortChange("reads", setSortOrder, setSortAttribute),
        value: sortOrder.reads,
      },
      filter: {
        searchKey: "reads",
        filterType: "text",
        value: readsFilter,
        placeHolder: "Specify reads",
        label: "Reads",
      },
    },

    {
      id: 8,
      label: "Writes",
      width: 0.75,
      uid: "writes",
      render: (item) => (
        <div className={classNames(styles.word_break, "condense-text")}>
          {formatNumber(item.writes)}
        </div>
      ),
      sort: {
        onChange: onSortChange("writes", setSortOrder, setSortAttribute),
        value: sortOrder.writes,
      },
      filter: {
        searchKey: "writes",
        filterType: "text",
        value: writesFilter,
        placeHolder: "Specify writes",
        label: "Writes",
      },
    },

    {
      id: 9,
      label: "Read Write Ratio",
      width: 0.75,
      uid: "read-write-ratio",
      render: (item) => (
        <div className={classNames(styles.word_break, "condense-text")}>
          {item.read_write_ratio}
        </div>
      ),
      sort: {
        onChange: onSortChange(
          "read_write_ratio",
          setSortOrder,
          setSortAttribute
        ),
        value: sortOrder.read_write_ratio,
      },
      filter: {
        searchKey: "readWriteRatio",
        filterType: "text",
        value: readWriteRatioFilter,
        placeHolder: "Specify read write ratio",
        label: "Read Write Ratio",
      },
    },

    {
      id: 10,
      label: "Owner",
      uid: "owner",
      render: (item) => (
        <div className={classNames(styles.word_break, "condense-text")}>
          {item.owner}
        </div>
      ),
      filter: {
        searchKey: "owner",
        filterType: "dropdown",
        options: filters.owners?.map((t) => ({
          label: t,
          value: t,
        })),
        value: ownerFilter,
      },
    },
  ];
  if (
    tables.total === 0 &&
    !(
      totalCostFilter ||
      insertionCostFilter ||
      storageCostFilter ||
      clusteringCostFilter ||
      (tableNameFilter && tableNameFilter.length > 0) ||
      (schemaFilter && schemaFilter.length > 0) ||
      (dbFilter && dbFilter.length > 0) ||
      tagFilter ||
      readsFilter ||
      writesFilter ||
      readWriteRatioFilter ||
      ownerFilter
    )
  ) {
    return <NoDatasets />; // only returned if no tables were found without applying any of the filters.
  }
  return (
    <>
      <div
        className={classNames(
          styles.dataset_list,
          "p-3 d-flex flex-column bg-white border-radius-bottom"
        )}
      >
        <TableHeader
          numTables={tables.total}
          tableFilters={getRequestBody()}
          tableNameFilterDispTag={tableNameFilterDispTag}
          schemaFilterDispTag={schemaFilterDispTag}
          dbFilterDispTag={dbFilterDispTag}
        />
        <Table
          header={header}
          data={tables}
          idKey={(item) => item.rk.rk}
          onRowClick={onTableClick}
          resetPage={resetPage}
          headerClass={styles.enableScrollingHeader}
          tableBodyClass={styles.enableScrollingTableBody}
          tableClass={styles.enableScrollingTable}
        />
        <Paginate
          itemCount={tables.total}
          page={page}
          pageSize={size}
          numPages={tables.pages}
          onPageClick={setPage}
          onPageSizeChange={updatePageSize}
          showPageSize
        />
      </div>
      {/* <SidebarModal
          isOpen={selectedColumn !== ""}
          toggleModal={() => setSelectedColumn("")}
          width="600"
        >
          <ColumnsAccessedSidebar columnObj={selectedColumn.column} />
        </SidebarModal> */}
    </>
  );
};

export default DatasetsList;
