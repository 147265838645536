import { RelativeComponentLoader } from "@/Components/Loader";
import { Paginate } from "@/Components/Paginate";
import { DisplayTable } from "@/Components/Tables";
import { getExportLineage } from "@/helpers/apis";
import dayjs from "dayjs";
import { useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { NoResource } from "@/Components/NoResource";

export const ExportLineageList = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const { data, isLoading } = useQuery({
    queryKey: ["GET-EXPORT-LINEAGE", page],
    queryFn: () => getExportLineage({ page: page + 1, size: 10 }),
  });
  if (isLoading) {
    return <RelativeComponentLoader label="Loading..." />;
  }

  if (!data?.total) {
    return (
      <NoResource
        title="No lineage exported"
        message="Please visit the extension to export lineage."
      />
    );
  }
  return (
    <div>
      <div className="fs-3 mb-2">Exported lineages</div>
      <DisplayTable
        header={[
          { id: 1, label: "Name" },
          { id: 2, label: "User" },
          { id: 3, label: "Created at" },
        ]}
        items={data?.items}
        templateColumns="2fr 2fr 1fr"
        onItemClick={(i: any) =>
          navigate(`/collaboration/export-lineage/${i.id}`)
        }
        rowRender={(i) => (
          <>
            <div>{i.name}</div>
            <div>
              {i.user.first_name} {i.user.last_name}
            </div>
            <div>{dayjs(i.created_at).format("DD MMM YYYY hh:mm")}</div>
          </>
        )}
        idKey={"id"}
        resetPage={() => {}}
      />
      {data && (
        <Paginate
          itemCount={data.total}
          page={page}
          pageSize={data.size}
          numPages={data.pages}
          onPageClick={setPage}
          onPageSizeChange={() => {}}
        />
      )}
    </div>
  );
};
