import { ReactNode } from "react";
import { IconContext } from "react-icons";

const Icon = ({ children }: { children: ReactNode }) => (
  <IconContext.Provider value={{ size: "1.2rem" }}>
    {children}
  </IconContext.Provider>
);

export default Icon;
