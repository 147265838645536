import { ErrorBoundary } from "react-error-boundary";
import { ReactNode, useRef, useState } from "react";
import { Tooltip as ReactStrapTooltip, TooltipProps } from "reactstrap";

interface Props {
  children: ReactNode;
  content?: string | ReactNode;
  id?: string;
  className?: string;
  tooltipContentWrapperClassName?: string;
  placement?: TooltipProps["placement"];
  maxWidth?: string;
  isOpen?: boolean;
}
const Tooltip = (props: Props): JSX.Element => {
  const [tooltipOpen, setTooltipOpen] = useState(props.isOpen || false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const idRef = useRef(
    (
      props.id ?? `tooltip-${Math.random().toString(36).substring(3, 9)}`
    ).replace(/\s/g, "-")
  );

  return (
    <ErrorBoundary fallback={<span id={idRef.current}>{props.children}</span>}>
      <span id={idRef.current} className={props.tooltipContentWrapperClassName}>
        {props.children}
      </span>
      {props.content ? (
        <ReactStrapTooltip
          isOpen={tooltipOpen}
          target={idRef.current}
          toggle={toggle}
          className={props.className}
          placement={props.placement ?? "auto"}
          style={{ maxWidth: props.maxWidth }}
        >
          {props.content}
        </ReactStrapTooltip>
      ) : null}
    </ErrorBoundary>
  );
};

export default Tooltip;
