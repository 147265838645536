import React from "react";
import styles from "./styles.module.scss";
import { default as DeleteIcon } from "../../assets/icons/delete_icon.svg?react";
import { Button } from "reactstrap";

const DialogBox = ({
  onClose,
  onDelete,
  mainMessage = "Are you sure?",
  subMessage = "Do you really want to delete this item? This process can’t be undone.",
}) => {
  return (
    <div className={styles.dialog_box_container}>
      <div className={styles.dialog_box}>
        <div className="m-4">
          <DeleteIcon />
        </div>
        <div className="fs-4 fw-semibold mb-4">{mainMessage}</div>
        <div className="text-muted mb-2">{subMessage}</div>

        <div className="m-4 d-flex gap-sm align-items-center justify-content-center">
          <Button outline onClick={onClose}>
            Cancel
          </Button>
          <Button color="danger" onClick={onDelete}>
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DialogBox;
