import { getQueryGroupDetailsByHash } from "@/helpers/apis";
import { AsyncPopover, List } from "@uicore";
import dayjs from "dayjs";
import classes from "./styles.module.scss";
import { useSearchParams } from "react-router-dom";

interface Props {
  queryHash: string;
}

export interface QueryGroupStats {
  num_runs: number;
  total_cost: string;
  avg_execution_time: string;
  last_run_time: string;
  max_cost_savings: string;
  max_time_savings: string;
  currency: string;
}

const QueryHashWithHover = ({ queryHash }: Props) => {
  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };
  const [searchParams] = useSearchParams();
  const period = searchParams.get("period") || "month";
  return (
    <AsyncPopover<QueryGroupStats>
      queryKey="GET_QUERY_GROUP_BY_HASH"
      text={queryHash}
      apiFn={() => getQueryGroupDetailsByHash(queryHash, { period })}
      textClassName={classes.queryHashText}
      loadingLabel="Fetching query group stats"
    >
      {({ data }) => {
        return (
          <List className={classes.queryHash} onClick={stopPropagation}>
            <li>Total runs: {data.num_runs}</li>
            <li>Total cost: {data.total_cost}</li>
            <li>Avg. execution time: {data.avg_execution_time}</li>
            <li>Max cost savings: {data.max_cost_savings}</li>
            <li>Max time savings: {data.max_time_savings}</li>
            <li>
              Last run time:{" "}
              {dayjs(data.last_run_time).format("DD, MMM, HH:MM")}
            </li>
          </List>
        );
      }}
    </AsyncPopover>
  );
};

export default QueryHashWithHover;
