import { ArrowDownIcon, ArrowUpIcon } from "@/assets/icons";
import { MouseEvent, ReactNode, useState } from "react";
import {
  DropdownProps,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown as ReactstrapDropdown,
} from "reactstrap";
import classes from "./dropdown.module.scss";

interface Option {
  label: string;
  value: string;
}
// @ts-ignore TODO fix this
interface Props extends DropdownProps {
  options: Option[];
  onOptionSelect: (value: string) => void;
  prefix?: string | ReactNode;
}
const Dropdown = ({ options, onOptionSelect, prefix, ...rest }: Props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const handleItemClick = (e: MouseEvent<HTMLInputElement>) => {
    // @ts-expect-error valid
    onOptionSelect(e.target.value);
  };

  const selectedLabel = options.find(
    (option) => option.value === rest.value
  )?.label;
  const hasOptions = options.length > 1;

  return (
    <ReactstrapDropdown
      {...rest}
      isOpen={dropdownOpen}
      toggle={toggle}
      className={classes.dropdown}
    >
      <DropdownToggle className="btn-ghost-secondary" outline>
        {prefix} <b>{selectedLabel} </b>
        {hasOptions ? !dropdownOpen ? <ArrowDownIcon /> : <ArrowUpIcon /> : null}
      </DropdownToggle>
      {hasOptions ? (
        <DropdownMenu>
          {options.map(({ label, value }) => (
            <DropdownItem onClick={handleItemClick} key={value} value={value}>
              {label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      ) : null}
    </ReactstrapDropdown>
  );
};

export default Dropdown;
