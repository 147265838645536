import { useLocation, useNavigate } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { useIntercom } from "react-use-intercom";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { getUser, logoutUser } from "../../services/auth";
import { useContext, useEffect, useState } from "react";
import { SimpleInput } from "../../Components/Form";
import { SearchContext } from "./Home";
import { UserProfileImage } from "../../Components/Profile";
import { signOut } from "supertokens-auth-react/recipe/session";
import { isSSOEnabled } from "@/helpers/tenant";

const Header = () => {
  const { pathname } = useLocation();
  const user = getUser();
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const { setSearch } = useContext(SearchContext);
  const { hardShutdown } = useIntercom();
  const ssoMode = isSSOEnabled();
  useEffect(() => {
    setSearch("");
  }, [pathname, setSearch]);
  return (
    <div
      className={classnames(
        "w-100 d-flex align-items-center justify-content-between bg-white",
        styles.header_container
      )}
    >
      <div
        className={classnames("w-50", {
          invisible: !["/datasets", "/dbt_models"].includes(pathname),
        })}
      >
        <SimpleInput
          placeholder="Search for Datasets and Columns"
          onChange={setSearch}
        />
      </div>
      <div className="d-flex align-items-center">
        <Dropdown
          isOpen={showDropdown}
          toggle={() => setShowDropdown((b) => !b)}
          size="sm"
        >
          <DropdownToggle color="bg-white">
            <div className="d-flex cursor-pointer align-items-center gap-sm">
              <div>{user?.first_name || ""}</div>
              <UserProfileImage size="xs" />
            </div>
          </DropdownToggle>

          <DropdownMenu>
            {/* <DropdownItem
              onClick={(e) => {
                e.stopPropagation();
                navigate("/profile");
              }}
            >
              Profile
            </DropdownItem> */}
            <DropdownItem
              onClick={async (e) => {
                e.stopPropagation();
                logoutUser();
                if (ssoMode) {
                  await signOut();
                }
                hardShutdown();
                navigate("/login");
              }}
            >
              Logout
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    </div>
  );
};

export { Header };
