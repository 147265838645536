import snowflake from "../../assets/images/snowflake.png";
import postgres from "../../assets/images/postgres.png";
import s3 from "../../assets/images/s3.png";
import { default as DBTIcon } from "../../assets/icons/dbt.svg?react";
import { capitalize } from "lodash";

const iconMap = {
  postgres: postgres,
  snowflake: snowflake,
  s3: s3,
};

export const getIconByDatastoreType = (datastoreType, className) => {
  if (datastoreType === "dbt") {
    return (
      <div className={className} style={{ width: "1.8em", height: "1.8em" }}>
        <DBTIcon />
      </div>
    );
  }
  const icon = iconMap[datastoreType];
  if (!icon) return null;

  return (
    <img src={icon} alt={capitalize(datastoreType)} className={className} />
  );
};
