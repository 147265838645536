import { Button, Card, CardBody, Form, Input, Label } from "reactstrap";
import styles from "./styles.module.scss";
import { useMutation, useQuery } from "react-query";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { ArrayInput } from "../../Components/Form/Input";
import { MarkdownEditor } from "../../Components/MarkdownEditor";
import {
  createExperiment,
  getQueryGroupDetail,
  getSchemaMetadata,
  getTableInsightsById,
  getWarehouseInsights,
  getWarehouseMetadata,
} from "../../helpers/backend_helper";
import { getUser } from "../../services/auth";
import { useLocation, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import {
  EntityTypeLabels,
  GET_SCHEMA_METADATA,
  GET_WAREHOUSE_METADATA,
} from "./constants";
import { Dropdown } from "../../Components/Form";
import { RelativeComponentLoader } from "../../Components/Loader/Loader";

const ExperimentationAdd = () => {
  const [name, setName] = useState("");
  const [experimentStart, setExperimentStart] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const [experimentEnd, setExperimentEnd] = useState(
    dayjs().format("YYYY-MM-DD")
  );
  const warehouseRef = useRef();
  const schemaRef = useRef();
  const warehouseInsightRef = useRef();
  const datasetsInsightRef = useRef();
  const groupsInsightRef = useRef();
  const metricsRef = useRef();
  const hypothesisRef = useRef();
  const instructionRef = useRef();
  const user = getUser();
  const [warehouseInsightsOptions, setWarehouseInsightsOptions] = useState([]);
  const [datasetsInsightsOptions, setDatasetsInsightsOptions] = useState([]);
  const [groupsInsightsOptions, setGroupsInsightsOptions] = useState([]);
  const [entities, setEntities] = useState({ entityType: "" });
  const [error, setError] = useState("");
  const [effortLevel, setEffortlevel] = useState("");
  const { state } = useLocation();
  useEffect(() => {
    console.log("locationState:", state);
    if (state?.warehouse) {
      setEntities({ entityType: "warehouse", warehouse: [state.warehouse] });
    } else if (state?.datasets) {
      setEntities({
        entityType: "datasets",
        datasets: state.datasets,
        name: state.name,
      });
      getTableInsightsById(state.tableId).then((data) => {
        setDatasetsInsightsOptions(
          (data.tags || []).map((t) => ({ label: t.label, value: t.label }))
        );
      });
    } else if (state?.queryHash) {
      setEntities({
        entityType: "query_groups",
        queryHash: state.queryHash,
        name: state.name,
      });
      getQueryGroupDetail(state.queryHash).then((data) => {
        setGroupsInsightsOptions(
          data.tags.map((t) => ({ label: t.tag_rk, value: t.tag_rk }))
        );
      });
    } else if (state?.jobId) {
      setEntities({
        entityType: "query_jobs",
        jobId: state.jobId,
        name: state.name,
      });
    }
  }, []);
  const navigate = useNavigate();

  const { data: warehouseMetadata, isLoading: isWarehouseLoading } = useQuery({
    queryKey: [GET_WAREHOUSE_METADATA],
    queryFn: () => getWarehouseMetadata(),
  });
  const { data: schemaMetadata, isLoading: isSchemaLoading } = useQuery({
    queryKey: [GET_SCHEMA_METADATA],
    queryFn: () => getSchemaMetadata(),
  });

  const { mutate } = useMutation({
    mutationFn: () => {
      setError("");
      const entity_type = entities?.entityType;
      if (!entity_type) {
        setError("Please select entity type");
        return;
      }
      let _entities = [];
      if (entity_type === "warehouse") {
        _entities = warehouseRef.current.getValue().map((e) => ({
          entity_rk: e.value,
          entity_type,
          entity_name: e.label,
        }));
        if (_entities.length > 0) {
          const _insights = warehouseInsightRef.current.getValue();
          _entities.forEach((e) => {
            e["insights"] = _insights
              .filter((i) => i._value.warehouse_rk === e.id)
              .map((i) => ({ tag_rk: i._value.tag_rk }));
          });
        }
      } else if (entity_type === "datasets") {
        const _insights = datasetsInsightRef.current.getValue();
        _entities = [
          {
            entity_rk: entities?.datasets,
            entity_type,
            entity_name: entities?.name,
            insights: _insights.map((i) => ({ tag_rk: i.value })),
          },
        ];
      } else if (entity_type === "query_groups") {
        const _insights = groupsInsightRef.current.getValue();
        _entities = [
          {
            entity_rk: entities?.queryHash,
            entity_type,
            entity_name: entities?.name,
            insights: _insights.map((i) => ({ tag_rk: i.value })),
          },
        ];
      } else if (entity_type === "query_jobs") {
        _entities = [
          {
            entity_rk: entities?.jobId,
            entity_type,
            entity_name: entities?.name,
          },
        ];
      } else if (entity_type === "schema") {
        _entities = schemaRef.current.getValue().map((e) => ({
          entity_rk: e.value,
          entity_type,
          entity_name: e.label,
        }));
      }

      const hypothesis = hypothesisRef.current.getValue();
      const instructions = instructionRef.current.getValue();
      const _experimentStart = dayjs(experimentStart);
      const _experimentEnd = dayjs(experimentEnd);
      if (!hypothesis) {
        setError("Hypothesis is required");
        return;
      }

      if (!instructions) {
        setError("Instructions is required");
        return;
      }

      if (_experimentEnd.diff(_experimentStart) < 0) {
        setError("End date should be later than start date");
        return;
      }

      if (!effortLevel) {
        setError("Please select effort level for the experiment");
        return;
      }

      const metrics = metricsRef.current
        .getValue()
        .map((item) => ({ metric_type: item.value }));
      return createExperiment({
        name,
        hypothesis,
        experiment_start: _experimentStart.toISOString(),
        experiment_end: _experimentEnd.toISOString(),
        instructions,
        entities: _entities,
        metrics,
        effort_level: effortLevel,
      });
    },
    onSuccess: (data) => {
      if (data?.ok) {
        navigate("/experimentation");
      }
    },
  });

  if (isWarehouseLoading || isSchemaLoading) {
    return <RelativeComponentLoader />;
  }
  return (
    <div className="d-flex flex-column">
      <h3 className="text-black">Experimentation</h3>
      <Card className="w-60">
        <CardBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              mutate();
            }}
            className={styles.form}
          >
            <div className="mb-3">
              <Label className="form-label" htmlFor="name">
                Name
              </Label>
              <Input
                className={classNames(styles.form_input)}
                id="name"
                name="name"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Entity type</Label>
              <Dropdown
                id="entity-type-dropdown"
                value={entities.entityType}
                options={["warehouse", "schema"].map((v) => ({
                  label: v,
                  value: v,
                }))}
                onChange={(v) =>
                  setEntities(
                    v === "warehouse"
                      ? { entityType: v, warehouse: [] }
                      : { entityType: v, schema: [] }
                  )
                }
              >
                <div className={classNames(styles.form_input)}>
                  {EntityTypeLabels[entities?.entityType] ||
                    "Select entity type"}
                </div>
              </Dropdown>
            </div>
            {entities?.entityType === "warehouse" && warehouseMetadata && (
              <>
                <div className="mb-3">
                  <Label className="form-label">Warehouses</Label>
                  <ArrayInput
                    ref={warehouseRef}
                    placeholder="Warehouse name"
                    defaultValue={entities?.warehouse || []}
                    options={warehouseMetadata.map((w) => ({
                      label: w.warehouse_name,
                      value: w.rk,
                    }))}
                    onChange={async (rks) => {
                      const _warehouseInsights = await getWarehouseInsights({
                        rks,
                      });
                      const _warehouseNameMap = {};
                      for (const w of warehouseMetadata) {
                        _warehouseNameMap[w.rk] = w.warehouse_name;
                      }
                      const warehouseOptions = [];
                      for (const item of _warehouseInsights) {
                        warehouseOptions[item.tag_rk] =
                          warehouseOptions[item.tag_rk] || [];
                        warehouseOptions[item.tag_rk].push(item.warehouse_rk);
                      }
                      setWarehouseInsightsOptions(
                        _warehouseInsights.map((w) => ({
                          label: `${w.tag_rk} (${
                            _warehouseNameMap[w.warehouse_rk]
                          })`,
                          value: `${w.tag_rk}-${w.warehouse_rk}`,
                          _value: w,
                        }))
                      );
                    }}
                  />
                </div>
                {warehouseInsightsOptions.length > 0 && (
                  <div className="mb-3">
                    <Label className="form-label">Insights</Label>
                    <ArrayInput
                      ref={warehouseInsightRef}
                      placeholder="Insight name"
                      options={warehouseInsightsOptions}
                    />
                  </div>
                )}
              </>
            )}

            {entities?.entityType === "schema" && schemaMetadata && (
              <>
                <div className="mb-3">
                  <Label className="form-label">Schemas</Label>
                  <ArrayInput
                    ref={schemaRef}
                    placeholder="Schema name"
                    defaultValue={entities?.schema || []}
                    options={schemaMetadata.map((w) => ({
                      label: w.name,
                      value: w.rk,
                    }))}
                  />
                </div>
              </>
            )}

            {entities?.entityType === "datasets" && (
              <>
                <div className="mb-3">
                  <Label className="form-label">Datasets</Label>
                  <Input
                    className={classNames(styles.form_input)}
                    id="datasets"
                    name="datasets"
                    value={entities?.name}
                    disabled
                  />
                </div>
                {datasetsInsightsOptions.length > 0 && (
                  <div className="mb-3">
                    <Label className="form-label">Insights</Label>
                    <ArrayInput
                      ref={datasetsInsightRef}
                      placeholder="Insight name"
                      options={datasetsInsightsOptions}
                    />
                  </div>
                )}
              </>
            )}

            {entities?.entityType === "query_groups" && (
              <>
                <div className="mb-3">
                  <Label className="form-label">Query Groups</Label>
                  <Input
                    className={classNames(styles.form_input)}
                    id="query_groups"
                    name="query_groups"
                    value={entities?.name}
                    disabled
                  />
                </div>
                {groupsInsightsOptions.length > 0 && (
                  <div className="mb-3">
                    <Label className="form-label">Insights</Label>
                    <ArrayInput
                      ref={groupsInsightRef}
                      placeholder="Insight name"
                      options={groupsInsightsOptions}
                    />
                  </div>
                )}
              </>
            )}

            {entities?.entityType === "query_jobs" && (
              <div className="mb-3">
                <Label className="form-label">Query Jobs</Label>
                <Input
                  className={classNames(styles.form_input)}
                  id="query_jobs"
                  name="query_jobs"
                  value={entities?.name}
                  disabled
                />
              </div>
            )}

            <div className="mb-3 d-flex gap-sm">
              <div>
                <Label className="form-label">Owner</Label>
                <Input
                  className={classNames(styles.form_input)}
                  id="owner"
                  name="owner"
                  value={user.first_name}
                  disabled
                />
              </div>
              <div className="flex-grow">
                <Label className="form-label">Effort Level</Label>
                <Dropdown
                  id="experiment-add-dropdown-effort-level"
                  value={effortLevel}
                  options={[
                    { label: "Low", value: "low" },
                    { label: "Medium", value: "medium" },
                    { label: "High", value: "high" },
                  ]}
                  onChange={setEffortlevel}
                >
                  <div className={classNames(styles.form_input)}>
                    {effortLevel || "Select effort level"}
                  </div>
                </Dropdown>
              </div>
            </div>
            <div className="mb-3">
              <Label className="form-label">Hypothesis/Reason</Label>
              <div className={styles.markdown_input}>
                <MarkdownEditor ref={hypothesisRef} />
              </div>
            </div>
            <div className="mb-3">
              <Label className="form-label">Metrics</Label>
              <ArrayInput
                ref={metricsRef}
                placeholder="Metrics name"
                options={[
                  { label: "Cost", value: "cost" },
                  {
                    label: "Total Execution Time",
                    value: "total_execution_time",
                  },
                ]}
              />
            </div>
            <div className="mb-3">
              <Label className="form-label">Period</Label>
              <div className="d-flex gap-lg w-100">
                <div className="d-flex gap-xs align-items-center">
                  <div>From:</div>
                  <Input
                    className={classNames(styles.form_input)}
                    id="experiment_start"
                    name="experiment_start"
                    type="date"
                    value={experimentStart}
                    onChange={(e) => setExperimentStart(e.target.value)}
                  />
                </div>
                <div className="d-flex gap-xs align-items-center">
                  <div>To:</div>
                  <Input
                    className={classNames(styles.form_input)}
                    id="experiment_end"
                    name="experiment_end"
                    type="date"
                    value={experimentEnd}
                    onChange={(e) => setExperimentEnd(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <Label className="form-label">Instructions</Label>
              <div className={styles.markdown_input}>
                <MarkdownEditor ref={instructionRef} />
              </div>
            </div>
            <div className={styles.buttons}>
              {error && <div className={styles.error_msg}>{error}</div>}
              <div className="spacer" />
              <Button
                className={styles.cancel_button}
                outline
                onClick={() => navigate("/experimentation")}
              >
                <span className={styles.text}>Cancel</span>
              </Button>
              <Button type="submit" className={styles.save_button}>
                <span className={styles.text}>Save</span>
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export { ExperimentationAdd };
