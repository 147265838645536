import { Nav, NavItem, NavLink, NavProps } from "reactstrap";

interface Props extends NavProps {
  items: { label: string; value: string }[];
  selectedTab: string;
  onTabSelect: (value: string) => void;
  pills?: boolean;
}

const Tabs = ({ items, selectedTab, onTabSelect, pills = true, ...rest }: Props) => {
  return (
    <Nav fill pills={pills} {...rest}>
      {items.map((tab) => (
        <NavItem key={tab.value}>
          <NavLink
            active={selectedTab === tab.value}
            onClick={() => onTabSelect(tab.value)}
          >
            {tab.label}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};

export default Tabs;
