import React, {
  createContext,
  useContext,
  useState,
  useReducer,
  useRef,
} from "react";
import { ACTIONS, getInitialState, processChatItem, reducer } from "./state";
import { useBoolean } from "../../helpers/hooks";

const CopilotContext = createContext();

export const CopilotProvider = ({ children }) => {
  const [workflow, setWorkflow] = useState("");
  const [tasks, setTasks] = useState([]);
  const [taskStatus, setTaskStatus] = useState({});
  const [resource, setResource] = useState(null);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [premium, setPremium] = useState(false);
  const [config, setConfig] = useState({});
  const [state, dispatch] = useReducer(reducer, null, getInitialState);
  const { value: autoApprove, toggle: toggleAutoApprove } = useBoolean(false);
  const scrollRef = useRef(null);

  const getLoaderArgs = (args, label) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!args.hasOwnProperty("approval")) return { label };
    if (args.approval) return { label };
    return { label: "Proceeding as per user suggestions" };
  };

  const getErrorMessage = (error) => {
    if (!error) return "Unknown error occurred!";
    if (error === "Network Error")
      return "Network issue. Please refresh the page.";
    return error;
  };

  const contextValue = {
    workflow,
    setWorkflow,
    tasks,
    setTasks,
    taskStatus,
    setTaskStatus,
    state,
    autoApprove,
    resource,
    showSuggestions,
    premium,
    config,
    scrollRef,
    setPremium,
    setShowSuggestions,
    setResource,
    toggleAutoApprove,
    dispatch,
    getLoaderArgs,
    getErrorMessage,
    setConfig,
  };

  return (
    <CopilotContext.Provider value={contextValue}>
      {children}
    </CopilotContext.Provider>
  );
};

export const useCopilot = () => {
  const context = useContext(CopilotContext);
  if (!context) {
    throw new Error("useCopilot must be used within a CopilotProvider");
  }
  return context;
};
