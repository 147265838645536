import { useQuery } from "react-query";
import { ReactNode, useRef, useState } from "react";
import { Popover, PopoverBody } from "@uicore";
import { ComponentLoader } from "@/Components/Loader";
import classes from "./styles.module.scss";

interface Props<T> {
  text: string;
  apiFn: (text: string, apiParams?: Record<string, unknown>) => unknown;
  children: (data: { data: T }) => ReactNode;
  queryKey: string;
  apiParams?: Record<string, unknown>;
  textClassName?: string;
  loadingLabel: string;
}

const AsyncPopover = <T,>({
  text,
  apiFn,
  children,
  queryKey,
  apiParams,
  textClassName,
  loadingLabel,
}: Props<T>) => {
  const [showPopover, setShowPopover] = useState(false);
  const timer = useRef<number | null>(null);
  const ref = useRef<HTMLSpanElement>(null);
  const { data, isFetching, refetch } = useQuery({
    queryKey: [queryKey, text],
    queryFn: () => apiFn(text, apiParams),
    enabled: false,
    staleTime: 10000,
  });

  const onMouseOut = () => {
    if (timer.current) {
      window.clearTimeout(timer.current);
    }
    timer.current = window.setTimeout(() => {
      setShowPopover(false);
    }, 200);
  };

  const onHover = () => {
    if (timer.current) {
      window.clearTimeout(timer.current);
    }
    timer.current = window.setTimeout(() => {
      setShowPopover(true);
      refetch();
    }, 200);
  };

  const onMouseOverPopover = () => {
    if (timer.current) {
      window.clearTimeout(timer.current);
    }
  };

  if (!text) {
    return null;
  }

  return (
    <>
      {ref.current ? (
        <Popover
          target={ref.current}
          isOpen={showPopover}
          className={classes.asyncPopover}
        >
          <PopoverBody onMouseOut={onMouseOut} onMouseOver={onMouseOverPopover}>
            {isFetching ? (
              <div className={classes.loader}>
                <ComponentLoader label={loadingLabel} />
              </div>
            ) : null}
            {data ? children({ data: data as T }) : null}
          </PopoverBody>
        </Popover>
      ) : null}
      <span className={textClassName} ref={ref} onMouseOver={onHover} onMouseOut={onMouseOut}>
        {text}
      </span>
    </>
  );
};

export default AsyncPopover;
