import { Badge as ReBadge, BadgeProps } from "reactstrap";
import Tooltip from "../tooltip/Tooltip";

interface Props extends BadgeProps {
  tooltip: Parameters<typeof Tooltip>[0]["content"];
}
const Badge = ({ tooltip, ...props }: Props) => {
  return (
    <Tooltip content={tooltip} placement="auto">
      <ReBadge {...props} />
    </Tooltip>
  );
};

export default Badge;
