import { Modal, ModalBody } from "reactstrap";
import contract_icon from "../../assets/icons/contract_icon.svg";
import classnames from "classnames";
import styles from "./styles.module.scss";
import close from "../../assets/icons/close.svg";
import { CueCodeEditor } from "../../Components/CodeEditor";
import { CopyButton } from "../../Components/CopyButton";

const Code = ({ isOpen, toggle, contract }) => {
  return (
    <Modal size="xl" isOpen={isOpen} toggle={toggle} centered>
      <ModalBody>
        <div className="mb-4 d-flex justify-content-between align-items-start">
          <div className="d-flex align-items-center">
            <div
              className={classnames("me-2", styles.icon_bg, {
                [styles.active]: contract.status === "active",
                [styles.inactive]: contract.status === "inactive",
              })}
            >
              <img src={contract_icon} alt="" />
            </div>
            <div className="me-4 fs-3 fw-semibold">{contract.name}</div>
            <CopyButton
              size="sm"
              value={contract.code}
              label="Copy Code"
              copiedLabel="Code Copied"
            />
          </div>
          <img
            src={close}
            alt="Close"
            className="cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              toggle();
            }}
          />
        </div>
        <CueCodeEditor value={contract.code} />
      </ModalBody>
    </Modal>
  );
};

export { Code };
