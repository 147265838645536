export const GET_EXPERIMENTATION = "GET-EXPERIMENTATION";
export const GET_TABLE_ID = "GET-TABLE-ID";
export const GET_EXPERIMENTATION_SUMMARY = "GET-EXPERIMENTATION-SUMMARY";
export const GET_WAREHOUSE_METADATA = "GET-WAREHOUSE-METADATA";
export const GET_SCHEMA_METADATA = "GET-SCHEMA-METADATA";
export const EntityTypeLabels = {
  warehouse: "Warehouse",
  datasets: "Datasets",
  query_jobs: "Query Jobs",
  query_groups: "Query Groups",
  schema: "Schema",
};
