import { Button } from "reactstrap";
import styles from "./styles.module.scss";
import classnames from "classnames";
import no_dbt_integration from "../../assets/images/no_dbt_integration.svg";
import { Link } from "react-router-dom";

const NoDbtIntegration = () => {
  return (
    <div className={styles.no_dbt_integration}>
      <div
        className={classnames(
          "d-flex flex-column align-items-center p-4",
          styles.container
        )}
      >
        <img
          src={no_dbt_integration}
          alt="No dbt Integration"
          className={styles.img}
        />
        <h2 className="mt-4 mb-4 text-black">No dbt Core Integration Found</h2>
        <p className="text-muted text-center">
          Please integrate dbt core to use this agent
        </p>
        <Link to="/settings/integrations">
          <Button color="primary">Add Integration</Button>
        </Link>
      </div>
    </div>
  );
};

export { NoDbtIntegration };
