import { getQueryTextByHash } from "@/helpers/apis";
import { AsyncPopover } from "@uicore";
import classes from "./styles.module.scss";
import { CodeBlock } from "@components";
import { QUERY_TEXT_API_KEY } from "./constants";
import { QueryTextResponse } from "./types";

interface Props {
  queryText: string;
  queryHash: string;
}

const QueryTextWithHover = ({ queryText, queryHash }: Props) => {
  const stopPropagation = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <AsyncPopover<QueryTextResponse>
      queryKey={QUERY_TEXT_API_KEY}
      text={queryText}
      apiFn={() => getQueryTextByHash(queryHash)}
      loadingLabel="Fetching query text"
    >
      {({ data }) => {
        return (
          <div onClick={stopPropagation}>
            <h3>Sql text</h3>
            <div>
            <CodeBlock
                code={data.query_text || queryText}
                language="sql"
                fileName=""
                className={classes.queryText}
                showLineNumbers={false}
                theme="dark"
                editorTheme="tomorrow"
                titleActions={null}
              />
            </div>
          </div>
        );
      }}
    </AsyncPopover>
  );
};

export default QueryTextWithHover;
