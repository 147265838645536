import { useNavigate, useParams } from "react-router-dom";
import { NotificationList } from "./NotificationList";
import styles from "./styles.module.scss";
import { Card, CardBody } from "reactstrap";
import { Paginate } from "../../Components/Paginate";
import { DebouncedInput } from "../../Components/Form";
import { AlertBox } from "./AlertBox";
import { useAlertNotificationsFilter } from "../../services/filters";
import { NoNotification } from "./NoNotification";
import { ComponentLoader } from "../../Components/Loader";

const AlertNotifications = () => {
  const { alertId } = useParams();

  let { notifications, isFilter, sort, timeFilter, paginate, isLoading } =
    useAlertNotificationsFilter({ alertId });

  if (paginate.itemCount === 0 && !isFilter) {
    return <NoNotification />;
  }

  return (
    <>
      <div className="h-100 d-flex flex-column">
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <h3 className="text-black">Issues</h3>
          <AlertBox />
        </div>
        {/* <Summary /> */}
        {isLoading ? (
          <ComponentLoader top={60} left={60} label="Loading issues..." />
        ) : (
          <div className={styles.listing}>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between align-items-center">
                  {/* <div className="d-flex gap-lg align-items-center">
                    <div className="text-black fs-4">Issues</div>
                  </div> */}
                  <div className="invisible">
                    <DebouncedInput
                      size="sm"
                      placeholder="Search"
                      onChange={() => {}}
                    />
                  </div>
                </div>

                <NotificationList
                  notifications={notifications}
                  sort={sort}
                  timeFilter={timeFilter}
                  onNotificationClick={() => {}}
                />
                <Paginate {...paginate} />
              </CardBody>
            </Card>
          </div>
        )}
      </div>
    </>
  );
};

export { AlertNotifications };
