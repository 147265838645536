const tzOffset = new Date().getTimezoneOffset() * 60000;

/**
 * converts date to start of the day without timezone offset
 * This is for getting iso string as 2024-09-02T00:00:00.000Z irrespective of timezone when user select a date
 * @param date 
 * @returns 
 */
export const getDateByStartOfDayWithoutTimeoffset = (date: Date) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return new Date(d.getTime() - tzOffset);
};

export const getDateByEndOfDayWithoutTimeoffset = (date: Date | null) => {
  if (!date) {
    return;
  }

  const d = new Date(date);
  d.setHours(23, 59, 59, 59);
  return new Date(d.getTime() - tzOffset);
};
