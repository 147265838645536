import { Dropdown } from "@uicore";
import { AggregateByValues } from "./constants";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";

interface Props {
  onSelect: (value: string) => void;
  aggregateBy?: string;
  isFuture?: boolean;
  startDate?: Date;
  endDate?: Date | null;
}
const AggregateFilter = ({
  onSelect,
  aggregateBy,
  isFuture,
  startDate,
  endDate,
}: Props) => {
  const handleItemClick = (value: string) => {
    onSelect(value);
  };

  const filterOptionsByRange = (
    options: { label: string; value: string }[],
    isMoreThanRange: boolean,
    ignoredOption: string
  ) => {
    if (!isMoreThanRange) {
      return options.filter((option) => option.value !== ignoredOption);
    }
    return options;
  };

  const filteredOptions = useMemo(() => {
    const isMoreThanOneMonth =
      dayjs(endDate).diff(dayjs(startDate), "month") > 1;
    const isMoreThanAWeek = dayjs(endDate).diff(dayjs(startDate), "days") > 7;

    const options = isFuture
      ? [{ label: "Monthly", value: AggregateByValues.Monthly }]
      : Object.entries(AggregateByValues).map(([label, value]) => ({
          label,
          value,
        }));

    // If the date range is less than one month or on week, we should not show the monthly/weekly option
    return filterOptionsByRange(
      filterOptionsByRange(options, isMoreThanAWeek, AggregateByValues.Weekly),
      isMoreThanOneMonth,
      AggregateByValues.Monthly
    );
  }, [startDate, endDate, isFuture]);

  // if user selected monthly and the date range is less than one month, we should reset to first option
  useEffect(() => {
    if (!filteredOptions.find((option) => option.value === aggregateBy)) {
      onSelect(filteredOptions[0].value);
    }
  }, [filteredOptions, aggregateBy]);

  return (
    <Dropdown
      value={aggregateBy}
      onOptionSelect={handleItemClick}
      prefix="Aggregate:"
      options={filteredOptions}
    />
  );
};

export default AggregateFilter;
