import { Button, Card, CardBody } from "reactstrap";
import styles from "./styles.module.scss";
import { Paginate } from "../../Components/Paginate";
import dayjs from "dayjs";
import classnames from "classnames";
import { getScopeString } from "../../helpers/utils";
import { getIconByDatastoreType } from "./DatastoreImage";
import { CopyIconButton } from "../../Components/CopyButton";
import { useMemo } from "react";
import { ColorTag } from "../../Components/Tags";
import { BetterTooltip } from "../../Components/Tooltip";
import { CollapsibleField } from "../../Components/Text";

const colors = ["blue", "orange", "yellow", "purple", "dark_green"];
const colorsMap = {};
let i = 0;

const TableItem = ({ table, onItemClick, onDetailsClick, select }) => {
  let matchStrs = [];
  if (table.matches.table_name) matchStrs.push("Table name");
  if (table.matches.table_purpose_match) matchStrs.push("Table description");
  if (table.matches.column_names > 0) {
    matchStrs.push(`${table.matches.column_names} column names`);
  }
  if (table.matches.column_description_matches > 0) {
    matchStrs.push(
      `${table.matches.column_description_matches} column descriptions`
    );
  }
  const matchStr = matchStrs.join(", ");

  const contractText = useMemo(
    () => [...new Set(table.contracts.map((c) => c.name))].join(", "),
    [table]
  );
  const scope = getScopeString(table.scope);

  return (
    <div
      className={styles.card}
      onClick={(e) => {
        e.stopPropagation();
        onItemClick?.(table);
      }}
    >
      <div className="d-flex">
        {getIconByDatastoreType(table.datasource_type, "me-2 icon-md")}
        <div className="text-primary fs-5 text-overflow">{table.name}</div>
      </div>
      <div className="d-flex align-items-center mt-2 mb-2">
        <div className="me-2 text-overflow">{scope}</div>
        <CopyIconButton value={scope} color="rgba(8, 34, 71, 0.5)" isLight />
      </div>
      <div className="d-flex mt-2 mb-2">
        <div className="me-2">Total Columns:</div>
        <div className="text-muted">{table.columns.length}</div>
      </div>
      <CollapsibleField label="Contracts" text={contractText} />
      {table.purpose && (
        <CollapsibleField
          label="Description"
          text={table.purpose}
          defaultLines={3}
        />
      )}
      <div className="d-flex mt-2 mb-2">
        {table.last_violation_timestamp ? (
          <>
            <div className="me-2">Last Validation:</div>
            <div>
              <span
                className={classnames(
                  "text-underline me-2 cursor-pointer",
                  table.total_violations === 0 ? "level-green" : "level-orange"
                )}
                onClick={(e) => {
                  e.stopPropagation();
                  onItemClick?.(table);
                }}
              >
                {table.total_violations} Incidents
              </span>
              <span className="text-muted">
                (
                {dayjs(table.last_violation_timestamp).format(
                  "DD MMM YYYY, hh:mm A"
                )}
                )
              </span>
            </div>
          </>
        ) : (
          <>
            <div className="me-2">Incidents:</div>
            <div className="text-muted">No validation</div>
          </>
        )}
      </div>
      {table.tags.length > 0 && (
        <div className="mt-2 d-flex gap-sm">
          <div className="me-2">Tags:</div>
          <div className="d-flex flex-wrap gap-sm">
            {table.tags.map((t) => {
              if (!colorsMap[t.name])
                colorsMap[t.name] = colors[i++ % colors.length];
              return (
                <BetterTooltip tooltipContent={t.description} key={t.name}>
                  <ColorTag color={colorsMap[t.name]} label={t.name} />
                </BetterTooltip>
              );
            })}
          </div>
        </div>
      )}
      <div className="mt-2 d-flex gap-sm align-items-center">
        {matchStr && (
          <div className="d-flex">
            <div className="me-2">Matches:</div>
            <div className="text-primary">{matchStr}</div>
          </div>
        )}
        <div className="spacer" />
        {select && (
          <Button
            outline={select.outline}
            color={select.color}
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              select.onSelect(table);
            }}
          >
            {select.label}
          </Button>
        )}
        {onDetailsClick && (
          <Button
            color="primary"
            size="sm"
            onClick={(e) => {
              e.stopPropagation();
              onDetailsClick(table);
            }}
          >
            View Details
          </Button>
        )}
      </div>
    </div>
  );
};

const NoSearchResults = ({ search }) => (
  <div className={styles.card}>
    <div className="fs-4 fw-semibold mb-2">
      No results found for : <span className="text-primary">{search}</span>
    </div>
    <div className="fs-5 mb-2">Search help</div>
    <ul className="text-muted mb-2">
      <li>Check your search for typos</li>
      <li>Use more specific search terms</li>
    </ul>
  </div>
);

const TableList = ({
  search,
  tables = [],
  onItemClick,
  paginate,
  onDetailsClick,
  select,
}) => (
  <div className={styles.table_list}>
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center">
          <div className="fs-5 text-muted">{paginate.itemCount} results</div>
        </div>
        {search && paginate.itemCount === 0 ? (
          <NoSearchResults search={search} />
        ) : (
          tables.map((table) => (
            <TableItem
              key={table.id}
              table={table}
              onItemClick={onItemClick}
              onDetailsClick={onDetailsClick}
              select={select}
            />
          ))
        )}
        <Paginate {...paginate} />
      </CardBody>
    </Card>
  </div>
);

export { TableList };
