import { useEffect, useRef } from "react";

export const DynamicHTML = ({ htmlContent }: { htmlContent: string }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current) {
      const links = containerRef.current?.getElementsByTagName("a");
      Array.from(links).forEach((link) => {
        link.addEventListener("click", (event) => {
          event.stopPropagation();
          if (!link.target) {
            link.target = "_blank";
          }
          if (!link.rel) {
            link.rel = "noopener noreferrer";
          }
        });
      });
    }
  }, [htmlContent]);

  return (
    <div ref={containerRef} dangerouslySetInnerHTML={{ __html: htmlContent }} />
  );
};
