import { Card, CardBody } from "reactstrap";
import styles from "./styles.module.scss";
import { default as BotImage } from "../../assets/icons/bot.svg?react";

const RobotBGCard = ({ children }) => {
  return (
    <Card>
      <CardBody className="p-4 position-relative">
        <div className={styles.content}>{children}</div>
        <BotImage className={styles.bot_image} />
        <div className={styles.background}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="184"
            height="100%"
            viewBox="0 0 184 248"
            fill="none"
          >
            <path
              d="M413.675 -18.9897C475.807 80.788 438.09 216.524 329.432 284.186C220.774 351.847 -78.5518 435.624 20.1902 226.034C118.932 16.4444 -4.22448 -9.47988 104.433 -77.1413C213.091 -144.803 351.544 -118.767 413.675 -18.9897Z"
              fill="url(#paint0_linear_8622_1359)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_8622_1359"
                x1="112.93"
                y1="21.1516"
                x2="497.009"
                y2="-25.6795"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#096CD7" />
                <stop offset="1" stop-color="#D9C1FF" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </CardBody>
    </Card>
  );
};

export { RobotBGCard };
