import { User } from "../types";
import { getTenant } from "../helpers/tenant";
import { posthogIdentify } from "./logging";
import { setAuthorizationHeader } from "@/helpers/api_helper";

const AUTH_USER = "authUser";
const REMEMBER_ME = "rememberMe";
const LAST_LOGIN = "lastLogin";

const REMEMBER_DURATION = 6 * 60 * 60 * 1000;

export const setRememberMe = (rememberMe: boolean) => {
  localStorage.setItem(REMEMBER_ME, `${rememberMe}`);
};

export const loginUser = (user: User) => {
  saveUser(user);
  localStorage.setItem(LAST_LOGIN, `${Date.now()}`);
  posthogIdentify(user);
  setAuthorizationHeader(user.auth_token);
};

export const saveUser = (user: User) => {
  if ("role_FK" in user && user["role_FK"]) {
    user["permission"] = user["role_FK"]["permission"].map((item) => item.name);
    // @ts-expect-error valid
    delete user["role_FK"]["permission"];
  }
  // Public admin doesn't have any permissions.
  if (getTenant() === "") user["permission"] = null;
  localStorage.setItem(AUTH_USER, JSON.stringify(user));
};

export const getUser = () => {
  const rememberMe = JSON.parse(localStorage.getItem(REMEMBER_ME) || "false");
  if (!rememberMe) {
    const lastLogin = localStorage.getItem(LAST_LOGIN);
    if (lastLogin && Date.now() - parseInt(lastLogin) > REMEMBER_DURATION) {
      localStorage.removeItem(AUTH_USER);
      return null;
    }
  }
  const user = localStorage.getItem(AUTH_USER);
  if (!user) return null;
  const parsed_user = JSON.parse(user);
  if (!parsed_user || !("permission" in parsed_user)) {
    logoutUser();
  }
  return parsed_user;
};

export const logoutUser = () => {
  localStorage.removeItem(AUTH_USER);
};

export const savePermissions = (permissions: User["permission"]) => {
  const user = getUser();
  user["permission"] = permissions?.map((item) =>
    typeof item !== "string" ? item.name : null
  );
  saveUser(user);
};
