import { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { default as CloseIcon } from "../../assets/icons/close.svg?react";
import { DebouncedInput } from "../../Components/Form";
import { useDatasetFilter } from "../../services/filters";
import { Filter, TableList } from "../Datasets";
import styles from "./styles.module.scss";
import { ComponentLoader } from "../../Components/Loader";
import classnames from "classnames";
import { ScrollContainer } from "../../Components/ScrollContainer";

const DatasetModal = ({ isOpen, toggle, onSelect }) => {
  const [search, setSearch] = useState("");
  const { filter, tables, paginate, isLoading } = useDatasetFilter({
    search,
  });
  const tableListProps = {
    paginate,
    tables,
    search,
    select: {
      label: "Generate Contract",
      color: "success",
      outline: false,
      onSelect: (t) => {
        onSelect(t);
        toggle();
      },
    },
  };

  return (
    <Modal
      isOpen={isOpen}
      size="xl"
      centered
      toggle={toggle}
      contentClassName="border-0"
    >
      <ModalBody className={classnames("bg-light br-3", styles.modal_body)}>
        <div className="mb-3 d-flex justify-content-between">
          <h3 className="text-black">Select Datasets</h3>
          <CloseIcon
            className="cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              toggle();
            }}
          />
        </div>
        <DebouncedInput
          size="lg"
          placeholder="Search for Datasets and Columns"
          onChange={setSearch}
          hideOutline
        />
        <div className="mb-3" />
        {isLoading ? (
          <ComponentLoader label="Loading datasets..." />
        ) : (
          <ScrollContainer>
            <div className={styles.dataset_container}>
              <Filter {...filter} />
              <TableList {...tableListProps} />
            </div>
          </ScrollContainer>
        )}
      </ModalBody>
    </Modal>
  );
};

export { DatasetModal };
