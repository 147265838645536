import SectionLoadingGif from "./section-loading.gif";
import ChartLoadingGif from "./chart-loading.gif";

const BreakdownSkeleton = ({
  type = "section",
}: {
  type?: "section" | "chart";
}) => {
  return (
    <img
      src={type === "section" ? SectionLoadingGif : ChartLoadingGif}
      alt="Loading"
      style={{width: "100%", height: "100%", objectFit: "cover"}}
    />
  );
};

export default BreakdownSkeleton;
