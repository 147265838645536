import { Card, CardBody } from "reactstrap";
import no_resource from "../../assets/images/no_resource.svg";
import styles from "./styles.module.scss";
import classnames from "classnames";
import { AlertBox } from "./AlertBox";

const NoNotification = () => {
  return (
    <div className={styles.no_rule}>
      <Card className={styles.card}>
        <CardBody className="d-flex justify-content-center">
          <div
            className={classnames(
              "d-flex flex-column align-items-center p-4",
              styles.container
            )}
          >
            <img
              src={no_resource}
              alt="No Issue"
              className={styles.img}
            />
            <h2 className="mt-4 mb-4 text-black">No Issue Found</h2>
            <AlertBox />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export { NoNotification };
