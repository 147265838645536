import styles from "./styles.module.scss";
import { Select } from "@/uiCore";
import { useOpportunities } from "../../useOpportunity";
import { getUser } from "@/services/auth";
import { useUsers } from "@/helpers/hooks";
import { useEffect, useState } from "react";

interface OwnerAssignerDropdownProps {
  opportunity_id: string;
  assignedOwner: string;
  setAssignedOwner: (status: string) => void;
  disableUpdateOnChange?: boolean;
  startDate: string;
  endDate: string;
}

interface HandleOwnerChangeParams {
  e: string | unknown;
  opportunity_id: string;
}

const OwnerAssignerDropdown = ({
  opportunity_id,
  assignedOwner,
  setAssignedOwner = () => {},
  disableUpdateOnChange = false,
  startDate,
  endDate,
}: OwnerAssignerDropdownProps) => {
  const { mutation } = useOpportunities("");
  const { mutate } = mutation;
  const user = getUser();
  const { users } = useUsers();
  const [userList, setUserList] = useState<{ label: string; value: string }[]>(
    []
  );

  useEffect(() => {
    if (users) {
      setUserList([
        {
          label: "Un-assigned",
          value: "-1",
        },
        ...users
          .filter(
            (user: { first_name: any; last_name: any }) =>
              user.first_name || user.last_name
          )
          .map((user: { first_name: any; last_name: any; id: any }) => ({
            label: `${user.first_name} ${user.last_name}`,
            value: user.id,
          })),
      ]);
    }
  }, [users]);

  const handleOwnerChange = ({
    e,
    opportunity_id,
  }: HandleOwnerChangeParams) => {
    mutate({
      changes: [
        {
          opportunity_id,
          owner: e as string,
          status: undefined,
        },
      ],
      user_id: user?.id,
      start_date: startDate,
      end_date: endDate,
    });
  };

  return (
    <Select
      id={`state-assigner`}
      onChange={(e) => {
        setAssignedOwner(e as string);
        if (!disableUpdateOnChange) {
          handleOwnerChange({ e, opportunity_id });
        }
      }}
      value={Number(assignedOwner)}
      options={userList}
      showDivider
      placeholder="Un-assigned"
      className={styles.bulk_select_dropdown}
    />
  );
};

export default OwnerAssignerDropdown;
