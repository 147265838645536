import styles from "./styles.module.scss";

import { StatusTagColors } from "../../constants";
import { StatusTagProps } from "./types";
import { capitalize } from "lodash";

const StatusTag = ({ status }: StatusTagProps) => {
  return (
    <div
      className={styles.statusTag}
      style={{
        backgroundColor:
          StatusTagColors[status as keyof typeof StatusTagColors].color,
        color:
          StatusTagColors[status as keyof typeof StatusTagColors].textColor,
      }}
    >
      {capitalize(status)}
    </div>
  );
};

export default StatusTag;
