import { Button } from "reactstrap";
import styles from "./styles.module.scss";
import premiumRobot from "@/assets/images/roboimagechat.svg";
import {
  isFreeStack,
  showEnterpriseEditionSuggestion,
  showPremiumDatapilot,
} from "../../helpers/tenant";
import { useIntercom } from "react-use-intercom";

const Suggestions = ({ workflow, handleSuggestionClick, premium = false }) => {
  const { show } = useIntercom();
  const suggestionData = getSuggestionDataByWorkflowType(workflow);

  if (!suggestionData?.type) {
    return null;
  }

  return (
    <>
      <div className={styles.suggestions_box}>
        <div className="d-flex flex-column">
          {suggestionData?.type == "text" ? (
            suggestionData?.data.map((item, i) => {
              return (
                <div key={i} className={styles.title}>
                  {item}
                </div>
              );
            })
          ) : (
            <>
              <div className={styles.title}>
                {suggestionData?.title || "Suggestions:"}
              </div>
              {suggestionData?.data.length !== 0 && (
                <div className={styles.subTitle}>
                  {suggestionData?.subTitle
                    ? suggestionData?.subTitle
                    : "Not sure where to start ? You can try,"}
                </div>
              )}
            </>
          )}

          {suggestionData?.type == "suggestion" &&
            suggestionData?.data.map((item, i) => {
              return (
                <div
                  className={styles.data}
                  key={i}
                  onClick={() => handleSuggestionClick(item)}
                >
                  <div className={styles.text}>{item}</div>
                </div>
              );
            })}
        </div>
      </div>
      {!showPremiumDatapilot() &&
        isFreeStack() &&
        premium &&
        showEnterpriseEditionSuggestion() && (
          <div className={styles.premium_suggestion}>
            <div className={styles.content}>
              <div className={styles.title}>
                Available in the enterprise edition
              </div>
              <div className={styles.text}>
                You need to upgrade to the enterprise edition to use this agent
              </div>
              <Button outline className={styles.button} onClick={show}>
                <div className={styles.contact}>Contact us</div>
              </Button>
            </div>
            <img src={premiumRobot} alt="premium" />
          </div>
        )}
    </>
  );
};

const getSuggestionDataByWorkflowType = (workflow) => {
  switch (workflow) {
    case "debug":
      return {
        type: "suggestion",
        title:
          "Data debug agent can be used to validate data in the specified tables.",
        data: [
          "Check for duplicate rows",
          "Email should be in the right format (regex check)",
          "payment column should have positive value, when status column is marked as paid",
        ],
      };

    case "snowflake_admin":
      return {
        title:
          "Snowflake admin agent can be used to get insights about your snowflake environment.",
        type: "suggestion",
        data: [
          "Find the top 10 stale tables in the last 30 days",
          "Identify my most expensive warehouses in the last 14 days",
          "Identify the users with the most expensive queries in the last 7 days",
          "Identify all users with the ACCOUNTADMIN role",
          "Identify all roles which altimate_user has access to",
        ],
      };

    case "rule_gen":
      return {
        type: "text",
        data: [
          "This agent generates recommendations for data contracts and quality tests.",
          "It can also generate the code for the same.",
        ],
      };

    case "query_explanation":
      return {
        type: "suggestion",
        title:
          "Generates summary and explanation for the query to understand it better.",
        data: [
          "select warehouse, sum(query_cost) as total_cost from warehouse_costs where date > dateadd(day, -5, CURRENT_DATE()) ORDER BY total_cost DESC",
          "SELECT * FROM employees WHERE job_title = 'Manager' ORDER BY last_name;",
        ],
      };

    case "dbt_gen":
      return {
        type: "suggestion",
        title:
          "This agent generates a dbt model from a SQL query. It also generates dbt tests.",
        data: [],
      };

    case "query_insights":
      return {
        type: "text",
        data: [
          "Query insights agent can be used to get performance and cost related insights about Snowflake queries.",
          "This information can be used to reduce snowflake costs. ",
        ],
      };
    case "doc_gen":
      return {
        type: "text",
        data: [
          "This agent generates descriptions for models and columns that include various details \
          including purpose of that particular table or column.",
        ],
      };
    case "alert_creation":
      return {
        type: "suggestion",
        title:
          "Alert creation agent can be used to create alerts on your data.",
        data: [
          "Check if cost for role ACCOUNTADMIN is greater than 1000 in the last 7 days",
          "Check if cost for warehouse {WH_NAME} is greater than 1000 in the last 7 days",
          "Check if cost of user {USER_NAME} is greater than 1000 in the last 7 days",
        ],
      };

    case "alert_creation_sidebar":
      return {
        type: "suggestion",
        title: "Alert Creation",
        subTitle:
          "Alert creation agent can be used to create alerts on your data.",
        data: [
          "Check if cost for role ACCOUNTADMIN is greater than 1000 in the last 7 days",
          "Check if cost for warehouse {WH_NAME} is greater than 1000 in the last 7 days",
          "Check if cost of user {USER_NAME} is greater than 1000 in the last 7 days",
        ],
      };
    case "alert_creation_query_sidebar":
      return {
        type: "suggestion",
        title: "Alert Creation",
        subTitle:
          "Alert creation agent can be used to create alerts on your data.",
        data: [
          "Check if total query cost is greater than 100$ in the last 30 days",
        ],
      };
    case "alert_creation_infra_sidebar":
      return {
        type: "suggestion",
        title: "Alert Creation",
        subTitle:
          "Alert creation agent can be used to create alerts on your data.",
        data: [
          "Check if total query cost is greater than 100$ in the last 30 days",
        ],
      };
    case "alert_creation_governance_sidebar":
      return {
        type: "suggestion",
        title: "Alert Creation",
        subTitle:
          "Alert creation agent can be used to create alerts on your data.",
        data: [
          "The user {user_name} exceeds ${cost} cost in the last 7 days",
          "The role {role_name} exceeds ${cost} cost in the last 7 days",
        ],
      };

    case "data_discovery":
      return {
        type: "text",
        data: [
          "Data discovery agent can be used to search for tables and columns in your data warehouse.",
        ],
      };

    case "lineage_explorer":
      return {
        type: "suggestion",
        title: "Lineage Explorer",
        data: [
          "What is the source of the TOTAL_PAID column in the `customers` model?",
        ],
      };

    default:
      return {};
  }
};

export { Suggestions };
