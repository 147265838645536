export const InfoMessages = {
  cancelSubscription:
    "Cancelling your subscription will result in loss of access to Premium tools and limit your execution capabilities",
  downgradeSubscription:
    "Your current subscription will be downgraded at the end of the current billing cycle.",
  upgradeSubscription:
    "Your new subscription will start from today, and you will be charged the new subscription price.",
  upgradeSubscriptionProration:
    "Your new subscription will start from today. You will be charged the difference between new subscription price and prorated current subscription price based on the no. of credits remaining.",
};
export const PieColorsMap = {
  remaining: "#247EFE",
  spent: "#FF754C",
  extra: "#ee140e",
  savedTime: "#0045CB",
  aboveAvgTime: "#EEEFF2",
};

export const UserColorsMap = [
  "#247efe",
  "#01cd8c",
  "#ffce73",
  "#2be2e5",
  "#144583",
];
