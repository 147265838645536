import React, { useEffect } from "react";
import { SqlLineage } from "../../../Components/Lineage";
import { getMoneySavings } from "@/pages/Explorer/utils";
import { useAppState } from "@/modules/app/useAppContext";
import classNames from "classnames";
import styles from "../styles.module.scss";
import { InfoIcon } from "@/assets/icons";

// Define the types for the props
type Report = {
  savings?: string;
  performance?: string;
};

type QueryTagMeta = {
  tag_rk: string;
  reports?: Report[];
  cost_savings_min: string;
  cost_savings_max: string;
  cost_savings_ui?: PercentValue;
  time_savings_ui?: PercentValue;
};

type SqlLineageProps = {
  query_tags_meta: { [key: string]: QueryTagMeta[] };
  details: { [key: string]: { node_cost?: number } };
  tableEdges: [string, string][];
  nodePositions: Record<string, [number, number]>;
  is_error: boolean;
};

type QuerySqlLineageProps = {
  sqlLineage: SqlLineageProps | null;
  selectedInsight: string | null;
  showSideContent: boolean;
  onNodeSelect: (node: string) => void;
};

type PercentValue = { percent: string; value: string; tooltip: string };

const QuerySqlLineage: React.FC<QuerySqlLineageProps> = ({
  sqlLineage,
  selectedInsight,
  showSideContent,
  onNodeSelect,
}) => {
  const { currency } = useAppState();
  useEffect(() => {
    if (!sqlLineage) return;

    const { query_tags_meta } = sqlLineage;
    if (selectedInsight) {
      const insightNodes: string[] = [];
      const nodesSavingsPerformance: Record<
        string,
        { savings?: PercentValue; performance?: PercentValue }
      > = {};

      for (const key in query_tags_meta) {
        for (const t of query_tags_meta[key] || []) {
          if (t.tag_rk !== selectedInsight) continue;
          insightNodes.push(key);
          if (t.cost_savings_ui || t.time_savings_ui) {
            nodesSavingsPerformance[key] = nodesSavingsPerformance[key] || {};
          }
          if (t.cost_savings_ui) {
            nodesSavingsPerformance[key]["savings"] = {
              percent: t?.cost_savings_ui?.percent,
              value: getMoneySavings(
                t.cost_savings_min,
                t.cost_savings_max,
                currency
              ),
              tooltip: t?.cost_savings_ui?.tooltip,
            };
          }
          if (t.time_savings_ui) {
            nodesSavingsPerformance[key]["performance"] = t.time_savings_ui;
          }
        }
      }

      document.dispatchEvent(
        new CustomEvent("onHighlightedNodes", { detail: insightNodes })
      );
      document.dispatchEvent(
        new CustomEvent("onNodesSavingsPerformance", {
          detail: nodesSavingsPerformance,
        })
      );
    } else {
      const nodesCost: { [key: string]: number } = {};
      for (const key in sqlLineage.details) {
        if (sqlLineage.details[key].node_cost)
          nodesCost[key] = sqlLineage.details[key].node_cost;
      }
      document.dispatchEvent(
        new CustomEvent("onNodesCost", { detail: nodesCost })
      );
    }
  }, [selectedInsight, sqlLineage]);

  useEffect(() => {
    document.dispatchEvent(new CustomEvent("fitView"));
  }, [showSideContent]);

  useEffect(() => {
    const handleSelectedNode = (e: CustomEvent) => {
      const node = e.detail;
      onNodeSelect(node);
      document.dispatchEvent(
        new CustomEvent("onSelectedNodes", { detail: node })
      );
    };

    // @ts-ignore
    document.addEventListener("selectedNode", handleSelectedNode);
    return () => {
      // @ts-ignore
      document.removeEventListener("selectedNode", handleSelectedNode);
    };
  }, []);

  if (!sqlLineage) return null;

  return (
    <div className="position-relative">
      {sqlLineage?.is_error && (
        <div
          className={classNames(
            "position-absolute d-flex gap-sm align-items-center",
            styles.sql_lineage_error_overlay
          )}
        >
          <InfoIcon />
          <div>
            Simplified view shown. Query contains unsupported keywords or
            constructs.
          </div>
        </div>
      )}
      <SqlLineage {...sqlLineage} height="50vh" externalSidePanel />
    </div>
  );
};

export { QuerySqlLineage };
