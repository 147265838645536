import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "./useAppContext";
import { setPreviousPath } from "./appSlice";

const LocationTracker = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [currentPath, setCurrentPath] = useState("");

  useEffect(() => {
    dispatch(setPreviousPath(currentPath));
    setCurrentPath(location.pathname);
  }, [location.pathname, dispatch]);
  return null;
};

export default LocationTracker;
