import { default as All } from "@/assets/icons/agent_categories/all.svg?react";
import { default as Snowflake } from "@/assets/icons/agent_categories/snowflake.svg?react";
import { default as Sql } from "@/assets/icons/agent_categories/sql.svg?react";
import { default as LookML } from "@/assets/icons/agent_categories/lookml.svg?react";
import { default as Airflow } from "@/assets/icons/agent_categories/airflow.svg?react";
import { default as Dbt } from "@/assets/icons/agent_categories/dbt.svg?react";

export const ALL = "All";
export const DBT = "dbt";
export const SQL = "SQL";
export const SNOWFLAKE = "Snowflake";
export const LOOKML = "Looker";
export const AIRFLOW = "Airflow";

export const AGENT_CATEGORIES = {
  ALL: {
    icon: All,
    label: ALL,
  },
  DBT: {
    icon: Dbt,
    label: DBT,
  },
  SQL: {
    icon: Sql,
    label: SQL,
  },
  SNOWFLAKE: {
    icon: Snowflake,
    label: SNOWFLAKE,
  },
  LOOKML: {
    icon: LookML,
    label: LOOKML,
  },
  AIRFLOW: {
    icon: Airflow,
    label: AIRFLOW,
  },
};

export const WORKFLOW_INPUT_TYPES = {
  SQL_QUERY: "sql_query",
  SQL_QUERY_TEXT: "sql_query_with_text",
  OLD_COPILOT: "old_copilot",
  DS_PURPOSE: "ds_purpose",
  SQL_QUERY_TAGS: "sql_query_tags",
  DOC_GEN: "doc_gen",
  ALERT_CREATION: "alert_creation",
  DBT_QUERY_TEXT: "dbt_query_text",
};

export const WORKFLOW_LABELS = {
  debug: "Data Debug",
  query_insights: "Query Insights",
  snowflake_admin: "Snowflake Admin",
  generic_python: "Generic Python Tool",
  rule_gen: "Data Contract Generation",
  queryDiff: "Query Diff",
  look_ml: "LookML Generation",
  airflow: "Airflow Config Generation",
  dbt_gen: "dbt Model Generation",
  doc_gen: "dbt Documentation",
  query_explanation: "Query Explanation",
  alert_creation: "Alert Creation",
  data_discovery: "Data Discovery",
  lineage_explorer: "Lineage Explorer",
};

export const WORKFLOWS = [
  {
    description: "Generate descriptions for models and columns",
    value: "doc_gen",
    enabled: true,
    showTags: false,
    enterprise: false,
    inputType: WORKFLOW_INPUT_TYPES.DOC_GEN,
    categories: [ALL, DBT],
  },
  {
    description:
      "Understand the query better with generated explanation and summary",
    value: "query_explanation",
    enabled: true,
    showTags: false,
    enterprise: false,
    inputType: WORKFLOW_INPUT_TYPES.SQL_QUERY_TEXT,
    categories: [ALL, SQL],
  },
  {
    description:
      "Helps you understand the lineage of your data and how it is connected",
    value: "lineage_explorer",
    enabled: true,
    showTags: false,
    enterprise: false,
    inputType: WORKFLOW_INPUT_TYPES.DBT_QUERY_TEXT,
    categories: [ALL],
  },
  {
    description: "Helps you discover data and understand it better",
    value: "data_discovery",
    enabled: true,
    showTags: false,
    freemiumEnterprise: true,
    enterprise: true,
    inputType: WORKFLOW_INPUT_TYPES.SQL_QUERY_TEXT,
    categories: [ALL, SNOWFLAKE],
  },
  {
    description:
      "Connect to different data sources and identify problems in the data",
    value: "debug",
    enabled: true,
    showTags: false,
    enterprise: true,
    inputType: WORKFLOW_INPUT_TYPES.DS_PURPOSE,
    categories: [ALL, SNOWFLAKE],
  },
  {
    description:
      "Get insights about your snowflake environment and identify problems",
    value: "snowflake_admin",
    enabled: true,
    showTags: false,
    enterprise: true,
    inputType: WORKFLOW_INPUT_TYPES.DS_PURPOSE,
    categories: [ALL, SNOWFLAKE],
  },
  // {
  //   description: "Get recommendations for data guardrails for specific tables",
  //   value: "rule_gen",
  //   enabled: true,
  //   enterprise: true,
  //   inputType: WORKFLOW_INPUT_TYPES.OLD_COPILOT,
  //   categories: [ALL, DBT],
  // },
  // {
  //   description: "Generate dbt model and tests",
  //   value: "dbt_gen",
  //   enabled: true,
  //   enterprise: true,
  //   inputType: WORKFLOW_INPUT_TYPES.SQL_QUERY,
  //   categories: [ALL, DBT],
  // },
  {
    description:
      "Get insights about your snowflake environment and identify problems",
    value: "query_insights",
    enabled: true,
    showTags: false,
    enterprise: true,
    inputType: WORKFLOW_INPUT_TYPES.SQL_QUERY_TAGS,
    categories: [ALL, SNOWFLAKE],
  },
  {
    description: "Create alerts from Altimate",
    value: "alert_creation",
    enabled: true,
    showTags: false,
    enterprise: true,
    inputType: WORKFLOW_INPUT_TYPES.ALERT_CREATION,
    categories: [ALL, SNOWFLAKE],
  },

  {
    description: "Helps you work with sql and python together",
    value: "generic_python",
    enabled: false,
    showTags: true,
    inputType: WORKFLOW_INPUT_TYPES.DS_PURPOSE,
    enterprise: true,
    categories: [ALL],
    label: "Coming Soon",
    label_color: "blue",
  },
  {
    description:
      "Do the diff between two queries to understand how query changed",
    value: "queryDiff",
    enabled: false,
    showTags: true,
    categories: [ALL, DBT],
    enterprise: true,
    label: "Coming Soon",
    label_color: "blue",
  },
  {
    description: "TBD",
    value: "look_ml",
    enabled: false,
    showTags: true,
    categories: [ALL, LOOKML],
    enterprise: true,
    label: "Coming Soon",
    label_color: "blue",
  },
  {
    description: "TBD",
    value: "airflow",
    enabled: false,
    showTags: true,
    enterprise: true,
    categories: [ALL, AIRFLOW],
    label: "Coming Soon",
    label_color: "blue",
  },
];

export const SAVED_TIME = [
  { label: "5 minutes", value: 5 },
  { label: "15 minutes", value: 15 },
  { label: "30 minutes", value: 30 },
  { label: "1 hour", value: 60 },
];

export const COLOR_PALETTE = [
  "#ffbe0b",
  "#fb5607",
  "#ff006e",
  "#8338ec",
  "#3a86ff",
];
