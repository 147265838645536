import * as Yup from "yup";
import MailChecker, { isValid } from "mailchecker";
import { freeEmailDomains } from "./freeEmailDomains";

const errorMessage =
  "Use 8 or more characters with a mix of letters, numbers & symbols";

export const passwordValidator = Yup.string()
  .min(8, errorMessage)
  .matches(/[a-zA-Z]/, errorMessage)
  .matches(/[0-9]/, errorMessage)
  .matches(/[!-/:-@[-`{-~]/, errorMessage)
  .required(errorMessage);

export const validateFormPassword = (password, setValidatePassword) => {
  let validPassword = [1, 1, 1, 1];
  if (password.length < 8) validPassword[0] = 0; // at least 8 characters
  if (!/[a-z]/.test(password)) validPassword[1] = 0; // at least 1 lower case characters
  if (!/[A-Z]/.test(password)) validPassword[1] = 0; // at least 1 upper case characters
  if (!/[0-9]/.test(password)) validPassword[2] = 0; // 1 or more numbers
  if (!/[!-/:-@[-`{-~]/.test(password)) validPassword[3] = 0; // 1 or more special characters
  setValidatePassword(validPassword);
};

export const isFormFieldError = (v) => (key) => v.touched[key] && v.errors[key];

export function isFreeEmailProvider(email) {
  if (email.includes("@")) {
    const domain = email.split("@")[1];
    return freeEmailDomains.includes(domain);
  }
  return false;
}

export const validateEmail = (e) => {
  if (!e) {
    return "";
  }
  if (isFreeEmailProvider(e)) {
    return "Please use a valid business email address";
  }
  if (!MailChecker.isValid(e)) {
    return "Please use a valid email address";
  }

  // Though single letter TLD is theoritically possible, it is not allowed by ICANN.
  // So we can safely assume that any email with single letter TLD is invalid.
  const [_, tldName] = e.split("@")[1].split(".");
  if (tldName.length < 2) {
    return "Please use valid domain";
  }
  return "";
};
