import styles from "./styles.module.scss";

import { ResourceTagColors } from "../../constants";
import { ResourceTagProps } from "./types";

const ResourceTag = ({ resource, index = 0, onClick }: ResourceTagProps) => {
  return (
    <div
      className={styles.resourceTag}
      style={{
        backgroundColor: ResourceTagColors[index % 3].color,
        color: ResourceTagColors[index % 3].textColor,
        width: "100%",
      }}
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
    >
      <div className={styles.tag}>
        <div className={styles.name}>{resource.resource_name}</div>
      </div>
    </div>
  );
};

export default ResourceTag;
