import styles from "./styles.module.scss";
import classnames from "classnames";
import { useLayoutEffect, useRef, useState } from "react";
import classNames from "classnames";

const CollapsibleField = ({
  label,
  text,
  defaultLines = 1,
  alignCenter = false,
  textStyle = "text-muted",
}) => {
  const parentRef = useRef();
  const childRef = useRef();

  const [isTruncated, setIsTruncated] = useState(false);
  const [hasExcessLines, setHasExcessLines] = useState(false);
  const [isContractExpanded, setIsContractExpanded] = useState(false);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsTruncated(
        childRef.current?.offsetWidth > parentRef.current?.offsetWidth ||
          childRef.current?.getClientRects().length > defaultLines
      );
      setHasExcessLines(
        childRef.current?.getClientRects().length > defaultLines
      );
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize); // Clean up the event listener
    };
  }, [defaultLines]);

  const parentStyle = {
    WebkitLineClamp: isContractExpanded ? "none" : defaultLines,
  };

  return (
    <div
      className={`d-flex ${alignCenter ? "align-items-center" : "mt-2 mb-2"}`}
    >
      {label && <div className="me-2">{label}:</div>}
      <div
        ref={parentRef}
        className={classnames(styles.parent, textStyle, {
          [styles.contract_collapse]: !isContractExpanded && defaultLines === 1,
          [styles.contract_collapse_clamp]:
            !isContractExpanded && defaultLines !== 1,
        })}
        style={parentStyle}
      >
        <span className={styles.child} ref={childRef}>
          {text}
        </span>
        {isContractExpanded && (
          <span
            id="collapsible-field-less"
            className="ms-2 text-primary fw-semibold cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              setIsContractExpanded(false);
            }}
          >
            Less
          </span>
        )}
      </div>
      {isTruncated && !isContractExpanded && hasExcessLines && (
        <div
          className={classNames(
            "ms-2 text-primary fw-semibold cursor-pointer",
            styles.collapsible_field_more
          )}
          id="collapsible-field-more"
          onClick={(e) => {
            e.stopPropagation();
            setIsContractExpanded(true);
          }}
        >
          More
        </div>
      )}
    </div>
  );
};

export { CollapsibleField };