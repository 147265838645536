import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { GET_SHARE_LIST, GET_SHARE_LIST_FILTERS } from "./queryKey";
import {
  deleteDbtDocsShare,
  getDbtDocsShareList,
  getDbtDocsShareUrl,
} from "../../helpers/backend_helper";
import { getDbtDocsShareListFilters } from "./types";
import { RelativeComponentLoader } from "../../Components/Loader";
import { onSortChange } from "../../helpers/utils";
import { Card, CardBody } from "reactstrap";
import styles from "./styles.module.scss";
import { FilterTagWrapper } from "../../Components/Tags";
import { ListingTable } from "../../Components/Tables";
import { CopyIconButton } from "../../Components/CopyButton";
import { Paginate } from "../../Components/Paginate";
import classNames from "classnames";
import { default as DeleteDBTShare } from "../../assets/icons/delete_dbt_share.svg?react";
import { default as NoDbtColabFound } from "../../assets/images/no_dbt_colab_found.svg?react";
import DialogBox from "../../Components/DialogueBoxes/Delete";
import { Link } from "react-router-dom";

const DbtDocsEmptySharesList = () => (
  <Card>
    <CardBody>
      <div className={styles.no_dbt_configs}>
        <div className="d-flex justify-content-center">
          <div className="d-flex flex-column align-items-center p-4">
            <NoDbtColabFound />
            <h2 className="mt-4 mb-4 text-black">No Colab Found</h2>
            <div className="text-muted">
              No dbt Documentation has been shared yet.
            </div>
          </div>
        </div>
      </div>
    </CardBody>
  </Card>
);

const DbtDocsSharesList = () => {
  const [page, setPage] = useState(0);
  const [sortAttribute, setSortAttribute] = useState("created_at");
  const [nameFilter, setNameFilter] = useState([]);
  const [ownerFilter, setOwnerFilter] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteItem, setDeleteItem] = useState({ shareId: 0, shareName: "" });
  const [shareAppURLs, setShareAppURLs] = useState({});

  const [ownerFilterIdToName, setOwnerFilterIdToName] = useState({});
  const [ownerFilterNameToId, setOwnerFilterNameToId] = useState({});

  const [sort, setSort] = useState({ created_at: "", expired_at: "" });

  const { data: filters, isLoading: isfiltersLoading } = useQuery({
    queryKey: [GET_SHARE_LIST_FILTERS],
    queryFn: () => getDbtDocsShareListFilters(),
    onSuccess: (res) => {
      res.owners.forEach((owner) => {
        setOwnerFilterIdToName((prevIdToName) => {
          return { ...prevIdToName, [owner.id]: owner.email };
        });
        setOwnerFilterNameToId((prevNameToId) => {
          return { ...prevNameToId, [owner.email]: owner.id };
        });
      });
    },
  });

  const getFilteredParams = () => {
    const params = {
      page: page + 1,
      size: 20,
      sort_key: sortAttribute,
      sort_order: sort[sortAttribute] || "desc",
    };
    return params;
  };

  const { data: dbtShares, isLoading } = useQuery({
    queryKey: [
      GET_SHARE_LIST,
      sortAttribute,
      sort[sortAttribute],
      page,
      ...nameFilter,
      ...ownerFilter,
    ],
    queryFn: () => {
      const params = getFilteredParams();
      if (nameFilter.length > 0) params.names = nameFilter;
      if (ownerFilter.length > 0) params.owners = ownerFilter;
      return getDbtDocsShareList(params);
    },
    onSuccess: async (data) => {
      const shareUrls = await Promise.all(
        data.items.map((share) =>
          getDbtDocsShareUrl(share.share_id).then((urlData) => ({
            [share.share_id]: urlData.app_url,
          }))
        )
      );
      setShareAppURLs(Object.assign({}, ...shareUrls));
    },
    staleTime: 0,
  });

  const queryClient = useQueryClient();
  const { mutate: deleteDbtShare } = useMutation({
    mutationFn: (shareId) => deleteDbtDocsShare(shareId),
    onSuccess: (data) => {
      if (data.ok) {
        queryClient.invalidateQueries({ queryKey: [GET_SHARE_LIST] });
      }
    },
  });

  if (isLoading || isfiltersLoading) return <RelativeComponentLoader />;
  if (
    dbtShares.total === 0 &&
    nameFilter.length === 0 &&
    ownerFilter.length === 0
  )
    return <DbtDocsEmptySharesList />;

  const header = [
    {
      id: 1,
      label: "Name of Share",
      filter: {
        filterType: "dropdown",
        value: nameFilter,
        options: filters.names.map((name) => ({
          label: name,
          value: name,
        })),
        onChange: setNameFilter,
      },
    },
    {
      id: 2,
      label: "Owner",
      filter: {
        filterType: "dropdown",
        value: ownerFilter,
        options: filters.owners.map((owner) => ({
          label: owner.email,
          value: owner.id,
        })),
        onChange: setOwnerFilter,
      },
    },
    { id: 3, label: "Conversations" },
    { id: 4, label: "Notes" },
    {
      id: 5,
      label: "Created",
      sort: {
        onChange: onSortChange("created_at", setSort, setSortAttribute),
        value: sort.created_at,
      },
    },
    { id: 6, label: "Action" },
  ];
  return (
    <Card>
      <CardBody>
        <div className="p-1 d-flex flex-column bg-white border-radius-bottom">
          <div className={styles.filter_tags}>
            <FilterTagWrapper
              filters={{
                nameFilter: {
                  filterStr: nameFilter,
                  label: "Name",
                  onclose: (removedFilter) =>
                    setNameFilter(
                      nameFilter.filter((filter) => filter !== removedFilter)
                    ),
                },
                userFilter: {
                  filterStr: ownerFilter.map((id) => [ownerFilterIdToName[id]]),
                  label: "User",
                  onclose: (removedFilter) =>
                    setOwnerFilter(
                      ownerFilter.filter(
                        (filter) =>
                          filter !== ownerFilterNameToId[removedFilter]
                      )
                    ),
                },
              }}
            />
          </div>
          <ListingTable
            header={header}
            items={dbtShares.items}
            onItemClick={() => {}}
            idKey={(item) => item.share_id}
            resetPage={() => setPage(0)}
            templateColumns="4fr 2.5fr 2fr 2fr 2fr 2fr"
            rowRender={(item) => (
              <>
                <div
                  className={classNames(
                    styles.word_break,
                    "text-primary cursor-pointer"
                  )}
                >
                  <Link to={`/collaboration/dbt-docs/${item.share_id}`}>
                    {item.name}
                  </Link>
                </div>
                <div className={styles.word_break}>{item.user.email}</div>
                <div className={styles.word_break}>
                  <div className="d-flex align-items-center gap-sm">
                    <div>
                      {item.open_conversations + item.resolved_conversations}
                    </div>
                    <div
                      className={classNames(
                        styles.open_conversation,
                        "d-flex align-items-center"
                      )}
                    >
                      <div
                        className={styles.text}
                      >{`${item.open_conversations} Open`}</div>
                    </div>
                  </div>
                </div>
                <div className={styles.word_break}>{item.description}</div>
                <div className={styles.word_break}>{item.created_at}</div>
                <div className="d-flex gap-sm align-items-center">
                  <div className="d-flex align-items-center">
                    <div className="text-primary">URL</div>
                    <CopyIconButton
                      value={shareAppURLs[item.share_id]}
                      color="#247EFE"
                    />
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteItem({
                        shareId: item.share_id,
                        shareName: item.name,
                      });
                      setIsOpen(true);
                    }}
                  >
                    <DeleteDBTShare />
                  </div>
                </div>
              </>
            )}
          />
          <Paginate
            itemCount={dbtShares.total}
            page={page}
            pageSize={dbtShares.size}
            numPages={dbtShares.pages}
            onPageClick={setPage}
          />
          {isOpen && (
            <DialogBox
              subMessage={`Are you sure you want to delete ${deleteItem.shareName}?`}
              onClose={() => setIsOpen(false)}
              onDelete={() => {
                deleteDbtShare(deleteItem.shareId);
                setIsOpen(false);
              }}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export default DbtDocsSharesList;
