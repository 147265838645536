import { Button, Input, Label, Form } from "reactstrap";
import { FormInput, FormSubmit, FormCancel } from "./form";
import { useState } from "react";
import { getIconByDatastoreType } from "./DatastoreImage";
import DatastoreTypeCheck from "../../assets/icons/datastore_type_check.svg";
import styles from "./styles.module.scss";
import { FormProgress } from "../../Components/FormProgress";
import classNames from "classnames";
import {
  PostgresConnection,
  SnowflakeConnection,
} from "./DatastoreConnections";
import { useNavigate } from "react-router-dom";

export const DatastoreAdd = () => {
  const navigate = useNavigate();
  const formProgressLabels = ["Type", "Connection"];
  const [currentFormPage, setCurrentFormPage] = useState(1);
  const [typeData, setTypeData] = useState({});

  const handleCancel = () => navigate("/settings/datastores");
  const handleTypeSubmit = (data) => {
    setTypeData(data);
    setCurrentFormPage(2);
  };

  return (
    <div className={styles.datastore_add}>
      <div className={styles.datastore_content}>
        <h4 className={styles.title}>Create A New DataStore</h4>
        <FormProgress
          labels={formProgressLabels}
          currentPage={currentFormPage}
        />
        {currentFormPage === 1 && (
          <DatastoreTypeSelection
            handleSubmit={handleTypeSubmit}
            handleCancel={handleCancel}
          />
        )}
        {currentFormPage === 2 &&
          getConnectionPageByType(typeData, handleCancel)}
      </div>
    </div>
  );
};

const DatastoreTypeSelection = ({ handleSubmit, handleCancel }) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState(null);
  const [selectedButton, setSelectedButton] = useState(-1);
  const handleNameChange = (e) => {
    setName(e.target.value);
  };
  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleDatastoreTypeChange = (e, i, type) => {
    e.preventDefault();
    if (i !== selectedButton) {
      setSelectedButton(i);
      setType(type);
    } else {
      setSelectedButton(-1);
      setType(null);
    }
  };

  const datastoreTypes = [
    // {
    //   name: "BigQuery",
    //   icon: getIconByDatastoreType("bigQuery", styles.datastore_type_icon),
    //   value: "",
    // },
    // {
    //   name: "MySQL",
    //   icon: getIconByDatastoreType("mySQL", styles.datastore_type_icon),
    //   value: "",
    // },
    {
      name: "Postgres",
      icon: getIconByDatastoreType("postgres", styles.datastore_type_icon),
      value: "postgres",
    },
    {
      name: "Snowflake",
      icon: getIconByDatastoreType("snowflake", styles.datastore_type_icon),
      value: "snowflake",
    },
    // {
    //   name: "S3 Bucket",
    //   icon: getIconByDatastoreType("s3", styles.datastore_type_icon),
    //   value: "",
    // },
  ];
  return (
    <div className={styles.form_card}>
      <div className={styles.title}>Type</div>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit({ name: name, type: type, description: description });
        }}
        className="mt-3"
      >
        <div className="mb-3">
          <Label className="form-label" htmlFor="product_name">
            Name
          </Label>
          <FormInput
            id="product_name"
            name="product_name"
            placeholder="example.us-east-1"
            onChange={handleNameChange}
            value={name}
          />
        </div>

        <div className="mb-3">
          <Label className="form-label" htmlFor="description">
            Description
          </Label>
          <FormInput
            id="description"
            name="description"
            type="textarea"
            placeholder=""
            onChange={handleDescriptionChange}
            value={description}
          />
        </div>

        <div className="mb-3">
          <Label className="form-label" htmlFor="datastore_type">
            Select datastore type
          </Label>
          <div className={styles.datastore_type}>
            {datastoreTypes.map((type, i) => {
              const isSelected = i === selectedButton;
              return (
                <Button
                  key={i}
                  onClick={(e) => handleDatastoreTypeChange(e, i, type.value)}
                  className={classNames(styles.button, {
                    [styles.button_selected]: isSelected,
                  })}
                >
                  {type.icon}
                  <span className={styles.name}>{type.name}</span>
                  {isSelected && (
                    <img
                      src={DatastoreTypeCheck}
                      alt="DatastoreTypeCheck"
                      className={styles.type_check}
                    />
                  )}
                </Button>
              );
            })}
          </div>
        </div>

        <div className={styles.form_submit_buttons}>
          <FormCancel onClick={handleCancel} />
          <FormSubmit disabled={!(name && description && type)}>
            Next
          </FormSubmit>
        </div>
      </Form>
    </div>
  );
};

const getConnectionPageByType = (typeData, handleCancel) => {
  switch (typeData?.type) {
    case "snowflake":
      return (
        <SnowflakeConnection typeData={typeData} handleCancel={handleCancel} />
      );
    case "postgres":
      return (
        <PostgresConnection typeData={typeData} handleCancel={handleCancel} />
      );
    default:
      return <div>Coming Soon...</div>;
  }
};
