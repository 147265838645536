import { ChatItemRenderer } from "./ChatItemRenderer";
import { AltimateProfileImage, UserProfileImage } from "@/Components/Profile";
import {
  TermTableSelection,
  RuleSelection,
  RuleInput,
  TermCode,
} from "./ChatItemRenderer";
import classNames from "classnames";
import styles from "./styles.module.scss";
import { useCopilot } from "./CopilotContext";
import { useEffect, useImperativeHandle, useRef } from "react";

const CopilotChat = ({ oldArgs }) => {
  const bottomChatRef = useRef(null);

  const { state, scrollRef } = useCopilot();

  const scrollToBottom = () => {
    setTimeout(() => {
      bottomChatRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 50);
  };

  useImperativeHandle(scrollRef, () => ({
    scrollToBottom,
  }));

  return (
    <div className="d-flex flex-column m-3 gap-sm">
      {state.items.map((item) => (
        <div key={item.id} className={styles.chat_item}>
          <div className="m-2 d-flex flex-column align-items-center">
            {item.isUser ? (
              <UserProfileImage bgColor="#ff754c" />
            ) : (
              <AltimateProfileImage />
            )}
            <div className={classNames("text-muted", styles.chat_time)}>
              {item.time.format("hh:mm a")}
            </div>
          </div>
          <div className={styles.card}>
            {oldArgs && item.type == "term-table-selection" && (
              <TermTableSelection {...oldArgs} />
            )}
            {oldArgs && item.type == "rule-selection" && (
              <RuleSelection {...oldArgs} />
            )}
            {oldArgs && item.type == "term-code" && <TermCode {...oldArgs} />}
            {oldArgs && item.type == "rule-input" && <RuleInput {...oldArgs} />}
            {item.type != "term-table-selection" &&
              item.type != "rule-selection" &&
              item.type != "term-code" &&
              item.type != "rule-input" && (
                <ChatItemRenderer type={item.type} args={item.args} />
              )}
          </div>
        </div>
      ))}
      <div ref={bottomChatRef} />
    </div>
  );
};

export { CopilotChat };
