import { updateTenantInfo } from "@/helpers/apis";
import useAppActions from "@/modules/app/useAppActions";
import useAppContext from "@/modules/app/useAppContext";
import { FormGroup, Tooltip, Input, Label } from "@uicore";
import { useState, ChangeEvent } from "react";

const LearningsToggle = () => {
  const [loading, setLoading] = useState(false);
  const {
    state: { teammatesEnabled },
    dispatch,
  } = useAppContext();
  const { fetchTenantInfo } = useAppActions(dispatch);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      setLoading(true);
      await updateTenantInfo({ teammatesEnabled: e.target.checked });
      await fetchTenantInfo();
    } catch (err) {
      console.error(err);
    }
    setLoading(false);
  };

  return (
    <FormGroup switch>
      <Tooltip content={!teammatesEnabled ? "Opt in" : "Opt out"}>
        <Input
          type="switch"
          role="switch"
          id={`toggle-enable-teammates`}
          checked={teammatesEnabled}
          disabled={loading}
          onChange={(e) => {
            handleChange(e);
          }}
        />
        <Label for={`toggle-enable-teammates`}>
          {loading ? "Saving..." : null}
        </Label>
      </Tooltip>
    </FormGroup>
  );
};

export default LearningsToggle;
