import React, { useState } from "react";
import styles from "./styles.module.scss";
import carousel7 from "../../../assets/images/carousel7.svg";
import carousel8 from "../../../assets/images/carousel8.svg";
import carousel9 from "../../../assets/images/carousel9.svg";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import classnames from "classnames";

const items = [
  {
    id: 1,
    caption: "Prevent Data Issues In Development",
    image: carousel7,
  },
  {
    id: 2,
    caption: "Accelerate dbt Workflows",
    image: carousel8,
  },
  {
    id: 3,
    caption: "Reduce Operational Costs",
    image: carousel9,
  },
];

function MobileAuthCarousel() {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div
      className={classnames(
        "h-20 d-flex flex-column align-items-center justify-content-center",
        styles.carousel_container
      )}
    >
      <Carousel
        showStatus={false}
        showThumbs={false}
        showArrows={false}
        showIndicators={false}
        autoPlay={true}
        infiniteLoop={true}
        stopOnHover={true}
        selectedItem={activeIndex}
        onChange={(i) => setActiveIndex(i)}
        emulateTouch={true}
      >
        {items.map((item) => (
          <div key={item.id}>
            <img
              src={item.image}
              alt={item.caption}
              style={{ width: "90vw", aspectRatio: "1 / 1" }}
            />
            <h4 className="text-white">{item.caption}</h4>
          </div>
        ))}
      </Carousel>
      <ul className={styles.mobile_carousel_indicator}>
        {items.map((item, i) => (
          <li
            key={item.id}
            onClick={() => setActiveIndex(i)}
            className={i === activeIndex ? styles.active : ""}
          />
        ))}
      </ul>
    </div>
  );
}

function AuthCarousel() {
  const isMobile = window.innerWidth <= 768;
  const [activeIndex, setActiveIndex] = useState(0);

  if (isMobile) {
    return <MobileAuthCarousel />;
  }

  return (
    <div
      className={classnames(
        "h-100 d-flex flex-column align-items-center justify-content-center",
        styles.carousel_container
      )}
    >
      <Carousel
        showStatus={false}
        showThumbs={false}
        showArrows={false}
        showIndicators={false}
        autoPlay={true}
        infiniteLoop={true}
        stopOnHover={true}
        selectedItem={activeIndex}
        onChange={(i) => setActiveIndex(i)}
        emulateTouch={true}
      >
        {items.map((item) => (
          <div key={item.id}>
            <img
              src={item.image}
              alt={item.caption}
              style={{ width: "60%", aspectRatio: "1 / 1" }}
            />
            <h2 className="text-white">{item.caption}</h2>
          </div>
        ))}
      </Carousel>
      <ul className={styles.carousel_indicator}>
        {items.map((item, i) => (
          <li
            key={item.id}
            onClick={() => setActiveIndex(i)}
            className={i === activeIndex ? styles.active : ""}
          />
        ))}
      </ul>
    </div>
  );
}

export default AuthCarousel;
