export const ACTIONS = {
  ADD_ITEM: "add",
  SUBMIT: "submit",
  DELETE_ITEM: "delete",
  RESET: "reset",
  OLD_COPILOT: "old_copilot",
  SET_SESSION: "set_session",
  SET_PURPOSE: "set_purpose",
};

export const getInitialState = () => ({ items: [], sessionId: "" });

export const reducer = (state, action) => {
  const { type, payload } = action;
  const patchState = (patch) => ({ ...state, ...patch });
  const { items } = state;
  const n = items.length;
  const patchLastArgs = (patch) => {
    const lastItem = { ...items[n - 1] };
    Object.assign(lastItem.args, patch);
    return patchState({ items: items.slice(0, -1).concat([lastItem]) });
  };
  switch (type) {
    case ACTIONS.ADD_ITEM: {
      return patchState({ items: [...items, payload] });
    }
    case ACTIONS.SUBMIT: {
      if (n === 0) return state;
      return patchLastArgs({ isSubmitted: true });
    }
    case ACTIONS.DELETE_ITEM: {
      if (n === 0) return state;
      return patchState({ items: items.slice(0, -1) });
    }
    case ACTIONS.RESET: {
      return getInitialState();
    }
    case ACTIONS.SET_SESSION: {
      return patchState({ sessionId: payload.session_id });
    }
    case ACTIONS.SET_PURPOSE: {
      if (n === 0) return state;
      return patchLastArgs({ purpose: payload.value });
    }
    case ACTIONS.OLD_COPILOT: {
      return patchState({ items: payload.items });
    }
    default:
      return state;
  }
};

const getMarkdownText = ({ suggested_action, speak, message_to_user }) => {
  let value = (message_to_user || speak) + "\n";
  if (suggested_action.name === "print_tool") {
    return suggested_action.args.text;
  }
  if (suggested_action.name === "query_sql_db") {
    value += "```sql\n";
    value += suggested_action.args.query;
    value += "\n```";
    return value;
  }
  value += "```yaml\n";
  for (const k in suggested_action.args)
    value += `${k}: ${suggested_action.args[k]}\n`;
  value += "```";
  return value;
};

export const processChatItem = ({
  session_id,
  workflow,
  dispatch,
  addNewChat,
  proceedWithChat,
  autoApprove,
  item: {
    output_type,
    output,
    is_finished,
    timestamp,
    pii,
    next_response_streaming,
  },
  defaultValue = "",
  isLive,
  resetWorkflow,
}) => {
  if (is_finished) {
    if (output_type === "block") {
      addNewChat("chat-block", true, timestamp, { data: pii?.message });
      return;
    }
    addNewChat("chat-finished", false, timestamp);
    addNewChat("chat-feedback", true, timestamp, {
      feedback_id: session_id,
      feedback_type: "datapilot",
      resetWorkflow: resetWorkflow,
    });
    return;
  }
  if (output_type === "tool") {
    const { tool_output, output_format, additional_output } = output;
    if (workflow === "doc_gen") {
      addNewChat("dbt-description", true, null, {
        value: tool_output,
      });
    } else {
      if (output_format === "lineage-dropdown") {
        addNewChat("lineage-dropdown", true, null, {
          options: additional_output,
          onSubmit: (selected) => {
            dispatch({ type: ACTIONS.SUBMIT });
            proceedWithChat({ selected });
          },
        });
        return;
      } else if (output_format === "lineage-graph") {
        addNewChat("lineage-graph", true, null, {
          data: additional_output,
        });
      } else {
        addNewChat("tool-output", false, timestamp, {
          value: tool_output,
          pii: pii,
        });
      }
    }
    if (isLive) proceedWithChat({});
    return;
  }
  if (output_type === "agent") {
    const { suggested_action, plan, text, reasoning, criticism, speak } =
      output;

    if (next_response_streaming && isLive) {
      addNewChat("chat-streaming", false, null, {
        session_id: session_id,
        onSubmit: () => {
          dispatch({ type: ACTIONS.SUBMIT });
          proceedWithChat({ approval: true, feedback: "" });
        },
      });
      return;
    }

    if (suggested_action.name === "human") {
      addNewChat("human-input", true, timestamp, {
        label: suggested_action.args.question,
        defaultValue,
        isSubmitted: !isLive,
        onSubmit: (feedback) => {
          dispatch({ type: ACTIONS.SUBMIT });
          proceedWithChat({ approval: true, feedback });
        },
      });
      return;
    }
    const value = getMarkdownText(output);
    const rejectText =
      output.suggested_action.name === "print_tool"
        ? "Continue with Suggestions"
        : "Reject with Suggestions";
    const isFinish = suggested_action.name === "finish";

    if (!suggested_action.user_requires_approval) {
      addNewChat("chat-action", false, timestamp, {
        workflow,
        value,
        plan,
        stepDetail: text,
        stepPii: pii,
        reasoning,
        criticism,
        speak,
        isSubmitted: true,
      });
      if (isLive) proceedWithChat({});
      return;
    }
    const markdownValue = isFinish
      ? suggested_action.args.response || value
      : value;
    addNewChat("chat-action", true, timestamp, {
      workflow,
      value: markdownValue,
      plan,
      stepDetail: text,
      stepPii: pii,
      reasoning,
      criticism,
      speak,
      isFinish,
      isSubmitted: !isLive || autoApprove,
      rejectText,
      onSubmit: (v) => {
        dispatch({ type: ACTIONS.SUBMIT });
        proceedWithChat(v);
      },
    });
    if (isLive && autoApprove)
      proceedWithChat({ approval: true, feedback: "" });
  }
};
