import { api } from "@/helpers/api_helper";
import { ApiHelper } from "@components";
import { useState, useEffect } from "react";

const useComponentsHelper = () => {
  const [isComponentsApiInitialized, setIsComponentsApiInitialized] =
    useState(false);

  useEffect(() => {
    console.info("updating components api helper");
    // This overrides the components library api methods
    // @ts-ignore
    ApiHelper.get = async (url: string, data: any, _request: any) => {
      return api.get(url, data);
    };
    // @ts-ignore
    ApiHelper.post = async (url: string, data: any, request: RequestInit = {}) => {
      // TODO updat api helper to handle other request methods
      if (request.method === "DELETE") {
        return api.delete(url, data);
      }
      if (request.method === "PUT") {
        return api.update(url, data);
      }
      return api.post(url, data);
    };
    setIsComponentsApiInitialized(true);
  }, []);

  return {
    isComponentsApiInitialized,
  };
};

export default useComponentsHelper;
