import {
  Alert,
  Badge,
  Card,
  CardBody,
  CardTitle,
  Chart,
  ChartTypes,
  formatNumber,
  IconButton,
  NumberFormatter,
  Stack,
} from "@uicore";
import { Cell, Pie, TooltipProps } from "recharts";
import classes from "../summary.module.scss";
import { OverallSavingsResponse } from "../Context/types";
import classNames from "classnames";
import { useAppState } from "@/modules/app/useAppContext";
import { InfoIcon } from "@/assets/icons";

const COLORS = ["#0088FE", "#00C49F"];

// TODO: refactor this
const CustomTooltip = ({
  active,
  payload,
  data,
}: TooltipProps<any, any> & {
  data: OverallSavingsResponse;
}) => {
  const { currency } = useAppState();

  if (active && payload && payload.length) {
    return (
      <div
        className={classNames(
          "custom-tooltip bg-white px-2 py-1 border-4 rounded"
        )}
      >
        <Badge tooltip="" color="orange" className="mb-2">
          Total Savings:
          <NumberFormatter
            options={{ currency }}
            value={data.total_savings}
          />
        </Badge>
        <p>
          <span
            className="px-2 rounded me-1"
            style={{ background: COLORS[0] }}
          />
          Autonomous:
          {formatNumber(data.total_autonomous_savings, {
            currency,
          })}
        </p>
        <p>
          <span
            className="px-2 rounded me-1"
            style={{ background: COLORS[1] }}
          />
          Assisted:
          {formatNumber(data.total_assisted_savings, {
            currency,
          })}
        </p>
      </div>
    );
  }

  return null;
};

const SavingsInFuture = ({ data }: { data?: OverallSavingsResponse }) => {
  const graphData = data
    ? [
      {
        name: "total_autonomous_savings",
        label: "Autonomous",
        value: data.total_autonomous_savings,
      },
      {
        name: "total_assisted_savings",
        label: "Assisted",
        value: data.total_assisted_savings,
      },
    ]
    : [];

  if (!data) {
    return <Alert>No data available</Alert>;
  }

  return (
    <Stack>
      <Card style={{ height: 400, padding: "1rem", flex: 1 }}>
        <CardTitle className="d-flex justify-content-between align-items-center">Money Savings Per Month
          <IconButton className="p-0" title="Projected money savings per month">
            <InfoIcon />
          </IconButton>
        </CardTitle>
        <CardBody>
          <Chart
            type={ChartTypes.PieChart}
            data={graphData}
            xAxisDataKey="name"
            title="Money Savings"
            hideFullscreenButton
            tooltipProps={{
              content: (props: TooltipProps<any, any>) => (
                <CustomTooltip {...props} data={data} />
              ),
            }}
          >
            {({ }) => (
              <Pie dataKey="value" data={graphData} fill="#247EFE">
                {graphData.map((_entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    name={_entry.label}
                  />
                ))}
              </Pie>
            )}
          </Chart>
        </CardBody>
      </Card>
      <Card className={classes.donutChart} style={{ padding: "1rem", flex: 1 }}>
        <CardTitle className="d-flex justify-content-between align-items-center">Time Savings Per Month<IconButton className="p-0" title="Projected time savings per month">
          <InfoIcon />
        </IconButton></CardTitle>
        <CardBody>
          <div>{data.time_saved}</div>
        </CardBody>
      </Card>
    </Stack>
  );
};

export default SavingsInFuture;
