import { Button } from "reactstrap";
import { default as QIB } from "@/assets/images/query_insights_background.svg?react";
import { PropsWithChildren, useState } from "react";
import classNames from "classnames";
import styles from "../styles.module.scss";
import { generateStream } from "../../../helpers/api_helper";
import { TypeWriterLoader } from "../../../Components/Loader";
import { BotIcon, ExplanationDownIcon } from "@assets/icons";
import { CodeMarkdown } from "@/uiCore";

export const QueryExplanation = ({
  queryId,
  queryHash,
}: PropsWithChildren<{ queryId?: string; queryHash?: string }>) => {
  const [explanation, setExplanation] = useState("");
  const [explanationError, setExplanationError] = useState("");
  const [showExplanation, setShowExplanation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleQueryExplanation = async () => {
    setIsLoading(true);

    let url = "";
    if (queryId) {
      url = "/copilot/quickchat/query-explain/v2/" + queryId;
    } else if (queryHash) {
      url = "/copilot/quickchat/query-group-explain/v2/" + queryHash;
    } else {
      console.error("No queryId or queryHash provided");
      setIsLoading(false);
      setExplanationError("No queryId or queryHash provided");
      return;
    }

    const onProgress = (chunk: string) => {
      if (!explanation && chunk.length > 0) {
        setIsLoading(false);
        setShowExplanation(true);
      }
      setExplanation((prev) => prev + chunk);
    };

    await generateStream(url, onProgress).catch((error: Error) => {
      setExplanationError(error.message);
      setIsLoading(false);
    });
  };

  const handleExplanationChange = () => {
    setShowExplanation((prev) => !prev);
  };

  return (
    <div className="d-flex flex-column">
      <div
        className={classNames(styles.query_explanation, {
          [styles.clicked]: showExplanation || isLoading,
        })}
      >
        <div className={styles.title}>
          DataPilot can try to summarize the query for you
        </div>
        <QIB className={styles.query_insights_background} />
        {explanation ? (
          <Button
            className={styles.arrow_button}
            outline
            onClick={handleExplanationChange}
          >
            {showExplanation ? (
              <ExplanationDownIcon />
            ) : (
              <ExplanationDownIcon className={styles.rotate_up} />
            )}
          </Button>
        ) : (
          <Button
            className={styles.button}
            outline
            onClick={handleQueryExplanation}
          >
            <BotIcon />
            <div className={styles.text}>
              {isLoading ? "Generating..." : "Summarize Query"}
            </div>
          </Button>
        )}
      </div>
      {isLoading && (
        <TypeWriterLoader
          label="Query summarization may take a few seconds"
          loadingTexts={[
            "Looking at some metadata",
            "Checking query history",
            "Analyzing data profile",
            "Traversing data lineage",
            "Adding some AI magic",
          ]}
        />
      )}
      {showExplanation && (
        <div className={styles.explanation_popup}>
          <div className={styles.explanation_text}>
            <CodeMarkdown
              value={explanation ? explanation : explanationError}
            />
          </div>
        </div>
      )}
    </div>
  );
};
