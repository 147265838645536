import styles from "./styles.module.scss";
import classnames from "classnames";
import { IconContext } from "react-icons";
import dayjs from "dayjs";
import { useMutation, useQueryClient } from "react-query";
// import { postSnoozeNotification } from "../../helpers/backend_helper";
import { GET_ALERT_NOTIFICATIONS } from "../../services/filters";
import { ListingTable } from "../../Components/Tables";

const NotificationList = ({
  notifications,
  sort,
  timeFilter,
  onNotificationClick,
}) => {
  // const queryClient = useQueryClient();
  // const { mutate } = useMutation(postSnoozeNotification, {
  //   onSuccess: () => {
  //     queryClient.invalidateQueries({ queryKey: [GET_ALERT_NOTIFICATIONS] });
  //   },
  // });
  return (
    <ListingTable
      header={[
        {
          id: 1,
          label: "ID",
          sort: {
            onChange: sort.onID,
          },
        },
        {
          id: 2,
          label: "Check ID",
          sort: {
            onChange: sort.onCheckID,
          },
        },
        {
          id: 3,
          label: "Name",
        },
        {
          id: 4,
          label: "Timestamp",
          filter: {
            filterType: "dropdown_single",
            id: "timestamp-dropdown",
            value: timeFilter.value,
            options: timeFilter.options,
            onChange: timeFilter.onChange,
          },
        },
        {
          id: 5,
          label: "Message",
        },
        {
          id: 6,
          label: "Value",
        },
      ]}
      onItemClick={() => {}}
      idKey={(item) => item.id}
      items={notifications}
      resetPage={() => {}}
      templateColumns="1fr 1fr 2fr 1fr 3fr 1fr"
      rowRender={(item) => {
        const snoozeId = "snooze-" + item.id;
        const timestamp = dayjs(item?.created_at);
        return (
          <>
            <div className="text-primary">{item.id}</div>
            <div className="text-primary">{item?.alert_id}</div>
            <div className={styles.break_word}>{item?.alert?.name}</div>
            <div>{dayjs(timestamp).format("DD-MMM-YY HH:mm")}</div>
            <div
              className={classnames(
                styles.blue,
                styles.scope,
                styles.break_word
              )}
            >
              {item?.message}
            </div>
            <div className="fw-bold">{item.value}</div>
          </>
        );
      }}
    />
  );
};

export { NotificationList };
