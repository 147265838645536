import { Filter } from "./types";

export const flattenFilters = (filters: Record<string, Filter>): Filter[] => {
  if (!filters) {
    return [];
  }
  return Object.values(filters).flatMap((filter) =>
    Array.isArray(filter.filterStr)
      ? filter.filterStr.map((filterStr) => ({
          filterStr,
          label: filter.label,
          onclose: filter.onclose
            ? () => filter.onclose?.(filterStr)
            : undefined,
          searchKey: filter.searchKey,
        }))
      : filter.filterStr
      ? [
          {
            filterStr: filter.filterStr,
            label: filter.label,
            onclose: () =>
              filter.onclose ? filter.onclose(filter.filterStr) : undefined,
            searchKey: filter.searchKey,
          },
        ]
      : []
  );
};

export const getFilterStringByMode = (value: string, mode: string) => {
  return value && `${mode === "lt" ? "<" : ">"} ${value}`;
};
