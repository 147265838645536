import { Link, useParams, useSearchParams } from "react-router-dom";
import { Alert, Badge, Stack } from "@uicore";
import {
  Learnings,
  TaskLabels,
  TeamMatesConfig,
} from "@components";
import useComponentsHelper from "@/modules/app/useComponentsHelper";
import { Button } from "reactstrap";
import { ArrowLeftIcon } from "@/assets/icons";
import classes from "./teammate.module.scss";

const Teammate = () => {
  const { isComponentsApiInitialized } = useComponentsHelper();

  const { teammate } = useParams();
  const [searchParams] = useSearchParams();
  const learning = searchParams.get("learning");

  if (!isComponentsApiInitialized) {
    return null;
  }

  const teammateConfig = TeamMatesConfig.find((t) => t.key === teammate);
  if (!teammateConfig) {
    return <Alert color="danger">Unable to find teammate details.</Alert>;
  }

  return (
    <div className={classes.teammate}>
      <div>
        <Button
          className={classes.breadcrumb}
          color="link"
          tag={Link}
          to="/teammates"
        >
          <ArrowLeftIcon />
          Back to Teammates
        </Button>
      </div>
      <Stack className="my-2">
        <img src={teammateConfig.avatar} alt={`${teammate} image`} />
        <div className="flex-1">
          <Stack className="align-items-center">
            <h2 className="mb-0">{teammateConfig.name}</h2>{" "}
            {teammateConfig.availability.map((availability) => (
              <Badge
                color="success"
                key={availability}
                tooltip={`Available in ${availability}`}
                className={availability}
              >
                Available in {availability}
              </Badge>
            ))}
          </Stack>
          <p>{teammateConfig.description}</p>
        </div>
      </Stack>
      <div className="mt-4">
        <h5>Learnings</h5>
        <p>
          These are learnings the AI teammate has gathered based on the coaching
          provided by users.
        </p>
        <Learnings
          filters={{ taskLabel: teammate as keyof typeof TaskLabels }}
          learning={learning}
        />
      </div>
    </div>
  );
};

export default Teammate;
