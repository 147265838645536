import { ArrowDownIcon, ArrowUpIcon } from "@/assets/icons";
import { Button, Stack } from "@/uiCore";
import { ReactNode, useState } from "react";

interface Props {
  title: ReactNode;
  howToText: string;
  howToContent: ReactNode;
}
const AccordionTitle = ({ title, howToText, howToContent }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const handleClick = () => {
    setExpanded((prev) => !prev);
  };
  return (
    <div>
      <Stack className="justify-content-between">
        {title}
        <Button
          style={{ textWrap: "nowrap" }}
          color="link"
          onClick={handleClick}
        >
          {howToText} {expanded ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </Button>
      </Stack>
      {expanded ? (
        <div>
          <h5>{howToText}</h5>
          {howToContent}
        </div>
      ) : null}
    </div>
  );
};

export default AccordionTitle;
