import { createContext, Suspense, useState } from "react";
import { Outlet } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { Loader } from "../../Components/Loader";
import { Header } from "./Header";
import { Sidebar } from "./Sidebar";
import styles from "./styles.module.scss";
import { IntercomChatBoot } from "../Intercom/IntercomChat";
import { INTERCOM_APP_ID, ENV } from "../../config";
import { getTenant } from "../../helpers/tenant";


export const SearchContext = createContext({ search: "", setSearch: () => {} });

const Home = () => {
  const [search, setSearch] = useState("");
  const tenant = getTenant();
  return (
    <SearchContext.Provider value={{ search, setSearch }}>
      <IntercomProvider appId={INTERCOM_APP_ID} autoBoot={false}>
        <IntercomChatBoot tenant={tenant} env={ENV} />
          <div className={styles.layout}>
            <Sidebar />
            <div className={styles.content}>
              <Header />
              <div id="altimate_main" className={styles.main_content}>
                <Outlet />
              </div>
            </div>
          </div>
      </IntercomProvider>
    </SearchContext.Provider>
  );
};

export { Home };
