import { useQuery } from "react-query";
import { getTenant } from "../helpers/tenant";
import { GET_USER_LIST } from "./constants";
import { getUserList } from "../helpers/backend_helper";

const useTenantUsers = () => {
  const tenant = getTenant();
  const { data: users, isLoading: isUsersLoading } = useQuery({
    queryKey: GET_USER_LIST,
    queryFn: () => getUserList(tenant),
    cacheTime: 300000,
  });
  return { users, isUsersLoading };
};

export { useTenantUsers };
