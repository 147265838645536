import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Input, Label, Form } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FormAlert,
  FormInput,
  FormSubmit,
  FormSuccess,
} from "./components/form";
import { BaseContainer, Header } from "./components/base";
import { isFormFieldError, validateEmail } from "./helper";
import { useMutation } from "react-query";
import { getEmailInstanceList } from "../../helpers/backend_helper";
import { default as TickGradientIcon } from "../../assets/icons/tick_gradient.svg?react";
import classNames from "classnames";
import styles from "./components/styles.module.scss";
import { InstanceList } from "./components/InstanceList";

const EMAIL_INPUT_ID = "email-input";

const FineLine = () => (
  <div
    style={{ width: "100%", height: "100%", border: "1px #D5DBE4 solid" }}
  ></div>
);

const OrLine = () => (
  <div className="d-flex align-items-center gap-xl">
    <FineLine />
    <div className="text-muted">or</div>
    <FineLine />
  </div>
);

const FindInstance = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [instanceList, setInstanceList] = useState([]);
  const [showInstances, setShowInstances] = useState(false);

  const [activeElement, setActiveElement] = useState(null);
  const onFocusChange = (e) => {
    setActiveElement(e.target);
  };

  const { mutate: companyList } = useMutation(getEmailInstanceList, {
    onSuccess: (response) => {
      if (response) {
        if (response.companies === null) {
          setEmailError("This email is not associated with any instance.");
        } else if (response.companies.length === 1) {
          window.location.href = `${response.companies[0].company_url}?user=${email}`;
        } else {
          setInstanceList(
            response.companies.map((company) => ({
              url: `${company.company_url}?user=${email}`,
              name: company.company.name,
              creationTime: company.company.existence_time,
            }))
          );
          setShowInstances(true);
          setEmail("");
        }
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const error = validateEmail(email);
    if (error) {
      setEmailError(error);
    } else {
      setEmailError("");
      companyList(email);
    }
  };

  const isMobile = window.innerWidth <= 768;

  return (
    <BaseContainer
      footer={
        !showInstances && (
          <div>
            <span className="text-muted fw-400">
              Don’t have an instance? Please sign up{" "}
            </span>
            <Link
              to="https://app.myaltimate.com/register"
              className="text-primary"
            >
              here
            </Link>
            .
          </div>
        )
      }
    >
      <div className={classNames({ [styles.mobile_login]: isMobile })}>
        <Header>
          {showInstances ? "Select your Instance" : "Enter your Email"}
        </Header>
        {showInstances ? (
          <InstanceList instances={instanceList} />
        ) : (
          <div className={styles.sub_header}>
            You need to login via the instance-specific URL. Get redirected to
            the correct URL by entering the email used for the sign-up.
          </div>
        )}
        {showInstances && <OrLine />}
        <Form
          onSubmit={handleSubmit}
          className={`${isMobile ? "w-100 mt-3" : "mt-4"}`}
        >
          <div className="mb-3">
            <Label className="form-label" htmlFor="email">
              {showInstances ? "Try with another email" : "Email"}
            </Label>
            <div className="d-flex flex-column">
              <div
                className={classNames(styles.tenant_form_input, {
                  [styles.in_focus]: activeElement?.id === EMAIL_INPUT_ID,
                  [styles.is_invalid]: emailError !== "",
                })}
              >
                <FormInput
                  id={EMAIL_INPUT_ID}
                  name="email"
                  type="email"
                  placeholder="Enter email"
                  onChange={(event) => setEmail(event.target.value)}
                  onFocus={onFocusChange}
                  onBlur={(e) =>
                    setEmailError(validateEmail(e.target.value.toLowerCase()))
                  }
                  value={email.toLowerCase()}
                />
              </div>
              {emailError !== "" && (
                <div
                  className={classNames(styles.form_feedback, {
                    [styles.is_error]: emailError !== "",
                  })}
                >
                  <span>{emailError}</span>
                </div>
              )}
            </div>
          </div>

          <div className="mt-4">
            <FormSubmit disabled={!email || emailError}>Continue</FormSubmit>
          </div>
        </Form>
      </div>
    </BaseContainer>
  );
};

export { FindInstance };
