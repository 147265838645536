import { useQuery } from "react-query";
import { ComponentLoader } from "@/Components/Loader";
import { getAllChatSessions } from "../../helpers/backend_helper";
import styles from "./styles.module.scss";
import { useMemo, useState } from "react";
import { DebouncedInput } from "../../Components/Form";
import { WORKFLOW_LABELS } from "./constants";
import classNames from "classnames";

const GET_CHAT_SESSIONS = "GET-CHAT-SESSIONS";

const History = ({ sessionId, onItemClick }) => {
  const { data: chatSessions = [], isLoading } = useQuery({
    queryKey: [GET_CHAT_SESSIONS, sessionId],
    queryFn: getAllChatSessions,
  });
  const [search, setSearch] = useState("");
  const filteredChats = useMemo(() => {
    const _search = search.toLowerCase();
    return chatSessions.filter((c) => c.purpose.includes(_search));
  }, [chatSessions, search]);
  if (isLoading) return <ComponentLoader />;
  if (chatSessions.length === 0) return null;

  return (
    <div className={styles.history}>
      <div className="fs-3 fw-500 mb-2">History</div>
      <DebouncedInput size="sm" placeholder="Search" onChange={setSearch} />
      <div className="mb-3" />
      <div className="d-flex flex-column gap-sm">
        {filteredChats.map((item) => (
          <div
            key={item.session_id}
            className={classNames(styles.history_item, {
              [styles.selected]: item.session_id === sessionId,
            })}
            onClick={(e) => {
              e.stopPropagation();
              onItemClick(item);
            }}
          >
            <div className="fs-5 fw-500">
              {WORKFLOW_LABELS[item.workflow_type]}
            </div>
            <div className={styles.purpose_text}>{item.purpose}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export { History };
