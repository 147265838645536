import { useMutation, useQueryClient } from "react-query";
import {
  cancelPlanDowngrade,
  createCheckoutSession,
  downgradePlan,
  downgradePlanFeedback,
  postCheckoutSession,
} from "../../helpers/backend_helper";
import {
  GET_ALL_SUBSCRIPTION_PLANS,
  GET_CREDIT_USAGE,
  GET_EXECUTION_HISTORY,
} from "./queryKey";
import styles from "./styles.module.scss";
import classNames from "classnames";

import { default as SuccessIcon } from "../../assets/icons/circle_check_enabled.svg?react";
import { default as DowngradeIcon } from "../../assets/icons/circle_check_enabled_red.svg?react";
import { default as WarningIcon } from "../../assets/icons/warning_1.svg?react";
import { default as FailIcon } from "../../assets/icons/failure.svg?react";
import { default as InfoIcon } from "../../assets/icons/info.svg?react";
import { default as InfoIconYellow } from "../../assets/icons/info_yellow.svg?react";
import { default as FAQ } from "../../assets/icons/faq.svg?react";
import { Button, Input } from "reactstrap";
import { useEffect, useState } from "react";
import { CustomTag } from "../../Components/Tags";
import { Tooltip } from "react-tooltip";

export const useCheckoutSessionMutation = (
  setShowSuccessMessage,
  currentTenure,
  setPlanChangeState
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ sessionCheckoutID, cancelled }) =>
      postCheckoutSession({
        checkout_session_id: sessionCheckoutID,
        cancelled: cancelled,
      }),
    onSuccess: (data) => {
      if (data.ok) {
        queryClient.invalidateQueries({ queryKey: [GET_CREDIT_USAGE] });
        queryClient.invalidateQueries({
          queryKey: [GET_EXECUTION_HISTORY],
          exact: false,
        });
        setShowSuccessMessage({
          message: "Your Subscription has been successfully upgraded!",
          type: "success",
        });
        queryClient.invalidateQueries({
          queryKey: [GET_ALL_SUBSCRIPTION_PLANS, currentTenure],
        });
        setPlanChangeState("");
      }
    },
  });

  return mutation;
};

export const useDowngradePlanMutation = (
  setShowSuccessMessage,
  currentTenure,
  setPlanChangeState
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ creditType, tenure }) =>
      downgradePlan({ plan_type: creditType, tenure: tenure }),
    onSuccess: (data) => {
      if (data.ok) {
        queryClient.invalidateQueries({ queryKey: [GET_CREDIT_USAGE] });
        queryClient.invalidateQueries({
          queryKey: [GET_EXECUTION_HISTORY],
          exact: false,
        });
        setShowSuccessMessage({
          message:
            "Your Subscription has been successfully downgraded, and it will be applied at the end of the existing Subscription",
          type: "downgrade",
        });
        queryClient.invalidateQueries({
          queryKey: [GET_ALL_SUBSCRIPTION_PLANS, currentTenure],
        });
        setPlanChangeState("");
      }
    },
  });

  return mutation;
};

export const useCancelPlanDowngradeMutation = (
  setShowSuccessMessage,
  currentTenure,
  setPlanChangeState
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: () => cancelPlanDowngrade(),
    onSuccess: (data) => {
      if (data.ok) {
        queryClient.invalidateQueries({ queryKey: [GET_CREDIT_USAGE] });
        queryClient.invalidateQueries({
          queryKey: [GET_EXECUTION_HISTORY],
          exact: false,
        });
        setShowSuccessMessage({
          message:
            "Your Subscription downgrade has been successfully cancelled, and your credits will refresh as per the existing plan.",
          type: "success",
        });
        queryClient.invalidateQueries({
          queryKey: [GET_ALL_SUBSCRIPTION_PLANS, currentTenure],
        });
        setPlanChangeState("");
      }
    },
  });

  return mutation;
};

export const useCheckoutSessionRedirectMutation = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: ({ creditType, tenure }) =>
      createCheckoutSession({ plan_type: creditType, tenure: tenure }),
    onSuccess: (res) => {
      if (res?.checkout_url) {
        window.location.href = res.checkout_url;
      }
    },
  });

  return mutation;
};

const colors = {
  blue: "#247efe",
  orange: "#ff754c",
  green: "#01cd8c",
  red: "#ee140f",
  white: "#ffffff",
  grey: "#eaedf2",
  grey1: "#7a899e",
  yellow: "#ffce73",
};

const typeColorMap = {
  success: { closeIcon: colors.green, icon: <SuccessIcon /> },
  downgrade: { closeIcon: colors.red, icon: <DowngradeIcon /> },
  error: { closeIcon: colors.red, icon: <FailIcon /> },
  warning: { closeIcon: colors.orange, icon: <WarningIcon /> },
  info: { closeIcon: colors.blue, icon: <InfoIcon /> },
  info_1: { closeIcon: colors.yellow, icon: <InfoIconYellow /> },
};

const CloseButton = ({ color, handleCloseMessage }) => (
  <div className="w-full h-full cursor-pointer" onClick={handleCloseMessage}>
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="x-close">
        <path
          id="Icon"
          d="M12 4L4 12M4 4L12 12"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  </div>
);

export const InfoTooltip = ({
  handleSuccessMessageClose,
  message,
  type,
  pageMessage = true,
  showCloseButton = true,
}) => (
  <div
    className={classNames(styles.info_tooltip, {
      [styles.success]: type === "success",
      [styles.error]: type === "error",
      [styles.warning]: type === "warning",
      [styles.info]: type === "info",
      [styles.info_1]: type === "info_1",
      [styles.downgrade]: type === "downgrade",
      [styles.not_page]: !pageMessage,
    })}
  >
    <div className={styles.message_icon}>
      {typeColorMap[type].icon}
      <div className={styles.message}>{message}</div>
    </div>
    {showCloseButton && (
      <CloseButton
        color={typeColorMap[type].closeIcon}
        handleCloseMessage={handleSuccessMessageClose}
      />
    )}
  </div>
);

export const DowngradeForm = ({ setDonwgradable }) => {
  const [input, setInput] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  // create a mutate for downgradePlanFeedback

  const mutation = useMutation(downgradePlanFeedback, {
    onSuccess: () => {
      setIsSubmitted(true);
      setDonwgradable(true);
    },
  });

  const handleSubmit = () => {
    mutation.mutate({ feedback: input });
  };

  return (
    <div className={styles.downgrade_form}>
      <div className={styles.q_n_a}>
        <div className={styles.q}>
          Why are you downgrading?{" "}
          <div className={styles.status}>(Required)</div>
        </div>
        <div className={styles.ans}>
          <Input
            placeholder="Write the reason here"
            onChange={(e) => setInput(e.target.value)}
          />
        </div>
      </div>
      <div className={styles.submit_sec}>
        <div className={styles.warning}>
          <FailIcon />
          <div className={styles.msg}>
            Once you have submitted the input, you can downgrade your plan.
          </div>
        </div>
        <Button
          className={styles.submit_button}
          onClick={handleSubmit}
          disabled={input === "" || isSubmitted}
        >
          Submit
        </Button>
      </div>
    </div>
  );
};

export const CheckFAQCollapsible = ({ numOfSecondsActive }) => {
  const [showFAQ, setShowFAQ] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowFAQ(false);
    }, numOfSecondsActive * 1000); // milliseconds
    return () => {
      clearTimeout(timer);
    };
  }, [numOfSecondsActive]);
  return (
    <div
      className={styles.tags}
      onClick={() =>
        window.open("https://docs.myaltimate.com/arch/pricingfaq", "_blank")
      }
    >
      <FAQ />
      {showFAQ && <div className={styles.faq}>FAQ</div>}
    </div>
  );
};

export const CheckFAQ = () => (
  <div
    className={styles.tags}
    onClick={() =>
      window.open("https://docs.myaltimate.com/arch/pricingfaq", "_blank")
    }
  >
    <FAQ />
    <div className={styles.faq}>FAQ</div>
  </div>
);

export const EditionTag = ({ editionName }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <>
      <div
        data-tooltip-id="edition-price-tooltip"
        data-tooltip-variant=""
        data-tooltip-place="bottom"
        className={styles.tags}
        onClick={() => setShowTooltip(true)}
      >
        <div className={styles.edition}>
          Edition: <span className={styles.name}>{editionName}</span>
        </div>
        <InfoIcon />
      </div>
      <Tooltip id="edition-price-tooltip" openOnClick clickable>
        {showTooltip && (
          <div className={styles.edition_tooltip}>
            <div className={styles.top_header}>
              <div className={styles.header}>What is {editionName} plan?</div>
              <CloseButton
                className={styles.close_info}
                color={colors.grey1}
                handleCloseMessage={() => setShowTooltip(false)}
              />
            </div>
            <div className={styles.plan_info}>
              In the {editionName} plan we provide you some dedicated features
              and support.
            </div>
            <div
              className={styles.link_line}
              onClick={(e) => {
                e.stopPropagation();
                window.open("https://www.altimate.ai/pricing", "_blank");
              }}
            >
              Learn more about our {editionName} plan
            </div>
          </div>
        )}
      </Tooltip>
    </>
  );
};

export const getGradientLineColor = (
  plans,
  currentPlan,
  initialPlanName,
  planChangeState
) => {
  let gradient;
  const initialPlanIndex = plans.findIndex(
    (plan) => plan.plan_name === initialPlanName
  );
  const currentPlanIndex = plans.findIndex(
    (plan) => plan.plan_name === currentPlan
  );
  const initialPlanPosition = (initialPlanIndex / (plans.length - 1)) * 98;
  const currentPlanPosition = (currentPlanIndex / (plans.length - 1)) * 98;
  const firstPos = Math.min(currentPlanPosition, initialPlanPosition);
  const secondPos = Math.max(currentPlanPosition, initialPlanPosition);

  if (currentPlan === initialPlanName) {
    gradient = `linear-gradient(to right, 
      ${colors.white} 0%, ${colors.white} 1%,
      ${colors.blue} 1%, ${colors.blue} ${firstPos}%, 
      ${colors.grey} ${firstPos}%, ${colors.grey} 98%, 
      ${colors.white} 98%, ${colors.white} 100%
    )`;
  } else {
    const gradientColor =
      planChangeState === "upgrade" ? colors.blue : colors.red;
    gradient = `linear-gradient(to right, 
      ${colors.white} 0%, ${colors.white} 1%,
      ${colors.blue} 1%, ${colors.blue} ${firstPos}%, 
      ${gradientColor} ${firstPos}%, ${gradientColor} ${secondPos}%, 
      ${colors.grey} ${secondPos}%, ${colors.grey} 98%, 
      ${colors.white} 98%, ${colors.white} 100%
    )`;
  }
  return gradient;
};
